import React from "react";
import BaseSelect from "../baseForm/BaseSelect";
import { BaseApi } from "../../../store/apis/BaseApi";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseSelectBox.md)
 */
export default function BaseSelectBox({
  endpoint,
  control,
  errors,
  name,
  label,
  size,
  validationProps,
  query = {},
  skip = false,
  defaultValue,
  ...rest
}) {
  const { useQuery } = BaseApi.endpoints[endpoint];
  const { data, isLoading } = useQuery(query, { skip });

  return (
    <BaseSelect
      name={name}
      id={name}
      label={label}
      defaultValue={defaultValue}
      size={size}
      control={control}
      errors={errors}
      loading={isLoading}
      options={data || []}
      validationProps={validationProps}
      {...rest}
    />
  );
}
