import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const {
  UTILITIES,
  DINING_AREAS,
  TEXTURES,
  RESIDENTS,
  SEASONS,
  MENUS,
  REPORTS,
  TRAY_CARD_REPORTS,
} = API_ENDPOINTS;

//Print Report for mealcard
export const PrintReportAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // define an endpoint for getting Print Report of mealCard
    //Selects (dropsDowns) for Print Report apis
    getMealSelect: builder.query({
      query: ({ includeAll }) =>
        `/${UTILITIES}/MealTypes?includeAll=${includeAll}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      keepUnusedDataFor: 60,
    }),
    residentDiningareaSelect: builder.query({
      query: ({ facilityId }) =>
        `/${DINING_AREAS}/${DINING_AREAS}?facilityId=${facilityId}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      keepUnusedDataFor: 60,
    }),
    texturesSelect: builder.query({
      query: () => `/${TEXTURES}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      keepUnusedDataFor: 60,
    }),
    residentsSelect: builder.query({
      query: ({ facilityId }) =>
        `/${RESIDENTS}?FacilityId=${facilityId}&filterNoTrayCards=false&filterNPO=false`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: `${res.lastName}, ${res.firstName}`,
          };
        }),
      keepUnusedDataFor: 60,
    }),
    residentsSelectPrintTrayCardTrue: builder.query({
      query: ({ facilityId }) =>
        `/${RESIDENTS}?FacilityId=${facilityId}&filterNoTrayCards=false&filterNPO=false`,
      transformResponse: (response) =>
        response?.data
          ?.filter((resident) => resident.printTrayCard === true)
          .map((res) => {
            return {
              id: res.id,
              label: `${res.lastName}, ${res.firstName}`,
            };
          }),
      keepUnusedDataFor: 60,
    }),
    seasonSelect: builder.query({
      query: ({ showArchived }) =>
        `/${SEASONS}?skip=0&take=100&forTableSide=false&showArchived=${
          showArchived || false
        }`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
            defaultSeason: res.defaultSeason,
          };
        }),
      keepUnusedDataFor: 60,
    }),
    menuSelect: builder.query({
      query: ({ seasonId }) =>
        `/${MENUS}/GetMenusBySeason?seasonId=${seasonId ?? 0}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      keepUnusedDataFor: 5,
    }),
    menuDaySelect: builder.query({
      query: ({ menuId }) => `/${MENUS}/GetMenuDays?menuId=${menuId ?? 0}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.day,
            label: res.day?.toString(),
          };
        }),
      keepUnusedDataFor: 60,
    }),
    // define an endpoint for getting addPrintReports of mealCard

    addPrintReports: builder.mutation({
      query: (data) => ({
        url: `/${REPORTS}/GetTrayCardReports`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: TRAY_CARD_REPORTS, id: result?.id },
      ],
    }),
    //HIGHLOGHTS APIS For MEALCARD
    GetHighlight: builder.query({
      query: () => `/${TRAY_CARD_REPORTS}/SetupHighlights`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: TRAY_CARD_REPORTS, id: response.id }, TRAY_CARD_REPORTS]
          : [TRAY_CARD_REPORTS],
    }),
    addHighlight: builder.mutation({
      query: (data) => ({
        url: `/${TRAY_CARD_REPORTS}/SetupHighlights`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: TRAY_CARD_REPORTS, id: result?.id },
      ],
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetMealSelectQuery,
  useResidentDiningareaSelectQuery,
  useTexturesSelectQuery,
  useResidentsSelectQuery,
  useResidentsSelectPrintTrayCardTrueQuery,
  useSeasonSelectQuery,
  useMenuSelectQuery,
  useMenuDaySelectQuery,
  useAddPrintReportsMutation,
  useGetHighlightQuery,
  useAddHighlightMutation,
} = PrintReportAPIs;
