import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import CancelIcon from "@mui/icons-material/Cancel";

const ITEM_HEIGHT = 64;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseMultiSelect.md)
 */
export function BaseMultiSelect({
  control,
  setValue,
  validationProps,
  options = [],
  errors,
  ...rest
}) {
  let OptionsArray = options;
  const handleChipDelete = (chipValue, selectedValues) => {
    const updatedValues = selectedValues.filter((value) => value !== chipValue);
    setValue(rest.name, updatedValues);
  };
  const renderChip = (selected, selectedValues) => (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
      {selected?.map((value) => (
        <Chip
          key={value}
          label={options.find((x) => x.id === value)?.label}
          onDelete={() => handleChipDelete(value, selectedValues)}
          deleteIcon={
            <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
          }
        />
      ))}
    </Box>
  );
  const renderOptions = (selectedValues) =>
    OptionsArray.filter((item) => !selectedValues.includes(item.id)).map(
      (value) => (
        <MenuItem disabled={value.disabled} value={value.id} key={value.id}>
          {value.label}
        </MenuItem>
      )
    );

  return (
    <FormControl fullWidth>
      {control ? (
        <Controller
          control={control}
          name={rest?.name}
          rules={{ ...validationProps }}
          defaultValue={[]}
          render={({ field }) => {
            const selectedValues = field.value || [];
            return (
              <FormControl>
                <InputLabel id="input-label">{rest?.label}</InputLabel>
                <Select
                  labelId="input-label"
                  {...field}
                  multiple
                  size={rest?.size}
                  defaultValue={[]}
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label={rest?.label}
                      error={!!errors?.[rest?.name]}
                    />
                  }
                  renderValue={(selected) =>
                    renderChip(selected, selectedValues)
                  }
                  MenuProps={MenuProps}>
                  {renderOptions(selectedValues)}
                </Select>
              </FormControl>
            );
          }}
        />
      ) : (
        <Select
          {...rest}
          multiple
          input={
            <OutlinedInput
              id="select-multiple-chip"
              label="Chip"
              error={!!errors?.[rest?.name]}
            />
          }
          renderValue={(selected) => renderChip(selected, selected)}>
          {renderOptions()}
        </Select>
      )}
      <FormHelperText error={!!errors?.[rest?.name]}>
        {errors?.[rest?.name]?.message ?? ""}
      </FormHelperText>
    </FormControl>
  );
}
