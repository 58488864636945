import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  season: null,
  menu: null,
};

export const seasonAndMenuSlice = createSlice({
  name: "seasonAndMenu",
  initialState,
  reducers: {
    setSeasonAndMenu: (state, action) => {
      state.season = action.payload.season;
      state.menu = action.payload.menu;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSeasonAndMenu } = seasonAndMenuSlice.actions;

export default seasonAndMenuSlice.reducer;
