import { BaseApi } from "./BaseApi";
import { replaceKeys } from "../../utils/ReplaceKeys";
import { API_ENDPOINTS } from "../store.constants";

const { BEVERAGES, UNITS } = API_ENDPOINTS;

export const BeverageAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // define an endpoint for getting Beverage
    getBeverage: builder.query({
      query: ({ searchTerm, skip, take }) =>
        `/${BEVERAGES}?searchText=${searchTerm}&skip=${skip}&take=${take}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.items.map(({ id }) => ({
                type: BEVERAGES,
                id,
              })),
              BEVERAGES,
            ]
          : [BEVERAGES];
      },
    }),
    getBeverageSelectBox: builder.query({
      query: (searchTerm) =>
        `/${BEVERAGES}?searchText=${searchTerm || ""}&skip=0&take=1000`,
      transformResponse: (response) =>
        response?.data?.items.map((item) => ({
          id: item.id,
          label: item.name,
          description: `in "${item?.defaultUnitName}"${
            item?.isSupplement ? " (Supplement)" : ""
          }`,
        })),
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: BEVERAGES,
                id,
              })),
              BEVERAGES,
            ]
          : [BEVERAGES];
      },
    }),
    getCorporateBeverageSelectBox: builder.query({
      query: (facilityId) =>
        `/${BEVERAGES}/CorporateGroup?facilityId=${facilityId}`,
      transformResponse: (response) =>
        response?.data?.map((item) => ({
          id: item.id,
          label: item.name,
          description: `in "${item?.defaultUnitName}"${
            item?.isSupplement ? " (Supplement)" : ""
          }`,
        })),
    }),
    getBeverageById: builder.query({
      query: (beverageId) => `/${BEVERAGES}/${beverageId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: BEVERAGES, id: response.id }, BEVERAGES]
          : [BEVERAGES],
    }),
    deleteBeverage: builder.mutation({
      query: (id) => ({
        url: `/${BEVERAGES}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: BEVERAGES, id: arg.id },
      ],
    }),
    addBeverage: builder.mutation({
      query: (data) => ({
        url: `/${BEVERAGES}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: BEVERAGES, id: result?.id }],
    }),
    updateBeverage: builder.mutation({
      query: (data) => ({
        url: `/${BEVERAGES}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: BEVERAGES, id: result?.id }],
    }),
    getBeverageUnit: builder.query({
      query: () => `/${UNITS}/GetByType?isBeverageUnit=true`,
      transformResponse: (response) => replaceKeys(response?.data),
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: UNITS,
                id,
              })),
              UNITS,
            ]
          : [UNITS];
      },
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetBeverageQuery,
  useGetBeverageByIdQuery,
  useDeleteBeverageMutation,
  useAddBeverageMutation,
  useUpdateBeverageMutation,
  useGetBeverageUnitQuery,
  useGetBeverageSelectBoxQuery,
  useGetCorporateBeverageSelectBoxQuery,
} = BeverageAPIs;
