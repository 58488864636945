import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isChangeTrue: false,
};

export const isChangeTrueSlice = createSlice({
  name: "changeTrue",
  initialState,
  reducers: {
    setIsChangeTrue: (state, action) => {
      state.isChangeTrue = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsChangeTrue } = isChangeTrueSlice.actions;

export default isChangeTrueSlice.reducer;
