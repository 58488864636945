import { REQUIRED_ERROR } from "utils/errorMessages";
import {
  adaptEquipRosterSort,
  diningAreaId,
  groupByWing,
  mealId,
  snackSort2,
  snackTimeId,
  supplementRosterSort,
  useExcelFormat,
  wing,
} from "../forms.constants";

export const AdaptiveEquipmentRosterFields = [
  adaptEquipRosterSort,
  useExcelFormat,
];

export const AdaptiveEquipmentRosterDefaults = {
  adaptEquipRosterSort: 1,
  useExcelFormat: false,
};

export const AllergyRosterFields = [useExcelFormat];

export const AllergyRosterDefaults = {
  useExcelFormat: false,
};

export const BeverageRosterFields = [
  { ...mealId, validationProps: {} },
  { ...diningAreaId, validationProps: {} },
  wing,
  useExcelFormat,
];

export const BeverageRosterDefaults = {
  mealId: -1,
  diningAreaId: -1,
  wing: "",
  useExcelFormat: false,
};


export const MealExtrasRosterFields = [
  { ...mealId, validationProps: {} },
  { ...diningAreaId, validationProps: {} },
  wing,
  useExcelFormat,
];

export const MealExtrasRosterDefaults = {
  mealId: -1,
  diningAreaId: -1,
  wing: "",
  useExcelFormat: false,
};

export const DislikeRosterFields = [useExcelFormat];

export const DislikeRosterDefaults = {
  useExcelFormat: false,
};

export const FluidRestrictionRosterFields = [useExcelFormat];

export const FluidRestrictionRosterDefaults = {
  useExcelFormat: false,
};

export const SnackRosterFields = [
  snackTimeId,
  groupByWing,
  {
    ...snackSort2,
    validationProps: { required: REQUIRED_ERROR("Sort Order") },
  },
  useExcelFormat,
];

export const SnackRosterDefaults = {
  snackTimeId: null,
  groupByWing: false,
  snackSort2: 1,
  useExcelFormat: false,
};

export const SupplementRosterFields = [
  mealId,
  {
    ...supplementRosterSort,
    validationProps: { required: REQUIRED_ERROR("Sort Order") },
  },
  useExcelFormat,
];

export const SupplementRosterDefaults = {
  mealId: "",
  supplementRosterSort: "1",
  useExcelFormat: false,
};

export const TubeFeedingRosterFields = [useExcelFormat];

export const TubeFeedingRosterDefaults = {
  useExcelFormat: false,
};
