import { REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "../../shared/generatedForm/GeneratedForm";

// constant values
export const MealIds = [
  { id: 1, label: "Breakfast" },
  { id: 2, label: "Lunch" },
  { id: 3, label: "Dinner" },
];

export const TexturesFilter = [
  { id: 1, label: "All Mealcards" },
  { id: 3, label: "Pureed Only" },
  { id: 2, label: "Dental Soft (Mech Soft) Only" },
  { id: 4, label: "All Mealcards except Pureed/Dental Soft" },
];
export const SortOrder = [
  {
    id: 5,
    label: "Dining Area/Table/Seat/Room #",
  },
  {
    id: 3,
    label: "Dining Area/Resident",
  },
  {
    id: 4,
    label: "Dining Area/Room #",
  },
  {
    id: 1,
    label: "Resident",
  },
  {
    id: 2,
    label: "Wing/Room #",
  },
  {
    id: 6,
    label: "Wing/Resident",
  },
  {
    id: 7,
    label: "Room #",
  },
  {
    id: 8,
    label: "Day/Resident",
  },
];

export const WeekDays = [
  {
    id: "1",
    label: "Sunday",
  },
  {
    id: "2",
    label: "Monday",
  },
  {
    id: "3",
    label: "Tuesday",
  },
  {
    id: "4",
    label: "Wednesday",
  },
  {
    id: "5",
    label: "Thursday",
  },
  {
    id: "6",
    label: "Friday",
  },
  {
    id: "7",
    label: "Saturday",
  },
];

export const Months = [
  {
    id: 0,
    label: "All",
  },
  {
    id: 1,
    label: "January",
  },
  {
    id: 2,
    label: "February",
  },
  {
    id: 3,
    label: "March",
  },
  {
    id: 4,
    label: "April",
  },
  {
    id: 5,
    label: "May",
  },
  {
    id: 6,
    label: "June",
  },
  {
    id: 7,
    label: "July",
  },
  {
    id: 8,
    label: "August",
  },
  {
    id: 9,
    label: "September",
  },
  {
    id: 10,
    label: "October",
  },
  {
    id: 11,
    label: "November",
  },
  {
    id: 12,
    label: "December",
  },
];

export const RadioGroup = [
  { value: 1, label: "Breakfast" },
  { value: 2, label: "Lunch" },
  { value: 3, label: "Dinner" },
];

export const SnackSort2 = [
  { id: 1, label: "Resident" },
  { id: 2, label: "Room Number" },
  { id: 3, label: "Snack Time" },
];

export const ResidentCare = [
  {
    id: "Assessments Due",
    label: "Assessments Due",
  },
  {
    id: "Assessments Due (< 30 days)",
    label: "Assessments Due (< 30 days)",
  },
  {
    id: "Birthday List",
    label: "Birthday List",
  },
  {
    id: "Care Plans Due (< 30 days)",
    label: "Care Plans Due (< 30 days)",
  },
  {
    id: "Care Plans Due",
    label: "Care Plans Due",
  },
  {
    id: "New Admissions (Last 4 days)",
    label: "New Admissions (Last 4 days)",
  },
  {
    id: "Resident Profile",
    label: "Resident Profile",
  },
  {
    id: "Resident Weight (Name)",
    label: "Resident Weight (Name)",
  },
  {
    id: "Resident Weight (B/W/R)",
    label: "Resident Weight (B/W/R)",
  },
  {
    id: "Residents by Doctor",
    label: "Residents by Doctor",
  },
  {
    id: "Weekly Weight Report",
    label: "Weekly Weight Report",
  },
];

// Dropdowns
export const diningAreaList = {
  props: {
    name: "diningAreaList",
    label: "Dining Area",
  },
  validationProps: {},
  size: 12,
  endpoint: "residentDiningareaSelect",
  type: FORM_TYPES.MultiSelectAPI,
};

export const trayCardSort = {
  props: {
    name: "trayCardSort",
    label: "Sort Order",
  },
  validationProps: {},
  size: 12,
  options: SortOrder,
  type: FORM_TYPES.Select,
};

export const textureFilter = {
  props: {
    name: "textureFilter",
    label: "Texture Filter",
  },
  validationProps: {},
  size: 12,
  options: TexturesFilter,
  type: FORM_TYPES.Select,
};

export const mealId = {
  props: {
    name: "mealId",
    label: "Meal",
  },
  validationProps: {
    required: REQUIRED_ERROR("Meal"),
  },
  size: 12,
  options: MealIds,
  type: FORM_TYPES.Select,
};

export const residentIds = {
  props: {
    name: "residentIds",
    label: "Select Residents",
  },
  validationProps: {},
  size: 12,
  endpoint: "residentsSelectPrintTrayCardTrue",
  type: FORM_TYPES.MultiSelectSearchApi,
};

export const showAllResidentIds = {
  props: {
    name: "residentIds",
    label: "Select Residents",
  },
  validationProps: {},
  size: 12,
  endpoint: "residentsSelect",
  type: FORM_TYPES.MultiSelectSearchApi,
};

export const seasonId = {
  props: {
    name: "seasonId",
    label: "Season",
  },
  validationProps: {},
  size: 12,
  endpoint: "seasonSelect",
  type: FORM_TYPES.SelectAPI,
};

export const menuId = {
  props: {
    name: "menuId",
    label: "Menu",
  },
  validationProps: {},
  size: 12,
  endpoint: "menuSelect",
  type: FORM_TYPES.SelectAPI,
};

export const startDay = {
  props: {
    name: "startMenuDay",
    label: "Start Day",
  },
  validationProps: {},
  size: 12,
  endpoint: "menuDaySelect",
  type: FORM_TYPES.SelectAPI,
};

export const endDay = {
  props: {
    name: "endMenuDay",
    label: "End Day",
  },
  validationProps: {},
  size: 12,
  endpoint: "menuDaySelect",
  type: FORM_TYPES.SelectAPI,
};

export const snackTimeId = {
  props: {
    name: "snackTimeId",
    label: "Snack Time",
  },
  validationProps: {},
  size: 12,
  endpoint: "getMealSelect",
  type: FORM_TYPES.SelectAPI,
};

export const snackDiningAreaId = {
  props: {
    name: "snackDiningAreaId",
    label: "Dining Area",
  },
  size: 12,
  endpoint: "residentDiningareaSelect",
  type: FORM_TYPES.SelectAPI,
};

export const residentId = {
  props: {
    name: "residentId",
    label: "Select Resident",
  },
  validationProps: {},
  size: 12,
  endpoint: "residentsSelectPrintTrayCardTrue",
  type: FORM_TYPES.SelectAPI,
};

export const showAllResidentId = {
  props: {
    name: "residentId",
    label: "Select Resident",
  },
  validationProps: {},
  size: 12,
  endpoint: "residentsSelect",
  type: FORM_TYPES.SelectAPI,
};

export const snackSort = {
  props: {
    name: "snackSort",
    label: "Sort Order",
  },
  validationProps: {},
  size: 12,
  options: SortOrder,
  type: FORM_TYPES.Select,
};

export const snackSort2 = {
  props: {
    name: "snackSort2",
    label: "Sort Order",
  },
  validationProps: {},
  size: 12,
  options: SnackSort2,
  type: FORM_TYPES.Select,
};

export const weekDay = {
  props: {
    name: "weekDay",
    label: "Day of Week",
  },
  validationProps: {},
  size: 12,
  options: WeekDays,
  type: FORM_TYPES.Select,
};

export const diningAreaId = {
  props: {
    name: "diningAreaId",
    label: "Dining Area",
  },
  validationProps: {
    required: REQUIRED_ERROR("Dining Area"),
  },
  size: 12,
  endpoint: "residentDiningareaSelect",
  type: FORM_TYPES.SelectAPI,
};

export const adaptEquipRosterSort = {
  props: {
    name: "adaptEquipRosterSort",
    label: "Sort Order",
  },
  validationProps: {},
  size: 12,
  options: SortOrder,
  type: FORM_TYPES.Select,
};

export const supplementRosterSort = {
  props: {
    name: "supplementRosterSort",
    label: "Sort Order",
  },
  validationProps: {},
  size: 12,
  options: SortOrder,
  type: FORM_TYPES.Select,
};

export const snackTimeIdResidentCare = {
  props: {
    name: "snackTimeId",
    label: "Snack Time",
  },
  validationProps: {},
  size: 12,
  options: ResidentCare,
  type: FORM_TYPES.Select,
};

export const month = {
  props: {
    name: "month",
    label: "Birthday List",
  },
  validationProps: {},
  size: 12,
  options: Months,
  type: FORM_TYPES.Select,
};

// Checkboxes
export const includeNameAtTop = {
  props: {
    name: "includeNameAtTop",
    label: "Show Resident Name at Top of MealCard",
  },
  validationProps: {},
  size: 12,
  type: FORM_TYPES.Checkbox,
};

export const includeNameAtBottom = {
  props: {
    name: "includeNameAtBottom",
    label: "Show Resident Name at Bottom of MealCard",
  },
  validationProps: {},
  size: 12,
  type: FORM_TYPES.Checkbox,
};

export const includeBeverageAmounts = {
  props: {
    name: "includeBeverageAmounts",
    label: "Include Beverage Amounts",
  },
  size: 12,
  type: FORM_TYPES.Checkbox,
};

export const includeMealExtrasAmounts = {
  props: {
    name: "includeMealExtrasAmounts",
    label: "Include Meal Add-Ons Amounts",
  },
  size: 12,
  type: FORM_TYPES.Checkbox,
};

export const enableHighlights = {
  props: {
    name: "enableHighlights",
    label: "Enable MealCard Highlighting",
  },
  size: 12,
  type: FORM_TYPES.Checkbox,
};

export const includeAlwaysAvailable = {
  props: {
    name: "includeAlwaysAvailable",
    label: "Include Other Menu Options",
  },
  validationProps: {},
  size: 12,
  type: FORM_TYPES.Checkbox,
};

export const includeExtraSpace = {
  props: {
    name: "includeExtraSpace",
    label: "Include blank line between Menu Items",
  },
  validationProps: {},
  size: 12,
  type: FORM_TYPES.Checkbox,
};

export const includePortionSize = {
  props: {
    name: "includePortionSize",
    label: "Include Portion Size on Menu Items",
  },
  validationProps: {},
  size: 12,
  type: FORM_TYPES.Checkbox,
};

export const includeCarbohydrate = {
  props: {
    name: "includeCarbohydrate",
    label: "Include Carbohydrate on Menu Items",
  },
  validationProps: {},
  size: 12,
  type: FORM_TYPES.Checkbox,
};

export const showIDDSIAbbreviations = {
  props: {
    name: "showIDDSIAbbreviations",
    label: "Show IDDSI Abbreviations",
  },
  validationProps: {},
  size: 12,
  type: FORM_TYPES.Checkbox,
};

export const boldLiquidConsistency = {
  props: {
    name: "boldLiquidConsistency",
    label: "Bold Liquid Consistency",
  },
  validationProps: {},
  size: 12,
  type: FORM_TYPES.Checkbox,
};

export const includeAdaptativeEquipment = {
  props: {
    name: "includeAdaptativeEquipment",
    label: "Include Adaptative Equipment Labels",
  },
  validationProps: {},
  size: 12,
  type: FORM_TYPES.Checkbox,
};

export const useExcelFormat = {
  props: {
    name: "useExcelFormat",
    label: "Output as Excel",
  },
  validationProps: {},
  size: 12,
  type: FORM_TYPES.Checkbox,
};
export const weightAlertField = {
  props: {
    name: "IncludeWeightAlertsOnly",
    label: "Include Weight Alerts Only",
  },
  validationProps: {},
  size: 12,
  type: FORM_TYPES.Checkbox,
};

export const includeNotes = {
  props: {
    name: "includeNotes",
    label: "Include Notes",
  },
  validationProps: {},
  size: 12,
  type: FORM_TYPES.Checkbox,
};

export const excludeRegularLiquids = {
  props: {
    name: "excludeRegularLiquids",
    label: "Exclude Regular Liquids",
  },
  validationProps: {},
  size: 12,
  type: FORM_TYPES.Checkbox,
};

export const groupByWing = {
  props: {
    name: "groupByWing",
    label: "Group by Wing",
  },
  validationProps: {},
  size: 12,
  type: FORM_TYPES.Checkbox,
};

export const includeWeightAlertsOnly = {
  props: {
    name: "includeWeightAlertsOnly",
    label: "Include Weight Alerts Only",
  },
  validationProps: {},
  size: 12,
  type: FORM_TYPES.Checkbox,
};

// TextBoxes
export const comment = {
  props: {
    name: "comment",
    label: "Comment",
  },
  validationProps: {},
  size: 12,
};

export const startDate = {
  props: {
    name: "startDate",
    label: "Start Date",
    placeholder: "Start Date",
  },
  validationProps: {},
  type: FORM_TYPES.DatePicker,
  size: 12,
};

export const menuStartDate = {
  props: {
    name: "menuStartDate",
    label: "Start Date",
    placeholder: "Start Date",
  },
  validationProps: {},
  type: FORM_TYPES.DatePicker,
  size: 12,
};

export const wing = {
  props: {
    name: "wing",
    label: "Wing",
    placeholder: "Wing",
  },
  validationProps: {},
  size: 12,
};

export const labelsToSkip = {
  props: {
    name: "labelsToSkip",
    label: "Labels to Skip",
    placeholder: "Labels to Skip",
    type: "number",
  },
  validationProps: {},
  size: 12,
};

// Typography
export const PrintAllTimes = {
  props: {
    text: 'Leave blank/unselected to "Print All" times.',
  },
  size: 12,
  type: FORM_TYPES.Text,
};

export const DateOrCommentPrint = {
  props: {
    text: "(Enter a date or comment to print on all Meal Cards)",
    fontWeight: 600,
  },
  size: 12,
  type: FORM_TYPES.Text,
};

export const AllDiningAreas = {
  props: {
    text: "Leave Blank/Unselected for all dining Areas.",
    name: "All DiningAreas",
  },
  size: 12,
  type: FORM_TYPES.Text,
};

export const AllResidents = {
  props: {
    text: "Leave blank/unselected for all residents.",
    name: "All Residents",
  },
  size: 12,
  type: FORM_TYPES.Text,
};

// RadioButtons
export const type = {
  props: {
    name: "type",
  },
  validationProps: {},
  size: 12,
  type: FORM_TYPES.Radio,
  options: RadioGroup,
};
