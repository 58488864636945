import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { LIQUID_CONSISTENCIES } = API_ENDPOINTS;

export const LiquidConsistencyAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // define an endpoint for getting LiquidConsistency
    getThickenedLiquid: builder.query({
      query: (searchTerm) =>
        `/${LIQUID_CONSISTENCIES}?searchText=${searchTerm}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: LIQUID_CONSISTENCIES, id })),
              LIQUID_CONSISTENCIES,
            ]
          : [LIQUID_CONSISTENCIES];
      },
    }),
    getThickenedLiquidSelectBox: builder.query({
      query: (searchTerm) =>
        `/${LIQUID_CONSISTENCIES}?searchText=${searchTerm || " "}`,
      transformResponse: (response) =>
        response?.data.map((x) => ({ label: x.name, id: x.id })),
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: LIQUID_CONSISTENCIES, id })),
              LIQUID_CONSISTENCIES,
            ]
          : [LIQUID_CONSISTENCIES];
      },
    }),
    getThickenedLiquidById: builder.query({
      query: (ThickenedLiquidId) =>
        `/${LIQUID_CONSISTENCIES}/${ThickenedLiquidId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              { type: LIQUID_CONSISTENCIES, id: response.id },
              LIQUID_CONSISTENCIES,
            ]
          : [LIQUID_CONSISTENCIES],
    }),
    deleteThickenedLiquid: builder.mutation({
      query: (id) => ({
        url: `/${LIQUID_CONSISTENCIES}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: LIQUID_CONSISTENCIES, id: arg.id },
      ],
    }),
    addThickenedLiquid: builder.mutation({
      query: (data) => ({
        url: `/${LIQUID_CONSISTENCIES}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: LIQUID_CONSISTENCIES, id: result?.id },
      ],
    }),
    updateThickenedLiquid: builder.mutation({
      query: (data) => ({
        url: `/${LIQUID_CONSISTENCIES}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: LIQUID_CONSISTENCIES, id: result?.id },
      ],
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetThickenedLiquidQuery,
  useGetThickenedLiquidSelectBoxQuery,
  useGetThickenedLiquidByIdQuery,
  useDeleteThickenedLiquidMutation,
  useAddThickenedLiquidMutation,
  useUpdateThickenedLiquidMutation,
} = LiquidConsistencyAPIs;
