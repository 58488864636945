export const CommunityVisitsReports = [
  // {
  //   Name: "Back",
  //   Link: "/admin/communities/communitydetail/visits",
  //   // IsActive: false,
  // },
  {
    Name: "Visit Details",
    Link: "/admin/communities/visitsDetail/detail",
    IsActive: false,
  },
  {
    Name: "Visits Reports",
    Link: "/admin/communities/visitsDetail/report",
    IsActive: false,
  },
  {
    Name: "Report Log",
    Link: "/admin/communities/visitsDetail/logReport",
    IsActive: false,
  },
];
