import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { CORPORATE_GROUP_BEVERAGES } = API_ENDPOINTS;

export const CorporateBeverageAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // define an endpoint for getting CorporateBeverage
    getCorporateBeverage: builder.query({
      query: ({ corporateGroupId, searchText }) =>
        `/${CORPORATE_GROUP_BEVERAGES}?corporateGroupId=${corporateGroupId}&searchText=${searchText}&skip=0&take=1000`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: CORPORATE_GROUP_BEVERAGES,
                id,
              })),
              CORPORATE_GROUP_BEVERAGES,
            ]
          : [CORPORATE_GROUP_BEVERAGES];
      },
    }),
    getCorporateBeverageById: builder.query({
      query: (beverageId) => `/${CORPORATE_GROUP_BEVERAGES}/${beverageId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              {
                type: CORPORATE_GROUP_BEVERAGES,
                id: response.id,
              },
              CORPORATE_GROUP_BEVERAGES,
            ]
          : [CORPORATE_GROUP_BEVERAGES],
    }),
    deleteCorporateBeverage: builder.mutation({
      query: (id) => ({
        url: `/${CORPORATE_GROUP_BEVERAGES}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: CORPORATE_GROUP_BEVERAGES, id: arg.id },
      ],
    }),
    addCorporateBeverage: builder.mutation({
      query: (data) => ({
        url: `/${CORPORATE_GROUP_BEVERAGES}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: CORPORATE_GROUP_BEVERAGES, id: result?.id },
      ],
    }),
    updateCorporateBeverage: builder.mutation({
      query: (data) => ({
        url: `/${CORPORATE_GROUP_BEVERAGES}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: CORPORATE_GROUP_BEVERAGES, id: result?.id },
      ],
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetCorporateBeverageQuery,
  useGetCorporateBeverageByIdQuery,
  useDeleteCorporateBeverageMutation,
  useAddCorporateBeverageMutation,
  useUpdateCorporateBeverageMutation,
} = CorporateBeverageAPIs;
