export const MealCardSetupElements = [
  {
    Name: "Adaptive Equipment",
    Link: "/admin/mealcard/adaptiveequipment",
    IsActive: false,
  },
  {
    Name: "Allergies",
    Link: "/admin/mealcard/allergies",
    IsActive: false,
  },
  // {
  //   Name: "Beverages",
  //   Link: "/admin/mealcard/beverages",
  //   IsActive: false,
  // },
  {
    Name: "Food Types",
    Link: "/admin/mealcard/foodtypes",
    IsActive: false,
  },
  {
    Name: "Liquid Consistensies",
    Link: "/admin/mealcard/liquidconsistency",
    IsActive: false,
  },
  {
    Name: "Meal Add-Ons",
    Link: "/admin/mealcard/MealExtras",
    IsActive: false,
  },
];
