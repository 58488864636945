import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { BaseButton } from "components/shared/BaseButton";

export default function ConfirmModal({ open, onClose, agree }) {
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" fontWeight="bold">
          Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <font color="red">
          You are choosing to ignore an allergy documented within this
           resident&apos;s MealCard profile. Please note that by choosing this option,
            you are accepting the responsibility to monitor this allergy. If needed, you can add additional communication in the
            &quot;special note&quot; section to ensure resident safety.
            </font>
            <br/><br/>
       
          It is the sole responsibility of the user to ensure all products ordered,
           prepared, and served comply with the resident&apos;s needs regarding any potentially harmful allergens. 
           Maintaining Allergy records associated Automations and Integrations with third party sites such as 
           electronic medical records are the responsibility of the user.
     
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <BaseButton text="I Disagree" colors="warning" onClick={onClose} />
          <BaseButton text="I Accept" onClick={agree} />
        </DialogActions>
      </Dialog>
    </>
  );
}
