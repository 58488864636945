import {
  endingDay,
  formatTypeId,
  includeAfternoonSnacks,
  includeEveningSnacks,
  includeIngredientsByWeight,
  includeMealBreakfast,
  includeMealLunch,
  includeMealOtherMenuOption,
  includeMealSupper,
  includeMorningSnacks,
  includeNutrientAnalysis,
  includeRecipeImage,
  includeVendorItems,
  mealSnackText,
  menuFootNote,
  offset6,
  qty1,
  qty2,
  qty3,
  regularQuantitiesText,
  startingDay,
} from "../form.constants";

export const RecipesForms = {
  Category: true,
  DayForm: [
    startingDay,
    offset6,
    endingDay,
    offset6,
    includeVendorItems,
    offset6,
    includeIngredientsByWeight,
    offset6,
    includeNutrientAnalysis,
    offset6,
    includeRecipeImage,
    offset6,
  ],
  Census: [
    regularQuantitiesText,
    qty1,
    qty2,
    qty3,
    menuFootNote,
  ],
  Diet: true,
  SnackMeal: [
    mealSnackText,
    includeMealBreakfast,
    offset6,
    includeMealLunch,
    offset6,
    includeMealSupper,
    offset6,
    includeMorningSnacks,
    offset6,
    includeAfternoonSnacks,
    offset6,
    includeEveningSnacks,
    offset6,
    includeMealOtherMenuOption,
    offset6,
  ],
  Report: [formatTypeId],
};
