import { BaseApi } from "./BaseApi";
import { replaceKeys } from "../../utils/ReplaceKeys";
import { API_ENDPOINTS } from "../store.constants";

const { MEAL_EXTRAS, UNITS } = API_ENDPOINTS;

export const MealExtrasApis = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // define an endpoint for getting MealExtra
    getMealExtra: builder.query({
      query: ({ searchTerm, skip, take }) =>
        `/${MEAL_EXTRAS}?searchText=${searchTerm}&skip=${skip}&take=${take}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.items.map(({ id }) => ({
                type: MEAL_EXTRAS,
                id,
              })),
              MEAL_EXTRAS,
            ]
          : [MEAL_EXTRAS];
      },
    }),
    getMealExtraSelectBox: builder.query({
      query: (searchTerm) =>
        `/${MEAL_EXTRAS}?searchText=${searchTerm || ""}&skip=0&take=1000`,
      transformResponse: (response) =>
        response?.data?.items.map((item) => ({
          id: item.id,
          recipeId: item.recipeId,
          recipeName: item.recipeName,
          label: item.name,
          description: `${
            item?.isSupplement ? " (Supplement)" : item?.isFood ? " (Food)" : item?.isBeverage ? " (Beverage)" : "Unknown"
          }`,
        })),
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: MEAL_EXTRAS,
                id,
              })),
              MEAL_EXTRAS,
            ]
          : [MEAL_EXTRAS];
      },
    }),
    getFacilityMealExtraSelectBox: builder.query({
      query: (facilityId) =>
        `/${MEAL_EXTRAS}/FacilityMealExtras?facilityId=${facilityId}`,
      transformResponse: (response) =>
        response?.data?.map((item) => ({
          id: item.id,
          unit: item.defaultUnitId,
          unitName: item.defaultUnitName,
          label: item.name,
          description: `${
            item?.isSupplement ? " (Supplement)" : item?.isFood ? " (Food)" : item?.isBeverage ? " (Beverage)" : "Unknown"
          }`,
        })),
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: MEAL_EXTRAS,
                id,
              })),
              MEAL_EXTRAS,
            ]
          : [MEAL_EXTRAS];
      },
      keepUnusedDataFor: 0,
    }),
    getMealExtraById: builder.query({
      query: (mealExtraId) => `/${MEAL_EXTRAS}/${mealExtraId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: MEAL_EXTRAS, id: response.id }, MEAL_EXTRAS]
          : [MEAL_EXTRAS],
    }),
    deleteMealExtra: builder.mutation({
      query: (id) => ({
        url: `/${MEAL_EXTRAS}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: MEAL_EXTRAS, id: arg.id },
      ],
    }),
    addMealExtra: builder.mutation({
      query: (data) => ({
        url: `/${MEAL_EXTRAS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: MEAL_EXTRAS, id: result?.id }],
    }),
    updateMealExtra: builder.mutation({
      query: (data) => ({
        url: `/${MEAL_EXTRAS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: MEAL_EXTRAS, id: result?.id }],
    }),
    getMealExtraUnit: builder.query({
      query: () => `/${UNITS}/GetByType?isMealExtraUnit=true`,
      transformResponse: (response) => replaceKeys(response?.data),
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: UNITS,
                id,
              })),
              UNITS,
            ]
          : [UNITS];
      },
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetMealExtraQuery,
  useGetMealExtraByIdQuery,
  useDeleteMealExtraMutation,
  useAddMealExtraMutation,
  useUpdateMealExtraMutation,
  useGetMealExtraUnitQuery,
  useGetMealExtraSelectBoxQuery,
  useGetFacilityMealExtraSelectBoxQuery,
} = MealExtrasApis;
