import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { ALIS_MANAGEMENTS } = API_ENDPOINTS;

export const ALISManagementApi = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getALISResidentList: builder.query({
      query: ({ alisFacilityId, facilityId }) =>
        `/${ALIS_MANAGEMENTS}?ALISCommunityId=${alisFacilityId}&facilityId=${facilityId}`,
      transformResponse: (response) => response?.data,
      keepUnusedDataFor: 5,
    }),
    updateALISResidentList: builder.mutation({
      query: ({ alisFacilityId, facilityId, data }) => ({
        url: `/${ALIS_MANAGEMENTS}?ALISCommunityId=${alisFacilityId}&facilityId=${facilityId}`,
        method: "PUT",
        body: data,
      }),
    }),
  }),
});

export const { useGetALISResidentListQuery, useUpdateALISResidentListMutation } =
  ALISManagementApi;
