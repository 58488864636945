import { FORM_TYPES } from "components/shared/generatedForm/GeneratedForm";
import { CustomPostedMenuForms } from "./reports/customPostedMenu";
import { CustomPostedMenuMultiForms } from "./reports/customPostedMenuMulti";
import { DailySelectionSheetForms } from "./reports/dailySelectionSheet";
import { DietSpreadsheetForms } from "./reports/dietSpreadsheet";
import { IngredientsUsedInMenuForms } from "./reports/ingredientsUsedInMenu";
import { MenuAllergensForms } from "./reports/menuAllergens";
import { MonthAtAGlanceForms } from "./reports/monthAtAGlance";
import { NutrientAnalysisForms } from "./reports/nutrientAnalysis";
import { ProductionSheetForms } from "./reports/productionSheet";
import { RecipesForms } from "./reports/recipes";
import { ShoppingListByCategoryForms } from "./reports/shoppingListByCategory";
import { ShoppingListByDayForms } from "./reports/shoppingListByday";
import { StandardPostedMenuForms } from "./reports/standardPostedMenu";
import { StandardPostedOtherMenuOptionsForms } from "./reports/standardPostedOtherMenuOptions";
import { WeekAtAGlanceForms } from "./reports/weekAtAGlance";
import { RecipeListForms } from "./reports/recipeList";

export const ReportDetails = [
  {
    value: 1,
    label: "Shopping List By Category",
    forms: ShoppingListByCategoryForms,
    defaults: null,
  },
  {
    value: 2,
    label: "Shopping List By Day",
    forms: ShoppingListByDayForms,
    defaults: null,
  },
  {
    value: 3,
    label: "Recipes",
    forms: RecipesForms,
    defaults: null,
  },
  {
    value: 4,
    label: "Week at a Glance",
    forms: WeekAtAGlanceForms,
    defaults: null,
  },
  {
    value: 14,
    label: "Month at a Glance",
    forms: MonthAtAGlanceForms,
    defaults: null,
  },
  {
    value: 5,
    label: "Nutrient Analysis",
    forms: NutrientAnalysisForms,
    defaults: null,
  },
  {
    value: 6,
    label: "Diet Spreadsheet",
    forms: DietSpreadsheetForms,
    defaults: null,
  },
  {
    value: 7,
    label: "Standard Posted Menu",
    forms: StandardPostedMenuForms,
    defaults: null,
  },
  {
    value: 13,
    label: "Standard Posted Other Menu Options",
    forms: StandardPostedOtherMenuOptionsForms,
    defaults: null,
  },
  {
    value: 15,
    label: "Custom Posted Menu",
    forms: CustomPostedMenuForms,
    defaults: null,
  },
  {
    value: 16,
    label: "Multi-Day Custom Posted Menu",
    forms: CustomPostedMenuMultiForms,
    defaults: null,
  },
  {
    value: 8,
    label: "Daily Selection Sheet",
    forms: DailySelectionSheetForms,
    defaults: null,
  },
  {
    value: 9,
    label: "Ingredients Used in Menu",
    forms: IngredientsUsedInMenuForms,
    defaults: null,
  },
  {
    value: 10,
    label: "Production Sheet",
    forms: ProductionSheetForms,
    defaults: null,
  },
  { value: 11, label: "Recipe List", forms: RecipeListForms, defaults: null },
  {
    value: 12,
    label: "Menu Allergens",
    forms: MenuAllergensForms,
    defaults: null,
  },
];

export const reportId = {
  props: {
    name: "reportId",
    label: "Report",
    rows: "column",
    defaultValue: ReportDetails[0].value,
  },
  validationProps: {},
  size: 12,
  options: ReportDetails,
  type: FORM_TYPES.Radio,
};
