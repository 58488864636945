import React from "react";
import AddMealExtras from "components/mealCard/mealExtrasSelection/addMealExtrasSelection/AddMealExtras";
import { useSearchParams } from "react-router-dom";

export default function AdminAddMealExtras() {
  const [searchParams] = useSearchParams();
  let currentMealExtraId = searchParams.get("id");
    return (
        <AddMealExtras mealExtraId={currentMealExtraId}/>
      );
    }
