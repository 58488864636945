import { Box } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import { useResidentMealExtrasQuery } from "../../../../store/apis/ResidentApis";
import { WarningText } from "../../../shared/WarningText";
import { BaseButton } from "components/shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";
import CreateBaseColumns from "components/shared/CreateBaseColumn";
import { PostAdd } from "@mui/icons-material";

export default function ResidentMealExtras() {
  const [searchParams] = useSearchParams();
  const residentId = searchParams.get("id");

  const {
    data: residentMealExtras,
    isLoading,
    isError,
  } = useResidentMealExtrasQuery(residentId, { skip: !residentId });

  const mealExtrasColumns = [
    {
      field: "mealExtraName",
      minWidth: 55,
      flex: 3,
      headerName: "Meal Add-On",
    },
    {
      field: "description",
      minWidth: 55,
      flex: 3,
      headerName: "Description",
    },
    {
      field: "nutritionIntervention",
      minWidth: 25,
      flex: 2,
      headerName: "Nutrition Intervention?",
      headerAlign: "center",
      isBoolean: true,
    },
    {
      field: "daysOfTheWeek",
      minWidth: 55,
      flex: 2,
      headerName: "Days of The Week",
      headerAlign: "center",
    },
    {
      field: "mealName",
      minWidth: 50,
      headerName: "Meal",
      headerAlign: "center",
    },
    {
      field: "amount",
      minWidth: 50,

      headerName: "Amount",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {params?.row?.quantity} {params?.row?.unitName}
        </>
      ),
    },
    {
      field: "actions",
      minWidth: 50,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={residentMealExtras}
            hasEdit={true}
            hasDelete={true}
            deleteProps={{
              entityName: params.row?.mealExtraName,
              apiPath: "deleteResidentMealExtras",
              title: "Resident Meal Add-On",
            }}
            editProps={{
              To: `addresidentMealExtras?mealExtrasId=${params.row.id}&id=${residentId}`,
              title: "Resident Meal Add-On",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          display: "flex",
          overflow: "hidden",
          flexDirection: "column",
          gap: 1,
        }}>
        <Box>
          <BaseButton
            text={"Add Meal Add-On"}
            endIcon={<PostAdd />}
            To={`addresidentMealExtras?id=${residentId}`}
          />
        </Box>
        <Box>
          <WarningText
            text={`**Add a Meal Add-On (food, beverage or supplement) to a resident’s Mealcard to capture preferences, standing orders, nutrition interventions, etc. 
    Don't see something on our list? You can add custom House Meal Add-Ons within your community's MealCard Settings.`}
            width="100%"
            color="primary.main"
            sx={{ marginLeft: "0px !important", marginY: "0px !important" }}
          />
        </Box>
        <Box sx={{ height: "100%", flexGrow: 1, overflowY: "auto", pb: 1 }}>
          <BaseDataGrid
            rows={residentMealExtras}
            columns={CreateBaseColumns(mealExtrasColumns)}
            error={isError}
            loading={isLoading}
            height={"100%"}
          />
        </Box>
      </Box>
    </>
  );
}
