export const CORPORATE_GROUP_MENU_ITEMS = ({
  mapFlag,
  hasConsulting,
  name,
  index,
}) => {
  const baseMenu = [
    {
      link: `menuExpiration?id=${index}`,
      label: `Extend Expiration`,
    },
    { link: `automaticSnack?id=${index}`, label: `Automatic Snacks` },
    { link: `beverageSelection?id=${index}`, label: `Beverage Selection` },
    { link: `superUser?id=${index}`, label: `Super users` },
    { link: `document?id=${index}`, label: `Documents` },
    {
      link: `exportPBJ?id=${index}&name=${name}`,
      label: `PBJ Exports`,
    },
  ];

  if (mapFlag) {
    baseMenu.push({
      link: `mealExtrasSelection?id=${index}`,
      label: `Meal Add-Ons Selection`,
    });
  }

  if (hasConsulting) {
    baseMenu.push({
      link: `consultantSchedule?id=${index}&name=${name}`,
      label: `Consultant Schedule`,
    });
  }

  return baseMenu;
};
