import {
  breakfastQty1,
  censusText,
  endingDay,
  offset6,
  offset8,
  shoppingListGrouping,
  startingDay,
  lunchQty2,
  supperQty3,
  formatTypeId,
} from "../form.constants";

export const ShoppingListByDayForms = {
  Category: true,
  DayForm: [
    {
      ...startingDay,
    },
    offset6,
    {
      ...endingDay,
    },
    offset6,
    shoppingListGrouping,
    offset6,
  ],
  Census: [
    censusText,
    breakfastQty1,
    offset8,
    lunchQty2,
    offset8,
    supperQty3,
    offset8,
  ],
  Diet: true,
  Report: [formatTypeId],
};
