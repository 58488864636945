import React, { useEffect } from "react";
import {
  useUpdateFacilityCustomMenuMutation,
  useGetFacilityCustomMenuQuery,
} from "../../../../../../../store/apis/FacilityApis";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Grid, Typography } from "@mui/material";
import BaseRichTextEditor from "../../../../../../shared/baseForm/BaseRichTextEditor";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../../admin.overlays";

export default function CommunityMenuCustomize() {
  const [updateCustomMenu, { isError: updateError, isLoading: updateLoading }] =
    useUpdateFacilityCustomMenuMutation();

  const [searchParams] = useSearchParams();
  const facilityId = searchParams.get("id");

  const {
    data: currentCustomMenu,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetFacilityCustomMenuQuery(facilityId);

  const isError = updateError;
  const isSubmitting = updateLoading;

  const navigate = useNavigate();

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (currentSuccess && !currentFetching && currentCustomMenu) {
      reset(currentCustomMenu);
    }
  }, [currentCustomMenu]);

  const onSubmit = (data) => {
    data.facilityId = facilityId;
    updateCustomMenu(data);
    navigate(-1);
  };

  return (<>
    {currentFetching ? (
      <EditSkeleton />
    ) : (
      <Box sx={{ paddingY: "15px" }}>
        <FormProvider>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={11}>
                <Typography
                  variant="body"
                  component={"body"}
                  sx={{
                    fontWeight: 600,
                    fontSize: 14,
                    color: "primary",
                    lineHeight: 1
                  }}>
                  WAAG Disclaimer
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <BaseRichTextEditor
                  label="WAAG Disclaimer"
                  id="waagDisclaimer"
                  name="waagDisclaimer"
                  placeholder=""
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  variant="body"
                  component={"body"}
                  sx={{
                    fontWeight: 600,
                    fontSize: 14,
                    color: "primary",
                    lineHeight: 1
                  }}>
                  Spreadsheet Guidelines
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <BaseRichTextEditor
                  label="Spreadsheet Guidelines"
                  id="spreadsheetGuidelines"
                  name="spreadsheetGuidelines"
                  placeholder=""
                  control={control}
                  errors={errors}
                />
              </Grid>

              <Grid item xs={5}>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <BaseSubmitButton
                    fullWidth
                    onClick={handleSubmit(onSubmit)}
                    isSubmitting={isSubmitting}
                  />
                </Box>
                {isError ? (
                  <Typography sx={{
                    color: "error"
                  }}>
                    There was an error submitting the form, please try again.
                  </Typography>
                ) : undefined}
              </Grid>
              <Grid item xs={3} />
            </Grid>
          </form>
        </FormProvider>
      </Box>
    )}
  </>);
}
