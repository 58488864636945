import { KeyboardArrowLeft } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

/**
 * @see [Documentation](../../../Wiki/Documentations/FrontEnd/Components/BaseButton.md)
 */
export const BackButton = ({
  To,
  text,
  onClick,
  disabled,
  sx,
  iconDisabled,
  rest,
}) => {
  // const navigate = useNavigate();
  // const backNavigation = () => navigate(-1);
  return (
    <Button
      {...rest}
      component={Link}
      to={To ? To : onClick ? null : -1}
      color={"primary"}
      disabled={disabled || false}
      sx={{
        ...sx,
      }}
      variant="contained"
      startIcon={iconDisabled ? null : <KeyboardArrowLeft />}
      onClick={onClick}>
      {text || "Back"}
    </Button>
  );
};
