import React, { useEffect, useState } from "react";
import { useAddCorporateGroupConsultantsScheduleMutation } from "../../../../../../store/apis/CorporateGroupApis";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { DownloadLinkHelper } from "../../../../../shared/utils/helpers";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import BaseDatePicker from "components/shared/baseForm/BaseDatePicker";
import dayjs from "dayjs";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { KeyboardArrowLeft } from "@mui/icons-material";

export default function ConsultantSchedule() {
  const [searchParams] = useSearchParams();
  const corporateId = searchParams.get("id");
  const corporateName = searchParams.get("name");

  const [message, setMessage] = useState("");

  const [
    addConsultantScheduleReport,
    { data: report, isError: addError, isFetching: addLoading, isSuccess },
  ] = useAddCorporateGroupConsultantsScheduleMutation();

  useEffect(() => {
    if (isSuccess && report && !addLoading) {
      DownloadLinkHelper(report);
    } else if (isSuccess && report === "" && !addLoading) {
      setMessage("No scheduled consultant visits.");
    }
  }, [isSuccess, report, addLoading]);

  const isError = addError;
  const isSubmitting = addLoading;

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    control,
    watch,
    clearErrors,
    formState: { errors },
  } = methods;

  const startDate = watch("reportStartDate");
  const endDate = watch("reportEndDate");
  useEffect(() => {
    if (
      dayjs(startDate).format("MM-DD-YYYY") <=
      dayjs(endDate).format("MM-DD-YYYY")
    ) {
      clearErrors("reportStartDate");
      clearErrors("reportEndDate");
    }
  }, [startDate, endDate]);
  const onSubmit = (data) => {
    addConsultantScheduleReport({
      ...data,
      reportEndDate: dayjs(data.reportEndDate).isValid()
        ? dayjs(data.reportEndDate).format("YYYY-MM-DD")
        : null,
      reportStartDate: dayjs(data.reportStartDate).isValid()
        ? dayjs(data.reportStartDate).format("YYYY-MM-DD")
        : null,
      corporateGroupId: corporateId,
      corporateGroupName: corporateName,
    });
  };

  return (<>
    <div className="leftActions">
      <Button
        component={Link}
        color={"primary"}
        className="baseButton"
        variant="contained"
        onClick={() => navigate(-1)}
        startIcon={<KeyboardArrowLeft />}>
        {"Back to  Corporate list"}
      </Button>
    </div>
    <Divider sx={{ marginBottom: "15px", border: "none" }} />
    <FormProvider>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <BaseDatePicker
              label={"Report Start Date:"}
              name={"reportStartDate"}
              placeholder="Report Satrt Date"
              control={control}
              errors={errors}
              validationProps={{
                max: {
                  value: endDate ? new Date(endDate) : null,
                  message: "Start Date must be before End Date",
                },
                required: REQUIRED_ERROR("Report Start Date"),
              }}
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3} />

          <Grid item xs={5}>
            <BaseDatePicker
              label={"Report End Date:"}
              name={"reportEndDate"}
              placeholder="Report End Date"
              control={control}
              errors={errors}
              validationProps={{
                min: {
                  value: startDate ? new Date(startDate) : null,
                  message: "End Date must be before Start Date",
                },
                required: REQUIRED_ERROR("Report End Date"),
              }}
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3} />

          <Grid item xs={5}>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <BaseSubmitButton
                fullWidth
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
                text={"Export"}
              />
            </Box>
            {message != "" ? (
              <Typography sx={{
                color: "error"
              }}>{message}</Typography>
            ) : undefined}
            {isError ? (
              <Typography sx={{
                color: "error"
              }}>
                There was an error submitting the form, please try again.
              </Typography>
            ) : undefined}
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </form>
    </FormProvider>
  </>);
}
