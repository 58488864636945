import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import React from "react";

export function AdminCard({ cardProperties }) {
  return (
    <Grid item xs={12} md={4}>
      <Card
        className={cardProperties.IsActive ? "adminCard active" : "adminCard"}
        sx={{ maxWidth: 345 }}>
        <CardContent className="adminCardContent">
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            className="adminCardTitle">
            <cardProperties.Icon
              style={{ fontSize: "36px", marginRight: "8px" }}
            />
            {cardProperties.Name}
          </Typography>
        </CardContent>
        <CardActions className="cardActionsRoot">
          {cardProperties.Children.map((data, index) => {
            return (
              <Grid
                key={index}
                component={NavLink}
                to={data.Link}
                item
                style={{ textDecoration: "none" }}
                xs
                className={"cardActions"}>
                {data.Name}
              </Grid>
            );
          })}
        </CardActions>
      </Card>
    </Grid>
  );
}
