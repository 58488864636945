import { Grid, Box, Typography } from "@mui/material";
import React, { useState, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import BaseAutoComplete from "../../../shared/baseForm/BaseAutoComplete";
import BaseSubmitButton from "../../../shared/baseSubmitButton/BaseSubmitButton";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useGetFacilityByUserFacilitiesQuery } from "../../../../store/apis/FacilityApis";
import {
  useShareRecipeMutation,
  useGetShareRecipesQuery,
} from "../../../../store/apis/RecipeAPIs";
import { selectFacilityId } from "../../../../store/slices/authSlice/AuthSlice";
import BaseContent from "../../../shared/baseContent/BaseContent";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";

import { debounce } from "lodash";
import BaseActionBox from "components/shared/BaseActionBox";

const ShareRecipe = () => {
  const formSelectProps = {
    props: {
      label: "Facility",
      id: "facilityId",
      name: "facilityId",
    },
  };

  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get("recipeId");
  const userfacilityId = useSelector(selectFacilityId);

  const [debouncedFacilitySearch, setDebouncedFacilitySearch] = useState("");

  const handleSearch = useMemo(
    () =>
      debounce((query) => {
        setDebouncedFacilitySearch(query);
      }, 400),
    []
  );

  const { data: searchResults, isFetching: facilityLoading } =
    useGetFacilityByUserFacilitiesQuery(
      { searchTerm: debouncedFacilitySearch || "" },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const { data: recipeShareData, isFetching: recipesLoading } =
    useGetShareRecipesQuery(
      {
        recipeId,
        userFacilityId: userfacilityId,
      },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const [
    ShareRecipe,
    {
      isLoading: ShareRecipeLoading,

      isError: hasError,
    },
  ] = useShareRecipeMutation();
  const isLoading = recipesLoading;
  const isSubmitting = ShareRecipeLoading;
  const isError = hasError;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    ShareRecipe({
      recipeId,
      facilityId: data.facilityId,
    });
  };

  const BeverageColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
    },

    {
      field: "actions",
      sortable: false,
      disableColumnMenu: false,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={recipeShareData}
            hasDelete={params.row?.removable}
            deleteProps={{
              entityName: params.row?.name,
              FacilityId: params.row.id,
              apiPath: "deleteFacilityShare",
              title: "Facility",
            }}
          />
        );
      },
    },
  ];

  return (<>
    <BaseContent headerText={"Share Recipe"} disableHeight={true}>
      <Box sx={{
        height: { md: "78vh", xl: "82vh" }
      }}>
        <Box sx={{
          margin: "20px"
        }}>
          <div className="share-recipe-row">
            <Grid
              container
              spacing={2}
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
              }}>
              <BaseAutoComplete
                options={searchResults ? searchResults : []}
                loading={facilityLoading}
                control={control}
                handleSearch={handleSearch}
                formSelectProps={formSelectProps}
                errors={errors}
                sx={{ margin: 0 }}
              />
            </Grid>
            <BaseSubmitButton
              onClick={handleSubmit(onSubmit)}
              style={{
                backgroundColor: "#FFFFFF",
                color: "#55c2c5",
                border: "2px solid #55c2c5",
                width: "20%",
                marginLeft: 5,
              }}
              isSubmitting={isSubmitting}
              text={"Share"}
            />
          </div>

          <BaseDataGrid
            rows={recipeShareData}
            columns={BeverageColumns}
            loading={isLoading}
            error={isError}
          />

          {isError ? (
            <>
              <Typography sx={{
                color: "error"
              }}>
                There was an error submitting the form, please try again.
              </Typography>
            </>
          ) : undefined}
        </Box>
      </Box>
    </BaseContent>
  </>);
};

export default ShareRecipe;
