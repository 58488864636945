import { FORM_TYPES } from "components/shared/generatedForm/GeneratedForm";
import { MAX_LENGTH_VALIDATION, REQUIRED_ERROR } from "utils/errorMessages";

export const SECTION_GROUP_FORM = [
  {
    props: {
      name: "name",
      id: "name",
      label: "Section Group Name",
    },
    validationProps: {
      required: REQUIRED_ERROR("Report Name"),
      maxLength: MAX_LENGTH_VALIDATION("Report Name", 40),
    },
    size: 3,
  },
  {
    props: {
      name: "description",
      id: "description",
      label: "Description",
    },
    size: 6,
  },
  {
    props: {
      name: "sortOrder",
      id: "sortOrder",
      label: "Sort Order",
      type: "number",
    },
    validationProps: {
      required: REQUIRED_ERROR("Sort Order"),
    },
    size: 3,
  },
];

export const SECTION_GROUP_DEFAULTS = {
  name: "",
  description: "",
  sortOrder: 1,
};

export const SECTION_FORM = [
  {
    props: {
      name: "name",
      id: "name",
      label: "Section Name",
    },
    validationProps: {
      required: REQUIRED_ERROR("Section Name"),
      maxLength: MAX_LENGTH_VALIDATION("Section Name", 40),
    },
    size: 3,
  },
  {
    props: {
      name: "size",
      id: "size",
      label: "Size",
      type: "number",
    },
    validationProps: {
      required: REQUIRED_ERROR("Sort Order"),
    },
    size: 3,
  },

  {
    props: {
      name: "hasSelectAllCompletedCheck",
      id: "hasSelectAllCompletedCheck",
      label: "Has Select All Completed Check",
    },
    size: 3,
    type: FORM_TYPES.Checkbox,
  },

  {
    props: {
      name: "hasIsApplicableCheck",
      id: "hasIsApplicableCheck",
      label: "Has Is Applicable Check",
    },
    size: 3,
    type: FORM_TYPES.Checkbox,
  },
];

export const SECTION_DEFAULTS = {
  name: "",
  size: 3,
  hasSelectAllCompletedCheck: false,
  hasIsApplicableCheck: false,
};

export const FIELD_GROUP_FORM = [
  {
    props: {
      name: "name",
      id: "name",
      label: "Field Group Name",
    },
    validationProps: {
      required: REQUIRED_ERROR("Field Group Name"),
      maxLength: MAX_LENGTH_VALIDATION("Field Group Name", 40),
    },
    size: 3,
  },
  {
    props: {
      name: "description",
      id: "description",
      label: "Description",
    },
    size: 6,
  },
  {
    props: {
      name: "sortOrder",
      id: "sortOrder",
      label: "Sort Order",
      type: "number",
    },
    validationProps: {
      required: REQUIRED_ERROR("Sort Order"),
    },
    size: 3,
  },

  {
    props: {
      name: "direction",
      id: "direction",
      label: "Direction",
    },
    size: 3,
    type: FORM_TYPES.Select,
    options: [
      { label: "Horizontal", id: "row", description: "Left to Right" },
      { label: "Vertical", id: "column", description: "Top to Bottom" },
    ],
  },
];

export const FIELD_GROUP_DEFAULTS = {
  name: "",
  description: "",
  sortOrder: 1,
  direction: "row",
};

export const FIELD_FORM = [
  {
    props: {
      name: "label",
      id: "label",
      label: "Field Label",
    },
    validationProps: {
      required: REQUIRED_ERROR("Field Label"),
      maxLength: MAX_LENGTH_VALIDATION("Field Label", 40),
    },
    size: 3,
  },
  {
    props: {
      name: "defaultHidden",
      id: "defaultHidden",
      label: "Is Default Hidden",
    },
    size: 3,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {
      name: "defaultValue",
      id: "defaultValue",
      label: "Default Value",
    },
    size: 3,
  },
  {
    props: {
      name: "canHaveMultiple",
      id: "canHaveMultiple",
      label: "Can Have Multiple",
    },
    size: 3,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {
      name: "triggers",
      id: "triggers",
      label: "FieldId of the field that triggers this field",
      type: "number",
    },
    size: 3,
  },
  {
    props: {
      name: "type",
      id: "type",
      label: "Type",
    },
    size: 3,
    type: FORM_TYPES.Select,
    options: [
      { label: "Input", id: "input", description: "Input" },
      { label: "Select", id: "select", description: "Dropdown" },
      { label: "Checkbox", id: "checkbox", description: "Checkbox" },
      { label: "Date", id: "date", description: "Date" },
      { label: "Time", id: "time", description: "Time" },
      { label: "DateTime", id: "datetime", description: "Date Time" },
      { label: "Radio", id: "radio", description: "Radio Button" },
      {
        label: "Multi CheckBox",
        id: "multiCheckbox",
        description: "Multi Checkbox",
      },
      { label: "Text", id: "text", description: "Single Line Text" },
    ],
  },
];

export const FIELD_OPTION_FORM = [
  {
    props: {
      name: "label",
      id: "label",
      label: "Option Label",
    },
    validationProps: {
      required: REQUIRED_ERROR("Option Label"),
      maxLength: MAX_LENGTH_VALIDATION("Option Label", 40),
    },
    size: 3,
  },
  {
    props: {
      name: "value",
      id: "value",
      label: "Option Value",
    },
    size: 3,
  },
  {
    props: {
      name: "description",
      id: "description",
      label: "Description",
    },
    size: 3,
  },
];
