import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { DIET, FACILITY_DIETS } = API_ENDPOINTS;

export const DietAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // define an endpoint for getting Diets
    getDiets: builder.query({
      query: (searchTerm) => `/${DIET}?searchText=${searchTerm}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [...response.map(({ id }) => ({ type: DIET, id })), DIET]
          : [DIET];
      },
    }),
    getDietSelectBox: builder.query({
      query: (searchTerm) =>
        `/${DIET}?searchText=${searchTerm || " "}`,
      transformResponse: (response) =>
        response?.data.map((x) => ({
          label: `${x.name}${x.isTexture ? " (texture)" : ""}`,
          id: x.id,
          isTexture: x.isTexture,
        })),
      providesTags: (response) => {
        return response
          ? [...response.map(({ id }) => ({ type: DIET, id })), DIET]
          : [DIET];
      },
    }),
    getDietById: builder.query({
      query: (dietId) => `/${DIET}/${dietId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response ? [{ type: DIET, id: response.id }, DIET] : [DIET],
    }),
    deleteDiet: builder.mutation({
      query: (id) => ({
        url: `/${DIET}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [{ type: DIET, id: arg.id }],
    }),
    addDiet: builder.mutation({
      query: (data) => ({
        url: `/${DIET}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: DIET, id: result?.id }],
    }),
    updateDiet: builder.mutation({
      query: (data) => ({
        url: `/${DIET}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: DIET, id: result?.id }],
    }),
    getFacilityDiets: builder.query({
      query: ({ facilityId, isTexture }) =>
        `/${DIET}/getFacilityDiets?facilityId=${facilityId}&isTexture=${isTexture}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: FACILITY_DIETS, id })),
              FACILITY_DIETS,
            ]
          : [FACILITY_DIETS];
      },
    }),
    getFacilityDietSelectBox: builder.query({
      query: (facilityId) => `/${DIET}/GetFacilityDiets/${facilityId}`,
      transformResponse: (response) =>
        response?.data.map((x) => ({
          label: `${x.dietName}${x.isTexture ? " (texture)" : ""}`,
          id: x.dietId,
          isTexture: x.isTexture,
        })),
      // providesTags: (response) => {
      //   return response
      //     ? [...response.map(({ id }) => ({ type: FACILITY_DIETS, id })), FACILITY_DIETS]
      //     : [FACILITY_DIETS];
      // },
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetDietsQuery,
  useGetDietByIdQuery,
  useDeleteDietMutation,
  useAddDietMutation,
  useUpdateDietMutation,
  useGetDietSelectBoxQuery,
  useGetFacilityDietsQuery,
  useGetFacilityDietSelectBoxQuery,
} = DietAPIs;
