import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { OptionsList } from "./headerConstant";
import { Tab, Tabs, Box } from "@mui/material";
import { isUserAuthorized } from "components/shared/utils/helpers";
import useMsalAccount from "utils/useMsalAccount";

export default function PlatefulTabs() {
  const currentLocation = useLocation();
  const { roles: userRoles } = useMsalAccount();

  const Options = useMemo(
    () => OptionsList.filter((res) => isUserAuthorized(res.Roles, userRoles)),
    [userRoles]
  );

  const [value, setValue] = useState(Options[0].id);

  useEffect(() => {
    let currentLink;
    if (currentLocation) {
      currentLink = Options.find((x) =>
        currentLocation?.pathname.includes(x.Link)
      );
      if (!currentLink) {
        currentLink = Options[0];
      }
    }

    setValue(currentLink.id);
  }, [currentLocation]);

  return (
    (<Box
      sx={{
        marginTop: "-32px",
        marginLeft: "-15px"
      }}>
      <Tabs
        TabIndicatorProps={{ sx: { display: "none" } }}
        sx={{
          "& .MuiTabs-flexContainer": {
            flexWrap: "wrap",
          },
          marginX: "15px",
        }}
        value={value}
        aria-label="recipe nav tabs ">
        {Options.map((data) => {
          return (
            <Tab
              key={data.id}
              component={Link}
              to={data.Link}
              value={data.id}
              id={data.id}
              disabled={false}
              sx={{ paddingY: "10px" }}
              className={"consultantTabs"}
              label={`${data.optionName}`}
            />
          );
        })}
      </Tabs>
    </Box>)
  );
}
