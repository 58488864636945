import { FORM_TYPES } from "../../../../../../shared/generatedForm/GeneratedForm";
import {
  INVALID_MULTIPLE_EMAIL_VALIDATION,
  REQUIRED_ERROR,
} from "utils/errorMessages";

export const sendReportFields = [
  {
    props: {
      name: "finished",
      id: "finished",
      label: "Mark report as finished",
    },
    type: FORM_TYPES.Checkbox,
    size: 12,
  },
  {
    props: {
      name: "sendTo",
      id: "sendTo",
      label: "Email To",
      multiline: true,
      rows: 4,
    },
    validationProps: {
      required: REQUIRED_ERROR("Email"),
      pattern: INVALID_MULTIPLE_EMAIL_VALIDATION(),
    },
    size: 12,
  },
  {
    props: {
      text: "Separate Multiple Emails with a comma.",
    },
    size: 12,
    type: FORM_TYPES.Text,
  },
  {
    props: {
      name: "subject",
      id: "subject",
      label: "Subject",
    },
    validationProps: {
      required: REQUIRED_ERROR("Subject"),
    },
    size: 12,
  },
  {
    props: {
      name: "message",
      id: "message",
      label: "Message",
      multiline: true,
      rows: 3,
    },
    // validationProps: {},
    size: 12,
  },
];
