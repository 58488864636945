import Box from "@mui/material/Box";
import { styled } from "@mui/system";

const OutletContainer = styled(Box)(({ theme, gap }) => {
  return {
    flexDirection: "column",
    flexGrow: 1,
    overflow: "auto",
    height: "100%",
    padding: theme.spacing(1),
    display: "flex",
    gap: theme.spacing(gap || 1),
  };
});

export default OutletContainer;
