import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, Divider, Grid, Typography } from "@mui/material";
import {
  useAddRegionMutation,
  useGetRegionByIdQuery,
  useUpdateRegionMutation,
} from "../../../../../../store/apis/RegionsApis";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import GeneratedForm from "../../../../../shared/generatedForm/GeneratedForm";
import { BaseButton } from "../../../../../shared/BaseButton";
import { EditSkeleton } from "../../../../admin.overlays";
import { REGION_FIELDS } from "./forms.constants";

export default function AddRegion() {
  const [addRegion, { isError: addError, isLoading: addLoading }] =
    useAddRegionMutation();

  const [updateRegion, { isError: updateError, isLoading: updateLoading }] =
    useUpdateRegionMutation();

  const isError = addError || updateError;
  const isSubmitting = addLoading || updateLoading;
  const [searchParams] = useSearchParams();
  const currentRegionId = searchParams.get("id");

  const isEdit = !!currentRegionId;

  const {
    data: currentRegion,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetRegionByIdQuery(currentRegionId, { skip: !isEdit });

  const isEditLoading = isEdit && currentFetching;

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (currentSuccess && !currentFetching && currentRegion) {
      reset(currentRegion);
    }
  }, [currentRegion]);

  const onSubmit = (data) => {
    if (isEdit) {
      updateRegion(data);
    } else {
      addRegion(data);
    }
    navigate(-1);
  };

  return (<>
    {isEditLoading ? (
      <EditSkeleton />
    ) : (
      <>
        <BaseButton
          text="Back To Region Management"
          startIcon={"keyboard_arrow_left"}
          onClick={() => navigate(-1)}
        />
        <Divider sx={{ marginBottom: "15px", border: "none" }} />
        <Box sx={{
          padding: "8px"
        }}>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}>
            <GeneratedForm
              control={control}
              list={REGION_FIELDS}
              errors={errors}
            />

            <Grid item md={6}>
              <BaseSubmitButton
                fullWidth
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
                text={isEdit ? "Submit" : "Create"}
              />
            </Grid>
            {isError ? (
              <Typography sx={{
                color: "error"
              }}>
                There was an error submitting the form, please try again.
              </Typography>
            ) : undefined}
          </Grid>
        </Box>
      </>
    )}
  </>);
}
