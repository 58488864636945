import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import BaseDataGrid from "components/shared/baseDataGrid/BaseDataGrid";
import { BaseButton } from "components/shared/BaseButton";
import { useInsertMultipleMutation } from "store/apis/ResidentAllergyDislikeApis";
import { Typography } from "@mui/material";
import CreateBaseColumns from "components/shared/CreateBaseColumn";

// Modal Styling
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 1,
};

function CopySubstitutionModal({
  open,
  onClose,
  menuCategoryName,
  copyDay,
  dislike,
  allergy,
  data,
  residentId,
  menuId,
  substituteId,
  specialNote,
}) {
  const [selectionModel, setSelectionModel] = useState([]);

  // Copy Post API

  const [insertMultiple, { isLoading, isSuccess }] =
    useInsertMultipleMutation();

  const dislikeColumns = [
    {
      field: "menuItemName",
      minWidth: 25,
      flex: 2,
      headerName: "Menu Item",
    },
    {
      field: "menuDayId",
      minWidth: 25,
      flex: 2,
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      headerName: "Menu Day",
    },
    {
      field: dislike ? "foodTypeName" : "allergyName",
      minWidth: 55,
      flex: 2,
      headerName: dislike ? "Dislike" : allergy ? "Allergy" : "",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
  ];

  useEffect(() => {
    if (isSuccess) onClose(true);
  }, [isSuccess]);

  const filteredData = data?.filter(
    (item) =>
      item.menuCategoryName == menuCategoryName && item.menuDayId !== copyDay
  );

  const handleSelection = (newSelection) => {
    setSelectionModel(newSelection);
  };

  const handleCopy = () => {
    const filteredData = data?.filter((item) =>
      selectionModel.includes(item.id)
    );

    const transformedData = {
      id: substituteId,
      residentId,
      menuId,
      appliedSpecialNotes: specialNote,
      appliedSubstituteId: substituteId,
      residentAllergyDislikesInsertDTO: filteredData,
    };

    insertMultiple(transformedData);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description">
      <Box sx={style}>
        <Box>
          <CloseIcon
            sx={{
              float: "right",
              border: "1px solid",
              borderRadius: "50%",
              borderColor: "primary.main",
              padding: "5px",
              margin: "5px",
              cursor: "pointer",
            }}
            onClick={onClose}
          />
        </Box>
        <Typography
          variant="h6"
          component="h6"
          sx={{
            fontWeight: "bold",
            marginBottom: "10px",
          }}>
          Apply Substitutions
        </Typography>
        <BaseDataGrid
          rows={filteredData || []}
          columns={CreateBaseColumns(dislikeColumns)}
          onRowSelectionModelChange={handleSelection}
          selectionModel={selectionModel}
          checkboxSelection
          getRowId={(row) => row?.id}
          autoHeight={false}
          width={"50vw"}
          height={{ md: "48vh", lg: "52vh", xl: "54vh" }}
        />
        <Box
          sx={{
            float: "right",
            marginTop: "10px",
          }}>
          <BaseButton
            text="Apply"
            onClick={handleCopy}
            disabled={selectionModel.length == 0}
            isSubmitting={isLoading}
          />
        </Box>
      </Box>
    </Modal>
  );
}

export default CopySubstitutionModal;
