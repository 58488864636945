import { REQUIRED_ERROR } from "utils/errorMessages";
import {
  baseNameAlphaNumericSpcialCharacterAltValidation,
  emailValidation,
  passwordValidation,
} from "utils/ValidationRegex";

export const RecipeSettingFields = {
  formSelectProps: {
    props: {
      label: "Sharing Group",
      id: "recipeSharingGroupId",
      name: "recipeSharingGroupId",
    },
  },
  formTextFields: [
    {
      props: {
        label: "Custom Servings",
        id: "customServings1",
        type: "number",
        name: "customServings1",
      },
    },
    {
      props: {
        label: "Custom Servings",
        id: "customServings2",
        type: "number",
        name: "customServings2",
      },
    },
    {
      props: {
        label: "Custom Servings",
        id: "customServings3",
        type: "number",
        name: "customServings3",
      },
    },
    {
      props: {
        label: "Custom Theme",
        id: "customThemeName",
        name: "customThemeName",
      },
    },
  ],
};

export const UserInformationFields = {
  formTextFields: [
    {
      props: {
        label: "Username",
        id: "userName",
        name: "userName",
        helperText:
          "Username is the Email Address that the user enters on the login screen. This will also be the Email Address that password reset emails will be sent.",
      },
      validationProps: {
        required: REQUIRED_ERROR("Username"),
        pattern: {
          value: emailValidation,
          message: "Username must be a valid email address.",
        },
      },
    },
    {
      props: {
        label: "Password",
        type: "password",
        id: "password",
        name: "password",
      },
      validationProps: {
        required: REQUIRED_ERROR("Password"),
        minLength: {
          value: 8,
          message: "Password should be at least 8 characters long.",
        },
        maxLength: {
          value: 30,
          message: "Password should be less than 30 characters.",
        },
        pattern: {
          value: passwordValidation,
          message:
            "Password must contain at least 2 of the following: upper case, lower case, numeric, symbol.",
        },
        // pattern: {
        //   value:
        //     /^(?=.*?[A-Z])(?=.*?[a-z])(?!.*?[^A-Za-z0-9#?!@$%^&*-]).{8,63}$|^(?=.*?[A-Z])(?=.*?[0-9])(?!.*?[^A-Za-z0-9#?!@$%^&*-]).{8,63}$|^(?=.*?[A-Z])(?=.*?[#?!@$%^&*-])(?!.*?[^A-Za-z0-9#?!@$%^&*-]).{8,63}$|^(?=.*?[a-z])(?=.*?[0-9])(?!.*?[^A-Za-z0-9#?!@$%^&*-]).{8,63}$|^(?=.*?[a-z])(?=.*?[#?!@$%^&*-])(?!.*?[^A-Za-z0-9#?!@$%^&*-]).{8,63}$|^(?=.*?[0-9])(?=.*?[#?!@$%^&*-])(?!.*?[^A-Za-z0-9#?!@$%^&*-]).*$/,
        //   message:
        //     "Password must contain at least 2 of the following: upper case, lower case, numeric, symbol. ",
        // },
      },
    },
    {
      props: {
        label: "First Name",
        id: "firstName",
        name: "firstName",
      },
      validationProps: {
        required: REQUIRED_ERROR("First Name"),
        pattern: {
          value: baseNameAlphaNumericSpcialCharacterAltValidation,
          message: "First Name is not valid.",
        },
      },
    },
    {
      props: {
        label: "Last Name",
        id: "lastName",
        name: "lastName",
      },
      validationProps: {
        required: REQUIRED_ERROR("Last Name"),
        pattern: {
          value: baseNameAlphaNumericSpcialCharacterAltValidation,
          message: "Last Name is not valid.",
        },
      },
    },
  ],
  formSelectProps: {
    props: {
      label: "Community",
      id: "facilityId",
      name: "facilityId",
    },
    validationProps: {
      required: REQUIRED_ERROR("Community"),
    },
  },
};

export const AccessPermissionFields = {
  MenuCheckBoxes: [
    {
      props: {
        label: "Print Diet Spreadsheet",
        name: "allowPrintDietSpreadsheet",
      },
    },
    {
      props: {
        label: "Print Shopping List",
        name: "allowPrintShoppingList",
      },
    },
    {
      props: {
        label: "Print Ingredients",
        name: "allowPrintIngredients",
      },
    },
    {
      props: {
        label: "Print Production Sheet",
        name: "allowPrintProductionSheet",
      },
    },
    {
      props: {
        label: "Print Nutritional Analysis",
        name: "allowPrintNutritionalAnalysis",
      },
    },
    {
      props: {
        label: "Print Posted Menu",
        name: "allowPrintPostedMenus",
      },
    },
    {
      props: {
        label: "Print Week at a Glance",
        name: "allowPrintWeekAtAglance",
      },
    },
    {
      props: {
        label: "Print Recipes",
        name: "allowPrintRecipes",
      },
    },
    {
      props: {
        label: "Print Selection Sheets",
        name: "allowPrintSelectionSheets",
      },
    },
    {
      props: {
        label: "Editable Shopping List",
        name: "allowEditableShoppingList",
      },
    },
    {
      props: {
        label: "Show Vendor Pricing",
        name: "showVendorPricing",
      },
    },
    {
      props: {
        label: "Include Calories/Protein on WAAG",
        name: "allowIncludeCaloriesOnWaag",
      },
    },
  ],
  SecurityCheckBoxes: [
    {
      props: {
        label: "Contracted Consultant",
        name: "htiemployee",
      },
    },
    {
      props: {
        label: "1099 Contractor",
        name: "is1099Contractor",
      },
    },
    {
      props: {
        label: "Advanced User",
        name: "advancedUser",
      },
    },
    {
      props: {
        label: "Graves User",
        name: "gravesUser",
      },
    },
    {
      props: {
        label: "Show Front Page Documents",
        name: "showFrontPageDocuments",
      },
    },
    {
      props: {
        label: "User Can Share Menus",
        name: "allowShareMenus",
      },
    },
    {
      props: {
        label: "User Can Share Recipes",
        name: "allowShareRecipes",
      },
    },
    {
      props: {
        label: "Menu Signing",
        name: "canSignMenus",
      },
      validationProsp: {},
    },
    {
      props: {
        label: "Archived",
        name: "archived",
      },
    },
  ],
};
