import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { PRODUCTS } = API_ENDPOINTS;

export const ProductAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getProductList: builder.query({
      query: () => ({
        url: `/${PRODUCTS}`,
      }),
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [...response.map(({ id }) => ({ type: PRODUCTS, id })), PRODUCTS]
          : [PRODUCTS],
    }),
  }),
});

export const { useGetProductListQuery } = ProductAPIs;
