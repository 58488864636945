import { REQUIRED_ERROR } from "utils/errorMessages";
import {
  AllDiningAreas,
  AllResidents,
  DateOrCommentPrint,
  boldLiquidConsistency,
  comment,
  includeCarbohydrate,
  diningAreaList,
  enableHighlights,
  endDay,
  includeAlwaysAvailable,
  // includeBeverageAmounts,
  includeMealExtrasAmounts,
  includeExtraSpace,
  includeNameAtBottom,
  includeNameAtTop,
  includePortionSize,
  mealId,
  menuId,
  residentIds,
  seasonId,
  showIDDSIAbbreviations,
  menuStartDate,
  startDay,
  textureFilter,
  trayCardSort,
} from "../forms.constants";
import dayjs from "dayjs";

export const MealCardDefaultValues = {
  mealId: "",
  diningAreaList: [],
  includeNameAtTop: true,
  includeNameAtBottom: false,
  trayCardSort: 5,
  textureFilter: 1,
  includeBeverageAmounts: true,
  includeMealExtrasAmounts: true,
  enableHighlights: false,
  comment: "",
};

export const MealCardFields = [
  { ...mealId, validationProps: { required: REQUIRED_ERROR("Meal") } },
  diningAreaList,
  AllDiningAreas,
  includeNameAtTop,
  includeNameAtBottom,
  trayCardSort,
  textureFilter,
  // includeBeverageAmounts,
  includeMealExtrasAmounts,
  enableHighlights,
  comment,
  DateOrCommentPrint,
];

export const MealCardAllMealsDefaultValues = {
  diningAreaList: [],
  includeNameAtTop: true,
  includeNameAtBottom: false,
  trayCardSort: 5,
  residentIds: [],
  textureFilter: 1,
  includeBeverageAmounts: true,
  includeMealExtrasAmounts: true,
  enableHighlights: false,
  comment: "",
};

export const MealCardAllMealsFields = [
  diningAreaList,
  AllDiningAreas,
  residentIds,
  AllResidents,
  includeNameAtTop,
  includeNameAtBottom,
  trayCardSort,
  textureFilter,
  //includeBeverageAmounts,
  includeMealExtrasAmounts,
  enableHighlights,
  comment,
  DateOrCommentPrint,
];

export const MealCardsAllMealsWithMenuDefaultValues = {
  diningAreaList: [],
  residentIds: [],
  includeNameAtTop: true,
  includeNameAtBottom: false,
  seasonId: null,
  menuId: null,
  startMenuDay: null,
  endMenuDay: null,
  includeAlwaysAvailable: false,
  includeExtraSpace: false,
  includePortionSize: false,
  includeCarbohydrate: false,
  showIDDSIAbbreviations: true,
  boldLiquidConsistency: true,
  trayCardSort: 5,
  textureFilter: 1,
  includeBeverageAmounts: true,
  includeMealExtrasAmounts: true,
  enableHighlights: false,
  menuStartDate: dayjs().format("YYYY-MM-DD"),
  comment: "",
};

export const MealCardAllMealsWithMenuFields = [
  diningAreaList,
  AllDiningAreas,
  residentIds,
  AllResidents,
  includeNameAtTop,
  includeNameAtBottom,
  { ...seasonId, validationProps: { required: REQUIRED_ERROR("Season") } },
  { ...menuId, validationProps: { required: REQUIRED_ERROR("Menu") } },
  { ...startDay, validationProps: { required: REQUIRED_ERROR("Start Day") } },
  { ...endDay, validationProps: { required: REQUIRED_ERROR("End Day") } },
  includeAlwaysAvailable,
  includeExtraSpace,
  includePortionSize,
  includeCarbohydrate,
  showIDDSIAbbreviations,
  boldLiquidConsistency,
  trayCardSort,
  textureFilter,
  //includeBeverageAmounts,
  includeMealExtrasAmounts,
  enableHighlights,
  menuStartDate,
  comment,
  DateOrCommentPrint,
];

export const MealCardsWithMenuDefaultValues = {
  mealId: "",
  diningAreaList: [],
  includeNameAtTop: true,
  includeNameAtBottom: false,
  seasonId: null,
  menuId: null,
  startMenuDay: null,
  endMenuDay: null,
  includeAlwaysAvailable: false,
  includeExtraSpace: false,
  includePortionSize: false,
  includeCarbohydrate: false,
  showIDDSIAbbreviations: true,
  boldLiquidConsistency: true,
  trayCardSort: 5,
  textureFilter: 1,
  includeBeverageAmounts: true,
  includeMealExtrasAmounts: true,
  enableHighlights: false,
  menuStartDate: dayjs().format("YYYY-MM-DD"),
  comment: "",
};

export const MealCardsWithMenuFields = [
  { ...mealId, validationProps: { required: REQUIRED_ERROR("Meal") } },
  diningAreaList,
  AllDiningAreas,
  includeNameAtTop,
  includeNameAtBottom,
  { ...seasonId, validationProps: { required: REQUIRED_ERROR("Season") } },
  { ...menuId, validationProps: { required: REQUIRED_ERROR("Menu") } },
  { ...startDay, validationProps: { required: REQUIRED_ERROR("Start Day") } },
  { ...endDay, validationProps: { required: REQUIRED_ERROR("End Day") } },
  includeAlwaysAvailable,
  includeExtraSpace,
  includePortionSize,
  includeCarbohydrate,
  showIDDSIAbbreviations,
  boldLiquidConsistency,
  trayCardSort,
  textureFilter,
  //includeBeverageAmounts,
  includeMealExtrasAmounts,
  enableHighlights,
  menuStartDate,
  comment,
  DateOrCommentPrint,
];
