import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { EditSkeleton } from "components/admin/admin.overlays";
import FullScreenMessage from "../FullScreenMessage";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/Forbidden.md)
 */
export default function Forbidden() {
  // Can't use useSearchParams() because it splits before the searchParams in the redirectURL
  const url = window.location.href?.split("redirectURL=");
  const redirectURL = url.length > 1 ? url[1] : "";
  const { inProgress, instance } = useMsal();

  const location = useLocation();
  const state = location.state;
  const isAuthorized =
    state?.isAuthorized === null || state?.isAuthorized === undefined
      ? true
      : state?.isAuthorized;
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated && isAuthorized && redirectURL != "")
      navigate(redirectURL);
  }, [isAuthenticated, isAuthorized, instance?.getActiveAccount()]);

  if (inProgress !== InteractionStatus.None) {
    return <EditSkeleton text={"Checking permissions"} color={"error"} />;
  }

  return (
    <FullScreenMessage
      title={`Forbidden!`}
      code={403}
      color={`error`}
      message={`You don't have permission to view this page!`}
      instructions={`Please contact your administrator for more details.`}
    />
  );
}
