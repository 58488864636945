import React from "react";
import {
  AccordionDetails,
  Box,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import BaseCheckBox from "components/shared/baseForm/BaseCheckBox";
import BaseInput from "components/shared/baseForm/BaseInput";

export default function MealObservationAccordionDetails({
  section,
  watch,
  control,
  errors,
}) {
  return (
    <AccordionDetails sx={{ padding: "1rem 0px" }}>
      {section?.items?.map((item, itemIndex) => (
        <Box key={itemIndex}>
          <Box className="visitReportsAccordian">
            <Typography
              component="p"
              sx={{
                color: "black",
                fontWeight: "700",
                background: "#C4E1E2",
                p: "0.8rem 1rem",
              }}>
              {item?.name}
            </Typography>

            <Divider />
            <Box>
              <Box sx={{ padding: "1rem" }}>
                {item?.configs?.map((config, taskId) => (
                  <Box key={taskId} className="configBox">
                    <Box
                      sx={{
                        width: {
                          md: "35vw",
                          xl: "25vw",
                        },
                      }}>
                      {!config?.isSubItem ? (
                        <Typography
                          component="p"
                          sx={{
                            fontWeight: "600",
                            fontSize: "18px",
                          }}>
                          {config?.name}
                        </Typography>
                      ) : (
                        <Typography
                          component="p"
                          sx={{
                            fontWeight: "600",
                            fontSize: "18px",
                          }}>
                          {config?.name}
                        </Typography>
                      )}
                    </Box>
                    <Box
                      sx={{
                        width: "95%",
                        marginTop: "5px",
                      }}>
                      <Grid container className="gridStyling" spacing={0.5}>
                        {config?.hasCompleted ? (
                          <Grid item>
                            <BaseCheckBox
                              name={`hasCompleted-${config?.taskId}`}
                              id={`hasCompleted-${config?.taskId}`}
                              label="Completed"
                              control={control}
                              errors={errors}
                              size="small"
                            />
                          </Grid>
                        ) : null}
                        {config?.hasMeetsStandard ? (
                          <Grid item>
                            <BaseCheckBox
                              name={`hasMeetsStandard-${config?.taskId}`}
                              id={`hasMeetsStandard-${config?.taskId}`}
                              label="Meets Standard"
                              control={control}
                              errors={errors}
                              size="small"
                            />
                          </Grid>
                        ) : null}
                        {config?.hasCompleteNext ? (
                          <Grid item>
                            <BaseCheckBox
                              name={`hasCompleteNext-${config?.taskId}`}
                              id={`hasCompleteNext-${config?.taskId}`}
                              label="Future Consult"
                              control={control}
                              errors={errors}
                              size="small"
                            />
                          </Grid>
                        ) : null}
                        {config?.hasNeedsCorrection ? (
                          <Grid item>
                            <BaseCheckBox
                              name={`hasNeedsCorrection-${config?.taskId}`}
                              id={`hasNeedsCorrection-${config?.taskId}`}
                              label="Action Required"
                              control={control}
                              errors={errors}
                              size="small"
                            />
                          </Grid>
                        ) : null}
                        {config?.hasSeeRecommendation ? (
                          <Grid item>
                            <BaseCheckBox
                              name={`hasSeeRecommendation-${config?.taskId}`}
                              id={`hasSeeRecommendation-${config?.taskId}`}
                              label="See Recommend"
                              control={control}
                              errors={errors}
                              size="small"
                            />
                          </Grid>
                        ) : null}
                        {config?.hasComment ||
                        watch(`hasSeeRecommendation-${config.taskId}`) ? (
                          <Grid item md={4}>
                            <BaseInput
                              name={`hasComment-${config?.taskId}`}
                              id={`hasComment-${config?.taskId}`}
                              label="Comment here"
                              control={control}
                              errors={errors}
                              type={"text"}
                              size="small"
                            />
                          </Grid>
                        ) : null}
                      </Grid>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      ))}
    </AccordionDetails>
  );
}
