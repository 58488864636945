import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { BASE_MENUS } = API_ENDPOINTS;

export const BaseMenuAPIs = BaseApi.injectEndpoints({
  // tagTypes: ['BaseMenus'],
  endpoints: (builder) => ({
    // define an endpoint for getting baseMenus
    getBaseMenus: builder.query({
      query: (searchTerm) => `/${BASE_MENUS}?searchText=${searchTerm}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: BASE_MENUS,
                id,
              })),
              BASE_MENUS,
            ]
          : [BASE_MENUS];
      },
    }),
    getBaseMenusSelectBox: builder.query({
      query: (searchTerm) => `/${BASE_MENUS}?searchText=${searchTerm}`,
      // providesTags: ['BaseMenus'],
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: BASE_MENUS,
                id,
              })),
              BASE_MENUS,
            ]
          : [BASE_MENUS];
      },
    }),
    deleteBaseMenu: builder.mutation({
      query: (id) => ({
        url: `/${BASE_MENUS}/${id}`,
        method: "DELETE",
        body: id,
      }),
      // invalidatesTags: ['BaseMenus'],
      invalidatesTags: (result, error, arg) => [
        { type: BASE_MENUS, id: arg.id },
      ],
    }),
    addBaseMenu: builder.mutation({
      query: (data) => ({
        url: `/${BASE_MENUS}`,
        method: "POST",
        body: data,
      }),
      // invalidatesTags: ['BaseMenus'],
      invalidatesTags: (result) => [{ type: BASE_MENUS, id: result?.id }],
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetBaseMenusQuery,
  useDeleteBaseMenuMutation,
  useAddBaseMenuMutation,
  useGetBaseMenusSelectBoxQuery,
} = BaseMenuAPIs;
