import React, { useEffect } from "react";
import {
  useGetThickenedLiquidByIdQuery,
  useAddThickenedLiquidMutation,
  useUpdateThickenedLiquidMutation,
} from "../../../../../../store/apis/LiquidConsistencyApis";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, Divider, Grid, Typography } from "@mui/material";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import GeneratedForm from "../../../../../shared/generatedForm/GeneratedForm";
import { EditSkeleton } from "../../../../admin.overlays";
import { LIQUID_CONSISTENCY_FIELDS } from "./forms.constants";
import { BaseButton } from "../../../../../shared/BaseButton";

export default function AddLiquidConsistency() {
  const [addLiquid, { isError: addError, isLoading: addLoading }] =
    useAddThickenedLiquidMutation();

  const [updateLiquid, { isError: updateError, isLoading: updateLoading }] =
    useUpdateThickenedLiquidMutation();

  const isError = addError || updateError;
  const isSubmitting = addLoading || updateLoading;
  const [searchParams] = useSearchParams();
  const EditId = searchParams.get("id");

  const isEdit = !!EditId;

  const {
    data: liquid,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetThickenedLiquidByIdQuery(EditId, {
    skip: !isEdit,
  });

  const isEditLoading = isEdit && currentFetching;

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (currentSuccess && !currentFetching && liquid) {
      reset(liquid);
    }
  }, [liquid]);

  const onSubmit = (data) => {
    if (isEdit) {
      updateLiquid(data);
    } else {
      addLiquid(data);
    }
    if (!isError) {
      navigate(-1);
    }
  };

  return (<>
    {isEditLoading ? (
      <EditSkeleton />
    ) : (
      <>
        <div className="leftActions">
          <BaseButton
            text="Back To Liquid Consistencies List"
            startIcon={"keyboard_arrow_left"}
            onClick={() => navigate(-1)}
          />
        </div>
        <Divider sx={{ marginBottom: "15px", border: "none" }} />

        <Box sx={{
          padding: "8px"
        }}>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}>
            <GeneratedForm
              control={control}
              list={LIQUID_CONSISTENCY_FIELDS}
              errors={errors}
            />
            <Grid item xs={5}>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <BaseSubmitButton
                  fullWidth
                  onClick={handleSubmit(onSubmit)}
                  isSubmitting={isSubmitting}
                  text={isEdit ? "Submit" : "Create"}
                />
              </Box>
              {isError ? (
                <Typography sx={{
                  color: "error"
                }}>
                  There was an error submitting the form, please try again.
                </Typography>
              ) : undefined}
            </Grid>
            <Grid item xs={3} />
          </Grid>
        </Box>
      </>
    )}
  </>);
}
