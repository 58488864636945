import React from "react";
import YesNoDisplay from "components/shared/YesNoDisplay";
import { startCase } from "lodash";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import { SCREEN_CONSTANTS } from "./constants";

export function CreateBaseColumn({
  field,
  sortable,
  headerName,
  renderCell,
  flex = 1,
  minWidth = 100,
  align,
  headerAlign = "left",
  isBoolean = false,
  valueFormatter = null,
  disableClickEventBubbling,
  disableColumnMenu,
  display = "flex",
  dateFormatter = null,
  cellClassName,
  ...rest
}) {
  let computedHeaderName = headerName;

  if (!headerName) {
    if (field === "actions") {
      computedHeaderName = "Actions";
    } else {
      computedHeaderName = startCase(field);
    }
  }

  let columnDef = {
    field,
    headerName: computedHeaderName,
    flex,
    minWidth,
    headerAlign,
    align: align || headerAlign || "left",
    display,
    sortable: sortable || field !== "actions",
    disableClickEventBubbling: disableClickEventBubbling || field === "actions",
    disableColumnMenu: disableColumnMenu || field === "actions",
    ...rest,
  };

  if (cellClassName) {
    columnDef.cellClassName = cellClassName;
  }

  if (isBoolean || rest.type === DATAGRID_COLUMN_TYPES.BOOLEAN) {
    columnDef.renderCell = ({ value }) => {
      return (
        <Box>
          <YesNoDisplay value={value} />
        </Box>
      );
    };
  } else if (renderCell) {
    columnDef.renderCell = renderCell;
  }

  if (valueFormatter) {
    columnDef.valueFormatter = valueFormatter;

    return columnDef;
  } else if (rest.type === DATAGRID_COLUMN_TYPES.DATE) {
    if (!dateFormatter) {
      columnDef.valueFormatter = COLUMN_DATE_FORMATTER.LOCALE_DATE;
    } else {
      columnDef.valueFormatter = dateFormatter;
    }
  } else if (rest.type === DATAGRID_COLUMN_TYPES.DATETIME) {
    if (!dateFormatter) {
      columnDef.valueFormatter = COLUMN_DATE_FORMATTER.LOCALE_DATE_TIME;
    } else {
      columnDef.valueFormatter = dateFormatter;
    }
  }

  return columnDef;
}

export default function CreateBaseColumns(columns) {
  return columns.map((column) => CreateBaseColumn(column));
}

// https://mui.com/x/react-data-grid/column-definition/#column-types
export const DATAGRID_COLUMN_TYPES = {
  STRING: "string",
  NUMBER: "number",
  DATE: "date",
  DATETIME: "datetime",
  BOOLEAN: "boolean",
  SINGLESELECT: "singleSelect",
  ACTIONS: "actions",
};

export const COLUMN_DATE_FORMATTER = {
  LOCALE_DATE: (value) => {
    const isDateValid = dayjs(value).isValid();
    return isDateValid
      ? dayjs(value).format(SCREEN_CONSTANTS.Date_Format)
      : "--";
  },
  LOCALE_DATE_TIME: (value) => {
    const isDateValid = dayjs(value).isValid();
    return isDateValid
      ? dayjs(value).format(SCREEN_CONSTANTS.DateTime_Format)
      : "--";
  },
};
