import React from "react";

import ProtectedRoute from "../ProtectedRoute";
import { PAGE_ROLES } from "components/shared/constants";

import ChatAIConvo from "components/chatAIConvo/ChatAIConvo";

const { AI_CHAT } = PAGE_ROLES;

export const chatAIConvo = {
  path: "chatAIConvo",
  element: (
    <ProtectedRoute allowedRoles={AI_CHAT}>
      <ChatAIConvo />
    </ProtectedRoute>
  ),
};
