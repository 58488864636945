export const DietManual2017 = [
  {
    id: 1,
    name: "Approval Page",
    url: "http://healthtechdocs.blob.core.windows.net/dietmanual2017-scc/SCC%202017%20Approval%20Page.pdf",
  },
  {
    id: 2,
    name: "Section 1: Introduction and Diet Summary",
    url: "http://healthtechdocs.blob.core.windows.net/dietmanual2017-scc/SCC%20Diet%20Manual%202017_Section%201%20Updated.pdf",
  },
  {
    id: 3,
    name: "Section 2: Consistency Modifications",
    url: "http://healthtechdocs.blob.core.windows.net/dietmanual2017-scc/SCC%20Diet%20Manual%202017_Section%202%20Updated.pdf",
  },
  {
    id: 4,
    name: "Section 3: Diabetes and Weight",
    url: "http://healthtechdocs.blob.core.windows.net/dietmanual2017-scc/SCC%20Diet%20Manual%202017_Section%203%20Updated.pdf",
  },
  {
    id: 5,
    name: "Section 4: Heart and Cardiovascular",
    url: "http://healthtechdocs.blob.core.windows.net/dietmanual2017-scc/SCC%20Diet%20Manual%202017_Section%204%20Updated.pdf",
  },
  {
    id: 6,
    name: "Section 5: Gastrointestinal",
    url: "http://healthtechdocs.blob.core.windows.net/dietmanual2017-scc/SCC%20Diet%20Manual%202017_Section%205%20Updated.pdf",
  },
  {
    id: 7,
    name: "Section 6: Renal",
    url: "http://healthtechdocs.blob.core.windows.net/dietmanual2017-scc/SCC%20Diet%20Manual%202017_Section%206%20Updated.pdf",
  },
  {
    id: 8,
    name: "Section 7: Increased Nutrient Needs",
    url: "http://healthtechdocs.blob.core.windows.net/dietmanual2017-scc/SCC%20Diet%20Manual%202017_Section%207%20Updated.pdf",
  },
  {
    id: 9,
    name: "Section 8: Other Diets",
    url: "http://healthtechdocs.blob.core.windows.net/dietmanual2017-scc/SCC%20Diet%20Manual%202017_Section%208%20Updated.pdf",
  },
  {
    id: 10,
    name: "Section 9: Enteral and Parenteral",
    url: "http://healthtechdocs.blob.core.windows.net/dietmanual2017-scc/SCC%20Diet%20Manual%202017_Section%209%20Updated.pdf",
  },
  {
    id: 11,
    name: "Section 10: Appendix",
    url: "http://healthtechdocs.blob.core.windows.net/dietmanual2017-scc/SCC%20Diet%20Manual%202017_Section%2010%20Appendix%20Updated.pdf",
  },
];

export const DietManual2022 = [
  {
    id: 0,
    optionName: "Terms and TOC",
    isActive: false,
    DropdownValues: [
      {
        id: 0,
        name: "Abbrevations",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/00_Abbreviations.pdf",
      },
      {
        id: 1,
        name: "Approval",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/00_Approval%20Page.pdf",
      },
      {
        id: 2,
        name: "Copy Right",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/00_Copyright.pdf",
      },
      {
        id: 3,
        name: "Terms and Conditions",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/00_TOC_2022%20Diet%20Manual.pdf",
      },
    ],
  },
  {
    id: 1,
    optionName: "Using this Manual",
    isActive: false,
    DropdownValues: [
      {
        id: 0,
        name: "Using the Diet Order Communication Form",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%201_1%20Using%20the%20Diet%20Order%20Communication%20Form.pdf",
      },
      {
        id: 1,
        name: "Diet Order Communication Form",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%201_2%20Diet%20Order%20Communication%20Form.pdf",
      },
      {
        id: 2,
        name: "Using the Individualized Dining Services Communication Form",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%201_3%20Using%20the%20Individualized%20Dining%20Services%20Communication%20Form.pdf",
      },
      {
        id: 3,
        name: "Diet Summary",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%201_4%20Diet%20Summary.pdf",
      },
      {
        id: 4,
        name: "Principles and Guidelines Used in Menu Planning",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%201_5%20Principles%20and%20Guidelines%20Used%20in%20Menu%20Planning.pdf",
      },
      {
        id: 5,
        name: "References",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%201_6%20References.pdf",
      },
    ],
  },
  {
    id: 2,
    optionName: "Diets for Consistency Modifications",
    isActive: false,
    DropdownValues: [
      {
        id: 0,
        name: "Dental Soft (Mechanical Soft) Diet",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%202_1%20Dental%20Soft%20(Mechanical%20Soft)%20Diet.pdf",
      },
      {
        id: 1,
        name: "Pureed Diet",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%202_2%20Pureed%20Diet.pdf",
      },
      {
        id: 2,
        name: "Full Liquid Diet",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%202_3%20Full%20Liquid%20Diet.pdf",
      },
      {
        id: 3,
        name: "Clear Liquid Diet",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%202_4%20Clear%20Liquid%20Diet.pdf",
      },
      {
        id: 4,
        name: "IDDSI Pureed Diet Level 4",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%202_5%20IDDSI%20Pureed%20Diet%20Level%204.pdf",
      },
      {
        id: 5,
        name: "IDDSI Minced & Moist Level 5",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%202_6%20IDDSI%20Minced%20&%20Moist%20Level%205.pdf",
      },
      {
        id: 6,
        name: "IDDSI Soft & Bite Sized Level 6",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%202_7%20IDDSI%20Soft%20&%20Bite%20Sized%20Level%206.pdf",
      },
      {
        id: 7,
        name: "IDDSI Regular Easy to Chew Level 7",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%202_8%20IDDSI%20Regular%20Easy%20to%20Chew%20Level%207.pdf",
      },
      {
        id: 8,
        name: "IDDSI Thickened Liquids",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%202_9%20IDDSI%20Thickened%20Liquids.pdf",
      },
      {
        id: 9,
        name: "Texture Modified Diets Tips to Assist With Safe Meal Intakes",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%202_10%20RESOURCE%20Texture%20Modified%20Diets%20Tips%20to%20Assist%20With%20Safe%20Meal%20Intakes.pdf",
      },
      {
        id: 10,
        name: "References",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%202_11%20References.pdf",
      },
    ],
  },
  {
    id: 3,
    optionName: "Diets for Diabetes and Weight Control",
    isActive: false,
    DropdownValues: [
      {
        id: 0,
        name: "Diabetic Precautions",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%203_1%20Diabetic%20Precautions.pdf",
      },
      {
        id: 1,
        name: "Diabetic Precautions Educational Handout",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%203_2%20RESOURCE%20Diabetic%20Precautions%20Educational%20Handout.pdf",
      },
      {
        id: 2,
        name: "Consistent Carbohydrate Diet",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%203_3%20Consistent%20Carbohydrate%20Diet.pdf",
      },
      {
        id: 3,
        name: "Carbohydrate Counting",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%203_4%20Carbohydrate%20Counting.pdf",
      },
      {
        id: 4,
        name: "Diabetic Meal Patterns",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%203_5%20Diabetic%20Meal%20Patterns.pdf",
      },
      {
        id: 5,
        name: "Diabetic Clear Liquid and Full Liquid Meal Plans and Sick Day Guidelines",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%203_6%20Diabetic%20Clear%20Liquid%20and%20Full%20Liquid%20Meal%20Plans%20and%20Sick%20Day%20Guidelines.pdf",
      },
      {
        id: 6,
        name: "Treatment of Hypoglycemia",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%203_7%20Treatment%20of%20Hypoglycemia.pdf",
      },
      {
        id: 7,
        name: "ORIGINAL Diet Manual Indiv Food Lists",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%203_9%20Resource%20ORIGINAL%20Diet%20Manual%20Indiv%20Food%20Lists.pdf",
      },
      {
        id: 8,
        name: "Weight Control",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%203_9%20Weight%20Control.pdf",
      },
      {
        id: 9,
        name: "References",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%203_10%20References.pdf",
      },
    ],
  },
  {
    id: 4,
    optionName: "Diets for Heart and Cardiovascular Disease",
    isActive: false,
    DropdownValues: [
      {
        id: 0,
        name: "No Added Salt Diet",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%204_1%20No%20Added%20Salt%20Diet.pdf",
      },
      {
        id: 1,
        name: "Sodium Precautions",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%204_2%20Sodium%20Precautions.pdf",
      },
      {
        id: 2,
        name: "Sodium Precautions Educational Handout",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%204_3%20RESOURCE%20Sodium%20Precautions%20Educational%20Handout.pdf",
      },
      {
        id: 3,
        name: "2 Gram Sodium Diet",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%204_2%20Gram%20Sodium%20Diet.pdf",
      },
      {
        id: 4,
        name: "1500 mg Sodium Diet",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%204_5%201500%20mg%20Sodium%20Diet.pdf",
      },
      {
        id: 5,
        name: "Dash Diet",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%204_6%20Dash%20Diet.pdf",
      },
      {
        id: 6,
        name: "Heart Healthy Precautions",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%204_7%20RESOURCE%20Heart%20Healthy%20Precautions.pdf",
      },
      {
        id: 7,
        name: "Heart Healthy Precautions Educational Handout",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%204_8%20RESOURCE%20Heart%20Healthy%20Precautions%20Educational%20Handout.pdf",
      },
      {
        id: 8,
        name: "Healthy Herbs and Spices",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%204_9%20RESOURCE%20Healthy%20Herbs%20and%20Spices.pdf",
      },
      {
        id: 9,
        name: "Cholesterol and Fats Information Sheet",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%204_10%20RESOURCE%20Cholesterol%20and%20Fats%20Information%20Sheet.pdf",
      },
      {
        id: 10,
        name: "References",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%204_11%20References.pdf",
      },
    ],
  },
  {
    id: 5,
    optionName: "Diets for Gastrointestinal Disorders",
    isActive: false,
    DropdownValues: [
      {
        id: 0,
        name: "Low Fat Diet",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%205_1%20Low%20Fat%20Diet.pdf",
      },
      {
        id: 1,
        name: "High Fiber Diet",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%205_2%20High%20Fiber%20Diet.pdf",
      },
      {
        id: 2,
        name: "Low Fiber Diet",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%205_3%20Low%20Fiber%20Diet.pdf",
      },
      {
        id: 3,
        name: "Bland Diet",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%205_4%20Bland%20Diet.pdf",
      },
      {
        id: 4,
        name: "GERD Hiatal Hernia Diet",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%205_5%20GERD%20Hiatal%20Hernia%20Diet.pdf",
      },
      {
        id: 5,
        name: "Gluten Free Diet",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%205_6%20Gluten%20Free%20Diet.pdf",
      },
      {
        id: 6,
        name: "Low Lactose Diet",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%205_7%20Low%20Lactose%20Diet.pdf",
      },
      {
        id: 7,
        name: "Low FODMAP",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%205_8%20Low%20FODMAP.pdf",
      },
      {
        id: 8,
        name: "References",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%205_8%20References.pdf",
      },
    ],
  },
  {
    id: 6,
    optionName: "Diets for Renal Disease",
    isActive: false,
    DropdownValues: [
      {
        id: 0,
        name: "Renal Precautions",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%206_1%20Renal%20Precautions.pdf",
      },
      {
        id: 1,
        name: "Renal Precautions Educational Handout",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%206_2%20RESOURCE%20Renal%20Precautions%20Educational%20Handout.pdf",
      },
      {
        id: 2,
        name: "Renal Diet Food Choice Lists",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%206_3%20Renal%20Diet%20Food%20Choice%20Lists.pdf",
      },
      {
        id: 3,
        name: "Renal Diet, Meal Pattern and Menu Plan",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%206_4%20Renal%20Diet,%20Meal%20Pattern%20and%20Menu%20Plan.pdf",
      },
      {
        id: 4,
        name: "Liberal Renal Diet",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%206_5%20Liberal%20Renal%20Diet.pdf",
      },
      {
        id: 5,
        name: "Liberal Renal CCHO (Consistent Carbohydrate) Diet",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%206_6%20Liberal%20Renal%20CCHO%20(Consistent%20Carbohydrate)%20Diet.pdf",
      },
      {
        id: 6,
        name: "Fluid Restrictions",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%206_7%20Fluid%20Restrictions.pdf",
      },
      {
        id: 7,
        name: "Renal Sack Lunch",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%206_8%20Renal%20Sack%20Lunch.pdf",
      },
      {
        id: 8,
        name: "References",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%206_9%20References.pdf",
      },
    ],
  },
  {
    id: 7,
    optionName: "Nutrition Interventions for Increased Nutrient Needs",
    isActive: false,
    DropdownValues: [
      {
        id: 0,
        name: "Pressure Ulcers and Wounds",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%207_1%20Pressure%20Ulcers%20and%20Wounds.pdf",
      },
      {
        id: 1,
        name: "Unintentional Weight Loss",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%207_2%20Unintentional%20Weight%20Loss.pdf",
      },
      {
        id: 2,
        name: "High Calorie and Protein Foods List",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%207_3%20RESOURCE%20High%20Calorie%20and%20Protein%20Foods%20List.pdf",
      },
      {
        id: 3,
        name: "Fortified Enhanced Power Foods Protocol (FEP)",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%207_4%20Fortified%20Enhanced%20Power%20Foods%20Protocol%20(FEP).pdf",
      },
      {
        id: 4,
        name: "References",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%207_5%20References.pdf",
      },
    ],
  },
  {
    id: 8,
    optionName: "Other Diets",
    isActive: false,
    DropdownValues: [
      {
        id: 0,
        name: "Allergy Diets",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%208_1%20Allergy%20Diets.pdf",
      },
      {
        id: 1,
        name: "Finger Food Diet",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%208_2%20Finger%20Food%20Diet.pdf",
      },
      {
        id: 2,
        name: "Vegetarian Diets",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%208_3%20Vegetarian%20Diets.pdf",
      },
      {
        id: 3,
        name: "Phenylketonuria PKU Diet",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%208_4%20Phenylketonuria%20PKU%20Diet.pdf",
      },
      {
        id: 4,
        name: "40 Gram Protein Diet",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%208_5%2040%20Gram%20Protein%20Diet.pdf",
      },
      {
        id: 5,
        name: "Food for Religious Faiths and Beliefs",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%208_6%20Food%20for%20Religious%20Faiths%20and%20Beliefs.pdf",
      },
      {
        id: 6,
        name: "References",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%208_7%20References.pdf",
      },
    ],
  },
  {
    id: 9,
    optionName: "Enteral and Parenteral Nutrition",
    isActive: false,
    DropdownValues: [
      {
        id: 0,
        name: "Enteral Nutrition",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%209_1%20Enteral%20Nutrition.pdf",
      },
      {
        id: 1,
        name: "Parenteral Nutrition",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%209_2%20Parenteral%20Nutrition.pdf",
      },
      {
        id: 2,
        name: "References",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Section%209_3%20References.pdf",
      },
    ],
  },
  {
    id: 10,
    optionName: "Appendix",
    isActive: false,
    DropdownValues: [
      {
        id: 0,
        name: "DRI Values Summary",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Appendix%20_01%20DRI%20Values%20Summary-%20A%20complete%20document%20containing%20the%20four%20tables%20listed%20above.pdf",
      },
      {
        id: 1,
        name: "Healthy Meal Selection Guidelines",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Appendix_2%20Healthy%20Meal%20Selection%20Guidelines.pdf",
      },
      {
        id: 2,
        name: "Height and Weight Determination for special circumstances",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Appendix_3%20Height%20and%20Weight%20Determination%20for%20special%20circumstances.pdf",
      },
      {
        id: 3,
        name: "Body Mass Index (BMI)",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Appendix_4%20Body%20Mass%20Index%20(BMI).pdf",
      },
      {
        id: 4,
        name: "BMI Table",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/BMI%20Table.pdf",
      },
      {
        id: 5,
        name: "Caffeine content of foods ",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Appendix_6%20Caffeine%20content%20of%20foods.pdf",
      },
      {
        id: 6,
        name: "Calcium Content of Foods",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Appendix_7%20Calcium%20Content%20of%20Foods.pdf",
      },
      {
        id: 7,
        name: "Fiber Content of Foods",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Appendix_8%20Fiber%20Content%20of%20Foods.pdf",
      },
      {
        id: 8,
        name: "Iron Content of Foods",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Appendix_9%20Iron%20Content%20of%20Foods.pdf",
      },
      {
        id: 9,
        name: "Potassium Content of Foods",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Appendix_10%20Potassium%20Content%20of%20Foods.pdf",
      },
      {
        id: 10,
        name: "Vitamin A Content of Foods",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Appendix_11%20Vitamin%20A%20Content%20of%20Foods.pdf",
      },
      {
        id: 11,
        name: "Vitamin K Content of Foods",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Appendix_12%20Vitamin%20K%20Content%20of%20Foods.pdf",
      },
      {
        id: 12,
        name: "References",
        url: "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/Appendix_14%20References.pdf",
      },
    ],
  },
];

export const GuidelineManualList = [
  {
    id: 1,
    optionName: "Department Organisation 2020",
    isActive: false,
    DropdownValues: [
      {
        id: 0,
        name: "Organizational Plan and Roles of Key Staff",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/01_Department%20Organization%202020/01.01_Organizational%20Plan%20and%20Roles%20of%20Key%20Staff.pdf",
      },
      {
        id: 1,
        name: "Dining Services Manager Roles",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/01_Department%20Organization%202020/01.02_Dining%20Service%20Manager%20Role.pdf",
      },
      {
        id: 2,
        name: "Registered Consultant Roles and Responsibilities ",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/01_Department%20Organization%202020/01.03_Registered%20Dietitian%20Roles%20and%20Responsibilities.pdf",
      },
    ],
  },
  {
    id: 2,
    optionName: "Budget and Cost Management 2020",
    isActive: false,
    DropdownValues: [
      {
        id: 0,
        name: "Cost Management Tools",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/02_Budget%20and%20Cost%20Management%202020/02.01_Cost%20Management%20Tools.pdf",
      },
      {
        id: 1,
        name: "Dining Services Budget",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/02_Budget%20and%20Cost%20Management%202020/02.02_Dining%20Services%20Budget.pdf",
      },
      {
        id: 2,
        name: "Monitoring Supplement Usage",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/02_Budget%20and%20Cost%20Management%202020/02.03_Monitoring%20Supplement%20Use.pdf",
      },
      {
        id: 3,
        name: "Resource_Census by Diet Form",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/02_Budget%20and%20Cost%20Management%202020/02.04_Resource_Census%20By%20Diet%20Form.pdf",
      },
      {
        id: 4,
        name: "Resource: How To Calculate Full-Time Equivalents (FTEs)",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/02_Budget%20and%20Cost%20Management%202020/02.05_Resource_How%20to%20Calculate%20Full%20Time%20Equivalents.pdf",
      },
      {
        id: 5,
        name: "Resource: Labor Hour Calculations and Meals per Labor Hour Calculation",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/02_Budget%20and%20Cost%20Management%202020/02.06_Resource_Labor%20Hour%20Calculations%20and%20Meals%20per%20Labor%20Hour%20Calculation.pdf",
      },
      {
        id: 6,
        name: "Resource: How To Determine the Amount Available to Spend in a Month",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/02_Budget%20and%20Cost%20Management%202020/02.07_Resource_How%20to%20Determine%20the%20Amount%20Available%20to%20Spend%20in%20a%20Month.pdf",
      },
      {
        id: 7,
        name: "Resource: Evaluating Dining Service Cost",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/02_Budget%20and%20Cost%20Management%202020/02.08_Resource_Evaluating%20Dining%20Service%20Cost.pdf",
      },
      {
        id: 8,
        name: "Resource_Sample Production Sheet",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/02_Budget%20and%20Cost%20Management%202020/02.09_Resource_Sample%20Production%20Sheet.pdf",
      },
      {
        id: 9,
        name: "Sample Order Guide",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/02_Budget%20and%20Cost%20Management%202020/02.10_Resource_Sample%20Order%20Guide.pdf",
      },
    ],
  },
  {
    id: 3,
    optionName: "Dining Services 2020",
    isActive: false,
    DropdownValues: [
      {
        id: 0,
        name: "Dining Service Objectives",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.01_Dining%20Service%20Objectives.pdf",
      },
      {
        id: 1,
        name: "Dining Service Staff Roles",
        url: "hhttps://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.02_Dining%20Service%20Staff%20Roles.pdf",
      },
      {
        id: 2,
        name: "Adaptive Devices",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.03_Adaptive%20Devices.pdf",
      },
      {
        id: 3,
        name: "Resource: Adaptive Device Inventory",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.04_RESOURCE%20Adaptive%20Device%20Inventory.pdf",
      },
      {
        id: 4,
        name: "Condiments Served at the Table",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.05_Condiments%20Served%20at%20the%20Table.pdf",
      },
      {
        id: 5,
        name: "Dining Options for Meal Service",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.06_Dining%20Options%20for%20Meal%20Service.pdf",
      },
      {
        id: 6,
        name: "Dining Room Standards",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.07_Dining%20Room%20Standards.pdf",
      },
      {
        id: 7,
        name: "Resource: Dining Room Audit",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.07_RESOURCE%20Dining%20Room%20Audit.pdf",
      },
      {
        id: 8,
        name: "Dining Service Resident Seating",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.08_Dining%20Service%20Resident%20Seating.pdf",
      },
      {
        id: 9,
        name: "Farmers Markets and Garden Produce",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.09_Farmers%20Markets%20&%20Garden%20Produce.pdf",
      },
      {
        id: 10,
        name: "Food Availability, Pantry or Kitchenette",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.10_Food%20Availability%20Pantry%20or%20Kitchenette.pdf",
      },
      {
        id: 11,
        name: "Food from Family, Visitors, Community",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.11_Food%20from%20Family%20&%20Visitors,%20Comm%20Meals.pdf",
      },
      {
        id: 12,
        name: "Food Provided by Activities",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.12_Food%20Provided%20by%20Activities.pdf",
      },
      {
        id: 13,
        name: "Guest Meals",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.13_Guest%20Meals.pdf",
      },
      {
        id: 14,
        name: "Handling Resident Concerns",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.14_Handling%20Resident%20Concerns.pdf",
      },
      {
        id: 15,
        name: "Resource for Handling Resident Concerns",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.15_Resource%20for%20Handling%20Resident%20Concerns.pdf",
      },
      {
        id: 16,
        name: "Honoring Choice",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.16_Honoring%20Choice.pdf",
      },
      {
        id: 17,
        name: "In-Room Dining",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.17A_In%20Room%20Dining.pdf",
      },
      {
        id: 18,
        name: "In-Room Dining for Infection Control",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.17B_In%20Room%20Dining%20for%20Infection%20Control.pdf",
      },
      {
        id: 19,
        name: "Resource: In-Room Dining for Infection Control",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.17C_Resource%20In%20Room%20Dining%20for%20Infection%20Control%20Delivery%20Cart%20Signage.pdf",
      },
      {
        id: 20,
        name: "Meal Identification, Resident Meal Card",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.18_Meal%20Identification%20Resident%20Meal%20Card.pdf",
      },
      {
        id: 21,
        name: "Meal Observation & Food Replacement",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.19_Meal%20Observation%20&%20Food%20Replacement.pdf",
      },
      {
        id: 22,
        name: "Meals Packed to Go",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.20_Meals%20Packed%20To%20Go.pdf",
      },
      {
        id: 23,
        name: "Offer Versus Serve Meal Service",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.21_Offer%20Versus%20Serve%20Meal%20Service.pdf",
      },
      {
        id: 24,
        name: "Paid Feeding Assistants",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.22_Paid%20Feeding%20Assistants.pdf",
      },
      {
        id: 25,
        name: "Pets",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.23_Pets.pdf",
      },
      {
        id: 26,
        name: "Posted Menu Display",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.24_Posted%20Menu%20Display.pdf",
      },
      {
        id: 27,
        name: "Precautions for Handling Hot Beverages",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.25_Precautions%20for%20Handling%20Hot%20Beverages.pdf",
      },
      {
        id: 28,
        name: "Pre-Service Meal Communication",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.26_Pre-Service%20Meal%20Communication.pdf",
      },
      {
        id: 29,
        name: "Resource: Pre-Service Meal Communication",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.27_Resource%20Pre-Service%20Meal%20Communication%20Form.pdf",
      },
      {
        id: 30,
        name: "RESOURCE: Dining Services Opening and Closing Checklist",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.27_RESOURCE_Dining%20Services%20Opening%20Closing%20Checklist.pdf",
      },
      {
        id: 31,
        name: "Single Service Articles in Meal Service",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.28_Single%20Service%20Articles%20in%20Meal%20Service.pdf",
      },
      {
        id: 32,
        name: "Use of Garnish",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.29_Use%20of%20Garnish.pdf",
      },
      {
        id: 33,
        name: "Use of Steam Table in Dining Area",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/03_Dining%20Services%202020/03.30_Use%20of%20Steam%20Table%20in%20Dining%20Area.pdf",
      },
    ],
  },
  {
    id: 4,
    optionName: "Disaster and Emergency 2020",
    isActive: false,
    DropdownValues: [
      {
        id: 0,
        name: "Disaster Instructions",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/04_Disaster%20and%20Emergency%202020/04.01_Disaster%20Instructions.pdf",
      },
      {
        id: 1,
        name: "Emergency Dishwashing",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/04_Disaster%20and%20Emergency%202020/04.02_Emergency%20Dishwashing.pdf",
      },
      {
        id: 2,
        name: "Resource_Sample Emergency Menu",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/04_Disaster%20and%20Emergency%202020/04.03_Resource_Sample%20Emergency%20Menu.pdf",
      },
      {
        id: 3,
        name: "Emergency Water Protocol",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/04_Disaster%20and%20Emergency%202020/04.04_Emergency%20Water%20Protocol.pdf",
      },
      {
        id: 4,
        name: "Resource: Emergency Supply Checklist",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/04_Disaster%20and%20Emergency%202020/04.05_Resource%20Emergency%20Supply%20Checklist.pdf",
      },
      {
        id: 5,
        name: "Resource: Emergency Supply Location Record",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/04_Disaster%20and%20Emergency%202020/04.06_Resource%20Emergency%20Supply%20Location%20Record.pdf",
      },
      {
        id: 6,
        name: "Resource: Employee Phone Roster",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/04_Disaster%20and%20Emergency%202020/04.07_Resource%20Employee%20Phone%20Roster.pdf",
      },
      {
        id: 7,
        name: "Resource: Individual Roles and Responsibilities",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/04_Disaster%20and%20Emergency%202020/04.08_Resource%20Individual%20Roles%20and%20Responsibilities.pdf",
      },
      {
        id: 8,
        name: "Emergency Preparedness and Training",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/04_Disaster%20and%20Emergency%202020/04.09_Emergency%20Preparedness%20and%20Training.pdf",
      },
    ],
  },
  {
    id: 5,
    optionName: "Food Production 2020",
    isActive: false,
    DropdownValues: [
      {
        id: 0,
        name: "Cooking and Cooling",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/05_Food%20Production%202020/05.01_Cooking%20and%20Cooling.pdf",
      },
      {
        id: 1,
        name: "RESOURCE_Cooling Monitor Form",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/05_Food%20Production%202020/05.02_RESOURCE_Cooling%20Monitor%20Form.pdf",
      },
      {
        id: 2,
        name: "Resource_Minimum Internal Cooking Food Temperatures",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/05_Food%20Production%202020/05.03_Resource_Minimum%20Internal%20Cooking%20Food%20Temperatures.pdf",
      },
      {
        id: 3,
        name: "RESOURCE_Food Temperature Log Breakfast",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/05_Food%20Production%202020/05.04_RESOURCE_Food%20Temperature%20Log%20Breakfast.pdf",
      },
      {
        id: 4,
        name: "RESOURCE_Food Temperature Log_Lunch Dinner",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/05_Food%20Production%202020/05.05_RESOURCE_Food%20Temperature%20Log_Lunch%20Dinner.pdf",
      },
      {
        id: 5,
        name: "Delivery Guidelines",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/05_Food%20Production%202020/05.06_Delivery%20Guidelines.pdf",
      },
      {
        id: 6,
        name: "Food Purchasing and Inspection of Food Deliveries",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/05_Food%20Production%202020/05.07_Food%20Purchasing%20and%20Inspection%20of%20Food%20Deliveries.pdf",
      },
      {
        id: 7,
        name: "Food Storage Dry Refrigerated Frozen",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/05_Food%20Production%202020/05.08A_Food%20Storage%20Dry%20Refrigerated%20Frozen.pdf",
      },
      {
        id: 8,
        name: "RESOURCE Food Storage Guide",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/05_Food%20Production%202020/05.08B_RESOURCE%20Food%20Storage%20Guide.pdf",
      },
      {
        id: 9,
        name: "Garbage and Rubbish Disposal",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/05_Food%20Production%202020/05.09_Garbage%20and%20Rubbish%20Disposal.pdf",
      },
      {
        id: 10,
        name: "HACCP and Foodborne Illness",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/05_Food%20Production%202020/05.10_HACCP%20and%20Foodborne%20Illness.pdf",
      },
      {
        id: 11,
        name: "Handling Leftover Food",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/05_Food%20Production%202020/05.12_Handling%20Leftover%20Food.pdf",
      },
      {
        id: 12,
        name: "Monitoring Food Temperatures for Meal Service",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/05_Food%20Production%202020/05.13_Monitoring%20Food%20Temperatures%20for%20Meal%20Service.pdf",
      },
      {
        id: 13,
        name: "Labeling Dating Foods Date Marking",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/05_Food%20Production%202020/05.14_Labeling%20Dating%20Foods%20Date%20Marking.pdf",
      },
      {
        id: 14,
        name: "Pureed Food Preparation",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/05_Food%20Production%202020/05.15_Pureed%20Food%20Preparation.pdf",
      },
      {
        id: 15,
        name: "Serving Temperatures for Hot and Cold Foods",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/05_Food%20Production%202020/05.16_Serving%20Temperatures%20for%20Hot%20and%20Cold%20Foods.pdf",
      },
      {
        id: 16,
        name: "Standardized Recipes",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/05_Food%20Production%202020/05.17_Standardized%20Recipes.pdf",
      },
      {
        id: 17,
        name: "Thermometer Calibration",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/05_Food%20Production%202020/05.18_Thermometer%20Calibration.pdf",
      },
      {
        id: 18,
        name: "Use of Production Sheets",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/05_Food%20Production%202020/05.19_Use%20of%20Production%20Sheets.pdf",
      },
      {
        id: 19,
        name: "Resource_Sample Production Sheet",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/05_Food%20Production%202020/05.20_Resource_Sample%20Production%20Sheet.pdf",
      },
      {
        id: 20,
        name: "Use of Shell Eggs and Past Egg Products",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/05_Food%20Production%202020/05.21_Use%20of%20Shell%20Eggs%20and%20Past%20Egg%20Products.pdf",
      },
    ],
  },
  {
    id: 6,
    optionName: "Menu Management 2020",
    isActive: false,
    DropdownValues: [
      {
        id: 0,
        name: "Menu Diet Spreadsheets",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/06_Menu%20Management%202020/06.01_Menu%20Diet%20Spreadsheets.pdf",
      },
      {
        id: 1,
        name: "Menu Planning and Requirements",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/06_Menu%20Management%202020/06.02_Menu%20Planning%20and%20Requirements.pdf",
      },
      {
        id: 2,
        name: "Menu Posting and Display",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/06_Menu%20Management%202020/06.03_Menu%20Posting%20and%20Display.pdf",
      },
      {
        id: 3,
        name: "Menu Record Filing",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/06_Menu%20Management%202020/06.04_Menu%20Record%20Filing.pdf",
      },
      {
        id: 4,
        name: "Menu Substitutions or Changes and Approval",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/06_Menu%20Management%202020/06.05_Menu%20Substitutions%20or%20Changes%20and%20Approval.pdf",
      },
      {
        id: 5,
        name: "RESOURCE_Menu Substution Form",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/06_Menu%20Management%202020/06.06_RESOURCE_Menu%20Substution%20Form.pdf",
      },
      {
        id: 6,
        name: "Other Menu Options",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/06_Menu%20Management%202020/06.07_Other%20Menu%20Selections.pdf",
      },
      {
        id: 7,
        name: "Portion Variations",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/06_Menu%20Management%202020/06.08_Portion%20Variations.pdf",
      },
      {
        id: 8,
        name: "Resident Choice and Holiday Meals",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/06_Menu%20Management%202020/06.09_Resident%20Choice%20and%20Holiday%20Meals.pdf",
      },
    ],
  },
  {
    id: 7,
    optionName: "Nutritional Care 2020",
    isActive: false,
    DropdownValues: [
      {
        id: 0,
        name: "Calorie Counts",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.01_Calorie%20Counts.pdf",
      },
      {
        id: 1,
        name: "RESOURCE_Calculating Food Intake Using Points",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.02_RESOURCE_Calculating%20Food%20Intake%20Using%20Points.pdf",
      },
      {
        id: 2,
        name: "RESOURCE_Calorie Count Documentation Form",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.03_RESOURCE_Calorie%20Count%20Documentation%20Form.pdf",
      },
      {
        id: 3,
        name: "Care Planning",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.04_Care%20Planning.pdf",
      },
      {
        id: 4,
        name: "Diet Alterations for Morbid Obesity and Bariatric Care",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.05_Diet%20Alterations%20for%20Morbid%20Obesity%20and%20Bariatric%20Care.pdf",
      },
      {
        id: 5,
        name: "Diet Manual",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.06_Diet%20Manual.pdf",
      },
      {
        id: 6,
        name: "Diet Orders",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.07_Diet%20Orders.pdf",
      },
      {
        id: 7,
        name: "Liberalized House Diet",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.08_Liberalized%20House%20Diet.pdf",
      },
      {
        id: 8,
        name: "Resource_Physician Letter for Liberalized Diets",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.09_Resource_Physician%20Letter%20for%20Liberalized%20Diets.pdf",
      },
      {
        id: 9,
        name: "AND Position Paper on IndividualizedNutritionApproachesforOlderAdults",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.10_AND%20Position%20Paper%20on%20IndividualizedNutritionApproachesforOlderAdults.pdf",
      },
      {
        id: 10,
        name: "Resource_Diet Order Communication Form",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.11_Resource_Diet%20Order%20Communication%20Form.pdf",
      },
      {
        id: 11,
        name: "Diet Precautions_Diabetic",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.12_Diet%20Precautions_Diabetic.pdf",
      },
      {
        id: 12,
        name: "Diet Precautions_Heart Healthy",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.13_Diet%20Precautions_Heart%20Healthy.pdf",
      },
      {
        id: 13,
        name: "Diet Precautions_Renal",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.14_Diet%20Precautions_Renal.pdf",
      },
      {
        id: 14,
        name: "Diet Precautions_Sodium",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.15_Diet%20Precautions_Sodium.pdf",
      },
      {
        id: 15,
        name: "Dining Services Communication Book",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.16_Dining%20Services%20Communication%20Book.pdf",
      },
      {
        id: 16,
        name: "End of Life Care Guidelines",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.17_End%20of%20Life%20Care%20Guidelines.pdf",
      },
      {
        id: 17,
        name: "Enteral Nutrition (EN) -Tube Feeding",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.18_Enteral%20Nutrition%20(EN)%20-Tube%20Feeding.pdf",
      },
      {
        id: 18,
        name: "Food Intake",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.19_Food%20Intake.pdf",
      },
      {
        id: 19,
        name: "Food Preferences",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.20_Food%20Preferences.pdf",
      },
      {
        id: 20,
        name: "Resource_Food Preferences Form",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.21_Resource_Food%20Preferences%20Form.pdf",
      },
      {
        id: 21,
        name: "Fortified Enhanced Power Foods (FEP) Protocol",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.22_Fortified%20Enhanced%20Power%20Foods%20(FEP)%20Protocol.pdf",
      },
      {
        id: 22,
        name: "Fortified Foods, Snack, Supplements",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.23_Fortified%20Foods,%20Snack,%20Supplements.pdf",
      },
      {
        id: 23,
        name: "Resource_High Calorie and Protein Foods List",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.24_Resource_High%20Calorie%20and%20Protein%20Foods%20List.pdf",
      },
      {
        id: 24,
        name: "Hydration Cart",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.25_Hydration%20Cart.pdf",
      },
      {
        id: 25,
        name: "Hydration Monitoring Protocol",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.26_Hydration%20Monitoring%20Protocol.pdf",
      },
      {
        id: 26,
        name: "Interventions for Gastroenteritis",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.27_Interventions%20for%20Gastroenteritis.pdf",
      },
      {
        id: 27,
        name: "Nutrition Education",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.28_Nutrition%20Education.pdf",
      },
      {
        id: 28,
        name: "Resident Nutritional Care",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.29_Resident%20Nutritional%20Care.pdf",
      },
      {
        id: 29,
        name: "RESOURCE_Nutrition Progress Checklist",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.30_RESOURCE_Nutrition%20Progress%20Checklist.pdf",
      },
      {
        id: 30,
        name: "Routine Nutritional Documentation and Assessment",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.31_Routine%20Nutritional%20Documentation%20and%20Assessment.pdf",
      },
      {
        id: 31,
        name: "Resource Diet History and Screening",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.32_Resource%20Diet%20History%20and%20Screening.pdf",
      },
      {
        id: 32,
        name: "Resource Dietary History and Initial Screening Form",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.32_Resource%20Dietary%20History%20and%20Initial%20Screening%20Form.pdf",
      },
      {
        id: 33,
        name: "Screening and Initial Evaluation",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.33_Screening%20and%20Initial%20Evaluation.pdf",
      },
      {
        id: 34,
        name: "Thickening Fluids",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.34_Thickening%20Fluids.pdf",
      },
      {
        id: 35,
        name: "Weight Assessment and Intervention",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.35_Weight%20Assessment%20and%20Intervention.pdf",
      },
      {
        id: 36,
        name: "Resource_Weekly Weight Log",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/07_Nutritional%20Care%202020/07.36_Resource_Weekly%20Weight%20Log.pdf",
      },
    ],
  },
  {
    id: 8,
    optionName: "Personnel and Training 2020",
    isActive: false,
    DropdownValues: [
      {
        id: 0,
        name: "Code of Dress and Personal Appearance",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/08_Personnel%20and%20Training%202020/08.01_Code%20of%20Dress%20and%20Personal%20Appearance.pdf",
      },
      {
        id: 1,
        name: "Employee Health Program and Infection Control",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/08_Personnel%20and%20Training%202020/08.02_Employee%20Health%20Program%20and%20Infection%20Control.pdf",
      },
      {
        id: 2,
        name: "Monthly Dietary Consultant Report",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/08_Personnel%20and%20Training%202020/08.03_Monthly%20Dietary%20Consultant%20Report.pdf",
      },
      {
        id: 3,
        name: "In-service and Training Documentation",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/08_Personnel%20and%20Training%202020/08.04_In-service%20and%20Training%20Documentation.pdf",
      },
      {
        id: 4,
        name: "In-service Education and Training",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/08_Personnel%20and%20Training%202020/08.05_In-service%20Education%20and%20Training.pdf",
      },
      {
        id: 5,
        name: "Resource_Inservice Training Documentation Forms",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/08_Personnel%20and%20Training%202020/08.06_Resource_Inservice%20Training%20Documentation%20Forms.pdf",
      },
      {
        id: 6,
        name: "Resource_Dining Services Orientation Check List",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/08_Personnel%20and%20Training%202020/08.07_Resource_Dining%20Services%20Orientation%20Check%20List.pdf",
      },
      {
        id: 7,
        name: "Resource_Comprehensive Employee Competency Review",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/08_Personnel%20and%20Training%202020/08.08_Resource_Comprehensive%20Employee%20Competency%20Review.pdf",
      },
    ],
  },
  {
    id: 9,
    optionName: "Quality Control 2020",
    isActive: false,
    DropdownValues: [
      {
        id: 1,
        name: "QAPI",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/09_Quality%20Control%202020/09.01_QAPI.pdf",
      },
      {
        id: 2,
        name: "Quality Control of Dining Services",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/09_Quality%20Control%202020/09.02_Quality%20Control%20of%20Dining%20Services.pdf",
      },
      {
        id: 3,
        name: "Point of Service Temperature Audit",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/09_Quality%20Control%202020/09.03_Point%20of%20Service%20Temperature%20Audit.pdf",
      },
      {
        id: 4,
        name: "RESOURCE_Point of Service Temperature Audit form",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/09_Quality%20Control%202020/09.04_RESOURCE_Point%20of%20Service%20Temperature%20Audit%20form.pdf",
      },
      {
        id: 5,
        name: "RESOURCE_Evaluating Weight Loss",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/09_Quality%20Control%202020/09.05_RESOURCE_Evaluating%20Weight%20Loss.pdf",
      },
      {
        id: 6,
        name: "RESOURCE Dining Room Audit",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/09_Quality%20Control%202020/09.07_RESOURCE%20Dining%20Room%20Audit.pdf",
      },
      {
        id: 7,
        name: "RESOURCE_Kitchen Inspection Checklist",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/09_Quality%20Control%202020/09.08_RESOURCE_Kitchen%20Inspection%20Checklist.pdf",
      },
      {
        id: 8,
        name: "RESOURCE_Meal Satisfaction Survey Form",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/09_Quality%20Control%202020/09.09_RESOURCE_Meal%20Satisfaction%20Survey%20Form.pdf",
      },
      {
        id: 9,
        name: "RESOURCE_MealCard Diet Order Audit form",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/09_Quality%20Control%202020/09.10_RESOURCE_MealCard%20Diet%20Order%20Audit%20form.pdf",
      },
    ],
  },
  {
    id: 10,
    optionName: "Safety 2020",
    isActive: false,
    DropdownValues: [
      {
        id: 1,
        name: "Accident Prevention",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/10_Safety%202020/10.01_Accident%20Prevention.pdf",
      },
      {
        id: 2,
        name: "Cutlery",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/10_Safety%202020/10.02_Cutlery.pdf",
      },
      {
        id: 3,
        name: "Foreign Items Found in Food",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/10_Safety%202020/10.03_Foreign%20Items%20Found%20in%20Food.pdf",
      },
      {
        id: 4,
        name: "Resource_Record of Foreign Items",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/10_Safety%202020/10.04_Resource%20Record%20of%20Foreign%20Items.pdf",
      },
      {
        id: 5,
        name: "General Health and Safety",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/10_Safety%202020/10.05_General%20Health%20and%20Safety.pdf",
      },
      {
        id: 6,
        name: "Glass",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/10_Safety%202020/10.06_Glass.pdf",
      },
      {
        id: 7,
        name: "Hazard Reports",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/10_Safety%202020/10.07_Hazard%20Reports.pdf",
      },
      {
        id: 8,
        name: "Hot Items",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/10_Safety%202020/10.08_Hot%20Items.pdf",
      },
      {
        id: 9,
        name: "Prevention of Falls",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/10_Safety%202020/10.09_Prevention%20of%20Falls.pdf",
      },
      {
        id: 10,
        name: "Prevention of Skin Injuries",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/10_Safety%202020/10.10_Prevention%20of%20Skin%20Injuries.pdf",
      },
      {
        id: 11,
        name: "Prevention of Sprains and Strains",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/10_Safety%202020/10.11_Prevention%20of%20Sprains%20and%20Strains.pdf",
      },
    ],
  },
  {
    id: 11,
    optionName: "Sanitation 2020",
    isActive: false,
    DropdownValues: [
      {
        id: 0,
        name: "Cleaning Rotation",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/11.01_Cleaning%20Rotation.pdf",
      },
      {
        id: 1,
        name: "RESOURCE_Sample Monthly Cleaning Schedule",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/11.02_RESOURCE_Sample%20Monthly%20Cleaning%20Schedule.pdf",
      },
      {
        id: 2,
        name: "RESOURCE_Monthly Cleaning Schedule",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/11.03_RESOURCE_Monthly%20Cleaning%20Schedule.pdf",
      },
      {
        id: 3,
        name: "RESOURCE_Sample Weekly Cleaning Schedule",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/11.04_RESOURCE_Sample%20Weekly%20Cleaning%20Schedule.pdf",
      },
      {
        id: 4,
        name: "RESOURCE_Weekly Cleaning Schedule",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/11.05_RESOURCE_Weekly%20Cleaning%20Schedule.pdf",
      },
      {
        id: 5,
        name: "RESOURCE_Sample Daily Cleaning Schedule",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/11.06_RESOURCE_Sample%20Daily%20Cleaning%20Schedule.pdf",
      },
      {
        id: 6,
        name: "RESOURCE_Daily Cleaning Schedule",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/11.07_RESOURCE_Daily%20Cleaning%20Schedule.pdf",
      },
      {
        id: 7,
        name: "Dishwashing Machine",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/11.08_Dishwashing%20Machine.pdf",
      },
      {
        id: 8,
        name: "RESOURCE_Dish Machine High Temperature Sanitizing Log",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/11.09_RESOURCE_Dish%20Machine%20High%20Temperature%20Sanitizing%20Log.pdf",
      },
      {
        id: 9,
        name: "RESOURCE_Dish Machine PPM Sanitizer Log",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/11.10_RESOURCE_Dish%20Machine%20PPM%20Sanitizer%20Log.pdf",
      },
      {
        id: 10,
        name: "Dishwashing Manual",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/11.11_Dishwashing%20Manual.pdf",
      },
      {
        id: 11,
        name: "Hair Restraints",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/11.12_Hair%20Restraints.pdf",
      },
      {
        id: 12,
        name: "Handling Tableware",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/11.12_Handling%20Tableware.pdf",
      },
      {
        id: 13,
        name: "Ice Handling and Cleaning",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/11.12_Ice%20Handling%20and%20Cleaning.pdf",
      },
      {
        id: 14,
        name: "Isolation Trays",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/11.13_Isolation%20Trays.pdf",
      },
      {
        id: 15,
        name: "Proper Hand Washing and Glove Use",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/11.14_Proper%20Hand%20Washing%20and%20Glove%20Use.pdf",
      },
      {
        id: 16,
        name: "Refrigerator & Freezer Temperatures",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/11.15_Refrigerator%20&%20Freezer%20Temperatures.pdf",
      },
      {
        id: 17,
        name: "Refrigerators in Resident Rooms",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/11.16_Refrigerators%20in%20Resident%20Rooms.pdf",
      },
      {
        id: 18,
        name: "Sanitation Guidelines for Open Dining",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/11.17_Sanitation%20Guidelines%20for%20Open%20Dining.pdf",
      },
      {
        id: 19,
        name: "Sanitation of Dining and Food Service Areas",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/11.18_Sanitation%20of%20Dining%20and%20Food%20Service%20Areas.pdf",
      },
      {
        id: 20,
        name: "Sanitizing Solution",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/11.19_Sanitizing%20Solution.pdf",
      },
      {
        id: 21,
        name: "Storing Utensils, Tableware, and Equipment",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/11.20_Storing%20Utensils,%20Tableware,%20and%20Equipment.pdf",
      },
      {
        id: 22,
        name: "Sanitizing Equipment and Food Contact Surfaces",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/11.21_Sanitizing%20Equipment%20and%20Food%20Contact%20Surfaces.pdf",
      },
      {
        id: 23,
        name: "Cleaning Instructions Beverage Table",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Beverage%20Table.pdf",
      },
      {
        id: 24,
        name: "Cleaning Instructions Cabinets and Drawers",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Cabinets%20and%20Drawers.pdf",
      },
      {
        id: 25,
        name: "Cleaning Instructions Can Opener",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Can%20Opener.pdf",
      },
      {
        id: 26,
        name: "Cleaning Instructions Ceilings and Walls",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Ceilings%20and%20Walls.pdf",
      },
      {
        id: 27,
        name: "Cleaning Instructions Cleaning Closet",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Cleaning%20Closet.pdf",
      },
      {
        id: 28,
        name: "Cleaning Instructions Cloths Pads Mops Buckets",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Cloths%20Pads%20Mops%20Buckets.pdf",
      },
      {
        id: 29,
        name: "Cleaning Instructions Coffee Machine",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Coffee%20Machine.pdf",
      },
      {
        id: 30,
        name: "Cleaning Instructions Conventional Oven",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Conventional%20Oven.pdf",
      },
      {
        id: 31,
        name: "Cleaning Instructions Cutting Boards",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Cutting%20Boards.pdf",
      },
      {
        id: 32,
        name: "Cleaning Instructions Deep Fat Fryer",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Deep%20Fat%20Fryer.pdf",
      },
      {
        id: 33,
        name: "Cleaning Instructions Flat-Top Grill",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Flat-Top%20Grill.pdf",
      },
      {
        id: 34,
        name: "Cleaning Instructions Floors",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Floors.pdf",
      },
      {
        id: 35,
        name: "Cleaning Instructions Food Carts",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Food%20Carts.pdf",
      },
      {
        id: 36,
        name: "Cleaning Instructions Food Preparation Appliances",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Food%20Preparation%20Appliances.pdf",
      },
      {
        id: 37,
        name: "Cleaning Instructions Garbage Disposal",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Garbage%20Disposal.pdf",
      },
      {
        id: 38,
        name: "Cleaning Instructions Hand Washing Sink",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Hand%20Washing%20Sink.pdf",
      },
      {
        id: 39,
        name: "Cleaning Instructions Hoods and Filters",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Hoods%20and%20Filters.pdf",
      },
      {
        id: 40,
        name: "Cleaning Instructions Ice Machine",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Ice%20Machine.pdf",
      },
      {
        id: 41,
        name: "Cleaning Instructions Ingredient Bins",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Ingredient%20Bins.pdf",
      },
      {
        id: 42,
        name: "Cleaning Instructions Insulated Food Containers",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Insulated%20Food%20Containers.pdf",
      },
      {
        id: 43,
        name: "Cleaning Instructions Kettles and Utensils",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Kettles%20and%20Utensils.pdf",
      },
      {
        id: 44,
        name: "Cleaning Instructions Microwave Oven",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Microwave%20Oven.pdf",
      },
      {
        id: 45,
        name: "Cleaning Instructions Range",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Range.pdf",
      },
      {
        id: 46,
        name: "Cleaning Instructions Reach In Refrigerator and Freezer",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Reach%20In%20Refrigerator%20and%20Freezer.pdf",
      },
      {
        id: 47,
        name: "Cleaning Instructions Slicer",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Slicer.pdf",
      },
      {
        id: 48,
        name: "Cleaning Instructions Steam Table",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Steam%20Table.pdf",
      },
      {
        id: 49,
        name: "Cleaning Instructions Tables and Chairs",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Tables%20and%20Chairs.pdf",
      },
      {
        id: 50,
        name: "Cleaning Instructions Toaster",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Toaster.pdf",
      },
      {
        id: 51,
        name: "Cleaning Instructions Trash Barrels and Trash Area",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Trash%20Barrels%20and%20Trash%20Area.pdf",
      },
      {
        id: 52,
        name: "Cleaning Instructions Walk In Refrigerator and Freezer",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Walk%20In%20Refrigerator%20and%20Freezer.pdf",
      },
      {
        id: 53,
        name: "Cleaning Instructions Work Tables and Counters",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/11_Sanitation%202020/12_Cleaning%20Instructions%20Work%20Tables%20and%20Counters.pdf",
      },
    ],
  },
  {
    id: 12,
    optionName: "Resources",
    isActive: false,
    DropdownValues: [
      {
        id: 1,
        name: "Entrance Conference Form",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/12_Resources/14.01_Entrance%20Conference%20Form.pdf",
      },
      {
        id: 2,
        name: "Dining-Observation_CMS",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/12_Resources/14.02_Dining-Observation_CMS.pdf",
      },
      {
        id: 3,
        name: "Kitchen-Food-Service-Observation_CMS",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/12_Resources/14.03_Kitchen-Food-Service-Observation_CMS.pdf",
      },
      {
        id: 4,
        name: "Resource Tag Crosswalk",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/12_Resources/14.04_Resource%20Tag%20Crosswalk.pdf",
      },
      {
        id: 5,
        name: "Resource_Case Studies for Survey Readiness",
        url: "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/12_Resources/14.05_Resource_Case%20Studies%20for%20Survey%20Readiness.pdf",
      },
    ],
  },
];
