import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const {
  RECIPES,
  COPYRECIPES,
  RECIPE_NUTRIENT_ANALYSIS,
  RECIPE_VENDOR_COSTS,
  VENDOR_INGREDIENTS,
} = API_ENDPOINTS;

export const RecipeAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRecipes: builder.query({
      query: (searchText) =>
        `/${RECIPES}?searchText=${encodeURIComponent(searchText)}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: RECIPES,
                id,
              })),
              RECIPES,
            ]
          : [RECIPES];
      },
    }),
    getRecipeSelectBox: builder.query({
      query: (searchText) =>
        `/${RECIPES}?searchText=${encodeURIComponent(searchText)}`,
      transformResponse: (response) =>
        response?.data?.map((item) => ({
          id: item.id,
          label: item.name,
          description: item?.description,
        })),
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: RECIPES,
                id,
              })),
              RECIPES,
            ]
          : [RECIPES];
      },
    }),
    getRecipeById: builder.query({
      query: (id) => `/${RECIPES}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response ? [{ type: RECIPES, id: response.id }, RECIPES] : [RECIPES],
    }),
    getRecipeByIdUserFacility: builder.query({
      query: ({ recipeId, userId, facilityId }) =>
        `/${RECIPES}/${recipeId}?userId=${userId}&facilityId=${facilityId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response ? [{ type: RECIPES, id: response.id }, RECIPES] : [RECIPES],
    }),
    getSubstituteRecipes: builder.query({
      query: ({ menuId, menuCategoryName, currentRecipeId, mealId }) =>
        `/${RECIPES}/SubstituteRecipes?menuId=${menuId}&menuCategoryName=${menuCategoryName}&currentRecipeId=${currentRecipeId}&mealId=${mealId}`,
      // transformResponse: (response) => response?.data,s
      transformResponse: (response) =>
        response?.data?.map((res) => ({
          id: res.id,
          label: res.name,
        })),
      providesTags: (response) =>
        response ? [{ type: RECIPES, id: response.id }, RECIPES] : [RECIPES],
    }),
    addRecipe: builder.mutation({
      query: ({ userId, facilityId, data }) => ({
        url: `/${RECIPES}/${userId}/${facilityId}`,
        method: "POST",
        body: data,
        formData: true,
      }),
      invalidatesTags: (result) => [{ type: RECIPES, id: result?.id }],
    }),
    updateRecipe: builder.mutation({
      query: ({ userId, facilityId, data }) => ({
        url: `/${RECIPES}/${userId}/${facilityId}`,
        method: "PUT",
        body: data,
        formData: true,
      }),
      invalidatesTags: (result) => [{ type: RECIPES, id: result?.id }],
    }),
    copyRecipe: builder.mutation({
      query: ({ recipeId, userId, facilityId, isChecked }) => {
        return {
          url: `/${COPYRECIPES}?recipeId=${recipeId}&userId=${userId}&facilityId=${facilityId}&copySpreads=${isChecked}`,
          method: "POST",
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: (result) => [{ type: RECIPES, id: result?.id }],
    }),
    deleteRecipe: builder.mutation({
      query: (id) => ({
        url: `/${RECIPES}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result) => [{ type: RECIPES, id: result?.id }],
    }),
    recipeNutrientAnalysis: builder.query({
      query: (id) => `${RECIPE_NUTRIENT_ANALYSIS}?recipeId=${id}`,
      // transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.data.nutrients.map(({ id }) => ({
                type: RECIPE_NUTRIENT_ANALYSIS,
                id,
              })),
              RECIPE_NUTRIENT_ANALYSIS,
            ]
          : [RECIPE_NUTRIENT_ANALYSIS];
      },
    }),
    recalculateNutrientAnalysis: builder.mutation({
      query: (id) => ({
        url: `${RECIPE_NUTRIENT_ANALYSIS}/Recalculate?recipeId=${id}`,
        method: "POST",
      }),
      invalidatesTags: [RECIPE_NUTRIENT_ANALYSIS],
    }),
    recipeVendorCosts: builder.query({
      query: ({ id, searchTerm }) =>
        `${RECIPE_VENDOR_COSTS}?recipeId=${id}&searchText=${searchTerm}&skip=0&take=1000`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response?.recipeVendors
          ? [
              ...response.recipeVendors.map(({ id }) => ({
                type: RECIPE_VENDOR_COSTS,
                id,
              })),
              RECIPE_VENDOR_COSTS,
            ]
          : [RECIPE_VENDOR_COSTS];
      },
    }),
    deleteRecipeVendorCost: builder.mutation({
      query: (id) => ({
        url: `${RECIPE_VENDOR_COSTS}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RECIPE_VENDOR_COSTS],
    }),
    addVendorCost: builder.mutation({
      query: (data) => ({
        url: `${RECIPE_VENDOR_COSTS}`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: [RECIPE_VENDOR_COSTS],
    }),
    recalculateVendorCosts: builder.mutation({
      query: (id) => ({
        url: `${RECIPE_VENDOR_COSTS}/Recalculate?recipeId=${id}`,
        method: "POST",
      }),
      invalidatesTags: () => [RECIPE_VENDOR_COSTS],
    }),
    vendorRecipeProducts: builder.query({
      query: ({ recipeId, vendorId }) =>
        `${RECIPE_VENDOR_COSTS}/GetIngredients?recipeId=${recipeId}&vendorId=${vendorId}&take=100`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response?.recipeVendorIngredients
          ? [
              ...response.recipeVendorIngredients.map(({ id }) => ({
                type: VENDOR_INGREDIENTS,
                id,
              })),
              VENDOR_INGREDIENTS,
            ]
          : [VENDOR_INGREDIENTS];
      },
    }),
    shareRecipe: builder.mutation({
      query: ({ data, recipeId, facilityId }) => {
        return {
          url: `${RECIPES}/Share?recipeId=${recipeId}&facilityId=${facilityId}`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: (result) => [{ type: RECIPES, id: result?.data?.id }],
    }),
    getShareRecipes: builder.query({
      query: ({ recipeId, userFacilityId }) =>
        `/${RECIPES}/Share?recipeId=${recipeId}&userFacilityId=${userFacilityId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: RECIPES,
                id,
              })),
              RECIPES,
            ]
          : [RECIPES];
      },
    }),
    deleteFacilityShare: builder.mutation({
      query: ({ id, FacilityId }) => ({
        url: `/${RECIPES}/Share?recipeId=${id}&facilityId=${FacilityId}`,
        method: "DELETE",
      }),
      invalidatesTags: [RECIPES],
      // invalidatesTags: (result, error, arg) => [{ type: RECIPES, id: arg.id }],
    }),
  }),
});

export const {
  useGetRecipesQuery,
  useGetRecipeSelectBoxQuery,
  useGetRecipeByIdQuery,
  useGetRecipeByIdUserFacilityQuery,
  useAddRecipeMutation,
  useUpdateRecipeMutation,
  useCopyRecipeMutation,
  useDeleteRecipeMutation,
  useRecipeNutrientAnalysisQuery,
  useRecalculateNutrientAnalysisMutation,
  useRecipeVendorCostsQuery,
  useAddVendorCostMutation,
  useRecalculateVendorCostsMutation,
  useVendorRecipeProductsQuery,
  useGetSubstituteRecipesQuery,
  useShareRecipeMutation,
  useGetShareRecipesQuery,
  useDeleteFacilityShareMutation,
} = RecipeAPIs;
