export const OptionsList = [
  {
    id: 0,
    optionName: "My Rosters",
    name: "My Roster",
    Link: "/Consultant/MyRosters",
    // isActive: false,
  },
  {
    id: 1,
    optionName: "Community Information",
    Link: "/Consultant/Community/Information",
    isActive: false,
  },
  {
    id: 2,
    optionName: "Community Visit History",
    Link: "/Consultant/Community/Profile",
    isActive: false,
  },
];
