export const COMMUNITY_DIETITIAN_DEFAULTS = {
  id: 0,
  facilityId: null,
  dietitianId: null,
  mileageRatePaidToDietitian: 0,
  hourlyRatePaidToDietitian: 0,
  lastUpdated: null,
  userUpdated: null,
  isTemporary: false,
  dateEndTemporary: null,
  originalFileName: "",
  secureFileName: "",
  dateUploaded: null,
};
