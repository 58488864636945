export const PlateRecipesOptionsList = [
  {
    id: 1,
    optionName: "Recipe Details",
    Link: "/plateFul/Recipes/RecipeDetail",
    isActive: false,
  },
  {
    id: 2,
    optionName: "Ingredients",
    Link: "/plateFul/Recipes/Ingredients",
    isActive: false,
  },
  {
    id: 3,
    optionName: "Nutrient Analysis",
    Link: "/plateFul/Recipes/Nutrient",
    isActive: false,
  },
  {
    id: 4,
    optionName: "Diets",
    Link: "/plateFul/Recipes/diet",
    IsActive: false,
  },
  {
    id: 5,
    optionName: "Sub-Recipes",
    Link: "/plateFul/Recipes/subRecipe",
    isActive: false,
  },
  {
    id: 6,
    optionName: "Dislikes/Intolerances",
    Link: "/plateFul/Recipes/dislikes",
    isActive: false,
  },
  {
    id: 7,
    optionName: "Tags",
    Link: "/plateFul/Recipes/RecipeTags",
    isActive: false,
  },
  {
    id: 8,
    optionName: "Copy This Recipe",
  },
  {
    id: 9,
    optionName: "Change History",
    Link: "/plateFul/Recipes/history",
    isActive: false,
  },
];

export const PlateRecipesOptions2List = [
  {
    id: 11,
    optionName: "Menu Usage",
    Link: "/plateFul/Recipes/menu",
    isActive: false,
  },
  {
    id: 12,
    optionName: "Vendor Cost",
    Link: "/plateFul/Recipes/Cost",
    isActive: false,
  },
];

export const Labels = {
  EMPTY_STRING: "",
  EMPTY_ARRAY: [],
};
