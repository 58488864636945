import React from "react";
import { Outlet } from "react-router-dom";
import { DietitianSetupElements } from "./dietitiansetup.constants";
import { Card } from "@mui/material";
import AdminTabs from "../../shared/AdminTabs";
import { Box } from "@mui/system";
import useMsalAccount from "utils/useMsalAccount";

export default function DietitianSetup() {

  const { roles: userRoles } = useMsalAccount();

  var tabs = DietitianSetupElements.filter((x) => {
    if (x.AllowedRoles) {
      if (userRoles) {
        for (const element of x.AllowedRoles) {
          if (userRoles.some((y) => y === element)) {
            return true;
          }
        }
      }
      return false;
    }
    return true;
  });

  // console.log(tabs);

  return (<>
    <AdminTabs Data={tabs} />
    <Card
      sx={{
        padding: "5px",
        overflow: "auto",
        borderTopLeftRadius: "0px !important",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}>
      <Box sx={{
        height: "auto"
      }}>
        <Outlet />
      </Box>
    </Card>
  </>);
}
