import { Visibility } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function ViewButton({ index, data, To, title, color }) {
  return (
    <Button
      title={title || "View"}
      style={{ paddingLeft: 8, paddingRight: 8, minWidth: "unset" }}
      color={color || "primary"}
      variant="contained"
      to={To}
      state={{
        title: "View Community",
        data: data?.find((row) => row.id === index),
      }}
      LinkComponent={Link}>
      <Visibility />
    </Button>
  );
}
