import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Navigate, useLocation } from "react-router-dom";

import React, { useEffect } from "react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { EditSkeleton } from "../components/admin/admin.overlays";
import { loginRequest } from "../authConfig";
import { isUserAuthorized } from "components/shared/utils/helpers";
import useMsalAccount from "utils/useMsalAccount";

export default function ProtectedRoute({
  allowedRoles,
  children,
  redirectToIfForbidden,
}) {
  const location = useLocation();
  const LOGGING_IN_TEXT = "Logging in...";
  const LOGGING_OUT_TEXT = "Logging out";
  const REDIRECTING_TEXT = "Redirecting.";
  const AUTHENTICATING_TEXT = "Authenticating";
  const ERROR_COLOR = "error";
  const WARNING_COLOR = "warning";

  const isAuthenticated = useIsAuthenticated();
  const { roles: userRoles } = useMsalAccount();
  const { inProgress, instance } = useMsal();

  useEffect(() => {}, [instance?.getActiveAccount()]);

  useEffect(() => {
    const handleLoginRedirect = async () => {
      if (
        !isAuthenticated &&
        inProgress === InteractionStatus.None &&
        !instance?.getActiveAccount()
      ) {
        try {
          await instance.loginRedirect({
            ...loginRequest,
            prompt: "login",
          });
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            await instance.loginRedirect({
              ...loginRequest,
              forceRefresh: true,
            });
          }
        }
      }
    };

    handleLoginRedirect();
  }, [inProgress, isAuthenticated, instance, loginRequest]);

  const isAuthorized = isUserAuthorized(allowedRoles, userRoles);

  if (inProgress === InteractionStatus.None) {
    if (!isAuthenticated) {
      return <EditSkeleton text={LOGGING_IN_TEXT} color={ERROR_COLOR} />;
    }

    if (!isAuthorized) {
      if (redirectToIfForbidden) return <Navigate to={redirectToIfForbidden} />;
      return (
        <Navigate
          to={`/forbidden?redirectURL=${location?.pathname}${location?.search}`}
          state={{ isAuthorized: false }}
        />
      );
    }

    return children;
  } else if (inProgress === InteractionStatus.Logout) {
    return <EditSkeleton text={LOGGING_OUT_TEXT} color={WARNING_COLOR} />;
  } else if (inProgress === InteractionStatus.Login) {
    return <EditSkeleton text={LOGGING_IN_TEXT} color={ERROR_COLOR} />;
  } else if (inProgress === InteractionStatus.HandleRedirect) {
    return <EditSkeleton text={REDIRECTING_TEXT} color={ERROR_COLOR} />;
  }

  return <EditSkeleton text={AUTHENTICATING_TEXT} color={WARNING_COLOR} />;
}
