import React, { useState } from "react";
import {
  useGetCorporateUserQuery,
  useRefreshCorporateUserMutation,
} from "../../../../../../store/apis/CorporateGroupUserApis";
import { Button } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { BaseButton } from "../../../../../shared/BaseButton";
import BaseSearch from "../../../../../shared/baseSearch/BaseSearch";
import BaseActionBox from "components/shared/BaseActionBox";
import { KeyboardArrowLeft, PersonAdd, Refresh } from "@mui/icons-material";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function SuperUser() {
  const [refreshingId, setRefreshingId] = useState(null);
  const SEARCH_PARAM = "search";

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );

  const currentId = searchParams.get("id");

  const [refreshUser, { isLoading: refreshLoading }] =
    useRefreshCorporateUserMutation();

  const {
    data: corporateGroupsUser,
    isError: corporateGroupsError,
    isFetching: corporateGroupsLoading,
  } = useGetCorporateUserQuery(
    {
      corporateGroupId: currentId,
      searchText: debouncedSearchValue || "",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const corporateGroupColumns = [
    {
      field: "userFullName",
      headerName: "User",
      flex: 2,
    },

    {
      field: "facilitiesCount",
      headerName: "Facility",
      flex: 1,
      //   headerAlign: "center",
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={corporateGroupsUser}
            hasDelete={true}
            deleteProps={{
              entityName: params.row?.userFullName,
              apiPath: "deleteCorporateUser",
              title: "User",
            }}
            childrenLocation={"end"}>
            <BaseButton
              text="Refresh"
              sx={{ mx: "0px !important" }}
              isSubmitting={refreshingId == params?.row?.id && refreshLoading}
              startIcon={<Refresh />}
              onClick={(event) => {
                event.preventDefault();
                setRefreshingId(params.row.id);
                refreshUser({
                  corporateGroupId: currentId,
                  userId: params.row.userId,
                });
              }}
            />
          </BaseActionBox>
        );
      },
    },
  ];

  return (
    <>
      <FlexBox
        justifyContent={"space-between"}
        my={"8px"}
        flexWrap={{ xs: "wrap", md: "nowrap" }}>
        <Button
          component={Link}
          to={"/admin/communities/corporateGroups"}
          color={"primary"}
          variant="contained"
          startIcon={<KeyboardArrowLeft />}>
          {"Back to Corporate Group  List"}
        </Button>
        <BaseSearch
          placeholder="Search..."
          disableUnderline={true}
          type="text"
          sx={{ ml: 1 }}
          SEARCH_PARAM={SEARCH_PARAM}
          value={searchValue}
          setSearchValue={setSearchValue}
          setDebouncedValue={setDebouncedSearchValue}
          setSearchParams={setSearchParams}
          isId={true}
          Id={currentId}
        />
        <Button
          component={Link}
          to={`addsuperUser?id=${currentId}`}
          color={"primary"}
          variant="contained"
          endIcon={<PersonAdd />}>
          Add User
        </Button>
      </FlexBox>

      <BaseDataGrid
        rows={corporateGroupsUser}
        columns={corporateGroupColumns}
        error={corporateGroupsError}
        loading={corporateGroupsLoading}
        autoHeight={false}
        height={{ md: "50dvh", xl: "55dvh" }}
      />
    </>
  );
}
