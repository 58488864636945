import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Grid, Typography, CircularProgress, Box } from "@mui/material";
import {
  useNavigate,
  unstable_useBlocker as useBlocker,
} from "react-router-dom";
import {
  useAddResidentMutation,
  useResidentDiningareaSelectQuery,
  useResidentDiningTableSelectQuery,
} from "../../../store/apis/ResidentApis";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../../store/slices/authSlice/AuthSlice";
import BaseSubmitButton from "../../shared/baseSubmitButton/BaseSubmitButton";
import GeneratedForm from "../../shared/generatedForm/GeneratedForm";
import BaseSelect from "../../shared/baseForm/BaseSelect";
import BaseCheckBox from "../../shared/baseForm/BaseCheckBox";
import BaseInput from "../../shared/baseForm/BaseInput";
import ValidationErrorSummary from "../../shared/validationErrorSummary/ValidationErrorSummary";

import { ResidentAddFields } from "./forms/shortforms.constants";
import BaseContent from "../../shared/baseContent/BaseContent";
import { WarningText } from "../../shared/WarningText";
import BlockerModal from "components/shared/blockerModal/BlockerModal";

export default function AddResidentShortForm() {
  const facilityId = useSelector(selectFacilityId);
  const facilityName = useSelector((state) => state.userInfo.facilityName);
  const [restrictError, setRestrictError] = useState();
  const [hasError, setHasError] = useState(false);

  // unsaved changes functionality here
  const [blockerModal, setBlockerModal] = useState(false);
  const [hasChange, setHasChange] = useState(false);

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  // Block navigating elsewhere when data has been entered into the input
  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) =>
      hasChange && currentLocation.pathname !== nextLocation.pathname,
    [hasChange]
  );

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === "blocked") {
      setBlockerModal(true);
    }
  }, [blocker]);

  useEffect(() => {
    if (watch("isNpo") == true) {
      setValue("dietId", null);
      setValue("dietTextureId", null);
      setValue("dietOther", "");
      setValue("printTrayCard", false);
    }
  }, [watch("isNpo")]);

  // Page Reload and navigation alert implementations
  useEffect(() => {
    window.onbeforeunload = function () {
      if (hasChange) {
        return "You have unsaved changes. Are you sure you want to leave?";
      }
    };
  }, [hasChange]);

  const handleOnLeave = () => {
    blocker.proceed();
    setBlockerModal(false);
    setHasChange(false);
  };
  const handleOnStay = () => {
    blocker.reset();
    setBlockerModal(false);
  };

  useEffect(() => {
    if (isDirty) {
      setHasChange(true);
    }
  }, [isDirty]);

  const [
    addResident,
    {
      data: addResidentData,
      isError: isError,
      isSuccess: isSuccess,
      isSubmitting: isSubmitting,
      error: error,
    },
  ] = useAddResidentMutation();

  const { data: diningmeal } = useResidentDiningareaSelectQuery(
    {
      facilityId: facilityId,
    },
    { skip: !facilityId }
  );

  const navigate = useNavigate();

  const hasFluidRestrict = watch("hasFluidRestrict");
  const breakfastRestrictQty = watch("breakfastRestrictQty");
  const lunchRestrictQty = watch("lunchRestrictQty");
  const supperRestrictQty = watch("supperRestrictQty");

  const breakfastDiningAreaId = watch("breakfastDiningAreaId");
  const lunchDiningAreaId = watch("lunchDiningAreaId");
  const supperDiningAreaId = watch("supperDiningAreaId");

  const { data: breakfastTables } = useResidentDiningTableSelectQuery(
    {
      diningAreaId: breakfastDiningAreaId,
    },
    { skip: !breakfastDiningAreaId }
  );

  const { data: lunchTables } = useResidentDiningTableSelectQuery(
    {
      diningAreaId: lunchDiningAreaId,
    },
    { skip: !lunchDiningAreaId }
  );

  const { data: supperTables } = useResidentDiningTableSelectQuery(
    {
      diningAreaId: supperDiningAreaId,
    },
    { skip: !supperDiningAreaId }
  );

  useEffect(() => {
    if (isSuccess && addResidentData?.data?.id > 0) {
      navigate(`/MealCard/resident/details?id=${addResidentData?.data?.id}`);
    }
  }, [isSuccess]);

  const onSubmit = async (data) => {
    setHasChange(false);
    if (
      hasFluidRestrict &&
      (breakfastRestrictQty === "" ||
        lunchRestrictQty === "" ||
        supperRestrictQty === "")
    ) {
      setHasError(true);
      setRestrictError(
        "You must specify Breakfast, Lunch and Dinner quantities if the 'Fluid Restrictions' box is checked."
      );
    } else {
      addResident({
        ...data,
        facilityId: facilityId,
        tableSideBalance: 0,
        archive: false,
      });
    }
  };

  const [queries] = useState([
    {
      name: "dietId",
      query: { facilityId: facilityId, isTexture: false },
      skip: !facilityId,
    },
    {
      name: "dietTextureId",
      query: { facilityId: facilityId, isTexture: true },
      skip: !facilityId,
    },
    {
      name: "diningmeal",
      query: { facilityId: facilityId },
      skip: !facilityId,
    },
  ]);

  return (<>
    {blockerModal ? (
      <BlockerModal
        text={`Hold up! You've got unsaved changes. Are you sure you want to leave?`}
        open={blockerModal}
        onStay={handleOnStay}
        onLeave={handleOnLeave}
      />
    ) : null}
    <BaseContent
      headerText={`Add New Resident for ${facilityName}`}
      disableHeight={true}>
      <Box sx={{
        height: { md: "83vh", xl: "88vh" }
      }}>
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "row",
          }}>
          <GeneratedForm
            list={ResidentAddFields}
            control={control}
            setValue={setValue}
            errors={errors}
            queries={queries}
          />
          <Grid item xs={12}>
            <div className="TableRow dining-table">
              <Grid
                className="dining-table-column dining-table-bg-color"
                item
                xs={2}>
                <Typography className="dining-table-cell"></Typography>
                <Typography className="dining-table-cell">
                  Breakfast
                </Typography>
                <Typography className="dining-table-cell">Lunch</Typography>
                <Typography className="dining-table-cell">Dinner</Typography>
              </Grid>
              <Grid className="dining-table-column" item xs={3}>
                <Typography className="dining-table-cell dining-table-bg-color">
                  Dining Area
                </Typography>
                <BaseSelect
                  options={diningmeal || []}
                  name={"breakfastDiningAreaId"}
                  id={"breakfastDiningAreaId"}
                  placeholder="Select"
                  label=" "
                  errors={errors}
                  control={control}
                  defaultValue={null}
                />
                <BaseSelect
                  options={diningmeal || []}
                  name={"lunchDiningAreaId"}
                  id={"lunchDiningAreaId"}
                  placeholder="Select"
                  label=" "
                  errors={errors}
                  control={control}
                  defaultValue={null}
                />
                <BaseSelect
                  options={diningmeal || []}
                  name={"supperDiningAreaId"}
                  id={"supperDiningAreaId"}
                  label=" "
                  placeholder="Select"
                  errors={errors}
                  control={control}
                  defaultValue={null}
                />
              </Grid>

              <Grid className="dining-table-column" item xs={2}>
                <Typography className="dining-table-cell dining-table-bg-color">
                  Table
                </Typography>
                {/* <BaseInput
                  name={"breakfastTable"}
                  control={control}
                  errors={errors}
                  defaultValue={""}
                /> */}
                <BaseSelect
                  options={breakfastTables || []}
                  name={"breakfastTableId"}
                  id={"breakfastTableId"}
                  placeholder="Select"
                  label=" "
                  errors={errors}
                  control={control}
                  defaultValue={null}
                />
                {/* <BaseInput
                  name={"lunchTable"}
                  control={control}
                  errors={errors}
                  defaultValue={""}
                /> */}
                <BaseSelect
                  options={lunchTables || []}
                  name={"lunchTableId"}
                  id={"lunchTableId"}
                  placeholder="Select"
                  label=" "
                  errors={errors}
                  control={control}
                  defaultValue={null}
                />
                {/* <BaseInput
                  name={"supperTable"}
                  control={control}
                  errors={errors}
                  defaultValue={""}
                /> */}
                <BaseSelect
                  options={supperTables || []}
                  name={"supperTableId"}
                  id={"supperTableId"}
                  placeholder="Select"
                  label=" "
                  errors={errors}
                  control={control}
                  defaultValue={null}
                />
              </Grid>
              <Grid className="dining-table-column" item xs={2}>
                <Typography className="dining-table-cell dining-table-bg-color">
                  Seat
                </Typography>
                <BaseInput
                  name={"breakfastSeat"}
                  control={control}
                  errors={errors}
                  defaultValue={""}
                />
                <BaseInput
                  name={"lunchSeat"}
                  control={control}
                  errors={errors}
                  defaultValue={""}
                />
                <BaseInput
                  name={"supperSeat"}
                  control={control}
                  errors={errors}
                  defaultValue={""}
                />
              </Grid>

              <Grid className="dining-table-column" item xs={2}>
                <Typography className="dining-table-cell dining-table-bg-color">
                  Fluid Restriction
                </Typography>
                <BaseInput
                  name={"breakfastRestrictQty"}
                  control={control}
                  errors={errors}
                  defaultValue={""}
                  validationProps={{
                    maxLength: {
                      value: 20,
                      message:
                        "Breakfast Fluid Restriction Quantity must be less than 20 characters",
                    },
                  }}
                />
                <BaseInput
                  name={"lunchRestrictQty"}
                  control={control}
                  errors={errors}
                  defaultValue={""}
                  validationProps={{
                    maxLength: {
                      value: 20,
                      message:
                        "Lunch Fluid Restriction Quantity must be less than 20 characters",
                    },
                  }}
                />
                <BaseInput
                  name={"supperRestrictQty"}
                  control={control}
                  errors={errors}
                  defaultValue={""}
                  validationProps={{
                    maxLength: {
                      value: 20,
                      message:
                        "Dinner Fluid Restriction Quantity must be less than 20 characters",
                    },
                  }}
                />
              </Grid>
            </div>
          </Grid>
          {hasError ? (
            <Box sx={{
              width: "100%"
            }}>
              <WarningText text={restrictError} color={"red"} />
            </Box>
          ) : null}
          <Grid item xs={8}>
            <BaseCheckBox
              name="printTrayCard"
              id="printTrayCard"
              label="Print Meal Card"
              control={control}
              errors={errors}
              defaultValue={true}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item md={6} sx={{ width: "100%" }}>
            <BaseSubmitButton
              onClick={handleSubmit(onSubmit)}
              sx={{ marginTop: 1 }}
              isSubmitting={isSubmitting}
              text="Create"
              startIcon={
                isSubmitting && (
                  <CircularProgress color="warning" size={"1rem"} />
                )
              }
            />
          </Grid>

          <Grid item xs={12}>
            {isError ? (
              <Typography sx={{
                color: "error"
              }}>{error?.data?.messages}</Typography>
            ) : undefined}
          </Grid>

          <Grid item xs={12}>
            <ValidationErrorSummary errors={errors} />
          </Grid>
        </Grid>
      </Box>
    </BaseContent>
  </>);
}
