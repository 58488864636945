import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

/**
 * @see [Documentation](../../../Wiki/Documentations/FrontEnd/Components/BaseButton.md)
 */
export const BaseButton = ({
  colors = "primary",
  variant,
  To,
  state,
  endIcon,
  startIcon,
  text,
  onClick,
  isSubmitting,
  target,
  fontColor,
  border,
  disabled = false,
  zIndex,
  marginLeft,
  sx,
  loadingColor,
  rest,
}) => {
  const StartIcon = isSubmitting ? (
    <CircularProgress color={loadingColor ? "primary" : "white"} size={18} />
  ) : startIcon ? (
    <>
      {typeof startIcon === "string" ? (
        <span className="material-icons">{startIcon}</span>
      ) : (
        <>{startIcon}</>
      )}
    </>
  ) : null;
  const EndIcon = endIcon ? (
    <>
      {typeof endIcon === "string" ? (
        <span className="material-icons">{endIcon}</span>
      ) : (
        <>{endIcon}</>
      )}
    </>
  ) : null;

  return (
    <Button
      {...rest}
      state={state}
      component={Link}
      to={To}
      target={target ?? ""}
      rel={target ? "noopener noreferrer" : ""}
      color={colors}
      disabled={isSubmitting || disabled}
      sx={{
        zIndex: zIndex || "",
        color: fontColor,
        border,
        cursor: "pointer",
        marginLeft,
        backgroundColor:
          variant == "outlined" && isSubmitting ? "#E8E8E8 !important" : "",
        // Fix for Link Components in BaseButton having incorrect hover colors
        ...sx,
      }}
      variant={variant || "contained"}
      startIcon={StartIcon}
      endIcon={EndIcon}
      onClick={onClick}>
      {text}
    </Button>
  );
};
