import { FORM_TYPES } from "components/shared/generatedForm/GeneratedForm";
import { MAX_LENGTH_VALIDATION, REQUIRED_ERROR } from "utils/errorMessages";

export const TextFieldsList = [
  {
    props: {
      label: "Consulting Type",
      id: "consultingTypeId",
      name: "consultingTypeId",
    },
    validationProps: {
      required: REQUIRED_ERROR("Consulting Type"),
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getConsultingTypeList",
    size: 10,
  },

  {
    props: {
      name: "rate",
      id: "rate",
      label: "Rate",
      type: "number",
    },
    validationProps: {
      required: REQUIRED_ERROR("Rate"),
      maxLength: MAX_LENGTH_VALIDATION("Rate", 15),
    },
    size: 10,
  },
];
