import { React } from "react";
import { Grid, styled } from "@mui/material";
import BaseSelect from "../../../shared/baseForm/BaseSelect";
import BaseInput from "../../../shared/baseForm/BaseInput";
import { ResidentInformationFields } from "./forms.constants";
import BaseDatePicker from "components/shared/baseForm/BaseDatePicker";
import { REQUIRED_ERROR } from "utils/errorMessages";

export default function ResidentInformationForm({
  isEdit,
  register,
  facilityLoading,
  control,
  errors,
  canEdit,
}) {
  const { formTextFields, formTextFields4, formSelectProps } =
    ResidentInformationFields;
  const gender = [
    {
      id: "M",
      label: "Male",
    },
    {
      id: "F",
      label: "Female",
    },
  ];

  const StyledBaseInput = styled(BaseInput)`
    .MuiInputBase-root {
      background-color: ${({ ...props }) =>
        (props.checkEMREdit ? canEdit : true) ? undefined : "lightgrey"};
    }
  `;
  const StyledBaseSelect = styled(BaseSelect)`
    .MuiInputBase-root {
      background-color: ${({ ...props }) =>
        (props.checkEMREdit ? canEdit : true) ? undefined : "lightgrey"};
    }
  `;

  return (
    <>
      {isEdit && (
        <input
          hidden
          {...register("id", { required: REQUIRED_ERROR("Id") })}></input>
      )}
      {formTextFields.map((data, index) => {
        const props = data?.props;
        const isChecked = (props.checkEMREdit ? canEdit : true)
          ? undefined
          : true;

        switch (props.controlType) {
          case "skip":
            return <Grid key={index} item xs={5}></Grid>;
          case "date":
            return (
              <Grid key={index} item xs={5}>
                <BaseDatePicker
                  control={control}
                  {...props}
                  name={props?.name}
                  validationProps={data?.validationProps}
                  errors={errors}
                  disabled={props.checkEMREdit ? !canEdit : false}
                  sx={{
                    background: (props.checkEMREdit ? canEdit : true)
                      ? undefined
                      : "lightgrey",
                    width: "100%",
                  }}
                />
              </Grid>
            );
          default:
            return (
              <Grid key={index} item xs={5}>
                {isChecked ? (
                  <StyledBaseInput
                    control={control}
                    {...props}
                    name={props?.name}
                    validationProps={data?.validationProps}
                    errors={errors}
                    disabled={props.checkEMREdit ? !canEdit : false}
                  />
                ) : (
                  <BaseInput
                    control={control}
                    {...props}
                    name={props?.name}
                    validationProps={data?.validationProps}
                    errors={errors}
                    disabled={props.checkEMREdit ? !canEdit : false}
                  />
                )}
              </Grid>
            );
        }
      })}

      {formTextFields4.map((data, index) => {
        const props = data?.props;

        switch (props.controlType) {
          case "skip":
            return <Grid key={index} item xs={5}></Grid>;
          case "date":
            return (
              <Grid key={index} item xs={5}>
                <BaseDatePicker
                  control={control}
                  {...props}
                  name={props?.name}
                  validationProps={data?.validationProps}
                  errors={errors}
                />
              </Grid>
            );
          default:
            return (
              <Grid key={index} item xs={5}>
                <BaseInput
                  control={control}
                  {...props}
                  name={props?.name}
                  validationProps={data?.validationProps}
                  errors={errors}
                />
              </Grid>
            );
        }
      })}

      <Grid item xs={5}>
        <StyledBaseSelect
          control={control}
          options={gender}
          errors={errors}
          loading={facilityLoading}
          validationProps={formSelectProps?.validationProps}
          label={formSelectProps?.props?.label}
          name={formSelectProps?.props?.name}
          id={formSelectProps?.props?.id}
          disabled={formSelectProps?.props?.checkEMREdit ? !canEdit : false}
        />
      </Grid>
    </>
  );
}
