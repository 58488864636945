export const OptionsList = [
  {
    id: 0,
    optionName: "My Rosters",
    name: "My Roster",
    Link: "/Consultant/MyRosters",
    // isActive: false,
  },
  {
    id: 1,
    optionName: "Visit Details",
    Link: "/Consultant/MyRosters/VisitDetails",
    // isActive: false,
  },
  // {
  //   id: 2,
  //   optionName: "Visit Reports",
  //   Link: "/Consultant/MyRosters/VisitReports",
  //   // isActive: false,
  // },
];
