import { Box } from "@mui/material";
import React from "react";
import BaseContent from "../../../../shared/baseContent/BaseContent";
import { OptionsDetails } from "../../../../shared/OptionsDetails";
import { OptionsList } from "./headerConstants";

export const ConsultantDetailsView = () => {
  return (
    <BaseContent
      disableBack={true}
      headerText={"Consultant"}
      transparent={true}>
      <Box
        sx={{
          marginTop: "-32px",
          marginLeft: "-15px",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflow: "hidden",
        }}>
        <OptionsDetails
          OutletProps={{ sx: { paddingLeft: 2 } }}
          data={OptionsList}
          button={true}
        />
      </Box>
    </BaseContent>
  );
};
