import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { MENUS_MANAGER } = API_ENDPOINTS;

export const MenuManagerApis = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMenuRecipes: builder.query({
      query: ({ menuId, weekNumber }) =>
        `${MENUS_MANAGER}/FetchMenuWeek?menuId=${menuId}&weekNumber=${weekNumber}`,
      transformResponse: (response) => response?.data,
    }),
    getRecipesList: builder.query({
      query: ({
        searchTerm,
        categoryId,
        corporateGroup,
        htiCategory,
        tagIds,
        skip = 0,
        take = 300,
      }) => {
        const queryParams = [];
        if (searchTerm) {
          queryParams.push(`searchTerm=${encodeURIComponent(searchTerm)}`);
        }
        if (categoryId) {
          queryParams.push(`categoryId=${categoryId}`);
        }
        tagIds?.length > 0
          ? tagIds.map((tags) => queryParams.push(`tagIds=${tags}`))
          : null;
        const queryString = queryParams.join("&");
        return {
          url: `${MENUS_MANAGER}/FetchRecipeByName?${queryString}&selectedCorporateGroup=${corporateGroup}&onlyHTI=${htiCategory}&skip=${skip}&take=${take}`,
        };
      },
      transformResponse: (response) => response?.data,
    }),
    getFetchWeekList: builder.query({
      query: ({ menuId }) => `${MENUS_MANAGER}/FetchWeekList?menuId=${menuId}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.weekNumber,
            label: res.name,
          };
        }),
    }),
    saveMenuData: builder.mutation({
      query: (data) => {
        return {
          url: `${MENUS_MANAGER}/SaveMenuWeek`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: MENUS_MANAGER, id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetMenuRecipesQuery,
  useGetRecipesListQuery,
  useGetFetchWeekListQuery,
  useSaveMenuDataMutation,
} = MenuManagerApis;
