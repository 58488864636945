import React, { useEffect, useMemo } from "react";
import BaseAutoComplete from "../../../../../../shared/baseForm/BaseAutoComplete";
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useAddFacilityUserMutation } from "../../../../../../../store/apis/FacilityApis";
import { debounce } from "lodash";
import { useGetUserSelectBoxQuery } from "../../../../../../../store/apis/UserApis";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { setErrorsForProperties } from "components/shared/utils/helpers";

export default function AddAssignedUser() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [userDebouncedSearch, setUserDebouncedSearch] = React.useState("");

  const id = searchParams.get("id");

  const [addFacilityUser, { isError, error, isSuccess, isLoading }] =
    useAddFacilityUserMutation();

  const {
    data: userList,
    isFetching: loadingUser,
    isError: userError,
  } = useGetUserSelectBoxQuery(userDebouncedSearch, {
    skip: !userDebouncedSearch,
  });

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = methods;
  useEffect(() => {
    if (isError) {
      setErrorsForProperties({
        responseError: error,
        setError,
      });
    }
  }, [isError, error]);
  const handleUserSearch = useMemo(
    () =>
      debounce((query) => {
        setUserDebouncedSearch(query);
      }, 400),
    []
  );

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);

  const onSubmit = (data) => {
    data.facilityId = id;
    addFacilityUser(data);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <BaseAutoComplete
          options={userList || []}
          defaultOptions={[]}
          loading={loadingUser}
          control={control}
          handleSearch={handleUserSearch}
          formSelectProps={{
            props: {
              label: "User",
              id: "userId",
              name: "userId",
            },
            validationProps: {
              required: REQUIRED_ERROR("User"),
            },
          }}
          errors={errors}
          loadingError={userError}
        />
      </Grid>
      <Grid item sm={6} />
      <Grid item md={6}>
        <BaseSubmitButton
          fullWidth
          onClick={handleSubmit(onSubmit)}
          isSubmitting={isLoading}
        />
      </Grid>
    </Grid>
  );
}
