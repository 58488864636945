import { MAX_LENGTH_VALIDATION, REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "../../../shared/generatedForm/GeneratedForm";

export const MenusFields = [
  {
    props: {
      name: "menuName",
      id: "menuName",
      label: "Menu Name",
    },
    validationProps: {
      required: REQUIRED_ERROR("Menu Name"),
      maxLength: MAX_LENGTH_VALIDATION("Menu Name", 50),
    },
    size: 6,
  },
  {
    props: {
      name: "title",
      id: "title",
      label: "Title (for Recipe)",
    },
    validationProps: {
      required: REQUIRED_ERROR("Title"),
      maxLength: MAX_LENGTH_VALIDATION("Menu Name", 50),
    },
    size: 6,
  },
  {
    props: {
      name: "seasonId",
      id: "seasonId",
      label: "Season",
    },
    validationProps: {
      required: REQUIRED_ERROR("Season"),
    },
    size: 6,
    type: FORM_TYPES.SelectAPI,
    endpoint: "seasonSelect",
  },
  {
    props: {
      name: "footNotes",
      id: "footNotes",
      label: "FootNotes",
    },
    size: 6,
  },
  {
    props: {
      name: "dessertCart",
      id: "dessertCart",
      label: "Dessert Cart/Bread Basket/Special Menu",
    },
    type: "checkbox",
    size: 6,
  },
];
