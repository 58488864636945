import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid } from "@mui/material";
import BaseCheckBox from "components/shared/baseForm/BaseCheckBox";
import { useForm } from "react-hook-form";
import { REQUIRED_ERROR } from "utils/errorMessages";
import BaseSelect from "components/shared/baseForm/BaseSelect";
import BaseInput from "components/shared/baseForm/BaseInput";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import {
  useAddResidentTableSideOrderMutation,
  useGetAvailableTablesideOrdersSelectBoxQuery,
  useGetMealByOrderSelectBoxQuery,
  useUpdateResidentTableSideOrderMutation,
} from "store/apis/ResidentApis";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetRecipeSelectBoxQuery } from "store/apis/RecipeAPIs";
import { debounce } from "lodash";
import BaseAutoComplete from "components/shared/baseForm/BaseAutoComplete";
import BaseBlockerModal from "components/shared/blockerModal/BaseBlockerModal";
import { BackButton } from "components/shared/BackButton";

export default function AddTablesideHistory() {
  const [isDirty, setIsDirty] = useState(false);

  const [searchParams] = useSearchParams();
  const residentId = searchParams.get("id");
  const orderItemId = searchParams.get("orderItemId");
  const orderId = searchParams.get("orderId");
  const quantity = searchParams.get("quantity");

  const navigate = useNavigate();

  const isEdit = !!quantity;

  // useForm here
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isDirty: valuesChange },
  } = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const isOnMenu = watch("isOnOffMenu");
  const setupOrderId = watch("setupOrderId");

  // APIs Here
  const { data: availableOrders, isFetching: availableLoading } =
    useGetAvailableTablesideOrdersSelectBoxQuery(residentId, {
      skip: !residentId,
    });

  const { data: menusByOrder, isFetching: menusLoading } =
    useGetMealByOrderSelectBoxQuery(
      { residentId: residentId, setupOrderId: setupOrderId },
      { skip: !setupOrderId || !isOnMenu }
    );

  const [
    addTableSideHistory,
    { isLoading: isAddSubmitting, isSuccess: isAddSuccess },
  ] = useAddResidentTableSideOrderMutation();

  const [
    updateTableSideHistory,
    { isLoading: isUpdateSubmitting, isSuccess: isUpdateSuccess },
  ] = useUpdateResidentTableSideOrderMutation();

  // const here
  const isSubmitting = isAddSubmitting || isUpdateSubmitting;
  const isSuccess = isAddSuccess || isUpdateSuccess;

  // useEffect here
  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);

  useEffect(() => {
    setIsDirty(valuesChange);
  }, [valuesChange]);

  // HandleSubmit Functionality here
  const onSubmit = (data) => {
    setIsDirty(false);
    if (!isEdit) {
      const setupOrderIdParts = data?.setupOrderId.split("|");
      const transformedData = {
        residentId: residentId,
        recipeId: data?.recipeId,
        quantity: data?.quantity,
        ...(data?.price !== undefined && { price: data?.price }),
        isOnOffMenu: data?.isOnOffMenu,
        setupId: setupOrderIdParts[0],
        orderId: setupOrderIdParts[1],
      };
      if (transformedData) addTableSideHistory(transformedData);
    }
    if (isEdit) {
      updateTableSideHistory({
        orderItemId,
        orderId,
        residentId,
        quantity: data?.quantity,
      });
    }
  };

  return (<>
    <BaseBlockerModal isDirty={isDirty} />
    <Box sx={{ padding: "5px", marginTop: "10px" }}>
      <BackButton To={-1} />
      <Box
        sx={{
          width: "35vw",
          marginTop: "10px"
        }}>
        <Grid container spacing={2}>
          {!isEdit ? (
            <>
              <Grid item md={6} lg={4} xl={4} xs={6} sm={7}>
                <BaseCheckBox
                  name="isOnOffMenu"
                  id="isOnOffMenu"
                  label="Is On Menu"
                  defaultValue={true}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item md={12} lg={12} xl={12} xs={12} sm={12}>
                <BaseSelect
                  name="setupOrderId"
                  id="setupOrderId"
                  label="Order"
                  validationProps={{
                    required: REQUIRED_ERROR("Order"),
                  }}
                  control={control}
                  errors={errors}
                  options={availableOrders || []}
                  loading={availableLoading}
                />
              </Grid>

              <Grid item md={12} lg={12} xl={12} xs={12} sm={12}>
                {isOnMenu ? (
                  <BaseSelect
                    name="recipeId"
                    id="recipeId"
                    label="Item"
                    control={control}
                    validationProps={{
                      required: REQUIRED_ERROR("Item"),
                    }}
                    errors={errors}
                    options={menusByOrder || []}
                    loading={menusLoading}
                  />
                ) : (
                  <RecipeAutocomplete control={control} errors={errors} />
                )}
              </Grid>

              {!isOnMenu && (
                <Grid item md={12} lg={12} xl={12} xs={12} sm={12}>
                  <BaseInput
                    name="price"
                    id="price"
                    label="Price"
                    type="number"
                    min={1}
                    control={control}
                    validationProps={{
                      min: {
                        value: 1,
                        message: "Price cannot be less than 1",
                      },
                      required: REQUIRED_ERROR("Price"),
                    }}
                    errors={errors}
                  />
                </Grid>
              )}
            </>
          ) : null}
          <Grid item md={12} lg={12} xl={12} xs={12} sm={12}>
            <BaseInput
              name="quantity"
              id="quantity"
              label="Quantity"
              type="number"
              min={1}
              control={control}
              validationProps={{
                min: { value: 1, message: "Quantity cannot be less than 1" },
                required: REQUIRED_ERROR("Quantity"),
              }}
              errors={errors}
              defaultValue={quantity ? quantity : 1}
            />
          </Grid>
          <Grid item md={12} lg={12} xl={12} xs={12} sm={12}>
            <BaseSubmitButton
              fullWidth
              onClick={handleSubmit(onSubmit)}
              isSubmitting={isSubmitting}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  </>);
}

function RecipeAutocomplete({ control, errors }) {
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const defaultOptions = [];

  const handleSearch = useMemo(
    () =>
      debounce((query) => {
        setDebouncedSearch(query);
      }, 400),
    []
  );

  const { data, isFetching: isLoading } = useGetRecipeSelectBoxQuery(
    debouncedSearch,
    { skip: !debouncedSearch }
  );

  return (
    <BaseAutoComplete
      fullWidth
      formSelectProps={{
        props: {
          name: "recipeId",
          id: "recipeId",
          label: "Item",
        },
        validationProps: {
          required: REQUIRED_ERROR("Item"),
        },
      }}
      control={control}
      errors={errors}
      options={data || []}
      loading={isLoading}
      defaultOptions={defaultOptions}
      handleSearch={handleSearch}
    />
  );
}
