import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { CORPORATE_GROUP_DOCUMENTS } = API_ENDPOINTS;

export const CorporateGroupDocumentsAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // define an endpoint for getting CorporateGroupDocuments
    getCorporateDocuments: builder.query({
      query: ({ corporateGroupId, searchText }) =>
        `/${CORPORATE_GROUP_DOCUMENTS}?corporateGroupId=${corporateGroupId}&searchText=${searchText}&skip=0&take=200`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response.documents
          ? [
              ...response.documents.map(({ id }) => ({
                type: CORPORATE_GROUP_DOCUMENTS,
                id,
              })),
              CORPORATE_GROUP_DOCUMENTS,
            ]
          : [CORPORATE_GROUP_DOCUMENTS],
      //{
      //   return response
      //     ? [
      //         ...response.map(({ id }) => ({ type: CORPORATE_GROUP_DOCUMENTS, id })),
      //         CORPORATE_GROUP_DOCUMENTS,
      //       ]
      //     : [CORPORATE_GROUP_DOCUMENTS];
      // },
    }),
    getCorporateDocumentsById: builder.query({
      query: (beverageId) => `/${CORPORATE_GROUP_DOCUMENTS}/${beverageId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              {
                type: CORPORATE_GROUP_DOCUMENTS,
                id: response.id,
              },
              CORPORATE_GROUP_DOCUMENTS,
            ]
          : [CORPORATE_GROUP_DOCUMENTS],
    }),
    deleteCorporateDocuments: builder.mutation({
      query: (id) => ({
        url: `/${CORPORATE_GROUP_DOCUMENTS}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: CORPORATE_GROUP_DOCUMENTS, id: arg.id },
      ],
    }),
    addCorporateDocuments: builder.mutation({
      query: (data) => ({
        url: `/${CORPORATE_GROUP_DOCUMENTS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: CORPORATE_GROUP_DOCUMENTS, id: result?.id },
      ],
    }),
    updateCorporateDocuments: builder.mutation({
      query: (data) => ({
        url: `/${CORPORATE_GROUP_DOCUMENTS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: CORPORATE_GROUP_DOCUMENTS, id: result?.id },
      ],
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetCorporateDocumentsQuery,
  useGetCorporateDocumentsByIdQuery,
  useDeleteCorporateDocumentsMutation,
  useAddCorporateDocumentsMutation,
  useUpdateCorporateDocumentsMutation,
} = CorporateGroupDocumentsAPIs;
