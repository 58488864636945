export const CommunityMenuElements = [
    {
        Name: "Communities",
        Link: "/admin/communities/communitySetup",
        IsActive: false,
    },
    {
        Name: "Corporate Groups",
        Link: "/admin/communities/corporateGroups",
        IsActive: false,
    },   
]