import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { MealCardSetupMenuElements } from "./mealcardsetupmenu.constants.jsx";
import BaseContent from "../../shared/baseContent/BaseContent.jsx";
import { useSelector } from "react-redux";

export default function MealCardSetupMenu() {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const [value, setValue] = useState(1);
  const facilityName = useSelector((state) => state?.userInfo?.facilityName);
  const featureMealExtra = useSelector(
    (state) => state?.featureInfo?.mealExtra
  );
  // const [mapFlag, setMapFlag] = useState(null);
  const [menuItems, setMenuItems] = useState(MealCardSetupMenuElements);

  useEffect(() => {
    let currentLink;
    if (currentLocation) {
      currentLink = menuItems.find((x) =>
        currentLocation?.pathname.includes(x.Link)
      );
      if (!currentLink) {
        currentLink = menuItems[0];
      }
    }
    const currentIndex =
      MealCardSetupMenuElements.indexOf(
        MealCardSetupMenuElements.find((res) =>
          currentLocation.pathname.includes(res.Link)
        )
      ) || 0;
    setValue(currentIndex !== -1 ? currentIndex : 0);
    setMenuItems(
      MealCardSetupMenuElements.map((res) => {
        if (currentLocation.pathname.includes(res.Link)) {
          res.IsActive = true;
        } else {
          res.IsActive = false;
        }
        return res;
      })
    );
  }, [currentLocation]);
  // console.log(mapFlag);
  useEffect(() => {
    var setActive = featureMealExtra?.enabled == "true";
    if (setActive) {
      // setMapFlag(true);
      setMenuItems(MealCardSetupMenuElements);
      // console.log("true -- ", menuItems)
    } else if (!setActive) {
      // setMapFlag(false);
      setMenuItems(
        MealCardSetupMenuElements.filter(
          (x) => x.Name !== featureMealExtra?.description
        )
      );
      // console.log("false --- ", menuItems, featureMealExtra);
    }
  }, [currentLocation, featureMealExtra]);

  return (
    <>
      <BaseContent
        headerText={`MealCard Settings for ${facilityName}`}
        cardOverFlow={false}
        transparent={true}
        cardMarginTop={"5px"}
        backLink={"/MealCard"}
        backText={"Back to Resident List"}>
        <Box
          sx={{
            flexGrow: 1,
            height: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}>
          <Box>
            <Tabs
              TabIndicatorProps={{ sx: { display: "none" } }}
              sx={{
                "& .MuiTabs-flexContainer": {
                  flexWrap: "wrap",
                },
              }}
              value={value}
              aria-label="User Menu nav tabs ">
              {menuItems.map((data, index) => {
                return (
                  <LinkTab
                    key={index}
                    value={index}
                    label={`${data.Name}`}
                    href={`${data.Link}`}
                    link={data.Link}
                    navigate={navigate}
                  />
                );
              })}
            </Tabs>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              backgroundColor: "#fff",
              overflowY: "auto",
              pt: 1,
              px: 1,
            }}>
            <Outlet />
          </Box>
        </Box>
      </BaseContent>
    </>
  );
}

function LinkTab({ label, link, navigate, ...rest }) {
  return (
    <Tab
      component="a"
      className={label === "Back to Resident List" ? "backTab" : "adminTabs"}
      onClick={(event) => {
        event.preventDefault();
        navigate({
          pathname: link,
        });
      }}
      label={label}
      {...rest}
    />
  );
}
