import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { OptionsDetails } from "../shared/OptionsDetails";
import { OptionsList } from "../consultant/headerDetails/headerConstant";
import BaseContent from "../shared/baseContent/BaseContent";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

export const Consultant = () => {
  const location = useLocation();
  const match = matchPath(location.pathname, "/consultant");
  const navigate = useNavigate();

  useEffect(() => {
    if (match) navigate(OptionsList[0]?.Link);
  }, [match]);

  return (
    <BaseContent
      cardOverFlow={false}
      disableBack={true}
      headerText="Consultant"
      transparent={true}>
      <Box
        sx={{
          marginTop: "-32px",
          marginLeft: "-15px",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflow: "hidden",
        }}>
        <OptionsDetails
          OutletProps={{ sx: { paddingLeft: 2 } }}
          data={OptionsList}
        />
      </Box>
    </BaseContent>
  );
};
