import { Button, Grid } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import BaseSelect from "../../../../../../shared/baseForm/BaseSelect";
import BaseInput from "../../../../../../shared/baseForm/BaseInput";
import { useForm } from "react-hook-form";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  useAddFacilityContractMutation,
  useFacilityContractByIdQuery,
  useUpdateFacilityContractMutation,
} from "../../../../../../../store/apis/FacilityApis";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../../admin.overlays";
import BaseDatePicker from "components/shared/baseForm/BaseDatePicker";
import { REQUIRED_ERROR } from "utils/errorMessages";
import DeleteModal from "../../../../../../shared/deleteModal/DeleteModal";
import dayjs from "dayjs";
import { BorderClear, Close, Description } from "@mui/icons-material";

export default function AddCommunityContract() {
  const fileInputRef = useRef(null);
  const [searchParams] = useSearchParams();
  const [uploadedFile, setUploadedFile] = useState();
  const [fileName, setFileName] = useState(null);
  const id = searchParams.get("id");
  const contractId = searchParams.get("contractId");
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const {
    data: facilityContract,
    isLoading: facilityContractLoading,
    isSuccess: facilityContractSuccess,
  } = useFacilityContractByIdQuery(contractId, { skip: !contractId });

  const [
    addFacilityContract,
    {
      isLoading: addFacilityContractLoading,
      isSuccess: addFacilityContractSuccess,
    },
  ] = useAddFacilityContractMutation();

  const [
    updateFacilityContract,
    {
      isLoading: updateFacilityContractLoading,
      isSuccess: updateFacilityContractSuccess,
    },
  ] = useUpdateFacilityContractMutation();

  const isSubmitting =
    addFacilityContractLoading || updateFacilityContractLoading;
  const isSuccess = addFacilityContractSuccess || updateFacilityContractSuccess;

  const contractTypes = [
    { id: "Contract", label: "Contract" },
    { id: "Consulting Contract", label: "Consulting Contract" },
    { id: "Software Contract", label: "Software Contract" },
    { id: "Education Contract", label: "Education Contract" },
    { id: "CDM Contract", label: "CDM Contract" },
    { id: "Business Agreement", label: "Business Agreement" },
    { id: "Cert of Insurance", label: "Cert of Insurance" },
    { id: "Other", label: "Other" },
  ];

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    clearErrors,
    formState: { errors },
  } = methods;

  const startDate = watch("contractStartDate");
  const endDate = watch("contractEndDate");

  useEffect(() => {
    if (
      dayjs(startDate).format("MM-DD-YYYY") <=
      dayjs(endDate).format("MM-DD-YYYY")
    ) {
      clearErrors("reportStartDate");
      clearErrors("reportEndDate");
    }
  }, [startDate, endDate]);

  const isEdit = !!contractId;
  const isEditLoading = isEdit && facilityContractLoading;

  useEffect(() => {
    if (facilityContractSuccess && !facilityContractLoading) {
      reset(facilityContract);
      setFileName(facilityContract?.originalFileName);
    }
  }, [facilityContract, facilityContractLoading, facilityContractSuccess]);

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);

  const onSubmit = (data) => {
    data.facilityId = id;
    data.contractEndDate = data.contractEndDate || "";

    const dataToSend = new FormData();
    dataToSend.append("file", uploadedFile);
    dataToSend.append("FacilityId", id);

    dataToSend.set("Description", data.description);
    dataToSend.set("DateUploaded", data.dateUploaded);
    dataToSend.set("ContractStartDate", data.contractStartDate);
    dataToSend.set("ContractEndDate", data.contractEndDate);
    dataToSend.set("DocumentType", data.documentType);

    // for (var property in data) {
    //   if (property === "file") {
    //     continue;
    //   }
    //   dataToSend.append(property, data[property]);
    // }

    if (isEdit) {
      dataToSend.set("Id", data.id);
      if (!uploadedFile) {
        dataToSend.set("AttachmentUrl", facilityContract?.attachmentUrl);
      }
      updateFacilityContract(dataToSend);
      return;
    }
    addFacilityContract(dataToSend);
  };

  const handleFileUpload = (e) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    setUploadedFile(file);
    const { name } = file;
    setFileName(name);
    e.target.value = null;
  };

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <Grid container spacing={2}>
          {openModal && (
            <DeleteModal
              id={contractId}
              entityName={"this attachment"}
              apiPath={"deleteFacilityAttachmentContract"}
              open={openModal}
              isafterSuccessFunction={true}
              close={() => {
                setOpenModal(false);
              }}
              afterSuccessFunction={() => {
                setOpenModal(false);
                setFileName(null);
                setUploadedFile(null);
              }}
            />
          )}
          <Grid item md={6}>
            <BaseInput
              name="description"
              id="description"
              label="Description"
              validationProps={{
                required: REQUIRED_ERROR("Description"),
              }}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item md={6} />
          <Grid item md={6}>
            <BaseSelect
              name="documentType"
              id="documentType"
              label="Document Type"
              control={control}
              validationProps={{
                required: REQUIRED_ERROR("Document Type"),
              }}
              errors={errors}
              options={contractTypes || []}
            />
          </Grid>
          <Grid item md={6} />
          <Grid item md={6}>
            <BaseDatePicker
              name="contractStartDate"
              id="contractStartDate"
              label="Choose Contract/Agreement Start Date"
              validationProps={{
                required: REQUIRED_ERROR("Contract/Agreement Start Date"),
              }}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item md={6} />
          <Grid item md={6}>
            <BaseDatePicker
              name="contractEndDate"
              id="contractEndDate"
              label="Choose Contract/Agreement End Date"
              validationProps={{
                min: {
                  value: startDate ? new Date(startDate) : null,
                  message: "End Date must be after Start Date",
                },
                // required: REQUIRED_ERROR("Contract/Agreement End Date"),
              }}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item md={6} />
          <Grid item md={6}>
            <Button
              component="label"
              variant="outlined"
              startIcon={<>{isEdit ? <Description /> : <BorderClear />}</>}
              className="file-text"
              sx={{ marginRight: "1rem", width: "85%" }}>
              {isEdit ? `Selected File: ` : `Select File: `}
              <input
                disabled={isEdit && fileName != null ? true : false}
                required={!isEdit}
                type="file"
                accept=".pdf, .xls, .xlsx, .doc, .docx, .txt"
                hidden
                ref={fileInputRef}
                onChange={handleFileUpload}
              />
              {fileName}
            </Button>
            <Button
              onClick={() => {
                if (isEdit) {
                  setOpenModal(true);
                } else {
                  setUploadedFile(null);
                  setFileName(null);
                }
              }}
              sx={{ color: "red" }}>
              <Close />
            </Button>
            {/* {openModal === true && ( */}

            {/* )} */}
            {/* <BaseInput
          name="file"
          id="file"
          label="Upload Contract/Agreement"
          
          control={control}
          errors={errors}
          validationProps={
            isEdit?{}:{
                required: "Contract/Agreement is required",
            }
          }
          type={"file"}
        /> */}
          </Grid>
          <Grid item md={6} />
          <Grid item md={6}>
            <BaseSubmitButton
              fullWidth
              onClick={handleSubmit(onSubmit)}
              isSubmitting={isSubmitting}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}
