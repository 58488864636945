import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";

export const InfoBanner = ({ currentDietitianData }) => {
  return (
    <Box
      sx={{
        background: "#1A4650",
        width: "100%",
        color: "white !important",
        paddingY: 0.5,
        paddingX: 2,
      }}>
      <Grid container spacing={0.5} alignItems="center">
        <Grid
          item
          xs={12}
          md={3}
          lg={3.5}
          xl={3.5}
          sx={{
            textAlign: { xs: "center", md: "left" },
          }}>
          <Typography
            variant="h6"
            component="h6"
            fontWeight="bold"
            fontSize="17px">
            {currentDietitianData.fullName}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={9}
          lg={8.5}
          xl={8.5}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { xs: "center", md: "space-between" },
            alignItems: "center",
            order: { xs: 0, md: 1 },
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              flexWrap: "wrap",
            }}>
            <Typography
              href={`mailto:${currentDietitianData.email}`}
              component="a"
              fontWeight="bold"
              fontSize="14px"
              sx={{
                textDecoration: "underline",
                color: "white !important",
                marginX: { xs: 0, md: 1 },
                "&:visited": {
                  color: "white !important",
                },
                "&:hover": {
                  color: "white !important",
                },
                "&:active": {
                  color: "white !important",
                },
                "&:focus": {
                  color: "white !important",
                },
              }}>
              {currentDietitianData.email}
            </Typography>
            <Divider
              orientation="vertical"
              color="white"
              sx={{ height: "100%", mx: 1 }}
            />
            <Typography
              variant="p"
              component="p"
              fontWeight="bold"
              fontSize="14px"
              sx={{ marginX: { xs: 0, md: 1 } }}>
              {currentDietitianData.phoneNumber}
            </Typography>
            <Divider
              orientation="vertical"
              color="white"
              sx={{ height: "100%", mx: 1 }}
            />
            <Typography
              variant="p"
              component="p"
              fontWeight="bold"
              fontSize="14px"
              sx={{ marginX: { xs: 0, md: 1 } }}>
              {currentDietitianData.city}, {currentDietitianData.state}
            </Typography>
            <Divider
              orientation="vertical"
              color="white"
              sx={{ height: "100%", mx: 1 }}
            />
            <Typography
              variant="p"
              component="p"
              fontWeight="bold"
              fontSize="14px"
              sx={{ marginX: { xs: 0, md: 1 } }}>
              Region: {currentDietitianData.primaryRegion}
            </Typography>
            {/* <Divider
              orientation="vertical"
              color="white"
              sx={{ height: "100%", mx: 1 }}
            />
            <Typography
              variant="p"
              component="p"
              fontWeight="bold"
              fontSize="14px"
              sx={{ marginX: { xs: 0, md: 1 } }}>
              Salaried:
            </Typography>
            <Divider
              orientation="vertical"
              color="white"
              sx={{ height: "100%", mx: 1 }}
            />
            <Typography
              variant="p"
              component="p"
              fontWeight="bold"
              fontSize="14px"
              sx={{ marginX: { xs: 0, md: 1 } }}>
              License: SC
            </Typography> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
