import React from "react";
import { Outlet } from "react-router-dom";
import { RecipeSetupElements } from "./recipesetup.constants";
import { Card } from "@mui/material";
import AdminTabs from "../../shared/AdminTabs";
import OutletContainer from "components/shared/styledComponents/OutletContainer";

export default function RecipeSetup() {
  return (
    <>
      <AdminTabs Data={RecipeSetupElements} />
      <Card
        id="recipe-setup-card"
        sx={{
          flexGrow: 1,
          display: "flex",
          overflow: "hidden",
          flexDirection: "column",
        }}>
        <OutletContainer id="recipe-setup-outlet">
          <Outlet />
        </OutletContainer>
      </Card>
    </>
  );
}
