import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { REPORTS, ADMINISTRATION } = API_ENDPOINTS;

export const ReportAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getReport: builder.mutation({
      query: (data) => ({
        url: `/${REPORTS}/GetReport`,
        method: "POST",
        body: data,
        timeout: 120000,
      }),
    }),
    getMissingVendorCostReport: builder.mutation({
      query: (data) => ({
        url: `/${ADMINISTRATION}/MissingVendorCost?menu=${data?.menuId}&vendor=${data?.vendorId}&sbtButton=${data?.isRecipe}`,
        method: "POST",
        timeout: 120000,
      }),
    }),
    getVendorCompareReport: builder.mutation({
      query: (data) => ({
        url: `/${ADMINISTRATION}/CompareVendorPricing?vendorId1=${data?.vendorId1}&vendorId2=${data?.vendorId2}`,
        method: "POST",
        timeout: 120000,
      }),
    }),
    getSageTimeMileageReport: builder.mutation({
      query: (data) => ({
        url: `/${ADMINISTRATION}/ExportHoursForBilling`,
        method: "POST",
        body: data,
        cache: "no-cache",
      }),
    }),
    getDietitian1099Report: builder.mutation({
      query: (data) => ({
        url: `/${ADMINISTRATION}/ExportDietitian1099?startDate=${data?.reportStartDate}&endDate=${data?.reportEndDate}`,
        method: "POST",
      }),
    }),
    getHTIOrdersReport: builder.mutation({
      query: (data) => ({
        url: `/${ADMINISTRATION}/ExportOrdersForBilling?MarkAsExported=${data?.markAsExported}&diningGroupId=${data?.diningRDGroupId}`,
        method: "POST",
      }),
    }),
    getOrdersNotSubmittedReport: builder.mutation({
      query: () => ({
        url: `/${ADMINISTRATION}/ExportUnsubmittedOrdersReport`,
        method: "POST",
      }),
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetReportMutation,
  useGetMissingVendorCostReportMutation,
  useGetVendorCompareReportMutation,
  useGetSageTimeMileageReportMutation,
  useGetDietitian1099ReportMutation,
  useGetHTIOrdersReportMutation,
  useGetOrdersNotSubmittedReportMutation,
} = ReportAPIs;
