import React from "react";
import { useGetResidentWeightQuery } from "../../../../store/apis/ResidentApis";
import { useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import dayjs from "dayjs";
import { BaseButton } from "components/shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";

export default function ResidentWeightHistory() {
  const [searchParams] = useSearchParams();
  const residentId = searchParams.get("id");

  const {
    data: WeightHistory,
    isLoading,
    isError,
  } = useGetResidentWeightQuery(residentId, { skip: !residentId });

  const residentWeights = WeightHistory;

  const WeightColumns = [
    {
      field: "weightValue",
      minWidth: 50,
      flex: 1,
      headerName: "Weight",
    },
    {
      field: "note",
      minWidth: 50,
      flex: 1,
      headerName: "Note",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "weightDate",
      minWidth: 50,
      flex: 1,
      headerName: "Date",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      valueGetter: (value) => dayjs(value).toDate(),
      valueFormatter: (value) => {
        const isDateValid = dayjs(value).isValid();
        return isDateValid
          ? dayjs(value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={WeightHistory}
            hasEdit={true}
            hasDelete={true}
            deleteProps={{
              entityName: params.row?.weightValue,
              apiPath: "deleteResidentWeight",
            }}
            editProps={{
              To: `addWeightHistory?id=${residentId}`,
              title: "WeightHistory",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          display: "flex",
          overflow: "hidden",
          flexDirection: "column",
          gap: 1,
        }}>
        <Box>
          <BaseButton
            text={"Add Weight Measurement"}
            To={`addWeightHistory?id=${residentId}`}
            state={{
              name: WeightHistory?.residentName,
            }}
            endIcon="post_add"
            marginLeft="0px !important"
            sx={{
              marginY: "0px !important",
            }}
          />
        </Box>
        <Box sx={{ height: "100%", flexGrow: 1, overflowY: "auto", pb: 1 }}>
          <BaseDataGrid
            rows={residentWeights}
            columns={WeightColumns}
            error={isError}
            loading={isLoading}
            height={"100%"}
          />
        </Box>
      </Box>
    </>
  );
}
