import { BaseApi } from "./BaseApi";

export const ChatApis = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // define an endpoint for getting Chat
    addChat: builder.mutation({
      query: (messageIn) => ({
        url: `ChatCompletion`,
        method: "POST",
        body: messageIn,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["Chat"],
    }),
    addChatData: builder.mutation({
      query: (messageIn) => ({
        url: `ChatCompletion/DataSearch`,
        method: "POST",
        body: messageIn,
      }),
      transformResponse: (response) => response?.data,
      transformErrorResponse: (response) => response,
      invalidatesTags: ["Chat"],
    }),
    getChatStatus: builder.query({
      query: () => `ChatCompletion/GetStatus`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response ? [{ type: "data", id: response.id }, "Data"] : ["Data"],
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useAddChatMutation,
  useAddChatDataMutation,
  useGetChatStatusQuery,
} = ChatApis;
