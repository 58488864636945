import { REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "../../../shared/generatedForm/GeneratedForm";

export const MenuDaysFields = [
  {
    props: {
      name: "daysInMenu",
      id: "daysInMenu",
      label: "Number of Days in Menu ",
      type: "number",
      defaultValue: 0,
    },
    validationProps: {
      required: REQUIRED_ERROR("Number of Days in Menu"),
    },

    size: 6,
  },
  {
    props: {
      name: "breakfast",
      id: "breakfast",
      label: "Breakfast",
    },
    type: FORM_TYPES.Checkbox,
    size: 6,
  },
  {
    props: {
      name: "lunch",
      id: "lunch",
      label: "Lunch",
    },
    type: FORM_TYPES.Checkbox,
    size: 6,
  },
  {
    props: {
      name: "supper",
      id: "supper",
      label: "Dinner",
    },
    type: FORM_TYPES.Checkbox,
    size: 6,
  },
  {
    props: {
      name: "morningSnack",
      id: "morningSnack",
      label: "Morning Snack",
    },
    type: FORM_TYPES.Checkbox,
    size: 6,
  },
  {
    props: {
      name: "afternoonSnack",
      id: "afternoonSnack",
      label: "Afternoon Snack",
    },
    type: FORM_TYPES.Checkbox,
    size: 6,
  },
  {
    props: {
      name: "eveningSnack",
      id: "eveningSnack",
      label: "Evening Snack",
    },
    type: FORM_TYPES.Checkbox,
    size: 6,
  },
];
