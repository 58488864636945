import React from "react";
import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionSummary, Box, Typography } from "@mui/material";
import MealObservationAccordionDetails from "./MealObservationAccordionDetails";

export default function MealObservationAccordion({
  data,
  visitReportTypeId,
  watch,
  control,
  errors,
}) {
  return (
    <>
      {data?.map((section, index) => (
        <Box
          key={index}
          className={
            visitReportTypeId === 1 || visitReportTypeId === 6
              ? "consultDataBox"
              : "dataBox"
          }>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={
                <ExpandMore
                  sx={{ color: "white !important", fontSize: "2rem" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                background: "#707070",
                padding: "2rem",
              }}
              className="itemBox">
              <Typography
                component="h2"
                sx={{
                  marginY: "10px",
                  fontWeight: "900",
                  color: "white !important",
                  fontSize: "1.2rem",
                }}>
                {section.section}
              </Typography>
            </AccordionSummary>

            {/* Accordion Details to hold section items */}
            <MealObservationAccordionDetails
              section={section}
              watch={watch}
              control={control}
              errors={errors}
            />
          </Accordion>
        </Box>
      ))}
    </>
  );
}
