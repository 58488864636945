import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Typography, Grid } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useAddTagMutation,
  useUpdateTagMutation,
  useGetTagByIdQuery,
} from "../../../../../../store/apis/TagsApi";

import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../admin.overlays";
import { setErrorsForProperties } from "components/shared/utils/helpers";
import { BaseButton } from "../../../../../shared/BaseButton";
import GeneratedForm from "../../../../../shared/generatedForm/GeneratedForm";
import { TAGS_FIELDS } from "./forms.constants";

function AddTag() {
  const [validationSummary, setValidationSummary] = React.useState([]);

  const [
    addTag,
    {
      isError: addError,
      isSuccess: addSuccess,
      isLoading: addLoading,
      error: addErrorMessage,
    },
  ] = useAddTagMutation();
  const [
    updateTag,
    {
      isError: updateError,
      isSuccess: updateSuccess,
      isLoading: updateLoading,
      error: updateErrorMessage,
    },
  ] = useUpdateTagMutation();

  const error = addErrorMessage || updateErrorMessage;
  const isError = addError || updateError;
  const isSuccess = addSuccess || updateSuccess;
  const isSubmitting = addLoading || updateLoading;
  const [searchParams] = useSearchParams();
  const currentTagId = searchParams.get("id");

  const isEdit = !!currentTagId;

  const {
    data: currentTag,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetTagByIdQuery(currentTagId, { skip: !isEdit });

  const isEditLoading = isEdit && currentFetching;

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    reset,
    setError,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (currentSuccess && !currentFetching && currentTag) {
      reset(currentTag);
    }
  }, [currentTag]);

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError && error) {
      setErrorsForProperties({
        setError: setError,
        responseError: error,
        setValidationSummary: setValidationSummary,
      });
    }
  }, [isError, error]);

  const onSubmit = (data) => {
    if (isEdit) {
      updateTag(data);
      return;
    }
    addTag(data);
  };

  return (<>
    {isEditLoading ? (
      <EditSkeleton />
    ) : (
      <>
        <BaseButton
          text="Back To Tags"
          startIcon={"keyboard_arrow_left"}
          onClick={() => navigate(-1)}
          sx={{ marginBottom: "0.8rem" }}
        />

        <Box sx={{
          padding: "8px"
        }}>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}>
            <GeneratedForm
              control={control}
              list={TAGS_FIELDS}
              errors={errors}
            />

            <Grid item md={6}>
              <BaseSubmitButton
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
                validationSummary={validationSummary}
                text={isEdit ? "Submit" : "Create"}
              />
            </Grid>
            {isError ? (
              <Typography sx={{
                color: "error"
              }}>
                There was an error submitting the form, please try again.
              </Typography>
            ) : undefined}
          </Grid>
        </Box>
      </>
    )}
  </>);
}

export default AddTag;
