import React, { useEffect } from "react";
import {
  useGetCorporateFoodTypesByIdQuery,
  useAddCorporateFoodTypesMutation,
  useUpdateCorporateFoodTypesMutation,
  useDietCorporateFoodTypesSelectQuery,
  useUnitCorporateFoodTypesSelectQuery,
  useMealCorporateFoodTypesSelectQuery,
  useFoodCorporateFoodTypesSelectQuery,
} from "../../../../../../../store/apis/CorporateGroupFoodTypeApis";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import BaseSelect from "../../../../../../shared/baseForm/BaseSelect";
import BaseCheckBox from "../../../../../../shared/baseForm/BaseCheckBox";
import BaseInput from "../../../../../../shared/baseForm/BaseInput";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../../admin.overlays";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { KeyboardArrowLeft } from "@mui/icons-material";

export default function AddCorporateAutomaticSnack() {
  const [addFoodType, { isError: addError, isLoading: addLoading }] =
    useAddCorporateFoodTypesMutation();

  const [updateFoodType, { isError: updateError, isLoading: updateLoading }] =
    useUpdateCorporateFoodTypesMutation();

  const isError = addError || updateError;
  const isSubmitting = addLoading || updateLoading;
  const [searchParams] = useSearchParams();

  const corporateId = searchParams.get("corporateId");
  const FoodTypeId = searchParams.get("id");

  const isEdit = !!FoodTypeId;

  const {
    data: foodTypeData,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetCorporateFoodTypesByIdQuery(FoodTypeId, {
    skip: !isEdit,
  });

  const isEditLoading = isEdit && currentFetching;

  const { data: diet, isLoading: loading } =
    useDietCorporateFoodTypesSelectQuery();

  const { data: units, isLoading: loadingUnit } =
    useUnitCorporateFoodTypesSelectQuery();

  const { data: meals, isLoading: loadingMeals } =
    useMealCorporateFoodTypesSelectQuery();

  const { data: foodType, isLoading: loadingFood } =
    useFoodCorporateFoodTypesSelectQuery();

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (currentSuccess && !currentFetching && foodTypeData) {
      reset(foodTypeData);
    }
  }, [foodTypeData]);

  const onSubmit = (data) => {
    if (isEdit) {
      updateFoodType(data);
    } else {
      addFoodType({
        ...data,
        corporateGroupId: corporateId,
        dietName: "",
        mealName: "",
        foodTypeName: "",
        description: "",
      });
    }
    if (!isError) {
      navigate(-1);
    }
  };

  return (<>
    {isEditLoading ? (
      <EditSkeleton />
    ) : (
      <>
        <div className="leftActions">
          <Button
            component={Link}
            color={"primary"}
            className="baseButton"
            variant="contained"
            onClick={() => navigate(-1)}
            startIcon={<KeyboardArrowLeft />}>
            {"Back to Snacks List"}
          </Button>
        </div>
        <Divider sx={{ marginBottom: "15px", border: "none" }} />
        <FormProvider>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <BaseSelect
                  label="Choose Diet"
                  name="dietId"
                  id="dietId"
                  control={control}
                  options={diet || []}
                  errors={errors}
                  loading={loading}
                  validationProps={{
                    required: REQUIRED_ERROR("Diet"),
                  }}
                />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />
              <Grid item xs={5}>
                <BaseSelect
                  label="Choose Unit"
                  name="unitId"
                  id="unitId"
                  control={control}
                  options={units || []}
                  errors={errors}
                  loading={loadingUnit}
                  validationProps={{
                    required: REQUIRED_ERROR("Unit"),
                  }}
                />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />

              <Grid item xs={5}>
                <BaseSelect
                  label="Choose Snack"
                  name="foodTypeId"
                  id="foodTypeId"
                  control={control}
                  options={foodType || []}
                  errors={errors}
                  loading={loadingFood}
                  validationProps={{
                    required: REQUIRED_ERROR("Snack"),
                  }}
                />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />

              <Grid item xs={5}>
                <BaseSelect
                  label="Choose Time"
                  name="mealId"
                  id="mealId"
                  control={control}
                  options={meals || []}
                  errors={errors}
                  loading={loadingMeals}
                  validationProps={{
                    required: REQUIRED_ERROR("Time"),
                  }}
                />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />
              <Grid item xs={5}>
                <BaseInput
                  label={"Quantity"}
                  name={"quantity"}
                  placeholder="Quantity"
                  control={control}
                  errors={errors}
                  validationProps={{
                    required: REQUIRED_ERROR("Quantity"),
                  }}
                />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />
              <Grid item xs={5}>
                <BaseCheckBox
                  name="onSun"
                  id="onSun"
                  label="Sunday"
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />

              <Grid item xs={5}>
                <BaseCheckBox
                  name="onMon"
                  id="onMon"
                  label="Monday"
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />

              <Grid item xs={5}>
                <BaseCheckBox
                  name="onTue"
                  id="onTue"
                  label="Tuesday"
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />

              <Grid item xs={5}>
                <BaseCheckBox
                  name="onWed"
                  id="onWed"
                  label="Wednesday"
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />

              <Grid item xs={5}>
                <BaseCheckBox
                  name="onThu"
                  id="onThu"
                  label="Thursday"
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />

              <Grid item xs={5}>
                <BaseCheckBox
                  name="onFri"
                  id="onFri"
                  label="Friday"
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />

              <Grid item xs={5}>
                <BaseCheckBox
                  name="onSat"
                  id="onSat"
                  label="Saturday"
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />
              <Grid item xs={5}>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <BaseSubmitButton
                    fullWidth
                    onClick={handleSubmit(onSubmit)}
                    isSubmitting={isSubmitting}
                    text={isEdit ? "Submit" : "Create"}
                  />
                </Box>
                {isError ? (
                  <Typography sx={{
                    color: "error"
                  }}>
                    There was an error submitting the form, please try again.
                  </Typography>
                ) : undefined}
              </Grid>
              <Grid item xs={3} />
            </Grid>
          </form>
        </FormProvider>
      </>
    )}{" "}
  </>);
}
