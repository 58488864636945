import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { SYSTEM_MESSAGES } = API_ENDPOINTS;

export const SystemMessagesAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSystemMessageById: builder.query({
      query: (systemId) => `/${SYSTEM_MESSAGES}/${systemId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: SYSTEM_MESSAGES, id: response.id }, SYSTEM_MESSAGES]
          : [SYSTEM_MESSAGES],
    }),
    getSystemMessagesOptional: builder.query({
      query: (url) => url,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              ...response.map(({ id }) => ({ type: SYSTEM_MESSAGES, id })),
              SYSTEM_MESSAGES,
            ]
          : [SYSTEM_MESSAGES],
    }),
    deleteSystemMessages: builder.mutation({
      query: (smId) => ({
        url: `/${SYSTEM_MESSAGES}/${smId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: SYSTEM_MESSAGES, id: arg.id },
      ],
    }),
    addSystemMessage: builder.mutation({
      query: (data) => ({
        url: `/${SYSTEM_MESSAGES}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: SYSTEM_MESSAGES, id: result?.id },
      ],
    }),
    updateSystemMessage: builder.mutation({
      query: (data) => ({
        url: `/${SYSTEM_MESSAGES}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: SYSTEM_MESSAGES, id: result?.id },
      ],
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetSystemMessagesOptionalQuery,
  useDeleteSystemMessagesMutation,
  useAddSystemMessageMutation,
  useGetSystemMessageByIdQuery,
  useUpdateSystemMessageMutation,
} = SystemMessagesAPIs;
