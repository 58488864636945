import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  facilityId: null,
  isModalShown: false,
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setFacilityId: (state, action) => {
      const parsedPayload = parseInt(action.payload);
      state.facilityId = isNaN(parsedPayload) ? action.payload : parsedPayload;
    },
    setIsModalShown: (state, action) => {
      state.isModalShown = action.payload;
    },
  },
});

export const { setFacilityId, setIsModalShown } = AuthSlice.actions;

export const selectFacilityId = (state) => state?.auth?.facilityId;

export const selectIsModalShown = (state) => state?.auth?.isModalShown;

export default AuthSlice.reducer;
