import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { ADAPTIVE_EQUIPMENTS } = API_ENDPOINTS;

export const AdaptiveEquipmentApi = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // define an endpoint for getting Diets
    getAdaptiveEquipments: builder.query({
      query: ({searchTerm, skip, take}) => `/${ADAPTIVE_EQUIPMENTS}?searchText=${searchTerm}&skip=${skip}&take=${take}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.items.map(({ id }) => ({
                type: ADAPTIVE_EQUIPMENTS,
                id,
              })),
              ADAPTIVE_EQUIPMENTS,
            ]
          : [ADAPTIVE_EQUIPMENTS];
      },
    }),
    getAdaptiveEquipmentById: builder.query({
      query: (adaptiveequipmentId) =>
        `/${ADAPTIVE_EQUIPMENTS}/${adaptiveequipmentId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              { type: ADAPTIVE_EQUIPMENTS, id: response.id },
              ADAPTIVE_EQUIPMENTS,
            ]
          : [ADAPTIVE_EQUIPMENTS],
    }),
    deleteAdaptiveEquipment: builder.mutation({
      query: (id) => ({
        url: `/${ADAPTIVE_EQUIPMENTS}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: ADAPTIVE_EQUIPMENTS, id: arg.id },
      ],
    }),
    addAdaptiveEquipment: builder.mutation({
      query: (data) => ({
        url: `/${ADAPTIVE_EQUIPMENTS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: ADAPTIVE_EQUIPMENTS, id: result?.id },
      ],
    }),
    updateAdaptiveEquipment: builder.mutation({
      query: (data) => ({
        url: `/${ADAPTIVE_EQUIPMENTS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: ADAPTIVE_EQUIPMENTS, id: result?.id },
      ],
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetAdaptiveEquipmentsQuery,
  useGetAdaptiveEquipmentByIdQuery,
  useDeleteAdaptiveEquipmentMutation,
  useAddAdaptiveEquipmentMutation,
  useUpdateAdaptiveEquipmentMutation,
} = AdaptiveEquipmentApi;
