import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  visitFacilityId: null,
};

export const facilitySlice = createSlice({
  name: "facilityId",
  initialState,
  reducers: {
    setVisitFacilityId: (state, action) => {
      state.visitFacilityId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setVisitFacilityId } = facilitySlice.actions;

export default facilitySlice.reducer;
