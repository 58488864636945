import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { CORPORATE_GROUP_FOOD_TYPES, DIET, UNITS, SNACKS, UTILITIES } =
  API_ENDPOINTS;

export const FoodTypeAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // define an endpoint for getting CorporateGroupFoodTypes
    GetCorporateFoodTypes: builder.query({
      query: ({ corporateGroupId, searchText }) =>
        `/${CORPORATE_GROUP_FOOD_TYPES}?corporateGroupId=${corporateGroupId}&searchText=${searchText}&skip=0&take=1000`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: CORPORATE_GROUP_FOOD_TYPES,
                id,
              })),
              CORPORATE_GROUP_FOOD_TYPES,
            ]
          : [CORPORATE_GROUP_FOOD_TYPES];
      },
    }),
    getCorporateFoodTypesById: builder.query({
      query: (Id) => `/${CORPORATE_GROUP_FOOD_TYPES}/${Id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              {
                type: CORPORATE_GROUP_FOOD_TYPES,
                id: response.id,
              },
              CORPORATE_GROUP_FOOD_TYPES,
            ]
          : [CORPORATE_GROUP_FOOD_TYPES],
    }),
    deleteCorporateFoodTypes: builder.mutation({
      query: (id) => ({
        url: `/${CORPORATE_GROUP_FOOD_TYPES}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: CORPORATE_GROUP_FOOD_TYPES, id: arg.id },
      ],
    }),
    addCorporateFoodTypes: builder.mutation({
      query: (data) => ({
        url: `/${CORPORATE_GROUP_FOOD_TYPES}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: CORPORATE_GROUP_FOOD_TYPES, id: result?.id },
      ],
    }),
    updateCorporateFoodTypes: builder.mutation({
      query: (data) => ({
        url: `/${CORPORATE_GROUP_FOOD_TYPES}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: CORPORATE_GROUP_FOOD_TYPES, id: result?.id },
      ],
    }),
    dietCorporateFoodTypesSelect: builder.query({
      query: () => `/${DIET}/GetDietsOrTextures?isTexture=false`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      providesTags: (response) =>
        response?.data
          ? [
              ...response.data.map(({ id }) => ({
                type: DIET,
                id,
              })),
              DIET,
            ]
          : [DIET],
    }),
    unitCorporateFoodTypesSelect: builder.query({
      query: () => `/${UNITS}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      providesTags: (response) =>
        response?.data
          ? [
              ...response.data.map(({ id }) => ({
                type: UNITS,
                id,
              })),
              UNITS,
            ]
          : [UNITS],
    }),
    mealCorporateFoodTypesSelect: builder.query({
      query: () =>`/${UTILITIES}/MealTypes`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      providesTags: (response) =>
        response?.data
          ? [
              ...response.data.map(({ id }) => ({
                type: SNACKS,
                id,
              })),
              SNACKS,
            ]
          : [SNACKS],
    }),
    foodCorporateFoodTypesSelect: builder.query({
      query: () => `/${SNACKS}?skip=0&take=1000`, 
      transformResponse: (response) =>
        response?.data?.items?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      // Commented out because not needed.
      // providesTags: (response) =>
      //   response?.data
      //     ? [
      //         ...response.data.map(({ id }) => ({
      //           type: CORPORATE_GROUP_FOOD_TYPES,
      //           id,
      //         })),
      //         CORPORATE_GROUP_FOOD_TYPES,
      //       ]
      //     : [CORPORATE_GROUP_FOOD_TYPES],
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetCorporateFoodTypesQuery,
  useGetCorporateFoodTypesByIdQuery,
  useDeleteCorporateFoodTypesMutation,
  useAddCorporateFoodTypesMutation,
  useUpdateCorporateFoodTypesMutation,
  useDietCorporateFoodTypesSelectQuery,
  useUnitCorporateFoodTypesSelectQuery,
  useMealCorporateFoodTypesSelectQuery,
  useFoodCorporateFoodTypesSelectQuery,
} = FoodTypeAPIs;
