import { useMsal } from "@azure/msal-react";
import { useMemo } from "react";

export default function useMsalAccount() {
  const { instance } = useMsal();

  const account = useMemo(() => {
    const activeAccount = instance.getActiveAccount();

    if (activeAccount?.idTokenClaims) {
      const claims = activeAccount?.idTokenClaims;
      return {
        userId: claims.extension_DiningManagerUserId,
        email: claims.email,
        roles: claims.extension_DiningManagerRoles?.split(","),
        signInName: claims.signInName,
        account: activeAccount,
        userName: claims.email || claims.signInName,
        fullName: claims.name,
        impersonatedUser: claims?.impersonatedUser,
      };
    }

    return {
      userId: null,
      email: null,
      roles: [],
      signInName: null,
      account: null,
      userName: null,
      fullName: null,
      impersonatedUser: null,
    };
  }, [instance.getActiveAccount()]);

  return account;
}
