// import { FORM_TYPES } from "../../../shared/generatedForm/GeneratedForm";

export const SymbolsDetails = [
  // {
  //   name: "Heart Healthy",
  //   icon: "favorite",
  //   color: "red",
  //   label: "hh",
  // },
  // {
  //   name: "Diabetic Choice",
  //   icon: "health_and_safety",
  //   color: "red",
  //   label: "dc",
  // },
  {
    name: "House Recipe",
    icon: "house",
    color: "var(--tealColor)",
    label: "fr",
  },
  {
    name: "Base Recipe",
    icon: "key",
    color: "var(--tealColor)",
    label: "isBaseRecipe",
  },
  // {
  //   name: "Other Establishment",
  //   icon: "school",
  //   color: "var(--yellowColor)",
  //   label: "oe",
  // },
  {
    name: "Dislike Substitute",
    icon: "star",
    color: "var(--tealColor)",
    label: "isSubstituteRecipe",
  },
  // {
  //   name: "Hide From Printed Menu",
  //   icon: "visibility_off",
  //   color: "var(--tealColor)",
  //   label: "hideFromPrintedMenu",
  // },
  {
    name: "Substitution Available",
    icon: "upload",
    color: "red",
    label: "substituteRecipeAvailable",
  },
];

export const categoryColorStrips = [
  { id: 1, menuCategory: "Entree", color: "#AA3939" },
  { id: 2, menuCategory: "Side", color: "#226666" },
  { id: 3, menuCategory: "Beverage", color: "#2D882D" },
  { id: 4, menuCategory: "Bread", color: "#AA6C39" },
  { id: 5, menuCategory: "Dessert", color: "#552600" },
  { id: 6, menuCategory: "Condiments", color: "#801515" },
  { id: 7, menuCategory: "Sandwich", color: "#555500"},
  { id: 8, menuCategory: "Appetizer", color: "#004400" },
];
