import React from "react";

// Admin/GroupMenu
// Admin/GroupMenu/UserMenu Imports
import UserMenu from "components/admin/groupMenu/userMenu/UserMenu";
import ManageDocuments from "components/admin/groupMenu/userMenu/manageDocuments/ManageDocuments";
import AddManageDocument from "components/admin/groupMenu/userMenu/manageDocuments/AddManageDocument/AddManageDocument";
import SystemMessages from "components/admin/groupMenu/userMenu/systemMessages/SystemMessages";
import UserSetup from "components/admin/groupMenu/userMenu/userSetup/UserSetup";
import AddUser from "components/admin/groupMenu/userMenu/userSetup/addUser/AddUser";
import UserFacilities from "components/admin/groupMenu/userMenu/userSetup/userFacilities/UserFacilities";
// import DietitianEdit from "components/admin/groupMenu/userMenu/userSetup/dietitianForm/DietitianForm";
import UserRoles from "components/admin/groupMenu/userMenu/userSetup/userRoles/UserRoles";
import UserMenus from "components/admin/groupMenu/userMenu/userSetup/userMenus/UserMenus";
import AddSystemMessage from "components/admin/groupMenu/userMenu/systemMessages/addSystemMessage/AddSystemMessage";
import DietitianForm from "components/admin/groupMenu/userMenu/userSetup/dietitianForm/DietitianForm";

// Admin/GroupMenu/CommunityMenu
import CommunitiesMenu from "components/admin/groupMenu/communityMenu/CommunityMenu";
import CommunitySetup from "components/admin/groupMenu/communityMenu/communitySetup/CommunitySetup";

// Admin/GroupMenu/CommunityMenu/CorporateGroup
import CorporateGroup from "components/admin/groupMenu/communityMenu/corporateGroup/CorporateGroup";
import ExportPBJ from "components/admin/groupMenu/communityMenu/corporateGroup/exportPBJ/ExportPBJ";
import AddCorporateGroup from "components/admin/groupMenu/communityMenu/corporateGroup/addCorporateGroup/AddCorporateGroup";
import MenuExpiration from "components/admin/groupMenu/communityMenu/corporateGroup/menuExpiration/MenuExpiration";
import SuperUser from "components/admin/groupMenu/communityMenu/corporateGroup/superUser/SuperUser";
import AddsuperUser from "components/admin/groupMenu/communityMenu/corporateGroup/superUser/addsuperUser/addsuperUser";
import Document from "components/admin/groupMenu/communityMenu/corporateGroup/document/Document";
import AddDocument from "components/admin/groupMenu/communityMenu/corporateGroup/document/addDocument/AddDocument";
import AutomaticSnack from "components/admin/groupMenu/communityMenu/corporateGroup/automaticSnack/AutomaticSnack";
import AddautomaticSnack from "components/admin/groupMenu/communityMenu/corporateGroup/automaticSnack/addautomaticSnack/AddautomaticSnack";
import BeverageSelection from "components/admin/groupMenu/communityMenu/corporateGroup/beverageSelection/BeverageSelection";
import AddbeverageSelection from "components/admin/groupMenu/communityMenu/corporateGroup/beverageSelection/addbeverageSelection/AddbeverageSelection";
import MealExtrasSelection from "components/admin/groupMenu/communityMenu/corporateGroup/mealExtrasSelection/MealExtrasSelection";
import AddMealExtrasSelection from "components/admin/groupMenu/communityMenu/corporateGroup/mealExtrasSelection/addMealExtrasSelection/AddMealExtrasSelection";
import ConsultantSchedule from "components/admin/groupMenu/communityMenu/corporateGroup/consultantSchedule/ConsultantSchedule";

// Admin/GroupMenu/CommunityMenu/communitySetup
import CommunityMenu from "components/admin/groupMenu/communityMenu/communitySetup/communityMenu/CommunityMenu";
import AddCommunity from "components/admin/groupMenu/communityMenu/communitySetup/addCommunity/AddCommunity";
import CommunityDiets from "components/admin/groupMenu/communityMenu/communitySetup/communityDiets/CommunityDiets";
import CommunityVisits from "components/admin/groupMenu/communityMenu/communitySetup/communityVisits/CommunityVisits";
import CommunityPortalDocuments from "components/admin/groupMenu/communityMenu/communitySetup/communityVisits/CommunityPortalDocuments";
import CommunityVisitsDetail from "components/admin/groupMenu/communityMenu/communitySetup/communityVisits/communityVisitsDetail/CommunityVisitsDetail";
import CommunityVisitsLog from "components/admin/groupMenu/communityMenu/communitySetup/communityVisits/communityVisitsLog/CommunityVisitsLog";
import CommunityVisitsReport from "components/admin/groupMenu/communityMenu/communitySetup/communityVisits/communityVisitsReport/CommunityVisitsReport";
import CommunityVisitsMenu from "components/admin/groupMenu/communityMenu/communitySetup/communityVisits/communityVisitsMenu/CommunityVisitsMenu";
import CommunityDetails from "components/admin/groupMenu/communityMenu/communitySetup/communityDetails/CommunityDetails";
import CommunityCommunication from "components/admin/groupMenu/communityMenu/communitySetup/communityCommunication/CommunityCommunications";
import AddCommunityCommunication from "components/admin/groupMenu/communityMenu/communitySetup/communityCommunication/addCommunityCommunication/AddCommunityCommunication";
import AddCommunityDiet from "components/admin/groupMenu/communityMenu/communitySetup/communityDiets/addCommunityDiet/AddCommunityDiet";
import CommunityContracts from "components/admin/groupMenu/communityMenu/communitySetup/communityContracts/CommunityContracts";
import AddCommunityContract from "components/admin/groupMenu/communityMenu/communitySetup/communityContracts/addCommunityContract/AddCommunityContract";
import CommunityAdaptiveEqipments from "components/admin/groupMenu/communityMenu/communitySetup/communityAdaptiveEqipments/CommunityAdaptiveEqipments";
import AssignedUsers from "components/admin/groupMenu/communityMenu/communitySetup/assignedUsers/AssignedUsers";
import AddAssignedUser from "components/admin/groupMenu/communityMenu/communitySetup/assignedUsers/addAssignedUser/AddAssignedUser";
import CommunityEHRs from "components/admin/groupMenu/communityMenu/communitySetup/communityEHRs/CommunityEHRs";
import CommunityEHRMapping from "components/admin/groupMenu/communityMenu/communitySetup/communityEHRs/CommunityEHRMapping";
import AddCommunityEHRMapping from "components/admin/groupMenu/communityMenu/communitySetup/communityEHRs/addCommunityEHRMapping/AddCommunityEHRMapping";
import CommunityEHRLiquidMapping from "components/admin/groupMenu/communityMenu/communitySetup/communityEHRs/CommunityEHRLiquidMapping";
import AddCommunityEHRLiquidMapping from "components/admin/groupMenu/communityMenu/communitySetup/communityEHRs/addCommunityEHRLiquidMapping/AddCommunityEHRLiquidMapping";
import AddCommunityEHR from "components/admin/groupMenu/communityMenu/communitySetup/communityEHRs/addCommunityEHR/AddCommunityEHR";
import PCCImport from "components/admin/groupMenu/communityMenu/communitySetup/communityEHRs/pccImport/PCCImport";
import ALISImport from "components/admin/groupMenu/communityMenu/communitySetup/communityEHRs/alisImport/ALISImport";
import MatrixImport from "components/admin/groupMenu/communityMenu/communitySetup/communityEHRs/matrixImport/MatrixImport";
import AddCommunityHTIOrder from "components/admin/groupMenu/communityMenu/communitySetup/communityHTIOrders/addCommunityHTIOrder/AddCommunityHTIOrder";
import AutomaticSetup from "components/admin/groupMenu/communityMenu/communitySetup/communityTableSide/automaticSetup/AutomaticSetup";
import CommunityCareType from "components/admin/groupMenu/communityMenu/communitySetup/communityCareType/CommunityCareType";
import EditCommunityCareType from "components/admin/groupMenu/communityMenu/communitySetup/communityCareType/editCommunityCareType/EditCommunityCareType";
import CommunityExport from "components/admin/groupMenu/communityMenu/communitySetup/communityExport/communityExport";
import CommunityTableSide from "components/admin/groupMenu/communityMenu/communitySetup/communityTableSide/communityTableSide";
import EditcommunityTableSide from "components/admin/groupMenu/communityMenu/communitySetup/communityTableSide/editcommunityTableSide/EditcommunityTableSide";
import CommunityMenuCustomize from "components/admin/groupMenu/communityMenu/communitySetup/communityMenuCustomize/communityMenuCustomize";
import EditcommunityMenuCustomize from "components/admin/groupMenu/communityMenu/communitySetup/communityMenuCustomize/editcommunityMenuCustomize/EditcommunityMenuCustomize";
import CommunityHTIOrders from "components/admin/groupMenu/communityMenu/communitySetup/communityHTIOrders/CommunityHTIOrders";
import CommunityConsulting from "components/admin/groupMenu/communityMenu/communitySetup/communityConsulting/CommunityConsulting";
import AddCommunityConsulting from "components/admin/groupMenu/communityMenu/communitySetup/communityConsulting/addCommunityConsulting/AddCommunityConsulting";
import CommunityLiquidConsistency from "components/admin/groupMenu/communityMenu/communitySetup/communityLiquidConsistency/communityLiquidConsistency";
import EditCommunityLiquidConsistency from "components/admin/groupMenu/communityMenu/communitySetup/communityLiquidConsistency/editcommunityLiquidConsistency/EditcommunityLiquidConsistency";
import EditCommunityAdaptiveEquipments from "components/admin/groupMenu/communityMenu/communitySetup/communityAdaptiveEqipments/editCommunityAdaptiveEquipments/EditCommunityAdaptiveEquipments";

// admin/groupMenu/vendorMenu Imports
import VendorMenu from "components/admin/groupMenu/vendorMenu/VendorMenu";
import VendorSetup from "components/admin/groupMenu/vendorMenu/vendorSetup/VendorSetup";
import PurchasingGroup from "components/admin/groupMenu/vendorMenu/purchasingGroups/PurchasingGroups";
import BaseMenus from "components/admin/groupMenu/vendorMenu/baseMenus/BaseMenus";
import VendorInfoForm from "components/admin/groupMenu/vendorMenu/vendorSetup/addVendor/forms/VendorInfoForm";
import VendorProducts from "components/admin/groupMenu/vendorMenu/vendorSetup/VendorProducts/VendorProducts";
import PurchasingGroupInfoForm from "components/admin/groupMenu/vendorMenu/purchasingGroups/addPurchasingGroup/PurchasingGroupInfoForm";
import ImportVendorPrice from "components/admin/groupMenu/vendorMenu/vendorSetup/importVendorPrice/ImportVendorPrice";
import ImportVendorFile from "components/admin/groupMenu/vendorMenu/vendorSetup/importVendorPrice/ImportVendorFile";
import RecalcVendorPrice from "components/admin/groupMenu/vendorMenu/vendorSetup/vendorRecalcPrice/RecalcVendorPrice";
import BulkProductEntry from "components/admin/groupMenu/vendorMenu/vendorSetup/bulkProductEntry/BulkProductEntry";

const AdminUserMenuRoute = {
  path: "UserMenu",
  element: <UserMenu />,
  children: [
    { path: "UserSetup", element: <UserSetup /> },
    { path: "UserSetup/AddUser", element: <AddUser /> },
    { path: "UserSetup/UserFacilities", element: <UserFacilities /> },
    { path: "UserSetup/UserRoles", element: <UserRoles /> },
    { path: "UserSetup/UserMenus", element: <UserMenus /> },
    { path: "UserSetup/DietitianUser", element: <DietitianForm /> },
    { path: "ManageDocuments", element: <ManageDocuments /> },
    {
      path: "ManageDocuments/AddManageDocument",
      element: <AddManageDocument />,
    },
    { path: "SystemMessages", element: <SystemMessages /> },
    {
      path: "SystemMessages/AddSystemMessage",
      element: <AddSystemMessage />,
    },
  ],
};
const AdminCommunitiesRoute = {
  path: "Communities",
  element: <CommunitiesMenu />,
  children: [
    { path: "CommunitySetup", element: <CommunitySetup /> },
    {
      path: "CommunitySetup/AddCommunity",
      element: <AddCommunity />,
    },
    {
      path: "communitydetail",
      element: <CommunityMenu />,
      children: [
        { path: "details", element: <CommunityDetails /> },
        { path: "diets", element: <CommunityDiets /> },
        {
          path: "diets/addcommunitydiet",
          element: <AddCommunityDiet />,
        },
        { path: "visits", element: <CommunityVisits /> },
        { path: "portaldocuments", element: <CommunityPortalDocuments /> },
        { path: "contracts", element: <CommunityContracts /> },
        { path: "htiorders", element: <CommunityHTIOrders /> },
        {
          path: "communications",
          element: <CommunityCommunication />,
        },
        {
          path: "communications/AddCommunityCommunication",
          element: <AddCommunityCommunication />,
        },
        {
          path: "htiorders/addHtiOrders",
          element: <AddCommunityHTIOrder />,
        },
        {
          path: "contracts/addcommunitycontract",
          element: <AddCommunityContract />,
        },
        {
          path: "adaptiveEquipments",
          element: <CommunityAdaptiveEqipments />,
        },
        {
          path: "adaptiveEquipments/editCommunityAdaptiveEquipments",
          element: <EditCommunityAdaptiveEquipments />,
        },
        { path: "assignedusers", element: <AssignedUsers /> },
        {
          path: "assignedusers/addassigneduser",
          element: <AddAssignedUser />,
        },
        { path: "ehridentifiers", element: <CommunityEHRs /> },
        {
          path: "ehridentifiers/addcommunityehr",
          element: <AddCommunityEHR />,
        },
        {
          path: "ehridentifiers/ehrdietmapping",
          element: <CommunityEHRMapping />,
        },
        {
          path: "ehridentifiers/ehrdietmapping/addcommunityehrmapping",
          element: <AddCommunityEHRMapping />,
        },
        {
          path: "ehridentifiers/ehrliquidmapping",
          element: <CommunityEHRLiquidMapping />,
        },
        {
          path: "ehridentifiers/ehrliquidmapping/addcommunityehrliquidmapping",
          element: <AddCommunityEHRLiquidMapping />,
        },
        { path: "ehridentifiers/pccImport", element: <PCCImport /> },
        {
          path: "ehridentifiers/alisImport",
          element: <ALISImport />,
        },
        {
          path: "ehridentifiers/matrixImport",
          element: <MatrixImport />,
        },
        { path: "consulting", element: <CommunityConsulting /> },
        { path: "tableSide", element: <CommunityTableSide /> },
        {
          path: "tableSide/edit",
          element: <EditcommunityTableSide />,
        },
        {
          path: "tableSide/automaticsetup",
          element: <AutomaticSetup />,
        },
        {
          path: "menuCustomize",
          element: <CommunityMenuCustomize />,
        },
        {
          path: "menuCustomize/edit",
          element: <EditcommunityMenuCustomize />,
        },
        {
          path: "consulting/addcommunityconsulting",
          element: <AddCommunityConsulting />,
        },
        {
          path: "consistency",
          element: <CommunityLiquidConsistency />,
        },
        {
          path: "consistency/edit",
          element: <EditCommunityLiquidConsistency />,
        },
        { path: "caretypes", element: <CommunityCareType /> },
        {
          path: "caretypes/edit",
          element: <EditCommunityCareType />,
        },
        { path: "export", element: <CommunityExport /> },
      ],
    },
    { path: "CorporateGroups", element: <CorporateGroup /> },
    { path: "CorporateGroups/exportPBJ", element: <ExportPBJ /> },
    {
      path: "CorporateGroups/consultantSchedule",
      element: <ConsultantSchedule />,
    },
    {
      path: "CorporateGroups/addCorporateGroups",
      element: <AddCorporateGroup />,
    },
    {
      path: "CorporateGroups/menuExpiration",
      element: <MenuExpiration />,
    },
    { path: "CorporateGroups/document", element: <Document /> },
    {
      path: "CorporateGroups/document/addDocument",
      element: <AddDocument />,
    },
    { path: "CorporateGroups/superUser", element: <SuperUser /> },
    {
      path: "CorporateGroups/superUser/addsuperUser",
      element: <AddsuperUser />,
    },
    {
      path: "CorporateGroups/automaticSnack",
      element: <AutomaticSnack />,
    },
    {
      path: "CorporateGroups/automaticSnack/AddautomaticSnack",
      element: <AddautomaticSnack />,
    },
    {
      path: "CorporateGroups/beverageSelection",
      element: <BeverageSelection />,
    },
    {
      path: "CorporateGroups/beverageSelection/AddbeverageSelection",
      element: <AddbeverageSelection />,
    },
    {
      path: "CorporateGroups/mealExtrasSelection",
      element: <MealExtrasSelection />,
    },
    {
      path: "CorporateGroups/mealExtrasSelection/AddMealExtrasSelection",
      element: <AddMealExtrasSelection />,
    },
  ],
};
const AdminCommunitiesvisitsDetailRoute = {
  path: "communities/visitsDetail",
  element: <CommunityVisitsMenu />,
  children: [
    { path: "detail", element: <CommunityVisitsDetail /> },
    { path: "logReport", element: <CommunityVisitsLog /> },
    {
      path: "report",
      element: <CommunityVisitsReport />,
    },
  ],
};
const AdminVendorsRoute = {
  path: "vendors",
  element: <VendorMenu />,
  children: [
    { path: "VendorSetup", element: <VendorSetup /> },
    { path: "VendorSetup/addVendor", element: <VendorInfoForm /> },
    {
      path: "VendorSetup/VendorProducts",
      element: <VendorProducts />,
    },
    {
      path: "VendorSetup/importvendorprice",
      element: <ImportVendorPrice />,
    },
    {
      path: "VendorSetup/ImportVendorPrice/ImportFile",
      element: <ImportVendorFile />,
    },
    {
      path: "VendorSetup/RecalcVendorPrice",
      element: <RecalcVendorPrice />,
    },
    {
      path: "VendorSetup/BulkProductEntry",
      element: <BulkProductEntry />,
    },
    { path: "PurchasingGroups", element: <PurchasingGroup /> },
    {
      path: "PurchasingGroups/addPurchasingGroup",
      element: <PurchasingGroupInfoForm />,
    },
    {
      path: "BaseMenus",
      element: <BaseMenus />,
    },
  ],
};

export const AdminGroupsRoute = {
  AdminUserMenuRoute,
  AdminCommunitiesRoute,
  AdminCommunitiesvisitsDetailRoute,
  AdminVendorsRoute,
};
