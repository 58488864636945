import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { REGIONS } = API_ENDPOINTS;

export const RegionsApis = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRegionList: builder.query({
      query: () => `/${REGIONS}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [...response.map(({ id }) => ({ type: REGIONS, id })), REGIONS]
          : [REGIONS];
      },
    }),
    getRegionSelectList: builder.query({
      query: () => `/${REGIONS}`,
      transformResponse: (response) =>
        response?.data?.map((value) => ({
          id: value.id,
          label: value.name,
        })),
      providesTags: (response) => {
        return response
          ? [...response.map(({ id }) => ({ type: REGIONS, id })), REGIONS]
          : [REGIONS];
      },
    }),
    getRegionById: builder.query({
      query: (id) => `/${REGIONS}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response ? [{ type: REGIONS, id: response.id }, REGIONS] : [REGIONS],
    }),
    addRegion: builder.mutation({
      query: (data) => ({
        url: `/${REGIONS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: REGIONS, id: result?.id }],
    }),
    updateRegion: builder.mutation({
      query: (data) => ({
        url: `/${REGIONS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: REGIONS, id: result?.id }],
    }),
    deleteRegion: builder.mutation({
      query: (id) => ({
        url: `/${REGIONS}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result) => [{ type: REGIONS, id: result?.id }],
    }),
  }),
});

export const {
  useGetRegionListQuery,
  useGetRegionByIdQuery,
  useGetRegionSelectListQuery,
  useAddRegionMutation,
  useUpdateRegionMutation,
  useDeleteRegionMutation,
} = RegionsApis;
