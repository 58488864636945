import {
  endingDay,
  formatTypeId,
  includeBreakfast,
  includeDayOfMenuLabel,
  includeOtherMenuSelections,
  includeResidentName,
  indicateOtherMenuSelections,
  offset6,
  onlyPrintOtherMenuSelections,
  regularQuantitiesText,
  productionReportDateOptionId,
  startingDay,
  firstDate,
} from "../form.constants";

export const DailySelectionSheetForms = {
  Category: true,
  DayForm: [
    includeBreakfast,
    offset6,
    includeResidentName,
    offset6,
    includeDayOfMenuLabel,
    offset6,
    includeOtherMenuSelections,
    offset6,
    onlyPrintOtherMenuSelections,
    offset6,
    indicateOtherMenuSelections,
    offset6,
  ],
  Census: [regularQuantitiesText, startingDay, offset6, endingDay, offset6],
  Diet: true,
  SnackMeal: [productionReportDateOptionId, offset6, firstDate,],
  Report: [formatTypeId],
};
