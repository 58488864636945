import { includeNotes, useExcelFormat } from "../forms.constants";

export const ByBuildingWingFields = [includeNotes, useExcelFormat];

export const ByBuildingWingDefaults = {
  includeNotes: false,
  useExcelFormat: false,
};

export const ByDietFields = [includeNotes, useExcelFormat];

export const ByDietDefaults = {
  includeNotes: false,
  useExcelFormat: false,
};

export const ByDietTextureFields = [includeNotes, useExcelFormat];

export const ByDietTextureDefaults = {
  includeNotes: false,
  useExcelFormat: false,
};

export const ByMealDiningAreaFields = [includeNotes, useExcelFormat];

export const ByMealDiningAreaDefaults = {
  includeNotes: false,
  useExcelFormat: false,
};

export const ByResidentFields = [includeNotes, useExcelFormat];

export const ByResidentDefaults = {
  includeNotes: false,
  useExcelFormat: false,
};

export const ByLiquidConsistencyFields = [includeNotes, useExcelFormat];

export const ByLiquidConsistencyDefaults = {
  includeNotes: false,
  useExcelFormat: false,
};

export const ByDislikeAllergiesFields = [includeNotes, useExcelFormat];

export const ByDislikeAllergiesDefaults = {
  includeNotes: false,
  useExcelFormat: false,
};

export const ByFluidRestrictionFields = [includeNotes, useExcelFormat];

export const ByFluidRestrictionDefaults = {
  includeNotes: false,
  useExcelFormat: false,
};
