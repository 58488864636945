import {
  alternateMenuItemtext,
  dietReportDateOptionId,
  endingDay,
  firstDate,
  formatTypeId,
  includeOtherMenuSelections2,
  indicateThinLiquid,
  lastDate,
  offset12,
  offset6,
  reportFooter,
  reportFooterText,
  showIDDSIAbbreviations,
  startingDay,
  useLegalSize,
} from "../form.constants";

export const DietSpreadsheetForms = {
  Category: true,
  DayForm: [
    startingDay,
    offset6,
    endingDay,
    offset6,
    includeOtherMenuSelections2,
    offset6,
    indicateThinLiquid,
    offset6,
    showIDDSIAbbreviations,
    offset6,
  ],
  Diet: true,
  SnackMeal: [
    dietReportDateOptionId,
    offset6,
    firstDate,
    offset6,
    lastDate,
    offset12,
    useLegalSize,
    offset6,
    reportFooterText,
    reportFooter,
    alternateMenuItemtext,
  ],
  Report: [formatTypeId],
};
