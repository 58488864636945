import { REQUIRED_ERROR } from "utils/errorMessages";

export const LIQUID_CONSISTENCY_FIELDS = [
  {
    props: {
      name: "name",
      id: "name",
      label: "Name (ThickenedLiquid)",
    },
    validationProps: {
      required: REQUIRED_ERROR("Name"),
    },
    size: 6,
  },
];
