export const COLUMN_IDS = {
  UnGrouped: -1,
  Grouping1: 0,
  Grouping2: 1,
  Grouping3: 2,
  Grouping4: 3,
  Grouping5: 4,
  Grouping6: 5,
  Grouping7: 6,
};

export const GROUPING_DEFAULTS = {
  mealId: null,
  menuDay: null,
  groupingTitle0: "",
  groupingTitle1: "",
  groupingTitle2: "",
  groupingTitle3: "",
  groupingTitle4: "",
  groupingTitle5: "",
};

export const ONLY_GROUPING_DEFAULTS = {
  groupingTitle0: "",
  groupingTitle1: "",
  groupingTitle2: "",
  groupingTitle3: "",
  groupingTitle4: "",
  groupingTitle5: "",
};
