import {
  month,
  showAllResidentId,
  AllResidents,
  startDate,
  useExcelFormat,
  weightAlertField,
  showAllResidentIds,
} from "../forms.constants";
import dayjs from "dayjs";

export const AssessmentsDueFields = [useExcelFormat];

export const AssessmentsDueDefaults = {
  useExcelFormat: false,
};

export const AssessmentsLessThanMonthDueFields = [useExcelFormat];

export const AssessmentsLessThanMonthDefaults = {
  useExcelFormat: false,
};

export const BirthdayListFields = [month, useExcelFormat];

export const BirthdayListDefaults = {
  month: 0,
  useExcelFormat: false,
};

export const CarePlanLessThanMonthDueFields = [useExcelFormat];

export const CarePlanLessThanMonthDefaults = {
  useExcelFormat: false,
};

export const CarePlansFields = [useExcelFormat];

export const CarePlansDefaults = {
  useExcelFormat: false,
};

export const NewAdmissionsLast4DaysFields = [useExcelFormat];

export const NewAdmissionsLast4DaysDefaults = {
  useExcelFormat: false,
};

export const ResidentProfileFields = [
  showAllResidentIds,
  AllResidents,
  useExcelFormat,
];

export const ResidentProfileDefaults = {
  residentIds: [],
  useExcelFormat: false,
};

export const ResidentWeightNameFields = [
  {
    ...showAllResidentId,
    validationProps: {}, // required: "Resident field is required."
  },
  AllResidents,
  startDate,
  weightAlertField,

  useExcelFormat,
];

export const ResidentWeightNameDefaults = {
  residentId: null,
  startDate: dayjs(new Date()).format("YYYY-MM-DD"),
  weightAlertField,
  useExcelFormat: false,
};

export const ResidentWeightBWERFields = [
  {
    ...showAllResidentId,
    validationProps: {}, // required: "Resident field is required."
  },
  AllResidents,
  startDate,
  weightAlertField,
  useExcelFormat,
];

export const ResidentWeightBWERDefaults = {
  residentId: null,
  startDate: dayjs(new Date()).format("YYYY-MM-DD"),
  useExcelFormat: false,
};

export const ResidentsByDoctorFields = [useExcelFormat];

export const ResidentsByDoctorDefaults = {
  useExcelFormat: false,
};

export const WeeklyWeightReportFields = [useExcelFormat];

export const WeeklyWeightReportDefaults = {
  useExcelFormat: false,
};
