import { Box, Grid } from "@mui/material";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import BaseSelect from "../../../../shared/baseForm/BaseSelect";
import BaseInput from "../../../../shared/baseForm/BaseInput";
import {
  useGetFacilityMealExtraSelectBoxQuery,
  useGetMealExtraUnitQuery,
} from "../../../../../store/apis/MealExtrasApis";
import BaseSubmitButton from "../../../../shared/baseSubmitButton/BaseSubmitButton";
import {
  useAddResidentMealExtrasMutation,
  useResidentMealExtrasByIdQuery,
  useUpdateResidentMealExtrasMutation,
  useResidentDietMealExtrasByIdQuery,
  useResidentMealExtrasQuery,
} from "../../../../../store/apis/ResidentApis";
import { EditSkeleton } from "../../../../admin/admin.overlays";
import { BaseMultiSelect } from "../../../../shared/baseForm/BaseMultiSelect";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { selectFacilityId } from "../../../../../store/slices/authSlice/AuthSlice";
import { WarningText } from "../../../../shared/WarningText";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import { dayFields } from "./forms.constants";
import { BackButton } from "components/shared/BackButton";
import BaseBlockerModal from "components/shared/blockerModal/BaseBlockerModal";
import BaseCheckBox from "components/shared/baseForm/BaseCheckBox";

const MealIds = [
  { id: 1, label: "Breakfast" },
  { id: 2, label: "Lunch" },
  { id: 3, label: "Dinner" },
];

const AddResidentMealExtras = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const facilityId = useSelector(selectFacilityId);
  const id = searchParams.get("mealExtrasId");
  const residentId = searchParams.get("id");
  const [selectedMealExtraId, setSelectedMealExtraId] = useState();
  const [hasAllowedSpread, setHasAllowedSpread] = useState(false);
  const [hasThickenedLiquid, setHasThickenedLiquid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const isEdit = Boolean(id);

  const {
    data: currentMealExtra,
    isFetching: loadingCurrent,
    isSuccess: successCurrent,
  } = useResidentMealExtrasByIdQuery(id, { skip: !id });

  const {
    data: mealExtras,
    isLoading: mealExtrasLoading,
    isSuccess: successMealExtra,
  } = useGetFacilityMealExtraSelectBoxQuery(facilityId);

  const { data: residentMealExtras } = useResidentMealExtrasQuery(residentId, {
    skip: !residentId,
  });

  const { data: mealExtrasUnits, isLoading: loadingUnits } =
    useGetMealExtraUnitQuery();

  const { data: validationMealExtra } = useResidentDietMealExtrasByIdQuery(
    { residentId, selectedMealExtraId },
    { skip: !selectedMealExtraId }
  );

  const isEditLoading = loadingCurrent && isEdit;

  const [
    addMealExtra,
    { isLoading: addLoading, isSuccess: addSuccess, error: addError },
  ] = useAddResidentMealExtrasMutation();
  const [
    updateMealExtra,
    { isLoading: updateLoading, isSuccess: updateSuccess, error: updateError },
  ] = useUpdateResidentMealExtrasMutation();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors, isDirty: valuesChange },
  } = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const selectedValue = watch("mealExtraId");

  const mealIds = useMemo(() => {
    let meals = [];
    if (mealExtras && selectedValue) {
      for (let i = 0; i < MealIds.length; i++) {
        if (
          residentMealExtras.some(
            (mealExtra) =>
              mealExtra.mealExtraId == selectedValue &&
              mealExtra.mealId == MealIds[i].id
          )
        ) {
          meals.push({ ...MealIds[i], disabled: true });
          continue;
        }
        meals.push({ ...MealIds[i], disabled: false });
      }
    }

    return meals;
  }, [residentMealExtras, mealExtras, selectedValue]);

  const mealId = watch("mealId");
  const dayCheckboxes = watch([
    "onSun",
    "onMon",
    "onTue",
    "onWed",
    "onThu",
    "onFri",
    "onSat",
  ]);

  const allChecked = dayCheckboxes.every(Boolean);
  const someChecked = dayCheckboxes.some(Boolean);

  const handleChange = useCallback(
    (event) => {
      const checked = event.target.checked;
      ["onSun", "onMon", "onTue", "onWed", "onThu", "onFri", "onSat"].forEach(
        (day) => {
          setValue(day, checked);
        }
      );
    },
    [setValue]
  );

  const isSubmitting = addLoading || updateLoading;
  const isSuccess = addSuccess || updateSuccess;
  const errorData = addError || updateError;

  useEffect(() => {
    if (selectedValue && mealExtras) {
      setSelectedMealExtraId(selectedValue);
      const defaultUnit = mealExtras.find(
        (field) => field.id === selectedValue
      );
      if (defaultUnit) {
        setValue("unitId", defaultUnit.unit);
      }
    }
  }, [selectedValue, successMealExtra, mealExtras, setValue]);

  useEffect(() => {
    if (validationMealExtra) {
      setHasAllowedSpread(validationMealExtra.hasAllowedSpread);
      setHasThickenedLiquid(validationMealExtra.hasThickenedLiquid);
    }
  }, [validationMealExtra]);

  useEffect(() => {
    if (successCurrent && !loadingCurrent) {
      reset(currentMealExtra);
    }
  }, [successCurrent, loadingCurrent, currentMealExtra, reset]);

  useEffect(() => {
    setIsDirty(valuesChange);
  }, [valuesChange]);

  const onSubmit = useCallback(
    (data) => {
      setIsDirty(false);
      const transformedData = {
        ...(id ? { id } : {}),
        residentId,
        mealExtraId: data.mealExtraId,
        unitId: data.unitId,
        quantity: data.quantity,
        nutritionIntervention: data.nutritionIntervention,
        onSun: data.onSun,
        onMon: data.onMon,
        onTue: data.onTue,
        onWed: data.onWed,
        onThu: data.onThu,
        onFri: data.onFri,
        onSat: data.onSat,
        description: data.description ?? "",
        meals: data.meals ?? [],
      };

      if (data.mealId) {
        transformedData.mealId = data.mealId;
      }

      if (!isEdit) {
        addMealExtra(transformedData);
      } else {
        updateMealExtra(transformedData);
      }
    },
    [id, residentId, addMealExtra, updateMealExtra, isEdit]
  );

  useEffect(() => {
    if (!isSubmitting && isSuccess) {
      navigate(-1);
    }
  }, [isSubmitting, isSuccess, navigate]);

  const warningText = useMemo(() => {
    if (!hasAllowedSpread && validationMealExtra?.residentDietName) {
      return `ALERT: Resident is on a "${validationMealExtra.residentDietName}" Diet which does not permit this Meal Add-On selection. Will need to select another selection.`;
    }
    if (hasAllowedSpread && validationMealExtra?.residentDietName) {
      return `ALERT: Resident is on a "${validationMealExtra.residentDietName}" Diet so Meal Add-On selection will need to have 
        altered consistency before serving. If you do not want to do this, recommended to choose another selection.`;
    }
    if (hasThickenedLiquid && validationMealExtra?.beverageOrSupplement) {
      return `ALERT: Resident is on Thickened Liquids. Beverage/Supplement selection will need to be thickened at point of service.`;
    }
    return null;
  }, [hasAllowedSpread, validationMealExtra, hasThickenedLiquid]);

  return (<>
    {isEditLoading ? (
      <EditSkeleton />
    ) : (
      <>
        <BaseBlockerModal isDirty={isDirty} />
        <Box sx={{ padding: "5px", marginTop: "10px" }}>
          <BackButton To={-1} />
          <Box
            sx={{
              marginTop: "10px",
              width: "40%"
            }}>
            <Grid container spacing={1}>
              <Grid item md={12}>
                <BaseSelect
                  name="mealExtraId"
                  id="mealExtraId"
                  label="Meal Add-On"
                  control={control}
                  errors={errors}
                  options={mealExtras || []}
                  validationProps={{
                    required: REQUIRED_ERROR("MealExtra"),
                  }}
                  loading={mealExtrasLoading}
                />
              </Grid>
              {watch("mealExtraId") === -1 && (
                <Grid item md={12}>
                  <BaseInput
                    name="description"
                    id="description"
                    label="Description"
                    control={control}
                    validationProps={{
                      required: REQUIRED_ERROR("description"),
                    }}
                    errors={errors}
                  />
                </Grid>
              )}
              <Grid item md={12}>
                {isEdit ? (
                  <BaseSelect
                    name="mealId"
                    id="mealId"
                    label="Meal"
                    control={control}
                    errors={errors}
                    defaultValue={mealIds.find((x) => x.id === mealId)}
                    options={mealIds || []}
                    validationProps={{ required: REQUIRED_ERROR("Meal") }}
                  />
                ) : (
                  <BaseMultiSelect
                    name="meals"
                    id="meals"
                    label="Meals"
                    control={control}
                    setValue={setValue}
                    options={mealIds || []}
                    validationProps={{ required: REQUIRED_ERROR("Meals") }}
                    errors={errors}
                  />
                )}
              </Grid>
              <Grid item md={6}>
                <BaseInput
                  name="quantity"
                  id="quantity"
                  label="Quantity"
                  control={control}
                  validationProps={{
                    required: "Quantity value is required",
                    min: {
                      value: 0.1,
                      message: "Value must be greater than 0.",
                    },
                  }}
                  errors={errors}
                  min={0}
                  type="number"
                />
              </Grid>
              <Grid item md={6}>
                <BaseSelect
                  name="unitId"
                  id="unitId"
                  label="Select unit"
                  control={control}
                  loading={loadingUnits}
                  options={mealExtrasUnits || []}
                  validationProps={{
                    required: "The default unit for this Meal Add-On",
                  }}
                  errors={errors}
                />
              </Grid>
            </Grid>
            <Grid container spacing={0.5} sx={{
              marginY: "5px"
            }}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <BaseCheckBox
                  name="nutritionIntervention"
                  id="nutritionIntervention"
                  label="Nutrition Intervention?"
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <BaseCheckBox
                  name="selectAll"
                  id="selectAll"
                  label="Select All"
                  checked={allChecked}
                  indeterminate={someChecked && !allChecked}
                  onChange={handleChange}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <GeneratedForm
                control={control}
                list={dayFields}
                errors={errors}
              />
            </Grid>
          </Box>
          <Grid container spacing={1} sx={{
            margin: "5px"
          }}>
            <Grid item md={2}>
              <BaseSubmitButton
                sx={{ marginTop: "20px" }}
                text={isEdit ? "Save" : "Create"}
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
                disabled={
                  !hasAllowedSpread ||
                  !dayFields.some((field) => watch(field.props.id))
                }
                validationSummary={errorData}
              />
            </Grid>
            <Grid item md={9}>
              {warningText && (
                <WarningText margin="5px" text={warningText} color="red" />
              )}
            </Grid>
          </Grid>
        </Box>
      </>
    )}
  </>);
};

export default AddResidentMealExtras;
