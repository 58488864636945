import React, { useMemo, useState } from "react";
import {
  useAddCorporateGroupCategoryMutation,
  useGetCorporateGroupSelectQuery,
} from "../../../../../../store/apis/CorporateGroupApis";
import { debounce } from "lodash";
import BaseAutoComplete from "../../../../../shared/baseForm/BaseAutoComplete";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { BaseButton } from "../../../../../shared/BaseButton";

export default function AddCorporateGroupCategory() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentRecipeCategoryId = searchParams.get("id");
  const [
    addCorporateGroupCategory,
    { isError: isError, isLoading: isLoading },
  ] = useAddCorporateGroupCategoryMutation();

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    data.categoryId = currentRecipeCategoryId;
    addCorporateGroupCategory(data);
    navigate(-1);
  };

  function CorporateGroupSelectBox({ control, errors, defaultOptions }) {
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const { data, isLoading } = useGetCorporateGroupSelectQuery(
      debouncedSearch,
      {
        skip: !debouncedSearch,
      }
    );
    const handleSearch = useMemo(
      () =>
        debounce((query) => {
          setDebouncedSearch(query);
        }, 400),
      []
    );
    return (
      <BaseAutoComplete
        formSelectProps={{
          props: {
            name: "corporateGroupId",
            id: "corporateGroupId",
            label: "Corporate Group",
          },
          validationProps: {
            required: REQUIRED_ERROR("Corporate Group"),
          },
        }}
        control={control}
        errors={errors}
        options={data || []}
        loading={isLoading}
        defaultOptions={defaultOptions}
        handleSearch={handleSearch}
      />
    );
  }

  return (<>
    <BaseButton
      text="Back To Corporate Group Categories"
      startIcon={"keyboard_arrow_left"}
      onClick={() => navigate(-1)}
    />
    <Divider sx={{ marginBottom: "15px", border: "none" }} />
    <Grid container spacing={2}>
      <Grid item md={3} />
      <Grid item md={6}>
        <CorporateGroupSelectBox
          control={control}
          errors={errors}
          defaultOptions={[]}
        />
      </Grid>
      <Grid item md={3} />
      <Grid item xs={3} />
      <Grid item xs={6}>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          {isLoading ? (
            <Button
              variant="contained"
              color="primary"
              disabled
              onClick={handleSubmit(onSubmit)}>
              Submit
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}>
              Submit
            </Button>
          )}
        </Box>
        {isError ? (
          <Typography sx={{
            color: "error"
          }}>
            There was an error submitting the form, please try again.
          </Typography>
        ) : undefined}
      </Grid>
      <Grid item xs={3} />
    </Grid>
  </>);
}
