import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { CORPORATE_GROUP_MEAL_EXTRAS } = API_ENDPOINTS;

export const CorporateMealExtraAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // define an endpoint for getting CorporateMealExtra
    getCorporateMealExtra: builder.query({
      query: ({ corporateGroupId, searchText }) =>
        `/${CORPORATE_GROUP_MEAL_EXTRAS}?corporateGroupId=${corporateGroupId}&searchText=${searchText}&skip=0&take=1000`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: CORPORATE_GROUP_MEAL_EXTRAS,
                id,
              })),
              CORPORATE_GROUP_MEAL_EXTRAS,
            ]
          : [CORPORATE_GROUP_MEAL_EXTRAS];
      },
    }),
    getCorporateMealExtraById: builder.query({
      query: (mealExtraId) => `/${CORPORATE_GROUP_MEAL_EXTRAS}/${mealExtraId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              {
                type: CORPORATE_GROUP_MEAL_EXTRAS,
                id: response.id,
              },
              CORPORATE_GROUP_MEAL_EXTRAS,
            ]
          : [CORPORATE_GROUP_MEAL_EXTRAS],
    }),
    deleteCorporateMealExtra: builder.mutation({
      query: (id) => ({
        url: `/${CORPORATE_GROUP_MEAL_EXTRAS}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: CORPORATE_GROUP_MEAL_EXTRAS, id: arg.id },
      ],
    }),
    addCorporateMealExtra: builder.mutation({
      query: (data) => ({
        url: `/${CORPORATE_GROUP_MEAL_EXTRAS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: CORPORATE_GROUP_MEAL_EXTRAS, id: result?.id },
      ],
    }),
    updateCorporateMealExtra: builder.mutation({
      query: (data) => ({
        url: `/${CORPORATE_GROUP_MEAL_EXTRAS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: CORPORATE_GROUP_MEAL_EXTRAS, id: result?.id },
      ],
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetCorporateMealExtraQuery,
  useGetCorporateMealExtraByIdQuery,
  useDeleteCorporateMealExtraMutation,
  useAddCorporateMealExtraMutation,
  useUpdateCorporateMealExtraMutation,
} = CorporateMealExtraAPIs;
