import { Box, Grid, Typography } from "@mui/material";
import { EditSkeleton } from "components/admin/admin.overlays";
import { BaseButton } from "components/shared/BaseButton";
import { WarningText } from "components/shared/WarningText";
import BaseAutoComplete from "components/shared/baseForm/BaseAutoComplete";
import BaseInput from "components/shared/baseForm/BaseInput";
import BaseRadio from "components/shared/baseForm/BaseRadio";
import BaseSelect from "components/shared/baseForm/BaseSelect";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  useNavigate,
  useSearchParams,
  unstable_useBlocker as useBlocker,
} from "react-router-dom";
import {
  useAddMealExtraMutation,
  useGetMealExtraByIdQuery,
  useGetMealExtraUnitQuery,
  useUpdateMealExtraMutation,
} from "store/apis/MealExtrasApis";
import { useGetMealExtrasRecipeSearchListQuery } from "store/apis/PlateFulRecipeDietApis";
import {
  useAddCustomFacilityMealExtraMutation,
  //  useUpdateCustomFacilityMealExtraMutation,
} from "store/apis/FacilityMealExtraApis";

import { selectFacilityId } from "store/slices/authSlice/AuthSlice";
import { REQUIRED_ERROR } from "utils/errorMessages";
import BlockerModal from "components/shared/blockerModal/BlockerModal";
import useMsalAccount from "utils/useMsalAccount";

export default function AddMealExtras(param) {
  const facilityId = useSelector(selectFacilityId);
  const { userId } = useMsalAccount();
  const [blockerModal, setBlockerModal] = useState(false);
  const [hasChange, setHasChange] = useState(false);

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    register,
    control,
    formState: { errors, isDirty },
  } = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const [addMealExtras, { isError: addError, isLoading: addLoading }] =
    useAddMealExtraMutation();

  const [updateMealExtras, { isError: updateError, isLoading: updateLoading }] =
    useUpdateMealExtraMutation();

  const [
    addFacilityMealExtras,
    { isError: addFacilityError, isLoading: addFacilityLoading },
  ] = useAddCustomFacilityMealExtraMutation();

  //  const [updateFacilityMealExtras, { isError: updateFacilityError, isLoading: updateFacilityLoading }] =
  //  useUpdateCustomFacilityMealExtraMutation();
  const isError = addError || updateError || addFacilityError;
  const isSubmitting = addLoading || updateLoading || addFacilityLoading;
  const [searchParams] = useSearchParams();
  const [currentMealExtraId, setCurrentMealExtraId] = useState(
    searchParams.get("id")
  );
  //const currentMealExtraId = searchParams.get("id");
  const useFacilityId = searchParams.get("facilityId");
  const isEdit = !!currentMealExtraId;
  const [defaultRecipeType, setDefaultRecipeType] = useState([]);
  const {
    data: currentMealExtra,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetMealExtraByIdQuery(currentMealExtraId, { skip: !isEdit });

  const isEditLoading = isEdit && currentFetching;
  useEffect(() => {
    if (param.mealExtraId !== null && param.mealExtraId !== undefined) {
      setCurrentMealExtraId(param.mealExtraId);
    } else {
      setCurrentMealExtraId(searchParams.get("id"));
    }

    if (currentMealExtra) {
      reset(currentMealExtra);
      setDefaultRecipeType([
        {
          id: currentMealExtra.recipeId,
          label: currentMealExtra.recipeName,
        },
      ]);
    }
  }, [currentMealExtra]);

  // UnSaved Changes Feature here
  // Block navigating elsewhere when data has been entered into the input
  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) =>
      hasChange && currentLocation.pathname !== nextLocation.pathname,
    [hasChange]
  );

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === "blocked") {
      setBlockerModal(true);
    }
  }, [blocker]);

  // Page Reload and navigation alert implementations
  useEffect(() => {
    window.onbeforeunload = function () {
      if (hasChange) {
        return "You have unsaved changes. Are you sure you want to leave?";
      }
    };
  }, [hasChange]);

  const handleOnLeave = () => {
    blocker.proceed();
    setBlockerModal(false);
    setHasChange(false);
  };
  const handleOnStay = () => {
    blocker.reset();
    setBlockerModal(false);
  };

  const { data: mealExtras, isLoading: loading } = useGetMealExtraUnitQuery();

  const [debouncedSearch, setDebouncedSearch] = useState("");
  const { data: searchData, isLoading: isLoadingSearch } =
    useGetMealExtrasRecipeSearchListQuery(
      {
        userId: userId,
        facilityId: facilityId,
        searchText: debouncedSearch,
      },
      {
        skip: !debouncedSearch,
        refetchOnMountOrArgChange: true,
      }
    );
  const handleSearch = useMemo(
    () =>
      debounce((query) => {
        setDebouncedSearch(query);
      }, 400),
    []
  );
  const RadioGroup = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const radioGroup = [
    { value: 1, label: "Is Food" },
    {
      value: 2,
      label: "Is Beverage",
    },
    {
      value: 3,
      label: "Is Supplement",
    },
  ];
  const filterOptions = (options, { inputValue }) => {
    const searchValues = inputValue.split(" ");

    const filtered = options.filter((v) =>
      searchValues.every((searchValue) =>
        v.label.toLowerCase().includes(searchValue.toLowerCase())
      )
    );

    return filtered;
  };

  const navigate = useNavigate();

  useEffect(() => {
    setHasChange(isDirty);
  }, [isDirty]);

  useEffect(() => {
    if (currentSuccess && !currentFetching && currentMealExtra) {
      reset(currentMealExtra);
      //ONLY Set to true/false if value is set else leave unset for user to select
      if (currentMealExtra?.isTexturedDietsAllowed == true) {
        setValue("isTexturedDietsAllowed", true);
      }
      if (currentMealExtra?.isTexturedDietsAllowed == false) {
        setValue("isTexturedDietsAllowed", false);
      }
      currentMealExtra?.isBeverage
        ? setValue("mealSelection", 2)
        : currentMealExtra?.isSupplement
        ? setValue("mealSelection", 3)
        : setValue("mealSelection", 1);
    }
  }, [currentMealExtra]);

  const onSubmit = (data) => {
    setHasChange(false);
    const transformedData = {
      id: data?.id,
      facilityId:
        data?.facilityId !== null
          ? data?.facilityId
          : useFacilityId !== null
          ? parseInt(useFacilityId, 10)
          : null,
      defaultUnitId: data?.defaultUnitId,
      mealExtraId: data?.mealExtraId,
      isTexturedDietsAllowed:
        data?.isTexturedDietsAllowed == "true" ||
        data?.isTexturedDietsAllowed == true
          ? true
          : false,
      name: data?.name,
      recipeId: data?.recipeId,
      isFood: data?.mealSelection == 1 ? true : false,
      isBeverage: data?.mealSelection == 2 ? true : false,
      isSupplement: data?.mealSelection == 3 ? true : false,
    };
    if (isEdit) {
      updateMealExtras(transformedData);
    } else {
      if (useFacilityId !== null) {
        addFacilityMealExtras({
          ...transformedData,
          defaultUnitName: "",
          facilityId: parseInt(useFacilityId, 10),
        });
      } else {
        addMealExtras({ ...transformedData, defaultUnitName: "" });
      }
    }
    if (!isError) {
      navigate(-1);
    }
  };

  const isFoodTrue = watch("mealSelection");

  return (<>
    {isEditLoading ? (
      <EditSkeleton />
    ) : (
      <>
        {blockerModal ? (
          <BlockerModal
            text={`Hold up! You've got unsaved changes. Are you sure you want to leave?`}
            open={blockerModal}
            onStay={handleOnStay}
            onLeave={handleOnLeave}
          />
        ) : null}
        <Box
          sx={{
            height: { sm: "70vh", md: "70vh", lg: "81vh", xl: "81vh" },
            overflow: "scroll"
          }}>
          <BaseButton
            text="Back to Meal Add-Ons List"
            startIcon={
              <span className="material-icons">keyboard_arrow_left</span>
            }
            To={-1}
            sx={{
              marginTop: "3px",
            }}
          />

          <Grid container spacing={0.5} sx={{ padding: "10px" }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <WarningText
                text={`Add a custom Meal Add-On here by adding name, default unit and whether it is a food, beverage or supplement. To ensure safety, 
            you will need to designate if food item is allowed on textured diets or option to attach recipe to utilize diet spreads.`}
                sx={{
                  marginX: "0px !important",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <BaseInput
                label={"Name"}
                name={"name"}
                placeholder="Name"
                control={control}
                errors={errors}
                validationProps={{
                  required: REQUIRED_ERROR("Name"),
                  maxLength: {
                    value: 50,
                    message: "Name should be less than 50 characters",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <BaseSelect
                label="Default Unit"
                name="defaultUnitId"
                id="defaultUnitId"
                control={control}
                options={mealExtras || []}
                errors={errors}
                loading={loading}
                validationProps={{
                  required: REQUIRED_ERROR("Unit"),
                }}
                sx={{
                  marginY: 0.5,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box
                fullWidth
                sx={{
                  backgroundColor: "#f6f6f6",
                  border: "1px solid",
                  borderColor: "#c6c6c6",
                  borderRadius: 1,
                  padding: 1,
                  marginY: 1
                }}>
                <BaseRadio
                  id="mealSelection"
                  name="mealSelection"
                  control={control}
                  defaultValue={1}
                  setValue={setValue}
                  options={radioGroup}
                  errors={errors}
                />
              </Box>
              {isFoodTrue == "1" ? (
                <Box
                  fullWidth
                  sx={{
                    backgroundColor: "#f6f6f6",
                    borderRadius: 1,
                    border: "1px solid",
                    borderColor: "#c6c6c6",
                    padding: 1
                  }}>
                  <Typography>
                    Is this item allowed textured diets?
                  </Typography>
                  <BaseRadio
                    // label={"Is this item allowed textured diets?"}
                    id="isTexturedDietsAllowed"
                    name="isTexturedDietsAllowed"
                    setValue={setValue}
                    control={control}
                    options={RadioGroup}
                    errors={errors}
                  />
                </Box>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography
                sx={{
                  color: "error",
                  fontSize: 12,
                  mb: 0.5
                }}>
                Optional: Attach a recipe for a Meal Add-On to utilize altered
                consistency diet spreads to ensure safety of resident.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <BaseAutoComplete
                formSelectProps={{
                  props: {
                    name: "recipeId",
                    id: "recipeId",
                    label: "Attached Recipe	",
                  },
                  validationProps: {},
                }}
                control={control}
                register={register}
                errors={errors}
                options={searchData || []}
                loading={isLoadingSearch}
                defaultOptions={defaultRecipeType}
                handleSearch={handleSearch}
                filterOptions={filterOptions}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <BaseSubmitButton
                fullWidth
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
                text={isEdit ? "Submit" : "Create"}
                sx={{
                  marginY: 0.5,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {isError ? (
                <Typography sx={{
                  color: "error"
                }}>
                  There was an error submitting the form, please try again.
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </Box>{" "}
      </>
    )}
  </>);
}
