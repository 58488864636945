import { REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "../../shared/generatedForm/GeneratedForm";

const mealOptions = [
  { id: 0, label: "All" },
  { id: 1, label: "Breakfast" },
  { id: 2, label: "Lunch" },
  { id: 3, label: "Dinner" },
];

export const TicketPrintingFields = [
  {
    props: {
      name: "mealId",
      id: "mealId",
      label: "Meal",
      defaultValue: {
        id: 0,
        label: "All",
      },
    },
    validationProps: {
      required: REQUIRED_ERROR("Meal"),
    },
    size: 6,
    type: FORM_TYPES.Select,
    options: mealOptions,
  },
  {
    props: {
      name: "diningAreaId",
      id: "diningAreaId",
      label: "Dining Area",
      defaultValue: {
        id: null,
        label: "All Dining Areas",
      },
    },
    size: 6,
    type: FORM_TYPES.SelectAPI,
    endpoint: "residentDiningareaSelectAll",
  },
  {
    props: {
      name: "startDate",
      label: "Start Date",
      placeholder: "Start Date",
    },
    validationProps: {},
    type: FORM_TYPES.DatePicker,
    size: 6,
  },
  {
    props: {
      name: "endDate",
      label: "End Date",
      placeholder: "End Date",
    },
    type: FORM_TYPES.DatePicker,
    validationProps: {},
    size: 6,
  },
  {
    props: {
      name: "showAll",
      id: "showAll",
      label: "Include printed tickets",
    },
    type: "checkbox",
    size: 6,
  },
];
