import React from "react";
import { SECTION_FORM } from "./forms.constants";
import FieldGroup from "./FieldGroup";
import GenericFieldForm from "./GenericFieldForm";

export default function Section({ control, nestIndex, errors }) {
  return (
    <GenericFieldForm
      name={`sectionGroups[${nestIndex}].sections`}
      control={control}
      errors={errors}
      fieldList={SECTION_FORM}
      appendObjectExample={{ fieldGroups: [{ fields: [{}] }] }}
      nameOfObject={"Section"}
      fieldPropName={`sectionGroups[${nestIndex}].sections`}
      ChildComponent={FieldGroup}
      childProps={{ control, errors, sectionIndex: nestIndex }}
      isSortOrder={true}></GenericFieldForm>
  );
}
