import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { BaseButton } from "../BaseButton";
import { Divider } from "@mui/material";
import { Report } from "@mui/icons-material";
import FlexBox from "../styledComponents/FlexBox";

// Modal Styling
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 1,
};

function BlockerModal({ open, onClose, text, onStay, onLeave }) {
  return (
    (<Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description">
      <Box sx={style}>
        <FlexBox
          alignItems={"center"}
          gap={0.5}
          paddingX={3}
          justifyContent={"space-between"}>
          <Report color="error" sx={{ fontSize: "90px" }} />

          <Box sx={{
            flexGrow: 1
          }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              color="error">
              {text}
            </Typography>
          </Box>
        </FlexBox>
        <Divider />
        <FlexBox justifyContent={"flex-end"} paddingRight={3} paddingY={1}>
          <BaseButton text="Stay" colors="warning" onClick={onStay} />
          <BaseButton text="Leave" colors="error" onClick={onLeave} />
        </FlexBox>
      </Box>
    </Modal>)
  );
}

export default BlockerModal;
