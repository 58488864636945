import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Divider } from "@mui/material";
import {
  useGetVendorImportDataQuery,
  useUpdateVendorImportDataMutation,
} from "../../../../../../store/apis/VendorApis";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { BaseButton } from "../../../../../shared/BaseButton";

const DataTable = ({ rows, columns, isFetching, hasError }) => {
  if (rows && columns) {
    return (
      <div style={{ height: "100%" }}>
        <BaseDataGrid
          rows={rows}
          columns={columns}
          loading={isFetching}
          error={hasError}
        />
      </div>
    );
  }
};

function ImportVendorFile() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentVendorId = searchParams.get("vendorId");
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [importData, setImportData] = useState({
    vendorId: 0,
    vendorName: "",
    importMatchList: [],
    importNoMatchList: [],
    importDifferenceList: [],
  });

  const hasError = false;

  const { data: vendorImportData, isFetching } =
    useGetVendorImportDataQuery(currentVendorId);

  const [
    updateVendorImportData,
    {
      // isError: addError,
      isSuccess: isSuccess,
      isLoading,
    },
  ] = useUpdateVendorImportDataMutation();

  useEffect(() => {
    if (vendorImportData) {
      setImportData(vendorImportData);
    }
  }, [vendorImportData]);

  useEffect(() => {
    if (
      selectedRowIds?.length === 0 &&
      importData.importDifferenceList.length > 0
    ) {
      setImportData({
        ...importData,
        importDifferenceList: importData.importDifferenceList.map((item) => {
          return {
            ...item,
            import: false,
          };
        }),
      });
    } else if (selectedRowIds.length > 0) {
      setImportData({
        ...importData,
        importDifferenceList: importData.importDifferenceList.map((item) => {
          return {
            ...item,
            import: selectedRowIds.includes(item.id),
          };
        }),
      });
    }
  }, [selectedRowIds]);

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const vendorMatchColumns = [
    {
      field: "importItemNumber",
      headerName: "Item #",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "description",
      headerName: "HTI Description",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "importDescription",
      headerName: "Vendor Description",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "price",
      headerName: "Old Price",
      type: "number",
      flex: 1,
      minWidth: 100,
      valueFormatter: (value) => currencyFormatter.format(value),
    },
    {
      field: "importPrice",
      headerName: "New Price",
      type: "number",
      flex: 1,
      minWidth: 100,
      valueFormatter: (value) => currencyFormatter.format(value),
    },
    {
      field: "change",
      headerName: "Change",
      type: "number",
      flex: 1,
      minWidth: 100,
      // valueGetter: (params) =>
      //   `${currencyFormatter.format(params.row.importPrice - params.row.price)}`,
      renderCell: (params) => {
        const change = params.row.importPrice - params.row.price;
        const color = change === 0 ? "black" : change > 0 ? "green" : "red";
        return (
          <div style={{ color: color }}>{currencyFormatter.format(change)}</div>
        );
      },
    },
  ];

  const vendorNoMatchColumns = [
    {
      field: "itemNumber",
      headerName: "Item #",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "description",
      headerName: "HTI Description",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      flex: 1,
      minWidth: 100,
      valueFormatter: (value) => currencyFormatter.format(value),
    },
  ];

  const vendorDifferenceColumns = [
    {
      field: "importItemNumber",
      headerName: "Item #",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "importDescription",
      headerName: "Vendor Description",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "importPrice",
      headerName: "Vendor Price",
      type: "number",
      flex: 1,
      minWidth: 100,
      valueFormatter: (value) => currencyFormatter.format(value),
    },
    {
      field: "price",
      headerName: "HTI Price",
      type: "number",
      flex: 1,
      minWidth: 100,
      valueFormatter: (value) => currencyFormatter.format(value),
    },
    {
      field: "importPackSize",
      headerName: "Vendor Pack Size",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "packSize",
      headerName: "HTI Pack Size",
      flex: 1,
      minWidth: 100,
    },
  ];

  const ImportantInfo = () => {
    return (
      <div className="importantInfoWrapper">
        <p>Note:</p>
        Selecting checkboxes in the grid below will update both the price and
        packsize in the database.
      </div>
    );
  };

  const WarningInfo = () => {
    return (
      <div className="warningInfoWrapper">
        <p>Warning!</p>
        Pressing the submit button below will update the database with all price
        changes (Table 1) and any Import rows selected (Table 3).
      </div>
    );
  };

  const handleSubmit = () => {
    updateVendorImportData(importData);
    // navigate(-1);
  };

  useEffect(() => {
    if (isSuccess && !isLoading) {
      navigate(-1);
    }
  }, [isSuccess, isLoading]);

  return (
    <>
      <BaseButton
        text="Back To Vendor Price Import"
        startIcon={"keyboard_arrow_left"}
        onClick={() => navigate(-1)}
      />
      <Divider sx={{ marginBottom: "15px", border: "none" }} />

      <h2>Imported Data for {importData.vendorName || ""}</h2>
      <h4>Table 1: Matched Items (with changes)</h4>

      <DataTable
        rows={importData.importMatchList}
        columns={vendorMatchColumns}
        isFetching={isFetching}
        hasError={hasError}
        includeCheckbox={false}
      />
      <Divider sx={{ marginBottom: "15px", border: "none" }} />
      <h4>
        Table 2: Items in database that are not in the spreadsheet (Item #
        change? Discontinued?)
      </h4>
      <DataTable
        rows={importData.importNoMatchList}
        columns={vendorNoMatchColumns}
        includeCheckbox={false}
        isFetching={isFetching}
        hasError={hasError}
      />
      <Divider sx={{ marginBottom: "15px", border: "none" }} />
      <ImportantInfo />
      <h4>Table 3: Item match with Price and/or Packsize mismatch</h4>
      <div style={{ height: "100%" }}>
        <BaseDataGrid
          rows={importData?.importDifferenceList}
          loading={isFetching}
          columns={vendorDifferenceColumns}
          error={hasError}
          height={"300px"}
          checkboxSelection
          rowSelectionModel={selectedRowIds}
          onRowSelectionModelChange={setSelectedRowIds}
          // onSelectionModelChange={(ids) => {
          //   setSelectedRowIds(ids);
          // }}
        />
      </div>
      <Divider sx={{ marginBottom: "15px", border: "none" }} />
      <WarningInfo />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          pt: 2,
        }}>
        <BaseSubmitButton
          isSubmitting={isLoading}
          text={"Submit"}
          onClick={() => handleSubmit()}
        />
      </Box>
    </>
  );
}

export default ImportVendorFile;
