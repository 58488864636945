import React from "react";
import { FIELD_OPTION_FORM } from "./forms.constants";
import GenericFieldForm from "./GenericFieldForm";

export default function FieldOption({
  control,
  nestIndex,
  sectionIndex,
  fieldGroupIndex,
  fieldIndex,
  errors,
}) {
  return (
    <GenericFieldForm
      name={`sectionGroups[${sectionIndex}].sections[${fieldGroupIndex}].fieldGroups[${fieldIndex}].fields[${nestIndex}].options`}
      control={control}
      errors={errors}
      fieldList={FIELD_OPTION_FORM}
      appendObjectExample={{}}
      nameOfObject={"Option"}
      fieldPropName={`sectionGroups[${sectionIndex}].sections[${fieldGroupIndex}].fieldGroups[${fieldIndex}].fields[${nestIndex}].options`}
      isSortOrder={true}></GenericFieldForm>
  );
}
