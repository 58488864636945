export const CommunityMenuElements = [
  {
    Name: "Back",
    Link: "",
    IsActive: false,
  },
  {
    Name: "Details",
    Link: "/admin/communities/communitydetail/details",
    IsActive: false,
  },
  {
    Name: "Care Types",
    Link: "/admin/communities/communitydetail/caretypes",
    IsActive: false,
  },
  {
    Name: "Visits",
    Link: "/admin/communities/communitydetail/visits",
    IsActive: false,
  },
  {
    Name: "Diets",
    Link: "/admin/communities/communitydetail/diets",
    IsActive: false,
  },
  {
    Name: "Adaptive Equipment",
    Link: "/admin/communities/communitydetail/adaptiveEquipments",
    IsActive: false,
  },
  {
    Name: "Liquid Consistency",
    Link: "/admin/communities/communitydetail/consistency",
    IsActive: false,
  },
  {
    Name: "Agreements",
    Link: "/admin/communities/communitydetail/contracts",
    IsActive: false,
  },
  {
    Name: "Assigned Users",
    Link: "/admin/communities/communitydetail/assignedusers",
    IsActive: false,
  },
  {
    Name: "HTI Orders",
    Link: "/admin/communities/communitydetail/htiorders",
    IsActive: false,
  },
  {
    Name: "EHRs",
    Link: "/admin/communities/communitydetail/ehridentifiers",
    IsActive: false,
  },
  {
    Name: "TableSide Setup",
    Link: "/admin/communities/communitydetail/tableSide",
    IsActive: false,
  },
  {
    Name: "Menu Customization",
    Link: "/admin/communities/communitydetail/menuCustomize",
    IsActive: false,
  },
  {
    Name: "Consulting",
    Link: "/admin/communities/communitydetail/consulting",
    IsActive: false,
  },
  {
    Name: "PBJ Export",
    Link: "/admin/communities/communitydetail/export",
    IsActive: false,
  },
  {
    Name: "Communication",
    Link: "/admin/communities/communitydetail/communications",
    IsActive: false,
  },
];
