import { Print, SupervisedUserCircle, Tune } from "@mui/icons-material";

export const AdminElements = [
  {
    Name: "Groups",
    Icon: SupervisedUserCircle,
    IsActive: true,
    Children: [
      {
        Name: "Users",
        Link: "/admin/usermenu",
        IsActive: false,
      },
      {
        Name: "Communities",
        Link: "/admin/communities",
        IsActive: false,
      },
      {
        Name: "Vendors",
        Link: "/admin/vendors",
        IsActive: false,
      },
    ],
  },
  {
    Name: "Setups",
    Icon: Tune,
    IsActive: false,
    Children: [
      {
        Name: "Consultant",
        Link: "/admin/dietitian",
        IsActive: true,
      },
      {
        Name: "Recipe",
        Link: "/admin/recipes",
        IsActive: false,
      },
      {
        Name: "MealCard",
        Link: "/admin/mealcard",
        IsActive: false,
      },
    ],
  },
  {
    Name: "Reports",
    Icon: Print,
    IsActive: false,
    Children: [
      {
        Name: "Recipe",
        Link: "/admin/recipereport",
        IsActive: false,
      },
      {
        Name: "Vendor",
        Link: "/admin/vendorreport",
        IsActive: false,
      },
      {
        Name: "Billing",
        Link: "/admin/billingreport",
        IsActive: false,
      },
    ],
  },
];
