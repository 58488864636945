export const QtyInputs = [
  {
    props: {
      name: "quantity1",
      label: "Qty 1",
    },
  },
  {
    props: {
      name: "quantity2",
      label: "Qty 2",
    },
  },
  {
    props: {
      name: "quantity3",
      label: "Qty 3",
    },
  },
];

export const Checkboxes = [
  {
    props: {
      name: "includeIngredientsByWeight",
      id: "includeIngredientsByWeight",
      label: "Include Ingredients by Weight",
    },
  },
  {
    props: {
      name: "includeVendorInfo",
      id: "includeVendorInfo",
      label: "Include Vendor Info",
    },
  },
  {
    props: {
      name: "includeNutrientAnalysis",
      id: "includeNutrientAnalysis",
      label: "Include Nutrient Analysis",
    },
  },
  {
    props: {
      name: "includeImage",
      id: "includeImage",
      label: "Include Recipe Image (if available)",
    },
  },
];
