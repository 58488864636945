import { REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "../../../../../shared/generatedForm/GeneratedForm";

export const ADAPTIVE_EQUIPMENT_FIELDS = [
  {
    props: {
      name: "name",
      id: "name",
      label: "Name",
    },
    validationProps: {
      required: REQUIRED_ERROR("Name field is required"),
      maxLength: {
        value: 50,
        message: "Name field cannot exceed 50 characters",
      },
    },
    size: 6,
  },
  {
    props: {
      name: "facilitySpecific",
      id: "facilitySpecific",
      label: "Community Specific",
    },
    size: 6,
    type: FORM_TYPES.Checkbox,
  },
];
