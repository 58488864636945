import React from "react";
import { useGetResidentAdaptiveEquipmentListQuery } from "../../../../store/apis/ResidentApis";
import { useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import { BaseButton } from "components/shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function AdaptiveEquipment() {
  const [searchParams] = useSearchParams();
  const residentId = searchParams.get("id");

  const {
    data: residentAdaptiveEquipments,
    isLoading,
    isError,
  } = useGetResidentAdaptiveEquipmentListQuery(residentId, {
    skip: !residentId,
  });

  const adaptiveEquipmentsColumns = [
    {
      field: "name",
      minWidth: 200,
      flex: 2,
      headerName: "Adaptive Equipment",
    },
    {
      field: "description",
      minWidth: 100,
      flex: 2,
      headerName: "Description",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "breakfastQuantity",
      minWidth: 50,
      flex: 1,
      headerName: "Breakfast",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "lunchQuantity",
      minWidth: 50,
      flex: 1,
      headerName: "Lunch",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "supperQuantity",
      minWidth: 50,
      flex: 1,
      headerName: "Dinner",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "snackQuantity",
      minWidth: 50,
      flex: 1,
      headerName: "Snack",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={residentAdaptiveEquipments}
            hasEdit={true}
            hasDelete={true}
            editProps={{
              To: `addAdaptiveEquipment?id=${residentId}&aeid=${params.row.id}`,
              title: "Adaptive Equipment",
            }}
            deleteProps={{
              entityName: params.row?.name,
              apiPath: "deleteResidentAdaptiveEquipment",
              title: "Adaptive Equipment",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          display: "flex",
          overflow: "hidden",
          flexDirection: "column",
        }}>
        <FlexBox sx={{ justifyContent: "start", mb: 1 }}>
          <BaseButton
            To={`addAdaptiveEquipment?id=${residentId}`}
            endIcon={"post_add"}
            text={"Add Adaptive Equipment"}
            marginLeft="0px !important"
            sx={{
              marginY: "0px !important",
            }}
          />
        </FlexBox>
        <Box sx={{ height: "100%", flexGrow: 1, overflowY: "auto", pb: 1 }}>
          <BaseDataGrid
            rows={residentAdaptiveEquipments}
            columns={adaptiveEquipmentsColumns}
            error={isError}
            loading={isLoading}
            autoHeight={false}
            height={"100%"}
          />
        </Box>
      </Box>
    </>
  );
}
