import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useAddFoodTypeMutation,
  useGetFoodTypeByIdQuery,
  useUpdateFoodTypeMutation,
} from "../../../../../../store/apis/FoodTypesApis";
import { useForm } from "react-hook-form";
import { Box, Divider, Grid, Typography } from "@mui/material";

import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { BaseButton } from "../../../../../shared/BaseButton";
import { EditSkeleton } from "../../../../admin.overlays";
import GeneratedForm from "../../../../../shared/generatedForm/GeneratedForm";

import { FOOD_TYPE_FIELDS } from "./forms.constants";

export default function AddFoodType() {
  const [addFoodType, { isError: addError, isLoading: addLoading }] =
    useAddFoodTypeMutation();

  const [updateFoodType, { isError: updateError, isLoading: updateLoading }] =
    useUpdateFoodTypeMutation();

  const isError = addError || updateError;
  const isSubmitting = addLoading || updateLoading;
  const [searchParams] = useSearchParams();
  const currentRecipeCategoryId = searchParams.get("id");

  const isEdit = !!currentRecipeCategoryId;

  const {
    data: currentFoodType,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetFoodTypeByIdQuery(currentRecipeCategoryId, { skip: !isEdit });

  const isEditLoading = isEdit && currentFetching;

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (currentSuccess && !currentFetching && currentFoodType) {
      reset(currentFoodType);
    }
  }, [currentFoodType]);

  const onSubmit = (data) => {
    if (isEdit) {
      updateFoodType(data);
    } else {
      addFoodType(data);
    }
    navigate(-1);
  };

  return (<>
    {isEditLoading ? (
      <EditSkeleton />
    ) : (
      <>
        <BaseButton
          text="Back To Food Types"
          startIcon={"keyboard_arrow_left"}
          onClick={() => navigate(-1)}
        />

        <Divider sx={{ marginBottom: "15px", border: "none" }} />
        <Box sx={{
          padding: "8px"
        }}>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}>
            <GeneratedForm
              control={control}
              list={FOOD_TYPE_FIELDS}
              errors={errors}
            />

            <Grid item md={6}>
              <BaseSubmitButton
                fullWidth
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
                text={isEdit ? "Submit" : "Create"}
              />
            </Grid>
            {isError ? (
              <Typography sx={{
                color: "error"
              }}>
                There was an error submitting the form, please try again.
              </Typography>
            ) : undefined}
          </Grid>
        </Box>
      </>
    )}
  </>);
}
