import React, { useState } from "react"; //{ useEffect }
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { useGetIngredientUsageQuery } from "../../../../../store/apis/IngredientAPIs";
import { Typography } from "@mui/material";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";
import YesNoDisplay from "components/shared/YesNoDisplay";
import { BaseButton } from "../../../../shared/BaseButton";

export default function IngredientUsage() {
  const SEARCH_PARAM = "search";
  const [debouncedValue, setDebouncedValue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const ingredientId = searchParams.get("id");
  const ingredientName = searchParams.get("name" || "");

  const [searchValue, setSearchValue] = useState("");

  const {
    data: recipeList,
    isError: isError,
    isFetching: isLoading,
  } = useGetIngredientUsageQuery(
    {
      ingredientId: ingredientId,
      searchTerm: debouncedValue || "",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const navigate = useNavigate();

  const RecipeListColumns = [
    {
      field: "recipeName",
      headerName: "Recipe",
      flex: 2,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <Link
              target={"_blank"}
              to={`/plateFul/Recipes/RecipeDetail?recipeId=${params?.row?.recipeId}`}
              style={{ textDecoration: "none", color: "blue" }}>
              {params?.row?.recipeName}
            </Link>
          </>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
      minWidth: 300,
    },
    {
      field: "htiRecipe",
      headerName: "HTI Recipe",
      flex: 1,
      minWidth: 50,
      renderCell: (params) => {
        return <YesNoDisplay value={params?.row?.htiRecipe} />;
      },
    },
  ];

  return (<>
    <BaseButton
      text="Back To Ingredients"
      startIcon={"keyboard_arrow_left"}
      onClick={() => navigate(-1)}
      sx={{ marginBottom: "10px" }}
    />
    <Typography variant="h5" className="userFacilityTitle" sx={{
      color: "primary"
    }}>
      Recipes in which {ingredientName} is used
    </Typography>
    <div className="actionsRow">
      <div className="leftActions">
        <BaseSearch
          placeholder="Search..."
          disableUnderline={true}
          type="text"
          sx={{ ml: 1 }}
          SEARCH_PARAM={SEARCH_PARAM}
          value={searchValue}
          setSearchValue={setSearchValue}
          setDebouncedValue={setDebouncedValue}
          setSearchParams={setSearchParams}
          isNameID={true}
          name={ingredientName}
          Id={ingredientId}
        />
      </div>
    </div>
    <BaseDataGrid
      autoHeight={false}
      rows={recipeList}
      columns={RecipeListColumns}
      loading={isLoading}
      error={isError}
      getRowId={(row) => row.recipeId}
      height={{ md: "55vh", xl: "65vh" }}
      sx={{
        "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
          py: "8px",
        },
        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
          py: "15px",
        },
        "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
          py: "22px",
        },
      }}
    />
  </>);
}
