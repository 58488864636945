import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import BaseSelect from "../../../../../shared/baseForm/BaseSelect";
import BaseAutoComplete from "../../../../../shared/baseForm/BaseAutoComplete";
import { BaseButton } from "../../../../../shared/BaseButton";
import {
  useGetVendorSelectBoxQuery,
  useRecalcVendorPricingMutation,
} from "../../../../../../store/apis/VendorApis";
import { debounce } from "lodash";
import { useGetSeasonSelectBoxQuery } from "../../../../../../store/apis/SeasonApis";
import { useGetMenusBySeasonSelectBoxQuery } from "../../../../../../store/apis/MenuApis";
import { REQUIRED_ERROR } from "utils/errorMessages";

export default function RecalcVendorPrice() {
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = methods;

  const navigate = useNavigate();
  const [debouncedVendorSearch, setDebouncedVendorSearch] = useState("");

  const vendorId = watch("vendorId");
  const seasonId = watch("seasonId") || "0";
  const menuId = watch("menuId");

  const { data: vendors, isFetching: isFetchingVendors } =
    useGetVendorSelectBoxQuery(debouncedVendorSearch, {
      refetchOnMountOrArgChange: true,
    });
  const { data: seasons, isFetching: isFetchingSeasons } =
    useGetSeasonSelectBoxQuery();
  const { data: menus, isFetching: isFetchingMenus } =
    useGetMenusBySeasonSelectBoxQuery(seasonId, {
      skip: seasonId === "0" || seasonId === undefined,
    });
  const [
    recalcPricing,
    { isLoading: isLoading, isSuccess: isSuccess, isError: isError },
  ] = useRecalcVendorPricingMutation();

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);

  const handleSearch = useMemo(
    () =>
      debounce((query) => {
        setDebouncedVendorSearch(query);
      }, 400),
    []
  );

  const onSubmit = () => {
    recalcPricing({ vendorId, menuId });
  };

  return (<>
    <BaseButton
      text="Back To Vendors"
      startIcon={"keyboard_arrow_left"}
      onClick={() => navigate(-1)}
    />
    <Divider sx={{ marginBottom: "15px", border: "none" }} />
    <FormProvider>
      <Grid container spacing={2}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <BaseAutoComplete
                options={vendors || []}
                defaultOptions={[]}
                loading={isFetchingVendors}
                control={control}
                handleSearch={handleSearch}
                formSelectProps={{
                  props: {
                    label: "Vendor",
                    id: "vendorId",
                    name: "vendorId",
                  },
                  validationProps: {
                    required: REQUIRED_ERROR("Vendor"),
                  },
                }}
                errors={errors}
              />
            </Grid>
            <Grid item xs={6}>
              <BaseSelect
                name="seasonId"
                id="seasonId"
                label="Season"
                control={control}
                options={seasons || []}
                loading={isFetchingSeasons}
                validationProps={{
                  required: REQUIRED_ERROR("Season"),
                }}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <BaseSelect
                name="menuId"
                id="menuId"
                label="Menu"
                options={isFetchingMenus ? [] : menus || []}
                control={control}
                loading={isFetchingMenus}
                validationProps={{
                  required: REQUIRED_ERROR("Menu"),
                }}
                errors={errors}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              pt: 2,
              justifyContent: "center",
            }}>
            <Button
              variant="contained"
              color={"primary"}
              onClick={handleSubmit(onSubmit)}
              startIcon={
                isLoading === true ? (
                  <CircularProgress color="white" size={18} />
                ) : (
                  ""
                )
              }>
              Save
            </Button>
          </Box>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      {isError ? (
        <Typography sx={{
          color: "error"
        }}>
          There was an error submitting the form, please try again.
        </Typography>
      ) : undefined}
    </FormProvider>
  </>);
}
