import { FORM_TYPES } from "components/shared/generatedForm/GeneratedForm";
import {
  endingDay,
  offset6,
  regularQuantitiesText,
  startingDay,
  breakfastQty1,
  lunchQty2,
  supperQty3,
  offset8,
  formatTypeId,
  firstDate,
  productionReportDateOptionId,
} from "../form.constants";

export const ProductionSheetForms = {
  Category: true,
  DayForm: [
    {
      ...startingDay,
      validationProps: {
        ...startingDay.validationProps,
        validate: (value, formValues) =>
          parseInt(value) + 6 < parseInt(formValues.endingDay)
            ? "Starting day should not be more than 7 days from the ending day"
            : null,
      },
    },
    offset6,
    {
      ...endingDay,
      validationProps: {
        ...endingDay.validationProps,
        validate: (value, formValues) =>
          parseInt(value) > parseInt(formValues.startingDay) + 6
            ? "Ending day should not be more than 7 days from the starting day"
            : null,
      },
    },
    offset6,
  ],
  Census: [
    regularQuantitiesText,
    breakfastQty1,
    offset8,
    lunchQty2,
    offset8,
    supperQty3,
    offset8,
  ],
  Diet: true,
  SnackMeal: [productionReportDateOptionId, offset6, firstDate, offset6],
  Report: [formatTypeId],
};

export const productionSheetCategories = [
  {
    props: {
      name: "useEntree",
      id: "useEntree",
      label: "Entrees",
      defaultValue: true,
    },
    size: 3,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {
      name: "useBread",
      id: "useBread",
      label: "Breads",
      defaultValue: true,
    },
    size: 3,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {
      name: "useCondiments",
      id: "useCondiments",
      label: "Condiment",
      defaultValue: true,
    },
    size: 3,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {
      name: "useSide",
      id: "useSide",
      label: "Sides",
      defaultValue: true,
    },
    size: 3,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {
      name: "useBeverage",
      id: "useBeverage",
      label: "Beverages",
      defaultValue: true,
    },
    size: 3,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {
      name: "useSandwichBuilder",
      id: "useSandwichBuilder",
      label: "Sandwich Builder",
      defaultValue: true,
    },
    size: 3,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {
      name: "useDessert",
      id: "useDessert",
      label: "Desserts",
      defaultValue: true,
    },
    size: 3,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {
      name: "useAppetizer",
      id: "useAppetizer",
      label: "Appetizers",
      defaultValue: true,
    },
    size: 3,
    type: FORM_TYPES.Checkbox,
  },
];

export const productionSheetExtras = [
  {
    props: {
      name: "includeMealAddOnTally",
      id: "includeMealAddOnTally",
      label: "Include Meal Add-Ons Tally",
      defaultValue: true,
    },
    size: 5,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {
      name: "includeOtherMenuOptions",
      id: "includeOtherMenuOptions",
      label: "Include Other Menu Items",
      defaultValue: true,
    },
    size: 5,
    type: FORM_TYPES.Checkbox,
  },
];
