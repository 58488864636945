import { REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "../../../../../shared/generatedForm/GeneratedForm";

export const tagType = [
  { id: 0, label: "Unknown", disabled: true },
  { id: 1, label: "Recipe" },
];

export const TAGS_FIELDS = [
  {
    props: {
      name: "typeId",
      id: "typeId",
      label: "Type",
    },
    size: 6,
    options: tagType,
    type: FORM_TYPES.Select,
  },
  {
    props: {
      name: "name",
      id: "name",
      label: "Name",
      placeholder: "Tag Name",
    },
    validationProps: {
      required: REQUIRED_ERROR("Tag Name"),
      maxLength: {
        value: 50,
        message: "Tag Name should not be greater than 50 characters.",
      },
    },
    size: 6,
  },
];
