import React from "react";
import Button from "@mui/material/Button";
import { loginRequest } from "authConfig";
import { useMsal } from "@azure/msal-react";

export const SignInButton = () => {
  const { instance } = useMsal();
  const handleLogin = async () => {
    const domainHint = localStorage.getItem("domain_hint");

    if (domainHint) {
      
      const domainHintLoginRequest = {
        ...loginRequest,
        extraQueryParameters: {
          server: loginRequest?.extraQueryParameters?.server,
          domain_hint: domainHint,
        },
      };

      await instance.loginRedirect({
        ...domainHintLoginRequest,
        prompt: "login",
      });
    } else {
      await instance.loginRedirect({ ...loginRequest, prompt: "login" });
    }

    localStorage.setItem("loginAsUsed", "NO");
  };

  return (
    <div>
      <Button onClick={handleLogin} color="inherit">
        Login
      </Button>
    </div>
  );
};
