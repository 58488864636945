import React from "react";

import GenericFieldForm from "./GenericFieldForm";
import Section from "./Section";
import { SECTION_GROUP_FORM } from "./forms.constants";

export default function SectionGroup({ control, errors }) {
  return (
    <GenericFieldForm
      name={"sectionGroups"}
      control={control}
      errors={errors}
      appendObjectExample={{ sections: [{ fieldGroups: [{ fields: [{}] }] }] }}
      nameOfObject={"Section Groups"}
      fieldPropName={`sectionGroups`}
      fieldList={SECTION_GROUP_FORM}
      ChildComponent={Section}
      childProps={{ control, errors }}
      isSortOrder={true}></GenericFieldForm>
  );
}
