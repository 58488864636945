import React from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  Divider,
  Fade,
  CircularProgress,
} from "@mui/material";
import "@fontsource/material-icons";
import { ApiCall } from "../../../../../../utils/ApiCall";
import "./TempPassword.css";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import BaseInput from "../../../../../shared/baseForm/BaseInput";
import { REQUIRED_ERROR } from "utils/errorMessages";

export default function TempPassword({ id, username, fullname, open, close }) {
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;
  const props = {
    label: "New Password",
    type: "password",
    id: `password-${id}`,
    name: "password",
  };
  const validationProps = {
    required: REQUIRED_ERROR("Password"),
    minLength: {
      value: 8,
      message: "Password should be at least 8 characters long.",
    },
    maxLength: {
      value: 30,
      message: "Password should be less than 30 characters.",
    },
    pattern: {
      value:
        /^(?=.*?[A-Z])(?=.*?[a-z])(?!.*?[^A-Za-z0-9#?!@$%^&*-]).{8,63}$|^(?=.*?[A-Z])(?=.*?[0-9])(?!.*?[^A-Za-z0-9#?!@$%^&*-]).{8,63}$|^(?=.*?[A-Z])(?=.*?[#?!@$%^&*-])(?!.*?[^A-Za-z0-9#?!@$%^&*-]).{8,63}$|^(?=.*?[a-z])(?=.*?[0-9])(?!.*?[^A-Za-z0-9#?!@$%^&*-]).{8,63}$|^(?=.*?[a-z])(?=.*?[#?!@$%^&*-])(?!.*?[^A-Za-z0-9#?!@$%^&*-]).{8,63}$|^(?=.*?[0-9])(?=.*?[#?!@$%^&*-])(?!.*?[^A-Za-z0-9#?!@$%^&*-]).*$/,
      message:
        "Password must contain at least 2 of the following: upper case, lower case, numeric, symbol. ",
    },
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    ApiCall(
      // eslint-disable-next-line no-undef
      process.env.REACT_APP_BASE_URL + `/UserLogin/ResetPassword`,
      "PUT",
      JSON.stringify({
        id: id,
        userName: username,
        password: data?.password,
      })
    )
      .then((response) => {
        if (response) {
          setIsLoading(false);
          setIsCompleted(true);
          enqueueSnackbar(`${fullname}'s password updated successfully.`, {
            variant: "success",
          });
          setTimeout(() => {
            close(true);
          }, 5000);
          setTimeRemaining(5);
        }
      })
      .catch((error) => {
        // console.error(error);
        setIsLoading(false);
        setIsCompleted(false);
        enqueueSnackbar(`${error}`, {
          variant: "error",
        });
        setTimeout(() => {
          close(true);
        }, 5000);
        setTimeRemaining(5);
      });
  };

  useEffect(() => {
    timeRemaining > 0 &&
      setTimeout(() => setTimeRemaining(timeRemaining - 1), 1000);
  }, [timeRemaining]);

  const stopPropogationForTab = (e) => {
    if (e.key === "Tab") {
      e.stopPropagation();
    }
  };

  return (
    (<Modal
      open={open}
      onClose={close}
      aria-labelledby={`password-modal-title-${id}`}
      aria-describedby={`password-modal-description-${id}`}>
      <Fade in={open} onKeyDown={stopPropogationForTab}>
        <Box
          className={
            isCompleted ? "passwordModal passwordModalSuccess" : "passwordModal"
          }>
          {isCompleted ? (
            <Box className="modalContent">
              <p>
                <span
                  className="material-icons modalIconTeal"
                  color={"success"}>
                  check_circle
                </span>
              </p>
              <Box>
                <Typography
                  id={`password-modal-title-${id}`}
                  variant="h6"
                  component="h2"
                  className="modalTitle">
                  {`"${fullname}"'s password updated successfully!`}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box className="modalContent">
              <p>
                <span className="material-icons modalIcon" color={"primary"}>
                  report
                </span>
              </p>
              <Box
                sx={{
                  width: "100%",
                  marginRight: "24px"
                }}>
                <BaseInput
                  control={control}
                  {...props}
                  name={props?.name}
                  validationProps={validationProps}
                  errors={errors}
                  color={"warning"}
                />
              </Box>
            </Box>
          )}
          <Divider sx={{ my: 0.5 }} />
          {isCompleted ? (
            <Box className="passwordActions" sx={{
              flex: true
            }}>
              <Button
                className="baseButton"
                variant="contained"
                onClick={close}
                color={"success"}>{`Close (${timeRemaining})`}</Button>
            </Box>
          ) : (
            <Box className="passwordActions" sx={{
              flex: true
            }}>
              <Button
                className="baseButton"
                variant="contained"
                onClick={close}
                color={"warning"}>
                Cancel
              </Button>
              <Button
                sx={{ marginLeft: "10px" }}
                color="primary"
                className="baseButton"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                startIcon={
                  isLoading ? <CircularProgress color="white" size={18} /> : ""
                }>
                Save
              </Button>
            </Box>
          )}
        </Box>
      </Fade>
    </Modal>)
  );
}
