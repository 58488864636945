import { Box, Skeleton, Typography } from "@mui/material";
import { BaseButton } from "components/shared/BaseButton";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import dayjs from "dayjs";
import React from "react";
import { DMStyles } from "styles/Styles";
import { theme } from "styles/theme";

function HeaderBoxVisitDetails({
  community,
  dateOfVisit,
  isFinal,
  visitPlanId,
}) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          p: "16px",
          color: DMStyles.White,
          bgcolor: theme.palette.primary.main,
        }}>
        {!community ? (
          <Skeleton variant="text" width={200} height={40} />
        ) : (
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
            }}>
            {community}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          position: "relative",
          marginTop: "10px",
          textAlign: "center",
          p: "5px",
          bgcolor: theme.palette.yellow.main,
        }}>
        {!dateOfVisit ? (
          <Box
            sx={{
              marginLeft: "50%",
              marginRight: "50%",
              transform: "translateX(-50%)",
            }}>
            <Skeleton variant="text" width={200} height={40} />
          </Box>
        ) : (
          <Typography
            variant="h7"
            sx={{
              fontWeight: 500,
            }}>
            Date of Consult:{" "}
            {dayjs(dateOfVisit).format(SCREEN_CONSTANTS.Date_Format)}
          </Typography>
        )}

        <Box
          sx={{
            position: "absolute",
            right: "20px",
            top: "5px",
            width: "50px",
          }}>
          {!isFinal ? (
            <Box>
              <BaseButton
                sx={{ height: "25px" }}
                colors={"white"}
                marginLeft={"0px !important"}
                text={"Edit"}
                To={`/Consultant/MyRosters/ConsultantsDetails?visitPlanId=${visitPlanId}&isEdit=${true}`}
                state={{
                  dateOfConsultant: dateOfVisit,
                }}
              />
            </Box>
          ) : null}
        </Box>
      </Box>
    </>
  );
}

export default HeaderBoxVisitDetails;
