import React, { useEffect, useState, useCallback } from "react";
import { Controller } from "react-hook-form";
import { Autocomplete, TextField, Grid, Typography } from "@mui/material";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseAutoComplete.md)
 */
export default function BaseAutoComplete({
  options,
  defaultOptions,
  control,
  loading,
  formSelectProps,
  errors,
  handleSearch,
  placeholder,
  filterOptions,
  isLabelBold,
  resetSearch,
}) {
  const defaultValue = defaultOptions?.[0];

  const [selectedOption, setSelectedOption] = useState(
    defaultValue !== undefined ? defaultValue : { id: null, label: "" }
  );

  useEffect(() => {
    setSelectedOption(defaultValue || { id: null, label: "" });
  }, [defaultValue, resetSearch]);

  const handleInputChange = useCallback(
    (event) => {
      handleSearch(event.target.value);
    },
    [handleSearch]
  );

  return (
    (<Controller
      control={control}
      name={formSelectProps.props?.name}
      rules={{ ...formSelectProps.validationProps }}
      defaultValue={defaultValue}
      render={({ field: { onChange } }) => {
        return (
          (<Autocomplete
            freeSolo={true}
            options={options}
            filterOptions={
              filterOptions ||
              ((options, params) => {
                const filters = params.inputValue
                  .split(" ")
                  .filter((value) => value);
                let filtered = options;

                filters.forEach((filter) => {
                  filtered = filtered.filter((option) =>
                    option.label.toLowerCase().includes(filter.toLowerCase())
                  );
                });

                return filtered;
              })
            }
            loading={loading}
            name={formSelectProps.props.name}
            getOptionLabel={(option) => option?.label || ""}
            value={selectedOption}
            onChange={(event, item) => {
              setSelectedOption(item);
              onChange(item?.id);
            }}
            renderInput={(params) => (
              <TextField
                size={formSelectProps?.props?.size}
                {...params}
                onChange={handleInputChange}
                label={formSelectProps.props?.label}
                error={!!errors[formSelectProps.props?.name]}
                helperText={errors[formSelectProps.props?.name]?.message ?? ""}
                placeholder={
                  placeholder ||
                  `Select a ${
                    formSelectProps.props?.label || formSelectProps.props?.name
                  }`
                }
              />
            )}
            renderOption={(props, option, index) => {
              const key = `AutoComplete-${option?.id}-${index}`;
              return (
                (<li {...props} key={key}>
                  <Grid container direction={"column"}>
                    <Grid
                      item
                      sx={{
                        width: "calc(100% - 44px)",
                        wordWrap: "break-word",
                      }}>
                      <Typography
                        variant="p"
                        sx={{
                          fontWeight: option?.description || isLabelBold
                            ? "bold"
                            : "regular"
                        }}>
                        {option?.label}
                      </Typography>
                      {option?.description && (
                        <Typography variant="body2" sx={{
                          color: "text.secondary"
                        }}>
                          {option?.description}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </li>)
              );
            }}
          />)
        );
      }}
    />)
  );
}
