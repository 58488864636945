import { Box, Button, Card, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import {
  useGetRecipeVendorIngredientQuery,
  useAddRecipeIngredientsMutation,
  useUpdateRecipeIngredientsMutation,
} from "../../../../../store/apis/RecipeIngredientApi";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
// import { EditButton } from "../../../../shared/EditButton";
import FormModal from "../../../../shared/formModal/FormModal";
import { Roles } from "../../../../shared/constants";
import "./RecipesIngredients.css";
import { RecipeIngredientsFields } from "./addRecipesIngredients/forms.constant";
import { useForm } from "react-hook-form";
import useMsalAccount from "utils/useMsalAccount";
import { useSelector } from "react-redux";
import {
  selectVendorId,
  selectVendorName,
} from "store/slices/userSlice/UserSlice";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { Fastfood } from "@mui/icons-material";

export const RecipesIngredients = () => {
  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get("recipeId");
  const currentLocation = useLocation();

  const [canEdit] = useState(currentLocation.state?.canEdit);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState();
  const [defaultValues, setDefaultValues] = useState([]);

  const [openFormModal, setOpenFormModal] = useState(false);

  const { roles: userRoles } = useMsalAccount();

  const vendorId = useSelector(selectVendorId);
  const vendorName = useSelector(selectVendorName);

  const isAdminOrMenuTeam = () => {
    const userAllowedWithRoles = [Roles.Admin, Roles.MenuTeam];
    const roleAllows = userRoles.some((roleToCheck) =>
      userAllowedWithRoles.includes(roleToCheck)
    );

    return roleAllows;
  };

  const {
    data: RecipeIngredientData,
    isFetching: loading,
    isError: hasError,
  } = useGetRecipeVendorIngredientQuery(
    {
      recipeId: recipeId,
      vendorId: vendorId,
    },
    { skip: !recipeId, refetchOnMountOrArgChange: true }
  );

  const RecipeIngredientsColumn = [
    {
      field: "quantity",
      minWidth: 100,
      flex: 1,

      headerName: "Qty 1",
      renderCell: (params) => {
        const ingredient = RecipeIngredientData?.find(
          (item) => item.id === params.row.id
        );
        return (
          <div>
            {ingredient?.quantity === null || ingredient?.quantity === 0
              ? " 0.00"
              : `${ingredient?.quantity.toFixed(2)}  ${ingredient?.unit}`}
          </div>
        );
      },
    },
    {
      field: "quantity2",
      minWidth: 100,
      flex: 1,
      headerName: "Qty 2",
      renderCell: (params) => {
        const ingredient = RecipeIngredientData?.find(
          (item) => item.id === params.row.id
        );
        return (
          <div>
            {ingredient?.quantity2 === null || ingredient?.quantity2 === 0
              ? " 0.00"
              : `${ingredient?.quantity2.toFixed(2)}  ${ingredient?.unit2}`}
          </div>
        );
      },
    },
    {
      field: "ingredient",
      minWidth: 100,
      flex: 2,
      headerName: "Ingredient",
      renderCell: (params) => {
        return isAdminOrMenuTeam() ? (
          <Link
            target="_blank"
            to={`/admin/recipes/ingredients/addIngredient?id=${params.row["ingredientId"]}`}
            style={{ textDecoration: "none", color: "blue" }}>
            {`${params.row.ingredient}`}
          </Link>
        ) : (
          <div>{`${params.row.ingredient}`}</div>
        );
      },
    },
    {
      field: "vendorItemNumber",
      minWidth: 100,
      flex: 1,
      headerName: "Vendor Item #",
    },
    {
      field: "description",
      minWidth: 100,
      flex: 2,
      headerName: "Preparation",
    },

    {
      field: "sortOrder",
      minWidth: 100,
      flex: 1,
      headerName: "Sort",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "percentToIncludeInAnalysis",
      minWidth: 100,
      flex: 1,
      headerName: "Percentage",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },

    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={RecipeIngredientData}
            hasEdit={canEdit}
            hasDelete={canEdit}
            editProps={{
              state: {
                title: `Edit RecipeIngredients`,
                data: RecipeIngredientData?.find(
                  (row) => row.id === params.row.id
                ),
                canEdit: canEdit,
              },
              title: "Recipe Ingredient",
              onClick: () => {
                setIsEdit(true);
                setEditData(params.row);
                setOpenFormModal(true);
              },
            }}
            deleteProps={{
              entityName: params.row?.ingredient,
              apiPath: "deleteRecipesIngredient",
              title: "Recipe Ingredient",
            }}
          />
        );
      },
    },
  ];

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (isEdit) {
      reset(editData);
      setDefaultValues([
        ...defaultValues,
        {
          name: "ingredientId",
          option: [
            {
              id: editData.ingredientId,
              label: editData.ingredient,
            },
          ],
        },
      ]);
    } else {
      reset({});
      setDefaultValues([]);
    }
  }, [isEdit, editData]);

  const [
    updateRecipeIngredient,
    {
      isLoading: updateRecipeIngredientLoading,
      // isSuccess: updateRecipeIngredientSuccess,
    },
  ] = useUpdateRecipeIngredientsMutation();
  const [
    AddRecipeIngredient,
    {
      isLoading: addRecipeIngredientLoading,
      // isSuccess: addRecipeIngredientSuccess,
    },
  ] = useAddRecipeIngredientsMutation();

  const isSubmitting =
    addRecipeIngredientLoading || updateRecipeIngredientLoading;
  // const isSuccess = addRecipeIngredientSuccess || updateRecipeIngredientSuccess;

  const onSubmit = (data) => {
    if (isEdit) {
      if (data.quantity2 === "") {
        data.quantity2 = 0;
      }

      updateRecipeIngredient(data);
    } else {
      const formData = {
        ...data,
        recipeId,
      };
      AddRecipeIngredient(formData);
    }
    setOpenFormModal(false);
    setIsEdit(false);
    setEditData();
    reset({});
    setDefaultValues([]);
  };

  return (<>
    <Card>
      <Box sx={{
        padding: 1
      }}>
        <FlexBox mb={1} justifyContent={"space-between"}>
          {canEdit === true ? (
            <FlexBox justifyContent={"start"}>
              <Button
                component={Link}
                state={{ canEdit: canEdit }}
                onClick={() => {
                  setOpenFormModal(true);
                  setIsEdit(false);
                  setEditData();
                }}
                color={"primary"}
                variant="contained"
                endIcon={<Fastfood />}>
                Add new ingredient
              </Button>
              <Button
                component={Link}
                to={`reorder?recipeId=${recipeId}`}
                color={"primary"}
                variant="contained">
                Re Order
              </Button>
            </FlexBox>
          ) : null}
          <FlexBox justifyContent={"end"}>
            <Typography variant="h6" component="h6">
              Vendor: {vendorName}
            </Typography>
          </FlexBox>
        </FlexBox>
        <Box>
          <BaseDataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: "sortOrder", sort: "asc" }],
              },
            }}
            autoHeight={false}
            height={{ md: "60vh", xl: "70vh" }}
            rows={RecipeIngredientData}
            columns={RecipeIngredientsColumn}
            error={hasError}
            loading={loading}
          />
        </Box>
      </Box>
      {openFormModal && (
        <FormModal
          HeaderTitle={isEdit ? "Edit Ingredients" : "Add new ingredient"}
          list={RecipeIngredientsFields}
          editData={editData}
          isEdit={isEdit}
          control={control}
          setValue={setValue}
          errors={errors}
          defaultValues={defaultValues}
          open={openFormModal}
          onClickBtn={handleSubmit(onSubmit)}
          isSubmitting={isSubmitting}
          close={() => {
            setOpenFormModal(false);
            reset({});
            setDefaultValues([]);
          }}
        />
      )}
    </Card>
  </>);
};
