import { REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "../../../../shared/generatedForm/GeneratedForm";

export const nonPlannedFields = [
  {
    props: {
      name: "date",
      id: "date",
      label: "Planned date for visit",
    },
    validationProps: {
      required: REQUIRED_ERROR("Planned date for visit"),
    },
    type: FORM_TYPES.DatePicker,
    size: 12,
  },
  {
    props: {
      name: "community",
      id: "community",
      label: "Community ",
    },
    validationProps: {
      required: REQUIRED_ERROR("Community"),
    },
    size: 12,
    type: FORM_TYPES.AutocompleteAPI,
    endpoint: "getFacilitySelectBox",
  },
];
