import {
  diningAreaId,
  excludeRegularLiquids,
  mealId,
  useExcelFormat,
} from "../forms.constants";

export const AdaptiveEquipmentTallyFields = [
  { ...mealId, validationProps: {} },
  { ...diningAreaId, validationProps: {} },
  useExcelFormat,
];

export const AdaptiveEquipmentTallyDefaults = {
  mealId: -1,
  diningAreaId: -1,
  useExcelFormat: false,
};

export const AllergyTallyFields = [
  { ...mealId, validationProps: {} },
  { ...diningAreaId, validationProps: {} },
  useExcelFormat,
];

export const AllergyTallyDefaults = {
  mealId: -1,
  diningAreaId: -1,
  useExcelFormat: false,
};

export const BeverageTallyFields = [
  { ...diningAreaId, validationProps: {} },
  useExcelFormat,
];

export const BeverageTallyDefaults = {
  diningAreaId: -1,
  useExcelFormat: false,
};

export const BeverageTallyByWingFields = [
  { ...diningAreaId, validationProps: {} },
  useExcelFormat,
];

export const BeverageTallyByWingDefaults = {
  diningAreaId: -1,
  useExcelFormat: false,
};

export const MealExtrasTallyFields = [
  { ...diningAreaId, validationProps: {} },
  useExcelFormat,
];

export const MealExtrasTallyDefaults = {
  diningAreaId: -1,
  useExcelFormat: false,
};

export const MealExtrasTallyByWingFields = [
  { ...diningAreaId, validationProps: {} },
  useExcelFormat,
];

export const MealExtrasTallyByWingDefaults = {
  diningAreaId: -1,
  useExcelFormat: false,
};

export const DietTallyFields = [
  { ...mealId, validationProps: {} },
  useExcelFormat,
];

export const DietTallyDefaults = {
  mealId: -1,
  useExcelFormat: false,
};

export const DietTextureTallyFields = [
  { ...mealId, validationProps: {} },
  useExcelFormat,
];

export const DietTextureTallyDefaults = {
  mealId: -1,
  useExcelFormat: false,
};

export const DietTextureByWingTallyFields = [
  { ...mealId, validationProps: {} },
  useExcelFormat,
];

export const DietTextureByWingTallyDefaults = {
  mealId: -1,
  useExcelFormat: false,
};

export const DietOtherTallyFields = [
  { ...mealId, validationProps: {} },
  { ...diningAreaId, validationProps: {} },
  useExcelFormat,
];

export const DietOtherTallyDefaults = {
  mealId: -1,
  diningAreaId: -1,
  useExcelFormat: false,
};

export const DislikeIntoleranceTallyFields = [
  { ...mealId, validationProps: {} },
  { ...diningAreaId, validationProps: {} },
  useExcelFormat,
];

export const DislikeIntoleranceTallyDefaults = {
  mealId: -1,
  diningAreaId: -1,
  useExcelFormat: false,
};

export const ResidentsPerDiningRoomFields = [mealId, useExcelFormat];

export const ResidentsPerDiningRoomDefaults = {
  mealId: "",
  useExcelFormat: false,
};

export const LiquidConsistencyTallyFields = [
  { ...mealId, validationProps: {} },
  { ...diningAreaId, validationProps: {} },
  excludeRegularLiquids,
  useExcelFormat,
];

export const LiquidConsistencyTallyDefaults = {
  mealId: -1,
  diningAreaId: -1,
  excludeRegularLiquids: false,
  useExcelFormat: false,
};
