import {
  includeBorder,
  includeNutrients,
  offset6,
  showHeartHealthy,
  showDiabeticChoice,
  includeDescription,
  formatTypeId,
} from "../form.constants";

export const StandardPostedOtherMenuOptionsForms = {
  Category: true,
  DayForm: [
    showHeartHealthy,
    offset6,
    showDiabeticChoice,
    offset6,
    includeBorder,
    offset6,
    includeDescription,
    offset6,
    includeNutrients,
    offset6,
  ],
  Diet: true,
  Report: [formatTypeId],
};
