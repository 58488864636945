import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import BaseSelect from "../../../../../../shared/baseForm/BaseSelect";
import { REQUIRED_ERROR } from "utils/errorMessages";
import BaseInput from "components/shared/baseForm/BaseInput";

export default function RatesSetup({
  dietitianTypes,
  loadingDietitianTypes,
  errors,
  control,
}) {
  useEffect(() => {}, [dietitianTypes]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <BaseSelect
          options={dietitianTypes || []}
          label="Type"
          name="dietitianTypeId"
          id="dietitianTypeId"
          validationProps={{
            required: REQUIRED_ERROR("Consultant Type"),
          }}
          loading={loadingDietitianTypes}
          control={control}
          errors={errors}></BaseSelect>
      </Grid>
      <Grid item xs={12}>
        <BaseInput
          label={"Default Mileage Rate"}
          control={control}
          errors={errors}
          validationProps={{
            required: REQUIRED_ERROR("Default Mileage Rate"),
            pattern: {
              value: /^\d*\.?\d{0,4}$/,
              message: "Please enter a number with up to 4 decimal places.",
            },
          }}
          id="defaultMileageRate"
          name="defaultMileageRate"
          unit="$"
          unitPosition={"start"}
          hideArrows={true}
        />
      </Grid>
      <Grid item xs={12}>
        <BaseInput
          control={control}
          name="defaultHourlyRate"
          id="defaultHourlyRate"
          label="Default Hourly Rate"
          unit="$"
          unitPosition={"start"}
          errors={errors}
          hideArrows={true}
          validationProps={{
            required: REQUIRED_ERROR("Default Hourly Rate"),
            pattern: {
              value: /^\d*\.?\d{0,4}$/,
              message: "Please enter a number with up to 4 decimal places.",
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <BaseInput
          control={control}
          errors={errors}
          name="comments"
          id="comments"
          label="Comments"
          multiline
          rows={4}
        />
      </Grid>
    </Grid>
  );
}
