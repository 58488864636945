import React, { useState } from "react";
import { Button } from "@mui/material";
import BaseInput from "components/shared/baseForm/BaseInput";
import { DMStyles } from "styles/Styles";

const ChatInput = ({ sendChatMessage, aiSubjectAreaId }) => {
  const [chatData, setChatData] = useState("");
  const aiSessionId = sessionStorage.getItem("aiSessionId");

  const handleSubmit = () => {
    if (chatData === null || chatData === "" || chatData.length < 5) return;
    if (aiSessionId === null || aiSessionId === "") {
      sendChatMessage({
        sessionId: null,
        sender: "user",
        message: chatData,
        subjectAreaId: aiSubjectAreaId,
      });
    } else {
      sendChatMessage({
        sessionId: aiSessionId,
        sender: "user",
        message: chatData,
        subjectAreaId: aiSubjectAreaId,
      });
    }
    setChatData("");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.shiftKey === false) {
      if (chatData === null || chatData === "" || chatData.length < 5) return;
      handleSubmit();
    }
  };
  return (
    <div className="chatInputMain">
      <BaseInput
        onKeyDown={(e) => handleKeyDown(e)}
        rows={2}
        className="chatInputTextArea"
        value={chatData}
        type="text"
        multiline
        label={"Enter Chat Request..."}
        onChange={(e) => setChatData(e.target.value)}
      />
      <div style={{ alignSelf: "flex-end" }}>
        <Button
          sx={{
            backgroundColor: DMStyles.Teal,
            color: "black",
            marginTop: "16px",
          }}
          className="baseButton"
          color={"primary"}
          variant="contained"
          onClick={handleSubmit}>
          Send
        </Button>
      </div>
    </div>
  );
};
export default ChatInput;
