import { PAGE_ROLES } from "components/shared/constants";

export const OptionsList = [
  {
    id: 1,
    optionName: "Menus",
    Link: "/plateFul/Menus",
    Roles: PAGE_ROLES.PLATEFUL_MENUS,
    // isActive: false,
  },
  {
    id: 2,
    optionName: "Recipes",
    Link: "/plateFul",
    Roles: PAGE_ROLES.PLATEFUL,
    // isActive: false,
  },
];
