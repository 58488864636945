import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { PCC_MANAGEMENTS } = API_ENDPOINTS;

export const PCCManagementApi = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPCCResidentList: builder.query({
      query: ({ orgUuid, pccFacilityId, facilityId }) =>
        `/${PCC_MANAGEMENTS}?orgUuid=${orgUuid}&pccFacilityId=${pccFacilityId}&facilityId=${facilityId}`,
      transformResponse: (response) => response?.data,
      keepUnusedDataFor: 5,
    }),
    updatePCCResidentList: builder.mutation({
      query: ({ orgUuid, pccFacilityId, facilityId, data }) => ({
        url: `/${PCC_MANAGEMENTS}?orgUuid=${orgUuid}&pccFacilityId=${pccFacilityId}&facilityId=${facilityId}`,
        method: "PUT",
        body: data,
      }),
    }),
  }),
});

export const { useGetPCCResidentListQuery, useUpdatePCCResidentListMutation } =
  PCCManagementApi;
