import { DownloadLinkHelper } from "components/shared/utils/helpers";
import dayjs from "dayjs";
import { useCallback, useEffect } from "react";
import {
  useDietitianNotSubmittedMutation,
  useDietScheduleReportMutation,
  useDietWeeklyReportMutation,
  useMissingConsultantMutation,
  useNotSubmittedReportMutation,
  useRDListMutation,
  useRedFlagsReportMutation,
} from "store/apis/ConsultantApis";

const useReports = (dietitianInputId, date, feature) => {
  const [
    DietitianScheduleReport,
    {
      data: scheduleReport,
      isLoading: scheduleReportLoading,
      isSuccess: scheduleReportSuccess,
    },
  ] = useDietScheduleReportMutation();

  const [
    GenerateWeeklyReport,
    { data: report, isLoading: generateLoading, isSuccess: addSuccess },
  ] = useDietWeeklyReportMutation();

  const [
    NotSubmittedReport,
    {
      data: notSubmittedReport,
      isLoading: notSubmittedLoading,
      isSuccess: notSubmittedSuccess,
    },
  ] = useNotSubmittedReportMutation();

  const [
    RedFlagsReport,
    {
      data: redFlagsReport,
      isLoading: redFlagLoading,
      isSuccess: redFlagsReportSuccess,
    },
  ] = useRedFlagsReportMutation();

  const [
    RDList,
    { data: rdList, isLoading: rdLoading, isSuccess: rdListSuccess },
  ] = useRDListMutation();

  const [
    MissingConsultant,
    {
      data: missingConsultant,
      isLoading: missingLoading,
      isSuccess: missingConsultantSuccess,
    },
  ] = useMissingConsultantMutation();

  const [
    DietitianNotSubmitted,
    {
      data: dietitianNotSubmitted,
      isLoading: dietitionNotSubmittedLoading,
      isSuccess: dietitianNotSubmittedSuccess,
    },
  ] = useDietitianNotSubmittedMutation();

  const loading =
    scheduleReportLoading ||
    generateLoading ||
    notSubmittedLoading ||
    redFlagLoading ||
    rdLoading ||
    missingLoading ||
    dietitionNotSubmittedLoading;

  const isSuccess =
    scheduleReportSuccess ||
    addSuccess ||
    notSubmittedSuccess ||
    redFlagsReportSuccess ||
    rdListSuccess ||
    missingConsultantSuccess ||
    dietitianNotSubmittedSuccess;

  const onSubmitScheduleReport = useCallback(
    async (all) => {
      DietitianScheduleReport({
        dietitianId: dietitianInputId,
        month: dayjs(date).format("MMMM YYYY"),
        allDietitians: all,
      });
    },
    [DietitianScheduleReport, dietitianInputId, date]
  );

  const onSubmitWeeklyReport = useCallback(async () => {
    GenerateWeeklyReport({
      dietitianId: dietitianInputId,
      month: dayjs(date).format("MMMM YYYY"),
    });
  }, [GenerateWeeklyReport, dietitianInputId, date]);

  const handleReport = useCallback(
    (id) => {
      switch (id) {
        case 1:
          onSubmitScheduleReport(false);
          break;
        case 2:
          onSubmitWeeklyReport();
          break;
        case 3:
          RDList();
          break;
        case 4:
          NotSubmittedReport();
          break;
        case 5:
          RedFlagsReport();
          break;
        case 6: {
          MissingConsultant({
            month: dayjs(date).format("MMMM YYYY"),
          });
          break;
        }
        case 7:
          DietitianNotSubmitted();
          break;
        case 8:
          onSubmitScheduleReport(true);
          break;
      }
    },
    [
      onSubmitScheduleReport,
      onSubmitWeeklyReport,
      RDList,
      NotSubmittedReport,
      RedFlagsReport,
      MissingConsultant,
      DietitianNotSubmitted,
    ]
  );

  useEffect(() => {
    if (isSuccess) {
      switch (feature) {
        case 1:
        case 8:
          scheduleReport?.data && DownloadLinkHelper(scheduleReport?.data);
          break;
        case 2:
          report?.data && DownloadLinkHelper(report?.data);
          break;
        case 3:
          rdList?.data && DownloadLinkHelper(rdList?.data);
          break;
        case 4:
          notSubmittedReport?.data &&
            DownloadLinkHelper(notSubmittedReport?.data);
          break;
        case 5:
          redFlagsReport?.data && DownloadLinkHelper(redFlagsReport?.data);
          break;
        case 6:
          missingConsultant?.data &&
            DownloadLinkHelper(missingConsultant?.data);
          break;
        case 7:
          dietitianNotSubmitted?.data &&
            DownloadLinkHelper(dietitianNotSubmitted?.data);
          break;
      }
    }
  }, [
    isSuccess,
    scheduleReport?.data,
    report?.data,
    rdList?.data,
    notSubmittedReport?.data,
    redFlagsReport?.data,
    missingConsultant?.data,
    dietitianNotSubmitted?.data,
  ]);

  return {
    loading,
    isSuccess,
    handleReport,
  };
};

export default useReports;
