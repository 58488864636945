import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { RECIPE_FOOD_TYPES } = API_ENDPOINTS;

export const RecipeFoodTypeAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRecipeFoodType: builder.query({
      query: ({ id }) => `/${RECIPE_FOOD_TYPES}?recipeId=${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: RECIPE_FOOD_TYPES, id })),
              RECIPE_FOOD_TYPES,
            ]
          : [RECIPE_FOOD_TYPES];
      },
    }),
    getRecipeFoodTypeById: builder.query({
      query: (id) => `/${RECIPE_FOOD_TYPES}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: RECIPE_FOOD_TYPES, id: response.id }, RECIPE_FOOD_TYPES]
          : [RECIPE_FOOD_TYPES],
    }),
    addRecipeFoodType: builder.mutation({
      query: (data) => ({
        url: `/${RECIPE_FOOD_TYPES}`,
        method: "POST",
        body: data,
        formData: true,
      }),
      invalidatesTags: (result) => [
        { type: RECIPE_FOOD_TYPES, id: result?.id },
      ],
    }),
    updateRecipeFoodType: builder.mutation({
      query: (data) => ({
        url: `/${RECIPE_FOOD_TYPES}`,
        method: "PUT",
        body: data,
        formData: true,
      }),
      invalidatesTags: (result) => [
        { type: RECIPE_FOOD_TYPES, id: result?.id },
      ],
    }),
    deleteRecipesFoodType: builder.mutation({
      query: (id) => ({
        url: `/${RECIPE_FOOD_TYPES}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: RECIPE_FOOD_TYPES, id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetRecipeFoodTypeQuery,
  useGetRecipeFoodTypeByIdQuery,
  useAddRecipeFoodTypeMutation,
  useUpdateRecipeFoodTypeMutation,
  useDeleteRecipesFoodTypeMutation,
} = RecipeFoodTypeAPIs;
