import React from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../../store/slices/authSlice/AuthSlice";
import { useGetWeightAlertQuery } from "../../../store/apis/WeightAlertApis";
import BaseDataGrid from "../../shared/baseDataGrid/BaseDataGrid";
import BaseActionBox from "components/shared/BaseActionBox";
import { useNavigate } from "react-router-dom";

export default function WeightAlert() {
  const facilityId = useSelector(selectFacilityId);
  const facilityName = useSelector((state) => state.userInfo.facilityName);

  const {
    data: weightAlerts,
    isFetching: loading,
    isError: hasError,
  } = useGetWeightAlertQuery(
    {
      facilityId: facilityId,
    },
    { refetchOnMountOrArgChange: true }
  );

  const residentsColumns = [
    {
      field: "_1monthWeightAlert",
      headerName: "One Month % Change",
      flex: 1,
    },
    {
      field: "_3monthWeightAlert",
      headerName: "Three Month % Change",
      flex: 1,
    },
    {
      field: "_6monthWeightAlert",
      headerName: "Six Month % Change",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (param) => {
        const navigate = useNavigate();
        const handleEdit = () => {
          navigate(`edit?id=${facilityId}`, {
            state: {
              title: "Edit WeightAlert",
              data: param.row,
            },
          });
        };

        return (
          <BaseActionBox
            hasEdit={true}
            editProps={{
              onClick: handleEdit,
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          display: "flex",
          overflow: "hidden",
          flexDirection: "column",
          gap: 1,
        }}>
        <Box>
          <Typography variant="h6" component="h2">
            {`Weight Alerts for ${facilityName}`}
          </Typography>
        </Box>
        <Box sx={{ height: "100%", flexGrow: 1, overflowY: "auto", pb: 1 }}>
          <BaseDataGrid
            rows={weightAlerts}
            getRowId={(row) => row.facilityId + row._3monthWeightAlert}
            columns={residentsColumns}
            loading={loading}
            error={hasError}
            height={"100%"}
          />
        </Box>
      </Box>
    </>
  );
}
