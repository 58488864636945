// RadioButtons

import { REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "../../shared/generatedForm/GeneratedForm";

export const options = [
  { value: 1, label: "Production Review" },
  { value: 2, label: "Guest/Employee Ticket Reconciliation" },
  { value: 3, label: "Cook's Ticket Tracking History" },
  { value: 4, label: "Declined Meal" },
  { value: 5, label: "Out of Building" },
  { value: 6, label: "Orders Taken" },
  { value: 7, label: "Order Tracking History" },
  { value: 8, label: "Resident Food Order History (by Resident)" },
  { value: 9, label: "Resident Food Order History (by Date/Meal)" },
  { value: 10, label: "Meal Count" },
];

export const options2 = [{ value: 11, label: "Unprinted Tableside Tickets" }];

const GroupByName = [
  { value: 1, label: "By Day" },
  { value: 2, label: "By Meal" },
  { value: 3, label: "By Dining Area" },
];
const MealGroup = [
  { value: 1, label: "Breakfast" },
  { value: 2, label: "Lunch" },
  { value: 3, label: "Dinner" },
];
const MealGroup2 = [
  { id: null, label: "All Meals" },
  { id: 1, label: "Breakfast" },
  { id: 2, label: "Lunch" },
  { id: 3, label: "Dinner" },
];
const ByMealGroup = [
  { value: 1, label: "By Meal" },
  { value: 2, label: "By Resident" },
];

const startDate = {
  props: {
    name: "startDate",
    id: "startDate",
    placeholder: "Start Date",
    label: "Start Date",
  },
  validationProps: {
    required: REQUIRED_ERROR("Start Date"),
  },
  type: FORM_TYPES.DatePicker,
  size: 11,
};
const endDate = {
  props: {
    name: "endDate",
    id: "endDate",
    label: "End Date",
  },
  validationProps: {
    required: REQUIRED_ERROR("End Date"),
  },
  type: FORM_TYPES.DatePicker,
  size: 11,
};
const mealDate = {
  props: {
    name: "mealDate",
    id: "mealDate",
    label: "Meal Date",
  },
  validationProps: {
    required: REQUIRED_ERROR("Meal Date"),
  },
  type: FORM_TYPES.DatePicker,
  size: 11,
};

const ResidentFoodOptions = [
  startDate,
  endDate,
  {
    props: {
      name: "residentId2",
      id: "residentId2",
      label: "Resident",
      defaultValue: {
        id: null,
        label: "All Residents",
      },
    },
    size: 11,
    type: FORM_TYPES.SelectAPI,
    endpoint: "getResidentSelectList",
  },
  {
    props: {
      name: "diningAreaId",
      id: "diningAreaId",
      label: "Dining Area",
      defaultValue: {
        id: null,
        label: "All Dining Areas",
      },
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "residentDiningareaSelectAll",
    size: 11,
  },
  {
    props: {
      name: "mealId2",
      id: "mealId2",
      label: "Meals",
      defaultValue: {
        id: null,
        label: "All Meals",
      },
    },
    type: FORM_TYPES.Select,
    options: MealGroup2,
    size: 11,
  },
  {
    props: {
      name: "fixedPrice",
      id: "fixedPrice",
      label: "Fixed Price (per Meal)",
      type: "number",
    },
    validationProps: {
      max: {
        value: 99.99,
        message: "Fixed Price (per Meal) should be less than $100",
      },
    },
    size: 11,
  },
];

const MealCountOptions = [
  startDate,
  endDate,
  {
    props: {
      name: "groupByResident",
      rows: "row",
      defaultValue: "1",
    },
    validationProps: {},
    size: 11,
    type: FORM_TYPES.Radio,
    options: ByMealGroup,
  },
  {
    props: {
      name: "residentId2",
      id: "residentId2",
      label: "Resident",
      defaultValue: {
        id: null,
        label: "All Residents",
      },
    },
    size: 11,
    type: FORM_TYPES.SelectAPI,
    endpoint: "getResidentSelectList",
  },
  {
    props: {
      name: "diningAreaId",
      id: "diningAreaId",
      label: "Dining Area",
      defaultValue: {
        id: null,
        label: "All Dining Areas",
      },
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "residentDiningareaSelectAll",
    size: 11,
  },
  {
    props: {
      name: "mealId2",
      id: "mealId2",
      label: "Meals",
      defaultValue: {
        id: null,
        label: "All Meals",
      },
    },
    type: FORM_TYPES.Select,
    options: MealGroup2,
    size: 11,
  },
];

export const Fields = [
  {
    id: 1,
    options: [
      startDate,
      endDate,
      {
        props: {
          name: "reportGrouping",
          rows: "column",
          defaultValue: 1,
        },
        validationProps: {},
        size: 11,
        type: FORM_TYPES.Radio,
        options: GroupByName,
      },
    ],
  },
  {
    id: 2,
    options: [startDate, endDate],
  },
  {
    id: 3,
    options: [
      startDate,
      endDate,
      {
        props: {
          name: "mealId",
          rows: "column",
          defaultValue: 1,
        },
        validationProps: {},
        size: 11,
        type: FORM_TYPES.Radio,
        options: MealGroup,
      },
    ],
  },
  {
    id: 4,
    options: [
      startDate,
      endDate,
      {
        props: {
          name: "mealId",
          rows: "column",
          defaultValue: 1,
        },
        validationProps: {},
        size: 11,
        type: FORM_TYPES.Radio,
        options: MealGroup,
      },
    ],
  },
  {
    id: 5,
    options: [
      startDate,
      endDate,
      {
        props: {
          name: "mealId",
          rows: "column",
          defaultValue: 1,
        },
        validationProps: {},
        size: 11,
        type: FORM_TYPES.Radio,
        options: MealGroup,
      },
    ],
  },
  {
    id: 6,
    options: [
      mealDate,
      {
        props: {
          name: "mealId",
          rows: "column",
          defaultValue: 1,
        },
        validationProps: {},
        size: 11,
        type: FORM_TYPES.Radio,
        options: MealGroup,
      },
    ],
  },
  {
    id: 7,
    options: [startDate, endDate],
  },
  {
    id: 8,
    options: ResidentFoodOptions,
  },
  {
    id: 9,
    options: ResidentFoodOptions,
  },
  {
    id: 10,
    options: MealCountOptions,
  },
];
