export const DMStyles = {
  // Colors
  White: "var(--whiteColor)",
  Teal: "var(--tealColor)",
  Red: "var(--redColor)",
  Green: "var(--greenColor)",
  Gray: "var(--grayColor)",
  OrangeGradient: "var(--orangeGradient)",
  OrangeColor: "var(--orangeColor)",
  PacificBlue: "#00BCBE",
  Yellow: "#FEC92C",

  // Box Shadows
  TealBoxShadow: "var(--tealBoxShadow)",
  DarkBoxShadow: "var(--darkBoxShadow)",

  // Borders
  FieldBorderRadius: "var(--fieldBorderRadius)",
  BorderRadius: "var(--borderRadius)",
  Border: "var(--border)",
  Border2: "var(--border2)",
  WhiteBorder: "var(--whiteBorder)",
  Border3: "var(--border3)",

  // Fonts
  FontFamily: "var(--fontFamily)",
  HeadingFontSize: "var(--heading-font-size)",
};
