import React from "react";

import BaseMultiSelectSearch from "../baseForm/BaseMultiSelectSearch";
import { BaseApi } from "store/apis/BaseApi";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseSelectBox.md)
 */
export default function BaseMultiSelectSearchBox({
  endpoint,
  control,
  errors,
  name,
  label,
  size,
  validationProps,
  query = {},
  skip = false,
  defaultValue,
  ...rest
}) {
  if (!endpoint) return;

  const { data, isLoading } = BaseApi.endpoints[endpoint].useQuery(query, {
    skip: skip,
  });

  return (
    <BaseMultiSelectSearch
      name={name}
      id={name}
      label={label}
      defaultValue={defaultValue}
      size={size}
      control={control}
      errors={errors}
      loading={isLoading}
      options={data || []}
      validationProps={validationProps}
      {...rest}
    />
  );
}
