import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recipeCategory: "",
};

export const recipeCategorySlice = createSlice({
  name: "recipeCategory",
  initialState,
  reducers: {
    setRecipeCategory: (state, action) => {
      state.recipeCategory = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setRecipeCategory } = recipeCategorySlice.actions;

export default recipeCategorySlice.reducer;
