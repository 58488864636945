import { Roles } from "components/shared/constants";
import { useMemo } from "react";
import { useUserFacilitiesQuery } from "store/apis/UserApis";
import useMsalAccount from "utils/useMsalAccount";

export const PERMISSION_TYPES = {
  CONSULTANT: "CONSULTANT",
  MEAL_CARD: "MEAL_CARD",
  PLATEFUL: "PLATEFUL",
  PLATEFUL_MENUS: "PLATEFUL_MENUS",
  FACILITY: "FACILITY",
};

export default function useCheckPermissions({
  type = PERMISSION_TYPES.FACILITY,
  facilityId = null,
}) {
  const { userId, roles: userRoles } = useMsalAccount();

  const { data: userFacilities } = useUserFacilitiesQuery(userId, {
    skip: !userId,
  });

  const isUserInFacility = useMemo(
    () =>
      userFacilities?.some((facility) => facility?.id == facilityId) || false,
    [userFacilities]
  );

  const isUserAdmin = useMemo(
    () => userRoles.includes(Roles.Admin),
    [userRoles]
  );

  const checkViewPermission = () => isUserAdmin || isUserInFacility;
  const checkEditPermission = () => {
    if (isUserAdmin) return true;
    if (!isUserInFacility) return false;
    return (
      userRoles.includes(Roles.Facility_Admin) ||
      userRoles.includes(Roles.Corporate_Group_Admin)
    );
  };

  const checkMealCardPermissions = () => ({
    canView: checkViewPermission() || userRoles.includes(Roles.Menu_Team),
    canEdit:
      isUserAdmin || userRoles.includes(Roles.Menu_Team) || isUserInFacility,
  });

  switch (type) {
    case PERMISSION_TYPES.CONSULTANT:
    case PERMISSION_TYPES.PLATEFUL:
    case PERMISSION_TYPES.PLATEFUL_MENUS:
    case PERMISSION_TYPES.FACILITY:
      return { canView: checkViewPermission(), canEdit: checkEditPermission() };
    case PERMISSION_TYPES.MEAL_CARD:
      return checkMealCardPermissions();
    default:
      return { canView: false, canEdit: false };
  }
}
