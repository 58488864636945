import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { RECIPES, RECIPE_CATEGORIES, TAGS, RECIPE_MENU_USAGES } = API_ENDPOINTS;

export const PlateFulRecipesAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPlateFulRecipes: builder.query({
      query: ({
        searchTerm,
        categoryId,
        tagIds,
        corporateGroup,
        htiCategory,
        usedInMenuPlannerOnly,
        userId,
        facilityId,
        skip,
        take,
      }) => {
        const tagIdsString =
          tagIds?.length > 0
            ? tagIds.map((tags) => `&tagIds=${tags.id}`).join("")
            : "";

        return {
          url: `/${RECIPES}/search?showArchived=false&searchText=${encodeURIComponent(
            searchTerm
          )}&userId=${userId}&facilityId=${facilityId}&categoryId=${categoryId}${tagIdsString}&corporategroupId=${corporateGroup}&htiOrder=${htiCategory}&usedInMenuPlannerOnly=${usedInMenuPlannerOnly}&skip=${
            skip || "0"
          }&take=${take || "100"}`,
        };
      },
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response?.data
          ? [...response.map(({ id }) => ({ type: RECIPES, id })), RECIPES]
          : [RECIPES],
    }),
    getPlateFulRecipesSelectBox: builder.query({
      query: ({ searchTerm, categoryId, userId, facilityId, tagIds }) => {
        const tagIdsString =
          tagIds.length > 0
            ? tagIds.map((tags) => `&tagIds=${tags.id}`).join("")
            : "";
        return {
          url: `/${RECIPES}?showArchived=false&searchText=${searchTerm}&userId=${userId}&facilityId=${facilityId}&categoryId=${categoryId}${tagIdsString}`,
        };
      },
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
            description: res?.description || "",
          };
        }),
      providesTags: (response) => {
        return response
          ? [...response.map(({ id }) => ({ type: RECIPES, id })), RECIPES]
          : [RECIPES];
      },
    }),
    getRecipe: builder.query({
      query: (id) => `/${RECIPES}/${id}`,
      transformResponse: (response) => response.data,
      providesTags: (response) =>
        response ? [{ type: RECIPES, id: response.id }, RECIPES] : [RECIPES],
    }),
    printRecipe: builder.mutation({
      query: (data) => ({
        url: `/${RECIPES}/Print`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: RECIPES, id: result?.id }],
    }),
    getRecipeCategory: builder.query({
      query: () => `/${RECIPE_CATEGORIES}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: RECIPE_CATEGORIES, id })),
              RECIPE_CATEGORIES,
            ]
          : [RECIPE_CATEGORIES];
      },
    }),
    getRecipeCategoryMenuPlanner: builder.query({
      query: () => `/${RECIPE_CATEGORIES}`,
      transformResponse: (response) =>
        response?.data?.map((res) => ({
          id: res.id,
          label: res.name,
        })),
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: RECIPE_CATEGORIES, id })),
              RECIPE_CATEGORIES,
            ]
          : [RECIPE_CATEGORIES];
      },
    }),
    getRecipeTags: builder.query({
      query: () => `/${TAGS}?typeId=1`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [...response.map(({ id }) => ({ type: TAGS, id })), TAGS]
          : [TAGS];
      },
    }),
    getRecipeTagsMenuPlanner: builder.query({
      query: () => `/${TAGS}?typeId=1`,
      transformResponse: (response) =>
        response?.data?.map((res) => ({
          id: res.id,
          label: res.name,
        })),
      providesTags: (response) => {
        return response
          ? [...response.map(({ id }) => ({ type: TAGS, id })), TAGS]
          : [TAGS];
      },
    }),
    // Recipe chnage history api
    getRecipeChangeHistory: builder.query({
      query: ({ recipeId }) =>
        `/${RECIPES}/ChangeHistory?recipeId=${recipeId}&skip=0&take=1000`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [...response.map(({ id }) => ({ type: RECIPES, id })), RECIPES]
          : [RECIPES];
      },
    }),
    // Recipe Menu api
    getRecipeMenu: builder.query({
      query: ({ recipeId }) =>
        `/${RECIPES}/MenuUsage?recipeId=${recipeId}&skip=0&take=1000`,
      transformResponse: (response) => response?.data?.menuList,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: RECIPE_MENU_USAGES, id })),
              RECIPE_MENU_USAGES,
            ]
          : [RECIPE_MENU_USAGES];
      },
    }),
    deleteMenuRecipes: builder.mutation({
      query: (id) => ({
        url: `/${RECIPES}/DeleteUsage/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: RECIPE_MENU_USAGES, id: arg.id },
      ],
    }),
    // Recipe Menu api ended
    deletePlatefulRecipes: builder.mutation({
      query: (id) => ({
        url: `/${RECIPES}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: RECIPES, id: arg.id }],
    }),
  }),
});
export const {
  useGetPlateFulRecipesQuery,
  useGetPlateFulRecipesSelectBoxQuery,
  useGetRecipeQuery,
  usePrintRecipeMutation,
  useGetRecipeCategoryQuery,
  useGetRecipeCategoryMenuPlannerQuery,
  useGetRecipeTagsQuery,
  useGetRecipeTagsMenuPlannerQuery,
  useGetRecipeChangeHistoryQuery,
  useGetRecipeMenuQuery,
  useDeleteMenuRecipesMutation,
} = PlateFulRecipesAPIs;
