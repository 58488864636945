import { REQUIRED_ERROR } from "utils/errorMessages";
import { baseNameAlphaNumericValidation } from "../../../../../../utils/ValidationRegex";

export const PURCHASING_GROUP_FIELDS = [
  {
    size: 6,
    props: {
      label: "Name",
      id: "name",
      name: "name",
    },
    validationProps: {
      required: REQUIRED_ERROR("Purchasing Group Name"),
      pattern: {
        value: baseNameAlphaNumericValidation,
        message: "Purchasing Group Name is not valid.",
      },
      maxLength: {
        value: 50,
        message: "Please enter less than 50 characters.",
      },
    },
  },
  {
    size: 6,
    props: {
      label: "Sage Customer Id",
      id: "sageCustomerId",
      name: "sageCustomerId",
    },
    validationProps: {
      maxLength: {
        value: 20,
        message: "Please enter 20 or less characters.",
      },
    },
  },
];
