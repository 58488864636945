import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useSearchParams, useLocation } from "react-router-dom";
import { GridRowModes, GridActionsCellItem } from "@mui/x-data-grid";
import {
  useGetVendorProductsQuery,
  useUpdateSingleVendorProductMutation,
} from "../../../../../../store/apis/VendorApis.jsx";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { useGetUnitsQuery } from "../../../../../../store/apis/UnitApis";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "../../../../../shared/baseSearch/BaseSearch";

export default function BulkProductEntry() {
  const SEARCH_PARAM = "search";
  const [debouncedValue, setDebouncedValue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchValue, setSearchValue] = useState("");
  const vendorId = searchParams.get("id");

  const location = useLocation();
  const vendorName = location.state?.vendorName;

  const {
    data: vendorProducts,
    isLoading: isLoading,
    isError: isError,
  } = useGetVendorProductsQuery(
    { vendorId: vendorId, searchText: debouncedValue },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: unitList } = useGetUnitsQuery("");
  const [
    updateVendorProduct,
    // { isError: isUpdateError, isLoading: isSubmitting },
  ] = useUpdateSingleVendorProductMutation();

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  useEffect(() => {
    if (vendorProducts) {
      setRows(vendorProducts?.products);
    }
  }, [vendorProducts]);

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = (newRow) => {
    updateVendorProduct(newRow);
    const updatedRow = { ...newRow };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const VendorProductsColumns = [
    {
      field: "description",
      headerName: "Product Description",
      flex: 3,
      minWidth: 150,
    },
    {
      field: "columnOfItemNumber",
      headerName: "Item Number",
      flex: 1,
      minWidth: 100,
      editable: true,
    },
    {
      field: "columnOfPrice",
      headerName: "Price",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      type: "number",
      flex: 1,
      minWidth: 100,
      editable: true,
      valueFormatter: (value) => {
        return `$${value.toFixed(2)}`;
      },
    },
    {
      field: "columnOfPackSize",
      headerName: "Pack Size",
      flex: 1,
      minWidth: 100,
      editable: true,
    },
    {
      field: "columnOfQuantity",
      headerName: "Quantity",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      type: "number",
      flex: 1,
      minWidth: 100,
      editable: true,
      valueFormatter: (value) => {
        return value.toFixed(2);
      },
    },
    {
      field: "columnOfUnitId",
      headerName: "Unit",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      editable: true,
      type: "singleSelect",
      valueOptions: unitList,
      getOptionValue: (value) => value.id,
      getOptionLabel: (value) => value.label,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={id}
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              key={id}
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <div
            key={id}
            className="editIcon"
            style={{ cursor: "pointer" }}
            onClick={handleEditClick(id)}>
            <span className="material-icons">edit</span>
          </div>,
        ];
      },
    },
  ];

  const handleCellClick = React.useCallback((params) => {
    if (!params.isEditable) {
      return;
    }

    setRowModesModel((prevModel) => {
      return {
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: { mode: GridRowModes.View },
          }),
          {}
        ),
        [params.id]: { mode: GridRowModes.Edit },
      };
    });
  }, []);

  return (
    <>
      <div className="actionsRow">
        <div className="leftActions">
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            sx={{ ml: 1 }}
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedValue}
            setSearchParams={setSearchParams}
            isId={true}
            Id={vendorId}
          />
          <Typography
            variant="p"
            style={{ marginTop: "10px", marginLeft: "10px" }}>
            {`Vendor: ${vendorName}`}
          </Typography>
        </div>
      </div>
      <Box
        sx={{
          display: "flex",
          height: "90%",
          width: "100%",
          "& .MuiInputBase-root": {
            "& input[type=number]": {
              textAlign: "right",
            },
          },
          "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
            bgcolor: "rgb(0, 188, 190, .4)",
          },
        }}>
        <BaseDataGrid
          rows={rows}
          loading={isLoading}
          columns={VendorProductsColumns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          processRowUpdate={processRowUpdate}
          onCellClick={handleCellClick}
          error={isError}
          autoHeight={false}
          height={{ md: "50dvh", xl: "55dvh" }}
        />
      </Box>
    </>
  );
}
