import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const {
  TIME_ZONES,
  REGIONS,
  DIETITIAN_USERS,
  USER_ROLES,
  UTILITIES,
  ALLERGY_FOODS,
  CORPORATE_GROUPS,
  PAN_SIZES,
  TEXTURES,
  THICKENED_LIQUIDS,
  RECIPES
} = API_ENDPOINTS;

export const GenericAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSearchByName: builder.query({
      query: ({ url, query }) => `/${url}/searchbyname?searchText=${query}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name || `${res?.firstName} ${res?.lastName}`,
          };
        }),
      keepUnusedDataFor: 0,
    }),
    getById: builder.query({
      query: ({ url, id }) => `/${url}/${id}`,
      transformResponse: (response) => response?.data,
      keepUnusedDataFor: 0,
    }),
    getByIdDefaultOption: builder.query({
      query: ({ url, id }) => `/${url}/${id}`,
      transformResponse: (response) => {
        return [
          {
            id: response?.data?.id,
            label:
              response?.data?.name ||
              `${response?.data?.firstName} ${response?.data?.lastName}`,
          },
        ];
      },
      keepUnusedDataFor: 0,
    }),
    getTimeZones: builder.query({
      query: () => `/${TIME_ZONES}`,
      keepUnusedDataFor: 120, // Timezones shouldn't change much, keep around for a longer time
      transformResponse: (response) => response?.data,
    }),
    getTimeZoneSelectList: builder.query({
      query: () => `/${TIME_ZONES}`,
      keepUnusedDataFor: 120, // Timezones shouldn't change much, keep around for a longer time
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
            description: res?.timeZoneController,
          };
        }),
    }),
    getRegionsSelectList: builder.query({
      query: () => `/${REGIONS}`,
      keepUnusedDataFor: 120, // Regions shouldn't change much, keep around for a longer time
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: `${res.name} (${res?.managerName})`,
            description: res?.states,
          };
        }),
    }),
    getRegionalManagerUserIdSelectList: builder.query({
      query: () => `/${DIETITIAN_USERS}/RegionalManagers`,
      keepUnusedDataFor: 120, // Regional Managers shouldn't change much, keep around for a longer time
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res?.userId,
            label: res?.name,
            description: res?.primaryRegion,
          };
        }),
    }),
    getRegionalManagerUserRoleSelectList: builder.query({
      query: () => `/${USER_ROLES}/RegionalManagers`,
      keepUnusedDataFor: 120, // Regional Managers shouldn't change much, keep around for a longer time
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res?.dietitianId,
            label: res?.fullName,
          };
        }),
    }),
    getBillToTypesSelectList: builder.query({
      query: () => `/${UTILITIES}/billtotypes`,
      keepUnusedDataFor: 120, // BillToTypes shouldn't change much, keep around for a longer time
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
    }),
    getDiningRDGroupSelectList: builder.query({
      query: () => `/${UTILITIES}/diningrdgroups`,
      keepUnusedDataFor: 120, // dining rd groups shouldn't change much, keep around for a longer time
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
            description: `${res?.sagePrefix} - ${res?.ardivisionNo}}`,
          };
        }),
    }),
    getMedicalRecordTypesSelectList: builder.query({
      query: () => `/${UTILITIES}/medicalrecordtypes`,
      keepUnusedDataFor: 120, // Medical record types shouldn't change much, keep around for a longer time
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
    }),
    getEHRProvidersSelectList: builder.query({
      query: () => `/${UTILITIES}/ehrproviders`,
      keepUnusedDataFor: 120, // EHR providers shouldn't change much, keep around for a longer time
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
    }),
    getMealTypesList: builder.query({
      query: () => `/${UTILITIES}/MealTypes`,
      keepUnusedDataFor: 120, // Meal Types shouldn't change much, keep around for a longer time
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
    }),
    getConsultingTypeList: builder.query({
      query: () => `/${UTILITIES}/consultingtypes`,
      keepUnusedDataFor: 120, // Consulting Types shouldn't change much, keep around for a longer time
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.consultingTypeName,
            description: res?.description,
            rateType: res?.rateType,
            defaultRate: res?.defaultRate,
          };
        }),
    }),
    getNoteTypes: builder.query({
      query: () => `/${UTILITIES}/GetNoteTypes`,
      keepUnusedDataFor: 120, // Note Types shouldn't change much, keep around for a longer time
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
    }),
    //Resident allergy dropdown api
    residentAllergySelect: builder.query({
      query: () => `/${ALLERGY_FOODS}?skip=0&take=1000`,
      transformResponse: (response) =>
        response?.data?.items.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      keepUnusedDataFor: 0,
    }),
    //Resident ThickinedLiquid dropdown api
    residentThickenedLiquidSelect: builder.query({
      query: () => `/${THICKENED_LIQUIDS}?skip=0&take=1000`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      keepUnusedDataFor: 0,
    }),
    getTexturesSelectBox: builder.query({
      query: () => `/${TEXTURES}`,
      transformResponse: (response) =>
        response?.data?.map((res) => ({
          id: res.id,
          label: res.name,
          description: `${res.iddsiabbreviation} - ${res.iddsilevel}`,
        })),
    }),
    getPanSizesSelectBox: builder.query({
      query: () => `/${PAN_SIZES}`,
      transformResponse: (response) =>
        response?.data?.map((res) => ({
          id: res.id,
          label: res.name,
        })),
    }),
    getCookingTempSelectBox: builder.query({
      query: () => `/${RECIPES}/CookingTemperatures`,
      transformResponse: (response) => response?.data?.map((res) => {
        return {
          id: res.temperatureText, //use text for ID so we save the string to DB
          label: res.temperatureText
        };
      })
    }),
    //CorporateGroupAccess for Menus dropdown api
    CorporateGroupAccessSelect: builder.query({
      query: () => `/${CORPORATE_GROUPS}?skip=0&take=1000`,
      transformResponse: (response) =>
        response?.data?.items?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      keepUnusedDataFor: 0,
    }),
    // Billing Period dropdown api
    getBillingPeriodSelect: builder.query({
      query: () => `/${UTILITIES}/BillingPeriods`,
      keepUnusedDataFor: 120, // Billing Periods shouldn't change much, keep around for a longer time
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
    }),
    // Subscription Period dropdown api
    getSubscriptionPeriodSelect: builder.query({
      query: () => `/${UTILITIES}/SubscriptionPeriods`,
      keepUnusedDataFor: 120, // Subscription Periods shouldn't change much, keep around for a longer time
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetSearchByNameQuery,
  useGetByIdQuery,
  useGetByIdDefaultOptionQuery,
  useGetTimeZonesQuery,
  useGetRegionalManagerUserIdSelectListQuery,
  useGetRegionalManagerUserRoleSelectListQuery,
  useGetRegionsSelectListQuery,
  useGetTimeZoneSelectListQuery,
  useGetBillToTypesSelectListQuery,
  useGetDiningRDGroupSelectListQuery,
  useGetMedicalRecordTypesSelectListQuery,
  useGetEHRProvidersSelectListQuery,
  useGetMealTypesListQuery,
  useGetConsultingTypeListQuery,
  useGetNoteTypesQuery,
  useResidentAllergySelectQuery,
  useResidentThickenedLiquidSelectQuery,
  useCorporateGroupAccessSelectQuery,
  useGetBillingPeriodSelectQuery,
  useGetSubscriptionPeriodSelectQuery,
} = GenericAPIs;
