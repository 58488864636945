import React from "react";
import { FIELD_FORM } from "./forms.constants";
import FieldOption from "./FieldOption";
import GenericFieldForm from "./GenericFieldForm";

export default function Field({
  control,
  nestIndex,
  sectionIndex,
  fieldGroupIndex,
  errors,
}) {
  return (
    <GenericFieldForm
      name={`sectionGroups[${sectionIndex}].sections[${fieldGroupIndex}].fieldGroups[${nestIndex}].fields`}
      control={control}
      errors={errors}
      fieldList={FIELD_FORM}
      appendObjectExample={{}}
      nameOfObject={"Field"}
      fieldPropName={`sectionGroups[${sectionIndex}].sections[${fieldGroupIndex}].fieldGroups[${nestIndex}].fields`}
      ChildComponent={FieldOption}
      childProps={{
        control,
        errors,
        fieldGroupIndex,
        fieldIndex: nestIndex,
        sectionIndex,
      }}
      isSortOrder={true}></GenericFieldForm>
  );
}
