import { Typography } from "@mui/material";
import React from "react";
import { DMStyles } from "../../styles/Styles";

export const WarningText = React.forwardRef(
  ({ text, color, width, fontSize, padding, margin, sx }, ref) => {
    const colors =
      color === "warning"
        ? DMStyles.Yellow
        : color === "red"
        ? DMStyles.Red
        : color === "black"
        ? DMStyles.Black
        : DMStyles.Teal;

    return (<>
      <Typography
        // Forward the ref to the Typography component
        ref={ref}
        variant="p"
        component={"p"}
        sx={{
          fontWeight: 600,
          color: colors,
          border: `1px solid ${color === "black" ? "gray" : colors}`,
          padding: padding || "10px",
          margin: margin || "20px !important",
          borderRadius: "10px",
          width: width,
          fontSize: fontSize,
          ...sx
        }}>
        {text}
      </Typography>
    </>);
  }
);

WarningText.displayName = "WarningText";
