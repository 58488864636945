import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Button,
  Typography,
  Fade,
  Stack,
  Grid,
  Skeleton,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGetHTIOrdersReportMutation } from "../../../../store/apis/ReportAPIs";
import { useGetDiningRDGroupSelectListQuery } from "../../../../store/apis/GenericApis";
import { DownloadLinkHelper } from "../../../shared/utils/helpers";
import BaseSelect from "../../../shared/baseForm/BaseSelect";
import LinearProgress from "@mui/material/LinearProgress";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { BaseButton } from "components/shared/BaseButton";

export default function ExportHTIOrders() {
  const { data: groupList, isFetching: groupListLoading } =
    useGetDiningRDGroupSelectListQuery();

  const [
    getReport,
    {
      isError: isError,
      isSuccess: isSuccess,
      isLoading: isLoading,
      data: report,
    },
  ] = useGetHTIOrdersReportMutation();

  const [readyToMark, setReadyToMark] = useState(false);

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = methods;

  const diningRDGroupId = watch("diningRDGroupId");

  useEffect(() => {
    if (isSuccess) {
      if (report?.data && report?.data !== "") {
        setReadyToMark(true);
        DownloadLinkHelper(report?.data);
      } else {
        setReadyToMark(false);
      }
    }
  }, [report?.data]);

  useEffect(() => {
    if (readyToMark) {
      setReadyToMark(false);
    }
  }, [diningRDGroupId]);

  const onSubmit = (data) => {
    data.markAsExported = false;

    // console.log(data);

    getReport(data);
  };

  const onMarkExported = (data) => {
    data.markAsExported = true;
    getReport(data);
  };

  return (<>
    <Box sx={{
      marginTop: "10px"
    }}>
      <BaseButton
        text="Back To Billing Reports"
        startIcon={"keyboard_arrow_left"}
        onClick={() => navigate(-1)}
      />
    </Box>
    <Box sx={{
      marginLeft: "10px"
    }}>
      <Typography
        variant="h5"
        className="userFacilityTitle"
        sx={{
          color: "primary",
          marginTop: "10px"
        }}>
        Export HTI Orders to Sage
      </Typography>
    </Box>
    <React.Fragment>
      <FormProvider>
        <form>
          <Grid container spacing={2} style={{ marginTop: "24px" }}>
            <Grid item xs={4}>
              {groupListLoading ? (
                <Skeleton />
              ) : (
                <BaseSelect
                  label="DiningRD Group"
                  name="diningRDGroupId"
                  id="diningRDGroupId"
                  control={control}
                  options={groupList}
                  errors={errors}
                  defaultValue={groupList[0]}
                  validationProps={{
                    required: REQUIRED_ERROR("DiningRD Group"),
                  }}
                />
              )}
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="body2"
                sx={{
                  display: "block",
                  color: "text.secondary"
                }}>
                The Menu group will be used to determine which group&apos;s
                order to export. <br />
                If the Menu group is not set, the Consulting group will be
                used.
              </Typography>
            </Grid>
          </Grid>

          <Stack spacing={2} sx={{ marginTop: "10px" }}>
            {" "}
            {/* sx={{ display: "flex", flexDirection: "row", pt: 2 }} */}
            {isLoading ? (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  disabled
                  onClick={handleSubmit(onSubmit)}>
                  Export HTI Orders
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}>
                  Export HTI Orders
                </Button>
              </div>
            )}
            <Fade
              in={isLoading}
              unmountOnExit
              sx={{ marginTop: "10px", marginBottom: "10px" }}>
              <LinearProgress />
            </Fade>
            {isError ? (
              <Typography sx={{
                color: "error"
              }}>
                Error occured during report generation. Please try again
                later.
              </Typography>
            ) : undefined}
            {readyToMark ? (
              <div>
                <div>
                  <Typography sx={{
                    color: "error"
                  }}>
                    If the reports look correct, you should mark these records
                    as &apos;Exported&apos; so they don&apos;t show up on the
                    report next time.
                  </Typography>
                </div>
                <div>
                  <Button
                    sx={{ marginTop: "10px" }}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(onMarkExported)}>
                    Mark These Records as Exported
                  </Button>
                </div>
              </div>
            ) : undefined}
          </Stack>
        </form>
      </FormProvider>
    </React.Fragment>
  </>);
}
