import React from "react";
import { Typography, MenuItem, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../../store/slices/authSlice/AuthSlice";
import { Link } from "react-router-dom";

import { useGetDiningAreaListQuery } from "../../../store/apis/ResidentApis";
import BaseDataGrid from "../../shared/baseDataGrid/BaseDataGrid";
import { BaseButton } from "components/shared/BaseButton";
import BaseMenu from "components/shared/BaseMenu";
import BaseActionBox from "components/shared/BaseActionBox";
import { PostAdd } from "@mui/icons-material";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function DiningArea() {
  const facilityId = useSelector(selectFacilityId);

  const {
    data: diningAreaList,
    isFetching: loading,
    isError: hasError,
  } = useGetDiningAreaListQuery(
    {
      facilityId: facilityId,
    },
    { refetchOnMountOrArgChange: true, skip: !facilityId }
  );

  const diningAreasColumns = [
    {
      field: "name",
      headerName: "Dining Area",
      flex: 2,
    },
    {
      field: "sortOrder",
      headerName: "Sort Order",
      flex: 1,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={diningAreaList}
            hasEdit={true}
            editProps={{
              To: `AddDiningArea?id=${params.row.id}&facilityId=${facilityId}`,
              title: "Dining Area",
            }}
            hasDelete={true}
            deleteProps={{
              entityName: params.row?.name || "",
              apiPath: "deleteDiningArea",
              additionalText: `This dining area will be removed from ${params.row.residentsCount} residents and ${params.row.ordersCount} orders.`,
            }}
            childrenLocation={"end"}>
            {" "}
            <BaseMenu>
              <MenuItem
                component={Link}
                to={`/MealCard/settings/DiningAreaTables?diningAreaId=${params.row.id}`}
                disableRipple>
                Tables
              </MenuItem>
            </BaseMenu>
          </BaseActionBox>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          display: "flex",
          overflow: "hidden",
          flexDirection: "column",
          gap: 1,
        }}>
        <FlexBox sx={{ justifyContent: "space-between" }}>
          <Typography variant="h6" component="h2">
            Dining Areas
          </Typography>

          <BaseButton
            text="Add Dining Area"
            To={`AddDiningArea?facilityId=${facilityId}`}
            endIcon={<PostAdd />}
          />
        </FlexBox>
        <Box sx={{ height: "100%", flexGrow: 1, overflowY: "auto", pb: 1 }}>
          <BaseDataGrid
            rows={diningAreaList}
            columns={diningAreasColumns}
            loading={loading}
            error={hasError}
            height={"100%"}
          />
        </Box>
      </Box>
    </>
  );
}
