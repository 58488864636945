import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { SEASONS } = API_ENDPOINTS;

export const SeasonApis = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSeasonSelectBox: builder.query({
      query: () => `/${SEASONS}`,
      keepUnusedDataFor: 120, // Seasons shouldn't change much, keep around for a longer time
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
            defaultSeason: res.defaultSeason,
          };
        }),
      providesTags: (response) =>
        response?.data
          ? [...response.map(({ id }) => ({ type: SEASONS, id })), SEASONS]
          : [SEASONS],
    }),
    getAvailableSeasonSelectBox: builder.query({
      query: ({ take, skip, forTableSide }) =>
        `/${SEASONS}?take=${take}&skip=${skip}&forTableSide=${
          forTableSide || false
        }`,
      keepUnusedDataFor: 120, // Seasons shouldn't change much, keep around for a longer time
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
            defaultSeason: res.defaultSeason,
          };
        }),
      providesTags: (response) =>
        response?.data
          ? [...response.map(({ id }) => ({ type: SEASONS, id })), SEASONS]
          : [SEASONS],
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetSeasonSelectBoxQuery,
  useGetAvailableSeasonSelectBoxQuery,
} = SeasonApis;
