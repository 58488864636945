import { React } from "react";
import { Grid } from "@mui/material";
import BaseAutoComplete from "../../../../../../shared/baseForm/BaseAutoComplete";
import BaseInput from "../../../../../../shared/baseForm/BaseInput";
import { UserInformationFields } from "./forms.constants";
import { REQUIRED_ERROR } from "utils/errorMessages";

export default function UserInformationForm({
  isEdit,
  register,
  searchResults,
  facilityLoading,
  handleSearch,
  control,
  errors,
  defaultFacilityOptions,
}) {
  const { formTextFields, formSelectProps } = UserInformationFields;

  return (
    <Grid container spacing={2}>
      {isEdit && (
        <input
          hidden
          {...register("id", { required: REQUIRED_ERROR("Id") })}></input>
      )}
      {formTextFields.map((data, index) => {
        const props = data?.props;
        if (
          data?.props?.name !== "password" ||
          (data?.props?.name === "password" && !isEdit)
        ) {
          return (
            <Grid key={index} item xs={6}>
              <BaseInput
                control={control}
                {...props}
                name={props?.name}
                validationProps={data?.validationProps}
                errors={errors}
              />
            </Grid>
          );
        }
      })}

      <Grid item xs={6}>
        <BaseAutoComplete
          options={searchResults ? searchResults : []}
          defaultOptions={defaultFacilityOptions}
          loading={facilityLoading}
          control={control}
          handleSearch={handleSearch}
          validationProps={formSelectProps?.validationProps}
          formSelectProps={formSelectProps}
          errors={errors}
        />
      </Grid>
    </Grid>
  );
}
