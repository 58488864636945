import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { SNACKS } = API_ENDPOINTS;

export const SnackApis = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSnackSelectBox: builder.query({
      query: () => `/${SNACKS}?searchText=&skip=0&take=1000`,
      transformResponse: (response) =>
        response?.data?.items.map((item) => ({
          id: item.id,
          label: item.name,
          defaultUnit: item.defaultUnitId,
        })),
      providesTags: (response) => {
        return response
          ? [...response.map(({ id }) => ({ type: SNACKS, id })), SNACKS]
          : [SNACKS];
      },
    }),
  }),
});

export const { useGetSnackSelectBoxQuery } = SnackApis;
