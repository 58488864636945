export const VendorMenuElements = [
    {
        Name: "Vendors",
        Link: "/admin/vendors/vendorSetup",
        IsActive: false,
    },
    {
        Name: "Purchasing Groups",
        Link: "/admin/vendors/purchasingGroups",
        IsActive: false,
    }, 
    // {
    //     Name: "Base Menus",
    //     Link: "/admin/vendors/baseMenus",
    //     IsActive: false,
    // },     
]