import React, { useEffect, useState } from "react";
import {
  useGetCorporateMealExtraByIdQuery,
  useAddCorporateMealExtraMutation,
  useUpdateCorporateMealExtraMutation,
} from "../../../../../../../store/apis/CorporateGroupMealExtraApis";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Button, Divider, Grid } from "@mui/material";
import BaseSelect from "../../../../../../shared/baseForm/BaseSelect";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../../admin.overlays";
import { useGetMealExtraSelectBoxQuery } from "../../../../../../../store/apis/MealExtrasApis";
import { setErrorsForProperties } from "components/shared/utils/helpers";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { KeyboardArrowLeft } from "@mui/icons-material";

export default function AddMealExtrasSelection() {
  const [validationSummary, setValidationSummary] = useState([]);
  const [
    addMealExtra,
    {
      isError: addError,
      isLoading: addLoading,
      isSuccess: addSuccess,
      error: addErrorData,
    },
  ] = useAddCorporateMealExtraMutation();

  const [
    updateMealExtra,
    {
      isError: updateError,
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      error: updateErrorData,
    },
  ] = useUpdateCorporateMealExtraMutation();

  const isError = addError || updateError;
  const isSuccess = addSuccess || updateSuccess;
  const isSubmitting = addLoading || updateLoading;
  const errorData = addErrorData || updateErrorData;
  const [searchParams] = useSearchParams();

  const corporateId = searchParams.get("corporateId");
  const MealExtraId = searchParams.get("id");

  const isEdit = !!MealExtraId;

  const {
    data: mealExtraData,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetCorporateMealExtraByIdQuery(MealExtraId, {
    skip: !isEdit,
  });

  const isEditLoading = isEdit && currentFetching;

  const { data: selectMealExtra, isLoading: loading } =
    useGetMealExtraSelectBoxQuery();

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    reset,
    setError,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (currentSuccess && !currentFetching && mealExtraData) {
      reset(mealExtraData);
    }
  }, [mealExtraData]);

  if (isSuccess) {
    navigate(-1);
  }

  const onSubmit = (data) => {
    if (isEdit) {
      updateMealExtra(data);
    } else {
      addMealExtra({
        ...data,
        corporateGroupId: corporateId,
        mealExtraName: "",
        corporateGroupName: "",
      });
    }
    if (isError) {
      setErrorsForProperties({
        responseError: errorData?.data,
        setError,
        setValidationSummary: setValidationSummary,
      });
    }
  };

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <>
          <div className="leftActions">
            <Button
              component={Link}
              color={"primary"}
              variant="contained"
              onClick={() => navigate(-1)}
              startIcon={<KeyboardArrowLeft />}>
              {"Back to Meal Add-Ons List"}
            </Button>
          </div>
          <Divider sx={{ marginBottom: "15px", border: "none" }} />
          <FormProvider>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <BaseSelect
                    label="Choose Meal Add-On"
                    name="mealExtraId"
                    id="mealExtraId"
                    control={control}
                    options={selectMealExtra || []}
                    errors={errors}
                    loading={loading}
                    validationProps={{
                      required: REQUIRED_ERROR("MealExtra"),
                    }}
                  />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />

                <Grid item xs={5}>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <BaseSubmitButton
                      fullWidth
                      onClick={handleSubmit(onSubmit)}
                      isSubmitting={isSubmitting}
                      validationSummary={validationSummary}
                      text={isEdit ? "Submit" : "Create"}
                    />
                  </Box>
                </Grid>
                <Grid item xs={3} />
              </Grid>
            </form>
          </FormProvider>
        </>
      )}
    </>
  );
}
