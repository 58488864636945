import React, { useEffect } from "react";
import { Box, Typography, Grid, Card } from "@mui/material";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";

import { FormProvider, useForm } from "react-hook-form";
import { useUpdateWeightAlertMutation } from "../../../../store/apis/WeightAlertApis";
import BaseInput from "../../../shared/baseForm/BaseInput";
import BaseSubmitButton from "../../../shared/baseSubmitButton/BaseSubmitButton";

export default function EditWeightAlert() {
  const [
    updateWeightAlert,
    { isError: updateError, isLoading: updateLoading },
  ] = useUpdateWeightAlertMutation();

  const isError = updateError;
  const isSubmitting = updateLoading;
  const [searchParams] = useSearchParams();

  const facilityId = searchParams.get("id");

  const isEdit = !!facilityId;

  const location = useLocation();
  const WeightAlertData = location.state.data;

  useEffect(() => {
    if (location.state && WeightAlertData) {
      reset(WeightAlertData);
    }
  }, [WeightAlertData]);

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    updateWeightAlert({ ...data, facilityId: facilityId });

    if (!isError) {
      navigate(-1);
    }
  };

  return (
    (<Card>
      <Typography variant="h6" component="h2" sx={{
        margin: "10px"
      }}>
        {`Edit Weight Alerts`}
      </Typography>
      <Box sx={{
        margin: "10px"
      }}>
        <FormProvider>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <BaseInput
                  label={"One Month % Change"}
                  name={"_1monthWeightAlert"}
                  placeholder="One Month % Change"
                  control={control}
                  type="number"
                  errors={errors}
                  validationProps={{
                    max: {
                      value: 100,
                      message: "Percentage cannot exceed 100%.",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />

              <Grid item xs={5}>
                <BaseInput
                  label={"Three Month % Change"}
                  name={"_3monthWeightAlert"}
                  placeholder="Three Month % Change"
                  type="number"
                  control={control}
                  errors={errors}
                  validationProps={{
                    max: {
                      value: 100,
                      message: "Percentage cannot exceed 100%.",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />
              <Grid item xs={5}>
                <BaseInput
                  label={"Six Month % Change"}
                  name={"_6monthWeightAlert"}
                  placeholder="Six Month % Change"
                  type="number"
                  control={control}
                  errors={errors}
                  validationProps={{
                    max: {
                      value: 100,
                      message: "Percentage cannot exceed 100%.",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />
              <Grid item xs={5}>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <BaseSubmitButton
                    fullWidth
                    onClick={handleSubmit(onSubmit)}
                    isSubmitting={isSubmitting}
                    text={isEdit ? "Submit" : "Create"}
                  />
                </Box>
                {isError ? (
                  <Typography sx={{
                    color: "error"
                  }}>
                    There was an error submitting the form, please try again.
                  </Typography>
                ) : undefined}
              </Grid>
              <Grid item xs={3} />
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </Card>)
  );
}
