import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { CORPORATE_GROUPS, CORPORATE_GROUP_CATEGORIES, USERS, UTILITIES } =
  API_ENDPOINTS;

export const CorporateGroupAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCorporateGroupSelect: builder.query({
      query: (query) => `/${CORPORATE_GROUPS}/searchbyname?searchText=${query}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name || res.username,
            description: `${res?.city} - ${res?.state}`,
          };
        }),
      providesTags: (response) =>
        response?.data
          ? [
              ...response.data.map(({ id }) => ({
                type: CORPORATE_GROUPS,
                id,
              })),
              CORPORATE_GROUPS,
            ]
          : [CORPORATE_GROUPS],
    }),
    getCorporateGroupEHRSelect: builder.query({
      query: () => `/${UTILITIES}/EHRProviders`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name || res.username,
          };
        }),
      providesTags: (response) =>
        response?.data
          ? [
              ...response.data.map(({ id }) => ({
                type: CORPORATE_GROUPS,
                id,
              })),
              CORPORATE_GROUPS,
            ]
          : [CORPORATE_GROUPS],
    }),
    getCorporateGroupHTISelect: builder.query({
      query: () => `/${USERS}/HTIUserList`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.fullName,
          };
        }),
      providesTags: (response) =>
        response?.data
          ? [
              ...response.data.map(({ id }) => ({
                type: USERS,
                id,
              })),
              USERS,
            ]
          : [USERS],
    }),
    getCorporateGroup: builder.query({
      query: (searchTerm) =>
        `${CORPORATE_GROUPS}?searchText=${searchTerm}&skip=0&take=100`,
      transformResponse: (response) => response?.data?.items,
      providesTags: (response) => [
        { type: CORPORATE_GROUPS, id: response?.id },
      ],
    }),
    getCorporateGroupPaged: builder.query({
      query: ({ searchTerm, skip, take }) =>
        `${CORPORATE_GROUPS}?searchText=${searchTerm}&skip=${skip}&take=${take}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => [
        { type: CORPORATE_GROUPS, id: response?.id },
      ],
    }),
    getCorporateGroupById: builder.query({
      query: (corporateId) => `/${CORPORATE_GROUPS}/${corporateId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: CORPORATE_GROUPS, id: response.id }, CORPORATE_GROUPS]
          : [CORPORATE_GROUPS],
    }),
    deleteCorporateGroup: builder.mutation({
      query: (id) => ({
        url: `/${CORPORATE_GROUPS}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: CORPORATE_GROUPS, id: arg.id },
      ],
    }),
    addCorporateGroup: builder.mutation({
      query: (data) => ({
        url: `/${CORPORATE_GROUPS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: CORPORATE_GROUPS, id: result?.id }],
    }),
    updateCorporateGroup: builder.mutation({
      query: (data) => ({
        url: `/${CORPORATE_GROUPS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: CORPORATE_GROUPS, id: result?.id }],
    }),
    addCorporateGroupPBJ: builder.mutation({
      query: (data) => ({
        url: `/${CORPORATE_GROUPS}/PBJExport?corporateGroupId=${data.corporateGroupId}&reportStartDate=${data.reportStartDate}&reportEndDate=${data.reportEndDate}`,
        method: "POST",
        body: data,
        responseHandler: (response) => response.blob(),
        cache: "no-cache",
      }),
    }),
    addCorporateGroupPBJExcel: builder.mutation({
      query: (data) => ({
        url: `/${CORPORATE_GROUPS}/PBJExcelExport?corporateGroupId=${data.corporateGroupId}&reportStartDate=${data.reportStartDate}&reportEndDate=${data.reportEndDate}`,
        method: "POST",
        body: data,
        cache: "no-cache",
      }),
    }),
    addCorporateGroupExtend: builder.mutation({
      query: (data) => ({
        url: `/${CORPORATE_GROUPS}/ExtendExpiration?corporateGroupId=${data.corporateGroupId}&menuExpiration=${data.menuExpiration}&mealCardExpiration=${data.mealCardExpiration}&tableSideExpiration=${data.tableSideExpiration}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: CORPORATE_GROUPS, id: result?.id }],
    }),
    addCorporateGroupConsultantsSchedule: builder.mutation({
      query: (data) => ({
        url: `/${CORPORATE_GROUPS}/ConsultantSchedule?corporateGroupId=${data.corporateGroupId}&reportStartDate=${data.reportStartDate}&reportEndDate=${data.reportEndDate}`,
        method: "POST",
        body: data,
        cache: "no-cache",
      }),
      transformResponse: (response) => response?.data,
    }),
    getCorporateGroupCategories: builder.query({
      query: (categoryId) =>
        `${CORPORATE_GROUP_CATEGORIES}?categoryId=${categoryId}&skip=0&take=100`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => [
        { type: CORPORATE_GROUP_CATEGORIES, id: response?.id },
      ],
    }),
    addCorporateGroupCategory: builder.mutation({
      query: (data) => ({
        url: `/${CORPORATE_GROUP_CATEGORIES}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: CORPORATE_GROUP_CATEGORIES, id: result?.id },
      ],
    }),
    deleteCorporateGroupCategory: builder.mutation({
      query: (id) => ({
        url: `/${CORPORATE_GROUP_CATEGORIES}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: CORPORATE_GROUP_CATEGORIES, id: arg.id },
      ],
    }),
    addCorporateGroupLogo: builder.mutation({
      query: (data) => ({
        url: `/${CORPORATE_GROUPS}/logo`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: CORPORATE_GROUPS, id: result.id }],
    }),
    updateCorporateGroupLogo: builder.mutation({
      query: (data) => ({
        url: `/${CORPORATE_GROUPS}/logo`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: CORPORATE_GROUPS, id: result.id }],
    }),
    deleteCorporateGroupLogo: builder.mutation({
      query: (facilityId) => ({
        url: `/${CORPORATE_GROUPS}/logo/${facilityId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: CORPORATE_GROUPS, id: arg.id },
      ],
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetCorporateGroupSelectQuery,
  useGetCorporateGroupEHRSelectQuery,
  useGetCorporateGroupHTISelectQuery,
  useGetCorporateGroupQuery,
  useGetCorporateGroupByIdQuery,
  useAddCorporateGroupMutation,
  useUpdateCorporateGroupMutation,
  useAddCorporateGroupPBJMutation,
  useGetCorporateGroupCategoriesQuery,
  useAddCorporateGroupExtendMutation,
  useAddCorporateGroupCategoryMutation,
  useDeleteCorporateGroupCategoryMutation,
  useDeleteCorporateGroupMutation,
  useAddCorporateGroupConsultantsScheduleMutation,
  useAddCorporateGroupPBJExcelMutation,
  useAddCorporateGroupLogoMutation,
  useUpdateCorporateGroupLogoMutation,
  useDeleteCorporateGroupLogoMutation,
  useGetCorporateGroupPagedQuery,
} = CorporateGroupAPIs;
