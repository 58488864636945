import React from "react";

import ProtectedRoute from "../ProtectedRoute";
import { PAGE_ROLES } from "components/shared/constants";

import MealCard from "components/mealCard/MealCard";
import AddResident from "components/mealCard/addResident/AddResident";
import AddResidentShortForm from "components/mealCard/addResident/AddResidentShortForm";
import ChangeHistory from "components/mealCard/changeHistory/ChangeHistory";
import ReservedList from "components/mealCard/reservedList/ReservedList";
import DiningArea from "components/mealCard/diningArea/DiningArea";
import AddDiningArea from "components/mealCard/diningArea/addDiningArea/AddDiningArea";
import DiningAreaTables from "components/mealCard/diningAreaTables/DiningAreaTables";
import AddDiningAreaTables from "components/mealCard/diningAreaTables/addDiningAreaTables/AddDiningAreaTables";
import WeightAlert from "components/mealCard/weightAlert/WeightAlert";
import UpdateWeightAlert from "components/mealCard/weightAlert/updateWeightAlert/UpdateWeightAlert";
import BulkWeight from "components/mealCard/bulkWeight/BulkWeight";
import PrintReport from "components/mealCard/printReport/PrintReport";
import Highlight from "components/mealCard/printReport/Highlight";

// MealCard/residentDetails
import ResidentNotes from "components/mealCard/residentDetails/residentNotes/ResidentNotes";
import AddResidentNotes from "components/mealCard/residentDetails/residentNotes/addResidentNotes/AddResidentNotes";
import ResidentDetails from "components/mealCard/residentDetails/ResidentDetails";
import ResidentMenu from "components/mealCard/residentDetails/residentMenu/ResidentMenu";
import ResidentAdaptiveEquipment from "components/mealCard/residentDetails/adaptiveEquipment/AdaptiveEquipment";
import AddResidentAdaptiveEquipment from "components/mealCard/residentDetails/adaptiveEquipment/addAdaptiveEquipment/AddAdaptiveEquipment";
import ResidentBeverages from "components/mealCard/residentDetails/residentBeverages/ResidentBeverages";
import AddResidentBeverage from "components/mealCard/residentDetails/residentBeverages/addResidentBeverage/AddResidentBeverage";
import ResidentAllergy from "components/mealCard/residentDetails/residentAllergy/ResidentAllergy";
import ResidentDislikes from "components/mealCard/residentDetails/residentDislikes/ResidentDislikes";
import AddResidentAllergy from "components/mealCard/residentDetails/residentAllergy/addResidentAllery/AddResidentAllergy";
import AddResidentDislike from "components/mealCard/residentDetails/residentDislikes/addResidentDislike/AddResidentDislike";
import ResidentLiquid from "components/mealCard/residentDetails/residentLiquid/ResidentLiquid";
import AddResidentLiquid from "components/mealCard/residentDetails/residentLiquid/addResidentLiquid/AddResidentLiquid";
import ResidentWeightHistory from "components/mealCard/residentDetails/residentWeightHistory/ResidentWeightHistory";
import AddResidentWeight from "components/mealCard/residentDetails/residentWeightHistory/addResidentWeight/AddResidentWeight";
import ResidentDelete from "components/mealCard/residentDetails/residentDelete/ResidentDelete";
import ResidentSnacks from "components/mealCard/residentDetails/residentSnacks/ResidentSnacks";
import ResidentTablesideHistory from "components/mealCard/residentDetails/residentTablesideHistory/ResidentTablesideHistory";
import AddTablesideHistory from "components/mealCard/residentDetails/residentTablesideHistory/addTablesideHistory/AddTablesideHistory";
import AddResidentSnack from "components/mealCard/residentDetails/residentSnacks/addResidentSnack/AddResidentSnack";
import ResidentChangeHistory from "components/mealCard/residentDetails/residentChangeHistory/residentChangeHistory";
import MealCardSetupMenu from "components/mealCard/mealCardSetup/MealCardSetupMenu";
import ResidentMealExtras from "components/mealCard/residentDetails/residentMealExtras/ResidentMealExtras";
import AddResidentMealExtras from "components/mealCard/residentDetails/residentMealExtras/addResidentMealExtras/AddResidentMealExtras";
import ResidentSubstitutions from "components/mealCard/residentDetails/residentSubstitutions/ResidentSubstitutions";

// MealCard/mealExtrasSelection
import FacilityMealExtrasSelection from "components/mealCard/mealExtrasSelection/FacilityMealExtrasSelection";
import AddFacilityMealExtrasSelection from "components/mealCard/mealExtrasSelection/addMealExtrasSelection/AddFacilityMealExtrasSelection";
import AddMealExtras from "components/mealCard/mealExtrasSelection/addMealExtrasSelection/AddMealExtras";

const { MEALCARD } = PAGE_ROLES;

const mealCard = {
  path: "mealCard",
  element: (
    <ProtectedRoute allowedRoles={MEALCARD}>
      <MealCard />
    </ProtectedRoute>
  ),
};

const mealCardSettings = {
  path: "mealCard/settings",
  element: (
    <ProtectedRoute allowedRoles={MEALCARD}>
      <MealCardSetupMenu />
    </ProtectedRoute>
  ),
  children: [
    { path: "changehistory", element: <ChangeHistory /> },
    { path: "reservedList", element: <ReservedList /> },
    { path: "DiningArea", element: <DiningArea /> },
    { path: "DiningArea/addDiningArea", element: <AddDiningArea /> },
    { path: "DiningAreaTables", element: <DiningAreaTables /> },
    {
      path: "DiningAreaTables/addDiningAreaTables",
      element: <AddDiningAreaTables />,
    },
    { path: "highlights", element: <Highlight /> },
    { path: "bulkWeight", element: <BulkWeight /> },
    { path: "weightAlert", element: <WeightAlert /> },
    { path: "weightAlert/edit", element: <UpdateWeightAlert /> },
    { path: "mealExtrasSelection", element: <FacilityMealExtrasSelection /> },
    {
      path: "mealExtrasSelection/addMealExtrasSelection",
      element: <AddFacilityMealExtrasSelection />,
    },
    { path: "mealExtrasSelection/addMealExtras", element: <AddMealExtras /> },
  ],
};

const mealCardAddResident = {
  path: "mealCard/addResident",
  element: (
    <ProtectedRoute allowedRoles={MEALCARD}>
      <AddResident />
    </ProtectedRoute>
  ),
};

const mealCardAddResidentShortForm = {
  path: "mealCard/addResidentShortForm",
  element: (
    <ProtectedRoute allowedRoles={MEALCARD}>
      <AddResidentShortForm />
    </ProtectedRoute>
  ),
};

const mealCardResidentChangeHistory = {
  path: "mealCard/residentChangeHistory",
  element: (
    <ProtectedRoute allowedRoles={MEALCARD}>
      <ResidentChangeHistory />
    </ProtectedRoute>
  ),
};

const mealCardPrintReport = {
  path: "mealCard/printReport",
  element: (
    <ProtectedRoute allowedRoles={MEALCARD}>
      <PrintReport />
    </ProtectedRoute>
  ),
};

const mealCardResident = {
  path: "mealcard/resident",
  element: (
    <ProtectedRoute allowedRoles={MEALCARD}>
      <ResidentMenu />
    </ProtectedRoute>
  ),
  children: [
    { path: "details", element: <ResidentDetails /> },
    { path: "adaptiveEquipment", element: <ResidentAdaptiveEquipment /> },
    {
      path: "adaptiveEquipment/addAdaptiveEquipment",
      element: <AddResidentAdaptiveEquipment />,
    },
    { path: "delete", element: <ResidentDelete /> },
    { path: "beverages", element: <ResidentBeverages /> },
    { path: "beverages/addresidentBeverage", element: <AddResidentBeverage /> },
    { path: "mealExtras", element: <ResidentMealExtras /> },
    {
      path: "mealExtras/addresidentMealExtras",
      element: <AddResidentMealExtras />,
    },
    { path: "residentAllergy", element: <ResidentAllergy /> },
    {
      path: "residentAllergy/addresidentAllergy",
      element: <AddResidentAllergy />,
    },
    { path: "residentLiquid", element: <ResidentLiquid /> },
    {
      path: "residentLiquid/AddresidentLiquid",
      element: <AddResidentLiquid />,
    },
    { path: "weightHistory", element: <ResidentWeightHistory /> },
    { path: "weightHistory/addWeightHistory", element: <AddResidentWeight /> },
    { path: "residentNotes", element: <ResidentNotes /> },
    { path: "residentNotes/addresidentNote", element: <AddResidentNotes /> },
    { path: "dislikes", element: <ResidentDislikes /> },
    { path: "dislikes/addresidentdislike", element: <AddResidentDislike /> },
    { path: "residentSnacks", element: <ResidentSnacks /> },
    { path: "residentSnacks/addResidentSnack", element: <AddResidentSnack /> },
    { path: "tablesideHistory", element: <ResidentTablesideHistory /> },
    {
      path: "tablesideHistory/addTablesideOrders",
      element: <AddTablesideHistory />,
    },
    { path: "substitutions", element: <ResidentSubstitutions /> },
  ],
};

export const MealCardRoute = {
  mealCard,
  mealCardSettings,
  mealCardAddResident,
  mealCardAddResidentShortForm,
  mealCardResidentChangeHistory,
  mealCardResident,
  mealCardPrintReport,
};
