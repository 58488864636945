import React, { useEffect } from "react";
import {
  useGetAdaptiveEquipmentByIdQuery,
  useAddAdaptiveEquipmentMutation,
  useUpdateAdaptiveEquipmentMutation,
} from "../../../../../../store/apis/AdaptiveEquipmentApi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { FormHelperText, Box, Divider, Grid, Typography } from "@mui/material";
import { ADAPTIVE_EQUIPMENT_FIELDS } from "./forms.constants";

import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import GeneratedForm from "../../../../../shared/generatedForm/GeneratedForm";
import { EditSkeleton } from "../../../../admin.overlays";
import { BaseButton } from "../../../../../shared/BaseButton";

export default function AddAdaptiveEquipment() {
  const [addAdaptiveEquipment, { isError: addError, isLoading: addLoading }] =
    useAddAdaptiveEquipmentMutation();

  const [
    updateAdaptiveEquipment,
    { isError: updateError, isLoading: updateLoading },
  ] = useUpdateAdaptiveEquipmentMutation();

  const isError = addError || updateError;
  const isSubmitting = addLoading || updateLoading;
  const [searchParams] = useSearchParams();
  const EditId = searchParams.get("id");

  const isEdit = !!EditId;

  const {
    data: adaptiveequipment,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetAdaptiveEquipmentByIdQuery(EditId, {
    skip: !isEdit,
  });

  const isEditLoading = isEdit && currentFetching;

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (currentSuccess && !currentFetching && adaptiveequipment) {
      reset(adaptiveequipment);
    }
  }, [adaptiveequipment]);

  const onSubmit = (data) => {
    if (isEdit) {
      updateAdaptiveEquipment(data);
    } else {
      addAdaptiveEquipment(data);
    }
    if (!isError) {
      navigate(-1);
    }
  };

  return (<>
    {isEditLoading ? (
      <EditSkeleton />
    ) : (
      <>
        <div className="leftActions">
          <BaseButton
            text="Back To Adaptive Equipment"
            startIcon={"keyboard_arrow_left"}
            onClick={() => navigate(-1)}
          />
        </div>
        <Divider sx={{ marginBottom: "15px", border: "none" }} />
        <Box sx={{
          padding: "8px"
        }}>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}>
            <GeneratedForm
              control={control}
              list={ADAPTIVE_EQUIPMENT_FIELDS}
              errors={errors}
            />

            <FormHelperText sx={{ marginLeft: "15px" }}>
              This will only be visible for communities that have specifically
              added this.
            </FormHelperText>
            <Grid item md={6}>
              <BaseSubmitButton
                fullWidth
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
                text={isEdit ? "Submit" : "Create"}
              />
            </Grid>
            {isError ? (
              <Typography sx={{
                color: "error"
              }}>
                There was an error submitting the form, please try again.
              </Typography>
            ) : undefined}
          </Grid>
        </Box>
      </>
    )}
  </>);
}
