import DeleteModal from "components/shared/deleteModal/DeleteModal";
import React, { useState } from "react";

export const MultipleRemoveButton = ({ selectedRowIds }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      {openModal && (
        <DeleteModal
          id={selectedRowIds}
          entityName={`${selectedRowIds?.length} records`}
          apiPath={"deleteUserFacility"}
          open={openModal}
          close={() => {
            setOpenModal(false);
          }}
        />
      )}
    </>
  );
};
