import React from "react";
import { Grid, Button } from "@mui/material";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import BaseRadio from "../../../../shared/baseForm/BaseRadio";
import BaseCheckBox from "../../../../shared/baseForm/BaseCheckBox";
import renderBaseEditCell, {
  renderBaseCell,
} from "components/shared/baseDataGrid/BaseCellEditable";

export default function DietForm({
  isFetchingDiets,
  isFetchingallergy,
  postedMenurows,
  setPostedMenuRows,
  isFetchingPostedMenu,
  isFetchingPostedOtherMenu,
  watch,
  errors,
  control,
  // setDietRows,
  dietrows,
  otherPostedMenurows,
  // setOtherPostedMenuRows,
  allergenRow,
  // setAllergenRow,
  // allergenIds,
  // setAllergenIds,
  processRowUpdate,
  rowModesModel,
  setRowModesModel,
}) {
  const CheckBoxExcludedIds = ["1", "2", "3", "5", "6", "7", "8", "12", "13"];
  const ReportId = watch("reportId");

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const updateRecipeMealftn = (mealName) => {
    const updatedRow = [...postedMenurows];
    const updatedData = updatedRow.map((item) =>
      item.mealName === mealName ? { ...item, islint: !item.islint } : item
    );
    setPostedMenuRows(updatedData);
  };

  const RadioGroup = [
    { value: 0, label: "Card Layout (one meal per page)" },
    { value: 1, label: "Card Layout (all meals per page)" },
    { value: 2, label: "Full Page Layout" },
    { value: 3, label: "Portrait Layout (with Border)" },
  ];

  const dietsColumns = [
    {
      field: "isInList",
      headerName: "",
      flex: 1,
      renderCell: renderBaseCell,
      renderEditCell: renderBaseEditCell,
      type: "boolean",
      editable: true,
    },
    {
      field: "dietName",
      headerName: "Diet",
      flex: 4,
      minWidth: 50,
    },
    {
      field: "census",
      checkField: "isInList",
      editable: true,
      headerName: "Census",
      min: 0,
      type: "number",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      renderCell: renderBaseCell,
      renderEditCell: renderBaseEditCell,
    },
  ];

  const dietsRecipeColumns = [
    {
      field: "isInList",
      headerName: "",
      type: "boolean",
      editable: true,
      renderCell: renderBaseCell,
      renderEditCell: renderBaseEditCell,
    },
    {
      field: "dietName",
      headerName: "Diet",
      flex: 4,
      minWidth: 50,
    },
    {
      field: "qty1",
      editable: true,
      checkField: "isInList",
      headerName: "Census",
      type: "number",
      min: 0,
      headerAlign: "left",
      flex: 1,
      minWidth: 50,
      renderCell: renderBaseCell,
      renderEditCell: renderBaseEditCell,
    },
    {
      field: "qty2",
      editable: true,
      min: 0,
      headerName: "",
      type: "number",
      checkField: "isInList",
      flex: 1,
      minWidth: 50,
      renderCell: renderBaseCell,
      renderEditCell: renderBaseEditCell,
    },
    {
      field: "qty3",
      min: 0,
      editable: true,
      headerName: "",
      checkField: "isInList",
      type: "number",
      flex: 1,
      minWidth: 50,
      renderCell: renderBaseCell,
      renderEditCell: renderBaseEditCell,
    },
    {
      field: "note",
      editable: true,
      headerName: "Note",
      checkField: "isInList",
      type: "string",
      multiline: true,
      // rows: 2,
      flex: 2,
      minWidth: 50,
      renderCell: renderBaseCell,
      renderEditCell: renderBaseEditCell,
    },
  ];

  const postedMenuColumns = [
    {
      field: "islint",
      headerName: "Selected",
      flex: 1,
      type: "boolean",
      editable: true,
      renderCell: renderBaseCell,
      renderEditCell: renderBaseEditCell,
    },
    {
      field: "day",
      headerName: "Day",
      flex: 1,
      minWidth: 50,
    },
    {
      field: "mealName",
      headerName: "Meal",
      flex: 1,
      minWidth: 50,
    },
    {
      field: "recipeName",
      headerName: "Recipe",
      flex: 1,
      minWidth: 50,
    },
  ];

  const postedOtherMenuColumns = [
    {
      field: "islint",
      headerName: "Selected",
      flex: 1,
      type: "boolean",
      editable: true,
      maxWidth: 100,
      renderCell: renderBaseCell,
      renderEditCell: renderBaseEditCell,
    },
    {
      field: "category",
      headerName: "Catgeory",
      flex: 4,
    },
  ];

  const dietsWeekatGlanceColumns = [
    {
      field: "isInList",
      headerName: "Diet",
      flex: 1,
      type: "boolean",
      editable: true,
      renderCell: renderBaseCell,
      renderEditCell: renderBaseEditCell,
    },
    {
      field: "dietName",
      headerName: "Diet",
      flex: 4,
      minWidth: 50,
    },
  ];

  const allergensColumns = [
    {
      field: "islint",
      headerName: "Selected",
      flex: 1,
      type: "boolean",
      editable: true,
      renderCell: renderBaseCell,
      renderEditCell: renderBaseEditCell,
    },
    {
      field: "name",
      headerName: "Allergen",
      flex: 4,
      minWidth: 50,
    },
  ];

  return (
    <>
      {ReportId == 7 && (
        <div style={{ marginBottom: 10 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => updateRecipeMealftn("Breakfast")}
            style={{ marginRight: 10 }}>
            Toggle Breakfast
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => updateRecipeMealftn("Lunch")}
            style={{ marginRight: 10 }}>
            Toggle Lunch
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => updateRecipeMealftn("Dinner")}>
            Toggle Dinner
          </Button>
        </div>
      )}
      {ReportId == 3 && (
        <BaseCheckBox
          name="includeRegularRecipes"
          id="includeRegularRecipes"
          label="Include Regular Recipes"
          control={control}
          errors={errors}
          defaultValue={true}
        />
      )}
      {!CheckBoxExcludedIds.includes(ReportId) && (
        <BaseCheckBox
          name="includeRegularDiet"
          id="includeRegularDiet"
          label="Include Regular Diet"
          control={control}
          errors={errors}
          defaultValue={true}
        />
      )}
      {ReportId != 8 && (
        <BaseDataGrid
          rows={
            ReportId == 12
              ? allergenRow
              : ReportId == 7
              ? postedMenurows
              : ReportId == 13
              ? otherPostedMenurows
              : ReportId == 5
              ? dietrows?.filter((diet) => diet?.dietName !== "Pureed")
              : dietrows
          }
          loading={
            ReportId == 12
              ? isFetchingallergy
              : ReportId == 7
              ? isFetchingPostedMenu
              : ReportId == 13
              ? isFetchingPostedOtherMenu
              : isFetchingDiets
          }
          columns={
            ["4", "5", "6"].includes(ReportId)
              ? dietsWeekatGlanceColumns
              : ReportId == 3
              ? dietsRecipeColumns
              : ReportId == 7
              ? postedMenuColumns
              : ReportId == 13
              ? postedOtherMenuColumns
              : ReportId == 12
              ? allergensColumns
              : dietsColumns
          }
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          processRowUpdate={processRowUpdate}
          getRowId={(row) =>
            ReportId == 12 || ReportId == 7
              ? row.id
              : ReportId == 13
              ? row.category + row.reportId
              : row.dietId
          }
          autoHeight={false}
          height={{ md: "50vh", xl: "62vh" }}
        />
      )}
      {ReportId == 8 && (
        <Grid item xs={6} className="print-item">
          <BaseRadio
            id={"layoutsForSelectionSheetId"}
            name={"layoutsForSelectionSheetId"}
            options={RadioGroup}
            errors={errors}
            control={control}
            defaultValue={0}
            value={watch("layoutsForSelectionSheetId")}
          />
        </Grid>
      )}
    </>
  );
}
