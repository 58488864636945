import { Roles } from "components/shared/constants";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setHeaderText } from "store/slices/appSlice/AppSlice";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import BaseSearch from "components/shared/baseSearch/BaseSearch";
import BaseDataGrid from "components/shared/baseDataGrid/BaseDataGrid";
import { useGetFacilityByUserFacilitiesQuery } from "store/apis/FacilityApis";
import useMsalAccount from "utils/useMsalAccount";

export default function CorporateGroupPortal() {
  const dispatch = useDispatch();
  const { roles: userRoles } = useMsalAccount();
  const isCorporateGroupRole = userRoles.includes(Roles.Corporate_Group_Portal);
  const isAdmin = userRoles.includes(Roles.Admin);

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const [searchValueDebounced, setSearchValueDebounced] = useState(
    searchParams.get("search") || ""
  );

  const SEARCH_PARAM = "search";

  const {
    data: facilitiesData,
    isLoading: loading,
    error: hasError,
  } = useGetFacilityByUserFacilitiesQuery({
    searchTerm: searchValueDebounced || "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!isCorporateGroupRole && !isAdmin) navigate("FacilityPortal");
  }, [isCorporateGroupRole]);

  useEffect(() => {
    // Set the header text with corporate group's name
    dispatch(setHeaderText("Corporate Group Portal"));
  }, []);

  const facilitiesColumn = [];

  return (
    <>
      <div className="actionsRow">
        <div className="leftActions">
          <BaseSearch
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setSearchValueDebounced}
            setSearchParams={setSearchParams}
          />
        </div>
      </div>
      <BaseDataGrid
        rows={facilitiesData}
        columns={facilitiesColumn}
        loading={loading}
        error={hasError}
        getRowHeight={() => "auto"}
        sx={{
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "15px",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "22px",
          },
        }}
      />
    </>
  );
}
