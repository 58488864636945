import React from "react";
import { Box, IconButton, Popover, Typography } from "@mui/material";
import { DMStyles } from "styles/Styles";
import { Link } from "react-router-dom";
import { Close } from "@mui/icons-material";

const ArrowPopover = ({
  id,
  open,
  anchorEl,
  handleClose,
  name,
  contact,
  email,
}) => {
  return (
    (<Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: contact ? 110 : 80,
        horizontal: "center",
      }}
      slotProps={{
        paper: {
          style: {
            overflow: "visible",
            boxShadow: `${DMStyles.DarkBoxShadow} !important`,
            backgroundColor: "transparent",
          },
        },
      }}>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: DMStyles.White,
            boxShadow: `${DMStyles.DarkBoxShadow} !important`,
            borderRadius: DMStyles.BorderRadius
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: DMStyles.Teal,
              borderTopLeftRadius: DMStyles.BorderRadius,
              borderTopRightRadius: DMStyles.BorderRadius
            }}>
            <Box></Box>
            <Typography align="center">{name}</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          {contact && (
            <Typography
              // component={Link}
              // to={`tel:${contact}`}
              sx={{
                backgroundColor: "white",
                mt: "4px",
                mx: "8px",
              }}
              align="center">
              {contact}
            </Typography>
          )}
          {email && (
            <Typography
              component={Link}
              to={`mailto:${email}`}
              sx={{
                backgroundColor: "white",
                mb: "6px",
                mx: "8px",
              }}
              align="center">
              {email}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "-6px",
            left: "50%",
            transform: "translateX(-50%)",
            width: 0,
            height: 0,
            boxShadow: `${DMStyles.DarkBoxShadow} !important`,
            borderLeft: "8px solid transparent",
            borderRight: "8px solid transparent",
            borderTop: `8px solid ${DMStyles.White}`,
          }}
        />
      </Box>
    </Popover>)
  );
};

export default ArrowPopover;
