import React from "react";

import ProtectedRoute from "../ProtectedRoute";
import { PAGE_ROLES } from "components/shared/constants";

// Administration
import Admin from "components/admin/Admin";
import { AdminGroupsRoute } from "./AdminGroupRoute";
import { AdminSetupsRoute } from "./AdminSetupRoute";
import { AdminReportRoute } from "./AdminReportRoute";

const { ADMIN } = PAGE_ROLES;

export const AdminRoute = {
  path: "admin",
  element: (
    <ProtectedRoute allowedRoles={ADMIN}>
      <Admin />
    </ProtectedRoute>
  ),
  children: [
    // Admin Group section
    AdminGroupsRoute.AdminUserMenuRoute,
    AdminGroupsRoute.AdminCommunitiesRoute,
    AdminGroupsRoute.AdminCommunitiesvisitsDetailRoute,
    AdminGroupsRoute.AdminVendorsRoute,
    // Admin Setup section
    AdminSetupsRoute.AdminDietitianRoute,
    AdminSetupsRoute.AdminRecipesRoute,
    AdminSetupsRoute.AdminMealCardRoute,
    // Admin Report section
    AdminReportRoute.AdminRecipeReportRoute,
    AdminReportRoute.AdminVendorReportRoute,
    AdminReportRoute.AdminRecipeMissingCostRoute,
    AdminReportRoute.AdminCompareVendorPricingRoute,
    AdminReportRoute.AdminbillingreportRoute,
    AdminReportRoute.AdminExportTimeMileageRoute,
    AdminReportRoute.AdminExportDietitianRoute,
    AdminReportRoute.AdminExportHTIOrdersRoute,
  ],
};
