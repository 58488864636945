import { styled } from "@mui/material";
import { MaterialDesignContent } from "notistack";

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) => ({
    "&.notistack-MuiContent-default": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    "&.notistack-MuiContent-success": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    "&.notistack-MuiContent-error": {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
    "&.notistack-MuiContent-warning": {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
    },
  })
);
