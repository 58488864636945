import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: [],
};

const genericSnackbarSlice = createSlice({
  name: "genericSnackbar",
  initialState,
  reducers: {
    enqueueGenericSnackbar: (state, action) => {
      const { notification } = action.payload;
      const key = notification.options && notification.options.key;

      state.notifications.push({
        key: key || new Date().getTime() + Math.random(),
        ...notification,
      });
    },
    closeGenericSnackbar: (state, action) => {
      const { key, dismissAll } = action.payload;

      state.notifications = state.notifications.map((notification) =>
        dismissAll || notification.key === key
          ? { ...notification, dismissed: true }
          : { ...notification }
      );
    },
    removeGenericSnackbar: (state, action) => {
      const key = action.payload;

      state.notifications = state.notifications.filter(
        (notification) => notification.key !== key
      );
    },
  },
});

export const {
  enqueueGenericSnackbar,
  closeGenericSnackbar,
  removeGenericSnackbar,
} = genericSnackbarSlice.actions;

export const selectGenericNotifications = (state) =>
  state.genericSnackbar.notifications;

export default genericSnackbarSlice.reducer;
