import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { VISIT_REPORT_TYPES } = API_ENDPOINTS;

export const VisitReportAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getVisitReportTypes: builder.query({
      query: () => `/visitreportbuilder`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? response
              .map(({ id }) => ({ type: VISIT_REPORT_TYPES, id }))
              .concat(VISIT_REPORT_TYPES)
          : [VISIT_REPORT_TYPES],
    }),
    getVisitReportTypeById: builder.query({
      query: (typeId) => `/visitreportbuilder/${typeId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: VISIT_REPORT_TYPES, id: response.id }, VISIT_REPORT_TYPES]
          : [VISIT_REPORT_TYPES],
    }),
    addVisitReportType: builder.mutation({
      query: (data) => ({
        url: `/visitreportbuilder`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: VISIT_REPORT_TYPES, id: result?.id },
      ],
    }),
    updateVisitReportType: builder.mutation({
      query: (data) => ({
        url: `/visitreportbuilder`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: VISIT_REPORT_TYPES, id: result.id },
      ],
    }),
    deleteVisitReportType: builder.mutation({
      query: (id) => ({
        url: `/visitreportbuilder/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: VISIT_REPORT_TYPES, id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetVisitReportTypesQuery,
  useGetVisitReportTypeByIdQuery,
  useAddVisitReportTypeMutation,
  useUpdateVisitReportTypeMutation,
  useDeleteVisitReportTypeMutation,
} = VisitReportAPIs;
