import React, { useEffect, useState } from "react";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import { showIDDSIAbbreviations } from "components/mealCard/printReport/forms.constants";

export default function DayForm({
  watch,
  list,
  errors,
  control,
  iddsiFlag,
  setValue,
  trigger,
  dirtyFields,
}) {
  const reportId = watch("reportId");

  const startingDay = watch("startingDay");
  const endingDay = watch("endingDay");

  const includeOtherMenuSelections = watch("includeOtherMenuSelections");

  const [hiddenList, setHiddenList] = useState([]);

  useEffect(() => {
    if (dirtyFields.startingDay && dirtyFields.endingDay) trigger("endingDay");
  }, [startingDay]);

  useEffect(() => {
    if (dirtyFields.endingDay && dirtyFields.startingDay)
      trigger("startingDay");
  }, [endingDay]);

  useEffect(() => {}, [iddsiFlag]);

  useEffect(() => {
    if (reportId == 8) {
      if (!includeOtherMenuSelections) {
        setHiddenList([
          { name: "onlyPrintOtherMenuSelections" },
          {
            name: "indicateOtherMenuSelections",
          },
        ]);
      } else {
        setHiddenList([]);
      }
    }
  }, [reportId, includeOtherMenuSelections]);

  return (
    <GeneratedForm
      hiddenList={hiddenList}
      list={
        list.some((item) => item.props?.name === "showIDDSIAbbreviations")
          ? [
              ...list.filter((x) => x.props?.name != "showIDDSIAbbreviations"),
              {
                ...showIDDSIAbbreviations,
                props: {
                  ...showIDDSIAbbreviations.props,
                  defaultValue: iddsiFlag,
                },
              },
            ]
          : list
      }
      errors={errors}
      control={control}
      setValue={setValue}
    />
  );
}
