import BaseContent from "components/shared/baseContent/BaseContent";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export default function ClientPortal() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location?.pathname === "/clientPortal") {
      navigate("/clientPortal/home");
    }
  }, [location?.pathname]);

  return (
    <BaseContent disableBack isStateHeaderText>
      <Outlet />
    </BaseContent>
  );
}
