import { Divider, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box } from "@mui/system";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useAddPurchasingGroupMutation,
  useGetPurchasingGroupByIdQuery,
  useUpdatePurchasingGroupMutation,
} from "../../../../../../store/apis/PurchasingGroupApis";
import { EditSkeleton } from "../../../../admin.overlays";
import { BaseButton } from "../../../../../shared/BaseButton";
import GeneratedForm from "../../../../../shared/generatedForm/GeneratedForm";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { PURCHASING_GROUP_FIELDS } from "./forms.constants";

export default function PurchasingGroupInfoForm() {
  const [
    addPurchasingGroup,
    {
      isError: isAddError,
      isSuccess: isAddSuccess,
      isLoading: addLoading,
      error: addError,
    },
  ] = useAddPurchasingGroupMutation();

  const [
    updatePurchasingGroup,
    {
      isError: isUpdateError,
      isSuccess: isUpdateSuccess,
      isLoading: updateLoading,
      error: updateError,
    },
  ] = useUpdatePurchasingGroupMutation();

  const isSuccess = isAddSuccess || isUpdateSuccess;
  const isError = isAddError || isUpdateError;
  const error = addError || updateError;
  const isSubmitting = addLoading || updateLoading;

  const [searchParams] = useSearchParams();
  const currentPurchasingGroupId = searchParams.get("id");
  const isEdit = !!currentPurchasingGroupId;

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess, isError, error]);

  const {
    data: currentPurchasingGroup,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetPurchasingGroupByIdQuery(currentPurchasingGroupId, {
    skip: !isEdit,
  });

  const isEditLoading = isEdit && currentFetching;

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (currentSuccess && !currentFetching && currentPurchasingGroup) {
      reset(currentPurchasingGroup);
    }
  }, [currentPurchasingGroup]);

  const onSubmit = (data) => {
    if (isEdit) {
      updatePurchasingGroup(data);
    } else {
      addPurchasingGroup(data);
    }
  };

  return (<>
    {isEditLoading ? (
      <EditSkeleton />
    ) : (
      <>
        <BaseButton
          text="Back To Purchasing Groups"
          startIcon={"keyboard_arrow_left"}
          onClick={() => navigate(-1)}
        />
        <Divider sx={{ marginBottom: "15px", border: "none" }} />
        <Box sx={{
          padding: "8px"
        }}>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}>
            <GeneratedForm
              control={control}
              list={PURCHASING_GROUP_FIELDS}
              errors={errors}
            />

            <Grid item md={6}>
              <BaseSubmitButton
                fullWidth
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
                text={isEdit ? "Submit" : "Create"}
              />
            </Grid>
            {isError ? (
              <Typography sx={{
                color: "error"
              }}>
                There was an error submitting the form, please try again.
              </Typography>
            ) : undefined}
          </Grid>
        </Box>
      </>
    )}
  </>);
}
