import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dietition: null,
};

export const dietitionSlice = createSlice({
  name: "dietitionId",
  initialState,
  reducers: {
    setDietitionID: (state, action) => {
      state.dietition = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDietitionID } = dietitionSlice.actions;

export default dietitionSlice.reducer;
