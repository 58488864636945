import { Chip } from "@mui/material";
import { styled } from "@mui/system";

const ChipButton = styled(Chip)(() => {
  return {
    marginRight: "8px",
    padding: "8px 16px 8px 16px",
    borderRadius: "6px",
    marginY: "4px",
    fontWeight: "600",
  };
});

export default ChipButton;
