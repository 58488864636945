import React from "react";

import ProtectedRoute from "../ProtectedRoute";
import { PAGE_ROLES } from "components/shared/constants";

import ClientPortal from "components/ClientPortal/ClientPortal";
import FacilityPortal from "components/ClientPortal/facilityPortal/FacilityPortal";
import CorporateGroupPortal from "components/ClientPortal/corporateGroupPortal/CorporateGroupPortal";
import ClientPortalHome from "components/ClientPortal/clientPortalHome/ClientPortalHome";

const { CONSULTANT_REPORTS } = PAGE_ROLES;

export const ClientPortalRoutes = {
  path: "clientPortal",
  element: (
    <ProtectedRoute allowedRoles={CONSULTANT_REPORTS}>
      <ClientPortal />
    </ProtectedRoute>
  ),
  children: [
    {
      path: "home",
      element: (
        <ProtectedRoute allowedRoles={CONSULTANT_REPORTS}>
          <ClientPortalHome />
        </ProtectedRoute>
      ),
    },
    {
      path: "facilityPortal",
      element: (
        <ProtectedRoute allowedRoles={CONSULTANT_REPORTS}>
          <FacilityPortal />
        </ProtectedRoute>
      ),
    },
    {
      path: "corporateGroupPortal",
      element: (
        <ProtectedRoute allowedRoles={CONSULTANT_REPORTS}>
          <CorporateGroupPortal />
        </ProtectedRoute>
      ),
    },
  ],
};
