import { useDrop } from "react-dnd";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Methods/useCustomDrop.md)
 */
export const useCustomDrop = (accept, statusChange) => {
  const [{ isOver }, drop] = useDrop({
    accept,
    drop: (item) => statusChange(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });
  return { isOver, drop };
};
