import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { FAQS } = API_ENDPOINTS;

export const FaqApis = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getFaqs: builder.query({
      query: () => `/${FAQS}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response ? [{ type: FAQS, id: response.id }, FAQS] : [FAQS],
    }),
  }),
});

// export hooks for calling endpoints
export const { useGetFaqsQuery } = FaqApis;
