import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  mealExtra: null,  
  //tallyMealExtra: null,
  //tallyByBuildingMealExtra: null,
  //tallyRosterMealExtra: null,
}

export const featureSlice = createSlice({
  name: 'featureInfo',
  initialState,
  reducers: {
    setMealExtra: (state, action) => {
      state.mealExtra = action.payload
    },
 /*   setTallyMealExtra: (state, action) => {
      state.tallyMealExtra = action.payload
    },
    setTallyByBuildingMealExtra: (state, action) => {
      state.tallyByBuildingMealExtra = action.payload
    },
    setRosterMealExtra: (state, action) => {
      state.rosterMealExtra = action.payload
    },
    */
  },
})

// Action creators are generated for each case reducer function
export const { setMealExtra } = featureSlice.actions
//export const { setTallyMealExtra } = featureSlice.actions
//export const { setTallyByBuildingMealExtra } = featureSlice.actions
//export const { setRosterMealExtra } = featureSlice.actions

export default featureSlice.reducer