import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Divider } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { FormProvider, useForm } from "react-hook-form";
import {
  useAddPrintReportsMutation,
  useResidentDiningareaSelectQuery,
  useResidentsSelectQuery,
} from "../../../store/apis/PrintReportApis";
import BaseSelect from "../../shared/baseForm/BaseSelect";
import BaseSubmitButton from "../../shared/baseSubmitButton/BaseSubmitButton";
import {
  DietRosters,
  MealCardReports,
  RECIPE_REPORT_TYPE,
  REPORT_CATEGORIES,
  ResidentCare,
  RostersReports,
  SnacksSupplements,
  Tallies,
} from "./reports.constants";
import GeneratedForm from "../../shared/generatedForm/GeneratedForm";
import { useGetFacilityByIdQuery } from "../../../store/apis/FacilityApis";
import { setErrorsForProperties } from "../../shared/utils/helpers";
import BaseContent from "../../shared/baseContent/BaseContent";
import { useSelector } from "react-redux";
import useCheckPermissions, {
  PERMISSION_TYPES,
} from "hooks/useCheckPermissions";
export default function PrintReport() {
  const [searchParams] = useSearchParams();
  const facilityId = searchParams.get("id");
  const [tallyItems, setTallyItems] = useState(Tallies);
  const [rosterReportItems, setRosterReportItems] = useState(RostersReports);
  const featureMealExtra = useSelector((state) => state.featureInfo.mealExtra);

  const { canView: isUserAllowed } = useCheckPermissions({
    type: PERMISSION_TYPES.MEAL_CARD,
    facilityId: facilityId,
  });

  const [
    GenerateReport,
    {
      data: report,
      isError: updateError,
      isLoading: updateLoading,
      isSuccess,
      error: errorResponse,
    },
  ] = useAddPrintReportsMutation();

  const { data: residentDiningAreas } = useResidentDiningareaSelectQuery(
    {
      facilityId: facilityId,
    },
    { skip: !isUserAllowed }
  );

  const { data: residents } = useResidentsSelectQuery(
    {
      facilityId: facilityId,
    },
    { skip: !isUserAllowed }
  );

  const { data: currentFacility } = useGetFacilityByIdQuery(facilityId, {
    skip: !isUserAllowed,
  });

  const [currentReport, setCurrentReport] = useState({});

  useEffect(() => {
    if (report && isSuccess) {
      const link = document.createElement("a");
      link.href = report?.data;
      link.setAttribute("download", `Report.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [report, isSuccess]);

  const isError = updateError;
  const isSubmitting = updateLoading;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    handleSubmit,
    watch,
    control,
    getValues,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (errorResponse) {
      setErrorsForProperties({
        setError: setError,
        responseError: errorResponse,
      });
    }
  }, [errorResponse]);

  const selectedReport = watch("Selected");

  useEffect(() => {
    if (selectedReport) {
      for (let prop in RECIPE_REPORT_TYPE) {
        if (RECIPE_REPORT_TYPE[prop]?.name === selectedReport) {
          setCurrentReport(RECIPE_REPORT_TYPE[prop]);
          break;
        }
      }
    }
  }, [selectedReport]);

  useEffect(() => {
    var setActive = featureMealExtra?.enabled == "true";
    if (setActive) {
      setTallyItems(Tallies);
      setRosterReportItems(RostersReports);
    } else if (!setActive && featureMealExtra != null) {
      setTallyItems(
        Tallies.filter((x) => !x.id?.includes(featureMealExtra?.description))
      );
      setRosterReportItems(
        RostersReports.filter(
          (x) => !x.id?.includes(featureMealExtra?.description)
        )
      );
    } else {
      setTallyItems(Tallies);
      setRosterReportItems(RostersReports);
    }
  }, [featureMealExtra]);

  const onSubmit = (data) => {
    if (data.startMenuDay && data.endMenuDay) {
      if (data.startMenuDay > data.endMenuDay) {
        setError("startMenuDay", {
          message: "Start day should be greater than End Day",
        });
        setError("endMenuDay", {
          message: "Start day should be greater than End Day",
        });
        return;
      }
    }
    if (
      currentReport?.form?.some((x) => x.props?.name === "All DiningAreas") &&
      residentDiningAreas
    ) {
      if (data?.diningAreaList?.length === 0) {
        data["diningAreaList"] = residentDiningAreas.map((d) => d.id);
        // add in the '0' dining area for any residents that don't have a dining area assigned
        data["diningAreaList"].push(0);
      }
    }
    if (
      currentReport?.form?.some((x) => x.props?.name === "All Residents") &&
      residents
    ) {
      if (data?.residentIds?.length === 0) {
        data["residentIds"] = residents.map((d) => d.id);
      }
    }
    if (data.Selected.includes("Tally")) {
      if (data.mealId === undefined) {
        data.mealId = -1;
      }
      if (data.diningAreaId === undefined) {
        data.diningAreaId = -1;
      }
    }

    GenerateReport({
      ...data,
      reportType: currentReport?.id,
      userId: 0,
      dietRosterSortOrder: currentReport?.dietRosterSortOrder || 0,
    });
  };
  // useEffect(() => {
  //   isSuccess && !updateLoading ? reset({}) : null;
  // }, [updateLoading, isSuccess]);

  return (
    (<BaseContent
      headerText={`Print Reports for ${currentFacility?.name}`}
      disableHeight={true}>
      <Box sx={{
        height: { md: "80vh", xl: "85vh" }
      }}>
        <Divider sx={{ marginBottom: "15px", border: "none" }} />
        <FormProvider>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Grid container spacing={1}>
                  <Grid item md={12}>
                    <BaseSelect
                      control={control}
                      options={MealCardReports}
                      errors={errors}
                      label={REPORT_CATEGORIES.Mealcards}
                      name={"Selected"}
                      id={"Selected"}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <BaseSelect
                      control={control}
                      options={SnacksSupplements}
                      errors={errors}
                      label={REPORT_CATEGORIES.SnacksSupplements}
                      name={"Selected"}
                      id={"Selected"}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <BaseSelect
                      control={control}
                      options={DietRosters}
                      errors={errors}
                      label={REPORT_CATEGORIES.DietRosters}
                      name={"Selected"}
                      id={"Selected"}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <BaseSelect
                      control={control}
                      options={tallyItems}
                      errors={errors}
                      label={REPORT_CATEGORIES.Tallies}
                      placeholder="Select a Tally"
                      name={"Selected"}
                      id={"Selected"}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <BaseSelect
                      control={control}
                      options={rosterReportItems}
                      errors={errors}
                      label={REPORT_CATEGORIES.RosterReports}
                      name={"Selected"}
                      id={"Selected"}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <BaseSelect
                      control={control}
                      options={ResidentCare}
                      errors={errors}
                      label={REPORT_CATEGORIES.ResidentCare}
                      name={"Selected"}
                      id={"Selected"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={7}>
                <Box
                  sx={{
                    border: "2px solid var(--tealColor)",
                    padding: "10px",
                    borderRadius: "var(--borderRadius)",
                    marginBottom: "10px"
                  }}>
                  {selectedReport ? (
                    <Grid container spacing={1}>
                      <GenerateReportForm
                        report={currentReport}
                        facilityId={facilityId}
                        watch={watch}
                        reset={reset}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        getValue={getValues}
                        selectedReport={selectedReport}
                      />
                    </Grid>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        paddingY: "50px"
                      }}>
                      <Typography
                        variant="h6"
                        component="h2"
                        sx={{
                          color: "var(--grayColor)"
                        }}>
                        {`Please select a report`}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={5} />
              <Grid item xs={7}>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <BaseSubmitButton
                    fullWidth
                    onClick={handleSubmit(onSubmit)}
                    isSubmitting={isSubmitting}
                    text={"Generate Report"}
                  />
                </Box>
                {isError ? (
                  <Typography sx={{
                    color: "error"
                  }}>
                    There was an error submitting the form, please try again.
                  </Typography>
                ) : undefined}
              </Grid>
              <Grid item xs={3} />
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </BaseContent>)
  );
}

function GenerateReportForm({
  report,
  facilityId,
  watch,
  reset,
  control,
  setValue,
  selectedReport,
  getValue,
  errors,
}) {
  const [queries, setQueries] = useState([
    {
      name: "diningAreaIds",
      query: { facilityId: facilityId },
      skip: !facilityId,
    },
    {
      name: "diningAreaList",
      query: { facilityId: facilityId },
      skip: !facilityId,
    },
    {
      name: "diningAreaId",
      query: { facilityId: facilityId },
      skip: !facilityId,
    },
    {
      name: "residentIds",
      query: { facilityId: facilityId },
      skip: !facilityId,
    },
    {
      name: "residentId",
      query: { facilityId: facilityId },
      skip: !facilityId,
    },
    {
      name: "snackDiningAreaId",
      query: { facilityId: facilityId },
      skip: !facilityId,
    },
    {
      name: "snackTimeId",
      query: { includeAll: false },
    },
  ]);

  const defaultOptions = report?.form?.map((res) => ({
    name: res?.props?.name,
    option: report?.defaultValues[res?.props?.name],
  }));

  const { data: currentFacility, isSuccess } =
    useGetFacilityByIdQuery(facilityId);

  const seasonId = watch("seasonId");
  const menuId = watch("menuId");

  useEffect(() => {
    if (seasonId) {
      setQueries([
        ...queries.filter((x) => x.name != "menuId"),
        {
          name: "menuId",
          query: { seasonId: seasonId },
        },
      ]);
    }
  }, [seasonId]);

  useEffect(() => {
    if (menuId) {
      setQueries([
        ...queries.filter(
          (x) => x.name != "endMenuDay" || x.name != "startMenuDay"
        ),
        { name: "startMenuDay", query: { menuId: menuId } },
        { name: "endMenuDay", query: { menuId: menuId } },
      ]);
    }
  }, [menuId]);

  useEffect(() => {
    if (report?.defaultValues?.showIDDSIAbbreviations !== undefined) {
      report.defaultValues.showIDDSIAbbreviations =
        currentFacility?.useIddsiterminology;
    }
    if (
      (report?.name === RECIPE_REPORT_TYPE.MealCards.name ||
        report?.name === RECIPE_REPORT_TYPE.MealCardsAllMeals.name ||
        report?.name === RECIPE_REPORT_TYPE.MealCardsAllMealsWithMenu.name ||
        report?.name === RECIPE_REPORT_TYPE.MealCardsWithMenu) &&
      currentFacility?.enableMealCardHighlighting &&
      isSuccess
    ) {
      reset({
        ...report?.defaultValues,
        Selected: selectedReport,
        enableHighlights: true,
      });
    } else {
      reset({ ...report?.defaultValues, Selected: selectedReport });
    }
  }, [report, currentFacility, isSuccess]);

  return (
    <GeneratedForm
      list={report?.form}
      queries={queries}
      getValue={getValue}
      control={control}
      setValue={setValue}
      defaultOptions={defaultOptions}
      errors={errors}
    />
  );
}
