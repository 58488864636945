import React from "react";
import ChipButton from "./styledComponents/ChipButton";
import { CircularProgress } from "@mui/material";

export default function BaseReportChip({
  id,
  typeId,
  reportTypes,
  onClick,
  loading,
}) {
  const type = reportTypes?.find((color) => color.id === typeId);
  return (
    <React.Fragment key={id}>
      <ChipButton
        size="small"
        sx={{ marginY: "0.2rem" }}
        icon={
          loading ? (
            <CircularProgress
              color="white"
              size={15}
              style={{ color: "white !important" }}
            />
          ) : null
        }
        color={type?.color || "primary"}
        label={type?.name || "Report"}
        onClick={onClick}></ChipButton>
    </React.Fragment>
  );
}
