import React from "react";
import {
  Modal,
  Box,
  Grid,
  Button,
  Typography,
  Divider,
  Fade,
} from "@mui/material";
import { Link } from "react-router-dom";
import "@fontsource/material-icons";
import "./FormModal.css";
import GeneratedForm from "../generatedForm/GeneratedForm";

export default function FormModal({
  list,
  control,
  setValue,
  errors,
  defaultValues,
  id,
  open,
  close,
  isEdit,
  onClickBtn,
  HeaderTitle,
  editData,
}) {
  return (
    (<Modal
      open={open}
      onClose={close}
      className="formModalBody"
      aria-labelledby={`delete-modal-title-${id}`}
      aria-describedby={`delete-modal-description-${id}`}>
      <Fade in={open}>
        <Box className={"formModal"}>
          <Box className="model-title">
            <Typography variant="h6" component="h2" className="modalTitle">
              {HeaderTitle}
            </Typography>
            <Link onClick={() => close(true)}>
              <span className="modalTitle material-icons">{"close"}</span>
            </Link>
          </Box>

          <Box className="formModalContent">
            <p className="modalTitle">{editData ? editData.ingredient : ""} </p>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                flexDirection: "row",
              }}>
              <GeneratedForm
                list={list}
                control={control}
                setValue={setValue}
                errors={errors}
                defaultOptions={defaultValues}
              />
            </Grid>
          </Box>

          <Divider sx={{ my: 0.5 }} />

          <Box className="formActions" sx={{
            flex: true
          }}>
            <Button
              className="cancelButton"
              variant="contained"
              onClick={() => close(false)}>
              Cancel
            </Button>
            <Button
              className="submitButton"
              variant="contained"
              onClick={() => onClickBtn()}>
              {isEdit ? "Save" : "Create"}
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>)
  );
}
