import { Roles } from "components/shared/constants";

export const visitHoursData = [
  {
    label: "Consulting Type",
    id: "consultingTypeName",
  },
  {
    label: "Future Consult",
    id: "futureConsult",
  },
  { id: "timeIn", label: "Time In" },
  { id: "timeOut", label: "Time Out" },
];
export const visitMileageData = [
  {
    label: "Consulting Type",
    id: "consultingTypeName",
  },
  {
    label: "Future Consult",
    id: "futureConsult",
  },
  { label: "Mileage", id: "quantity" },
];
export const inputFields = [
  {
    props: {
      name: "subject",
      id: "subject",
      label: "Subject",
    },
    size: 8,
  },
  {
    props: {
      name: "message",
      id: "message",
      label: "Message",
      multiline: true,
      rows: 4,
    },

    size: 8,
  },
];
export const userAllowedWithRoles = [
  Roles.Dietitian_Regional_Admin,
  Roles.Admin,
  Roles.Dietitian_Global_Admin,
];
