import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function WeightMetricsSection({ residentId, resident }) {
  return (
    <Grid
      item
      xs={12}
      sx={{
        mt: 1,
        borderRadius: "4px",
        marginLeft: 1,
        boxShadow: "0px 4px 4px 0px #00000040",
        mb: 2,
      }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}>
        <Box
          style={{ borderRadius: "4px 4px 0px 0px" }}
          sx={{
            backgroundColor: "#C4E1E2",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 16px",
          }}>
          <span style={{ fontSize: "22px", fontWeight: "700" }}>
            Weight Metrics
          </span>
          <Link
            to={`/MealCard/addResident?id=${residentId}&step=1`}
            className="newEditButton">
            <Typography
              component="span"
              sx={{
                fontWeight: 700,
              }}>
              Edit
            </Typography>
          </Link>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            padding: "8px 16px",
            display: "flex",
            justifyContent: "space-between",
          }}>
          <Grid
            item
            xs={6}
            sm={3}
            sx={{
              display: "flex",
              gap: "0.5rem",
              flexGrow: 1,
            }}>
            <Typography
              component="span"
              sx={{
                fontWeight: 700,
              }}>
              Weight:
            </Typography>
            <Typography>{resident.weight}</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            sx={{
              display: "flex",
              gap: "0.5rem",
              flexGrow: 1,
            }}>
            <Typography
              component="span"
              sx={{
                fontWeight: 700,
              }}>
              Usual Body Weight:
            </Typography>
            <Typography>{resident.usualBodyWeight}</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            sx={{
              display: "flex",
              gap: "0.5rem",
              flexGrow: 1,
            }}>
            <Typography
              component="span"
              sx={{
                fontWeight: 700,
              }}>
              Weight Sourced:
            </Typography>
            <Typography>
              {resident.usualBodyWeightSource == 1
                ? "Per Resident"
                : resident.usualBodyWeightSource == 2
                ? "Per Family"
                : "Other"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            sx={{
              display: "flex",
              gap: "0.5rem",
              flexGrow: 1,
            }}>
            <Typography
              component="span"
              sx={{
                fontWeight: 700,
              }}>
              Height:
            </Typography>
            <Typography component="span">
              {resident.heightFeet} ft. {resident.heightInches} in.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
