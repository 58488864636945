import Box from "@mui/material/Box";
import { styled } from "@mui/system";

const FlexBox = styled(Box)(
  ({
    theme,
    justifyContent = "center",
    gap,
    width = "100%",
    alignItems = "center",
  }) => {
    return {
      display: "flex",
      gap: theme.spacing(gap || 1),
      width: width,
      justifyContent: justifyContent,
      alignItems: alignItems,
    };
  }
);

export default FlexBox;
