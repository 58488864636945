import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const {CORPORATE_GROUP_USER} = API_ENDPOINTS;

export const CorporateUserAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // define an endpoint for getting CorporateUser
    getCorporateUser: builder.query({
      query: ({ corporateGroupId, searchText }) =>
        `/${CORPORATE_GROUP_USER}?corporateGroupId=${corporateGroupId}&searchText=${searchText}&skip=0&take=100`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: CORPORATE_GROUP_USER,
                id,
              })),
              CORPORATE_GROUP_USER,
            ]
          : [CORPORATE_GROUP_USER];
      },
    }),
    getCorporateUserById: builder.query({
      query: (beverageId) =>
        `/${CORPORATE_GROUP_USER}/${beverageId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              { type: CORPORATE_GROUP_USER, id: response.id },
              CORPORATE_GROUP_USER,
            ]
          : [CORPORATE_GROUP_USER],
    }),
    deleteCorporateUser: builder.mutation({
      query: (id) => ({
        url: `/${CORPORATE_GROUP_USER}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: CORPORATE_GROUP_USER, id: arg.id },
      ],
    }),
    addCorporateUser: builder.mutation({
      query: (data) => ({
        url: `/${CORPORATE_GROUP_USER}?corporateGroupId=${data.corporateGroupId}&userIdentifier=${data.userIdentifier}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: CORPORATE_GROUP_USER, id: result?.id },
      ],
    }),
    refreshCorporateUser: builder.mutation({
      query: ({ corporateGroupId, userId }) => ({
        url: `/${CORPORATE_GROUP_USER}/Refresh?corporateGroupId=${corporateGroupId}&userId=${userId}`,
        method: "PUT",
      }),
      invalidatesTags: (result) => [
        { type: CORPORATE_GROUP_USER, id: result?.id },
      ],
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetCorporateUserQuery,
  useGetCorporateUserByIdQuery,
  useDeleteCorporateUserMutation,
  useAddCorporateUserMutation,
  useRefreshCorporateUserMutation,
} = CorporateUserAPIs;
