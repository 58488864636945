import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import BaseSelect from "../../../../../../shared/baseForm/BaseSelect";
import BaseCheckBox from "../../../../../../shared/baseForm/BaseCheckBox";
// import BaseInput from "../../../../../../shared/baseForm/BaseInput";
import { useGetEHRProvidersSelectListQuery } from "../../../../../../../store/apis/GenericApis";
import BaseDatePicker from "components/shared/baseForm/BaseDatePicker";

export default function PermissionFields({ control, errors }) {
  const { data: ehrproviders, isLoading: ehrprovidersLoading } =
    useGetEHRProvidersSelectListQuery();

  return (
    (<Grid container spacing={2}>
      <Grid item md={12}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography variant="p" component={"p"} sx={{
              fontWeight: 600
            }}>
              Active
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="p" component={"p"} sx={{
              fontWeight: 600
            }}>
              Effective
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="p" component={"p"} sx={{
              fontWeight: 600
            }}>
              Terminated
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="p"
              component={"p"}
              sx={{
                fontWeight: 600
              }}></Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <BaseCheckBox
              control={control}
              label={"RD Consulting"}
              name={"isConsultingCustomer"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <BaseDatePicker
              control={control}
              label={"Effective Date"}
              name={"effectiveDate"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <BaseDatePicker
              control={control}
              label={"Termination Date"}
              name={"termDate"}
              errors={errors}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <BaseCheckBox
              control={control}
              label={"CDM Consulting"}
              name={"isCdmconsultingCustomer"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <BaseDatePicker
              control={control}
              label={"Effective Date"}
              name={"cdmeffectiveDate"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <BaseDatePicker
              control={control}
              label={"Termination Date"}
              name={"cdmtermDate"}
              errors={errors}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <BaseCheckBox
              control={control}
              label={"Menus"}
              name={"isMenuCustomer"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <BaseDatePicker
              control={control}
              label={"Effective Date"}
              name={"menuEffectiveDate"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <BaseDatePicker
              control={control}
              label={"Termination Date"}
              name={"menuTermDate"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <Box
              sx={{
                flexDirection: "column",
                display: "flex",
                alignItems: "center"
              }}>
              <BaseCheckBox
                control={control}
                label={"Web"}
                name={"isMenuWebBased"}
                errors={errors}
              />
              <BaseCheckBox
                control={control}
                label={"Printed"}
                name={"isMenuPrinted"}
                errors={errors}
              />
              <BaseCheckBox
                control={control}
                label={"PRN Menus"}
                name={"isRecipeOnlyCustomer"}
                errors={errors}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <BaseCheckBox
              control={control}
              label={"MealCard"}
              name={"isMealCardCustomer"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <BaseDatePicker
              control={control}
              label={"Effective Date"}
              name={"mealcardEffectiveDate"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <BaseDatePicker
              control={control}
              label={"Termination Date"}
              name={"mealcardTermDate"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <Box
              sx={{
                flexDirection: "column",
                display: "flex",
                alignItems: "center"
              }}>
              <BaseCheckBox
                control={control}
                label={"Web"}
                name={"isMealCardWebBased"}
                errors={errors}
              />
              {/* <BaseCheckBox
                control={control}
                label={"CD"}
                name={"isMealCardCdbased"}
                errors={errors}
              /> */}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <BaseCheckBox
              control={control}
              label={"TableSide"}
              name={"isTablesideCustomer"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <BaseDatePicker
              control={control}
              label={"Effective Date"}
              name={"tablesideEffectiveDate"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <BaseDatePicker
              control={control}
              label={"Termination Date"}
              name={"tablesideTermDate"}
              errors={errors}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item md={12}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <BaseCheckBox
              control={control}
              label={"Guidelines & Procedures"}
              
              name={"hasGuidelinesAndProcedures"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <BaseInput
              control={control}
              label={"Order Date"}
              
              name={"guidelinesAndProceduresOrderDate"}
              errors={errors}
            
            />
          </Grid>
        </Grid>
      </Grid> */}
      {/* <Grid item md={12}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <BaseCheckBox
              control={control}
              label={"Diet Manuals"}
              
              name={"hasDietManual"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <BaseInput
              control={control}
              label={"Order Date"}
              
              name={"dietManualOrderDate"}
              errors={errors}
           
            />
          </Grid>
        </Grid>
      </Grid> */}
      <Grid item md={12}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <BaseSelect
              name="ehrproviderId"
              id="ehrproviderId"
              label="EHR Integration"
              control={control}
              errors={errors}
              loading={ehrprovidersLoading}
              options={ehrproviders || []}
            />
          </Grid>
          <Grid item xs={3}>
            <BaseDatePicker
              control={control}
              label={"Effective Date"}
              name={"ehrEffectiveDate"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <BaseDatePicker
              control={control}
              label={"Termination Date"}
              name={"ehrTermDate"}
              errors={errors}
            />
          </Grid>
          <Grid item xs={3}>
            <BaseCheckBox
              name="allowUpdatesOfNonEhrresidents"
              id="allowUpdatesOfNonEhrresidents"
              label="Update Non-EHR Residents"
              control={control}
              errors={errors}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>)
  );
}
