import Box from "@mui/material/Box";
import { styled } from "@mui/system";

const DataGridContainer = styled(Box)(({ theme }) => {
  return {
    height: "100%",
    flexGrow: 1,
    overflowY: "auto",
    pb: theme.spacing(1),
  };
});

export default DataGridContainer;
