import { REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "../../../../shared/generatedForm/GeneratedForm";

export const searchFields = [
  {
    props: {
      name: "newVendorId",
      id: "newVendorId",
      label: "Vendor",
    },
    validationProps: {
      required: REQUIRED_ERROR("Vendor"),
    },
    size: 8,
    type: FORM_TYPES.AutocompleteAPI,
    endpoint: "getVendorSelectList",
  },
];
