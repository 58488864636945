import React, { useState } from "react"; //{ useEffect }
import "../Ingredient.css";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { useGetIngredientAllergiesQuery } from "../../../../../../store/apis/IngredientAPIs";
import { Typography, Button } from "@mui/material";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "../../../../../shared/baseSearch/BaseSearch";
import { BaseButton } from "../../../../../shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { HealthAndSafety, KeyboardArrowLeft } from "@mui/icons-material";

export default function IngredientAllergy() {
  const SEARCH_PARAM = "search";
  const [debouncedValue, setDebouncedValue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchValue, setSearchValue] = useState("");
  const ingredientId = searchParams.get("id");
  const ingredientName = searchParams.get("name" || "");

  const {
    data: allergyList,
    isError: isError,
    isFetching: isLoading,
  } = useGetIngredientAllergiesQuery(
    {
      ingredientId: ingredientId,
      searchTerm: debouncedValue || "",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const navigate = useNavigate();

  const AllergyListColumns = [
    {
      field: "allergyFoodName",
      headerName: "Allergy",
      flex: 3,
      minWidth: 400,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={allergyList}
            hasDelete={true}
            deleteProps={{
              entityName: params.row["allergyFoodName"],
              apiPath: "deleteIngredientAllergy",
              title: "Ingredient Allergy",
            }}
          />
        );
      },
    },
  ];

  return (<>
    <FlexBox
      justifyContent={"space-between"}
      my={"8px"}
      flexWrap={{ xs: "wrap", md: "nowrap" }}>
      <BaseButton
        text="Back To Ingredients"
        sx={{ margin: "0px !important" }}
        startIcon={<KeyboardArrowLeft />}
        onClick={() => navigate(-1)}
      />
      <Button
        component={Link}
        to={`addIngredientAllergy?ingredientId=${ingredientId}&name=${ingredientName}`}
        color={"primary"}
        variant="contained"
        endIcon={<HealthAndSafety />}>
        Add Allergy
      </Button>
    </FlexBox>
    <Typography variant="h5" className="userFacilityTitle" sx={{
      color: "primary"
    }}>
      Allergies for {ingredientName}
    </Typography>
    <FlexBox
      justifyContent={"space-between"}
      flexWrap={{ xs: "wrap", md: "nowrap" }}>
      <BaseSearch
        placeholder="Search..."
        disableUnderline={true}
        type="text"
        SEARCH_PARAM={SEARCH_PARAM}
        value={searchValue}
        setSearchValue={setSearchValue}
        setDebouncedValue={setDebouncedValue}
        setSearchParams={setSearchParams}
        isNameID={true}
        name={ingredientName}
        Id={ingredientId}
      />
    </FlexBox>
    <BaseDataGrid
      rows={allergyList}
      columns={AllergyListColumns}
      loading={isLoading}
      error={isError}
    />
  </>);
}
