import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import styles from "../Resource.module.css";
import classNames from "classnames";
export const DropdownUI = ({
  item,
  index,
  isActiveId,
  setIsActiveId,
  isDietScreen,
}) => {
  return (
    <>
      <Box
        key={index}
        className={classNames(styles.communityInfo, styles.isresourceBarRow, {
          [styles.iscommunityInfo]: item.id === isActiveId,
        })}
        onClick={() => setIsActiveId(item.id)}>
        <Typography variant="h6" component="h4" className={styles.title}>
          {isDietScreen ? "Section " : "0"}
          {item.id} - {item.optionName}
        </Typography>
        <span className={`${styles["material-icons"]} material-icons`}>
          {isActiveId === item.id ? "arrow_drop_up" : "arrow_drop_down"}
        </span>
      </Box>
      {isActiveId === item.id && (
        <Box
          className={`${styles.communityContainer} ${styles.resourceBarContainer}`}>
          {item?.DropdownValues?.map((childItem, ChildIndex) => {
            return (
              <Link
                key={ChildIndex}
                to={`${childItem?.url}`}
                target={"_blank"}
                style={{ textDecoration: "none" }}>
                <Typography
                  variant="p"
                  component="p"
                  className={styles.dropdownBox}>
                  {childItem?.name}
                </Typography>
              </Link>
            );
          })}
        </Box>
      )}
    </>
  );
};
