import { useState } from "react";

export default function useModal(intialValue = false) {
  const [isOpen, setIsOpen] = useState(intialValue);

  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);

  return { isOpen, setIsOpen, closeModal, openModal };
}
