export const UserMenuElements = [
  {
    Name: "User Setup",
    Link: "/admin/usermenu/usersetup",
    IsActive: false,
  },
  {
    Name: "Manage General Access Documents",
    Link: "/admin/usermenu/managedocuments",
    IsActive: false,
  },
  {
    Name: "System Messages",
    Link: "/admin/usermenu/systemmessages",
    IsActive: false,
  },
];
