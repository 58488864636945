import { REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "../../../../../shared/generatedForm/GeneratedForm";

export const VendorCostFields = [
  {
    props: {
      name: "vendorId",
      id: "vendorId",
      label: "Vendor",
    },
    validationProps: {
      required: REQUIRED_ERROR("Vendor"),
    },
    size: 6,
    type: FORM_TYPES.AutocompleteAPI,
    endpoint: "getVendorSelectBox",
  },
  {
    props: {},
    type: FORM_TYPES.Offset,
    size: 6,
  },
  {
    props: {
      name: "costPerServing",
      id: "costPerServing",
      label: "Cost Per Serving",
      type: "number",
      unit: "$",
      unitPosition: "start",
      min: 0.001,
      step: "any",
      max: 99999,
    },
    validationProps: {
      min: {
        value: 0.001,
        message: "Cost must be greater than 0.",
      },
      max: {
        value: 99999,
        message: "Cost must be less than 6 digits.",
      },
      pattern: {
        value: /^\d+(\.\d{0,3})?$/,
        message: "Cost must be a number with up to 3 decimal points.",
      },
      required: REQUIRED_ERROR("Cost Per Serving"),
    },
    size: 6,
  },
  {
    props: {},
    type: FORM_TYPES.Offset,
    size: 6,
  },
];

export const vendorCostDefaults = {
  recipeId: "",
  vendorId: "",
  costPerServing: 0,
};
