import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Button,
  Typography,
  Grid,
  Fade,
  Skeleton,
  Stack,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import BaseSelect from "../../../shared/baseForm/BaseSelect";
import BaseCheckBox from "../../../shared/baseForm/BaseCheckBox";
import { useGetSageTimeMileageReportMutation } from "../../../../store/apis/ReportAPIs";
// import { DownloadLinkHelper } from "../../../shared/utils/helpers";
import LinearProgress from "@mui/material/LinearProgress";
import { useGetDiningRDGroupSelectListQuery } from "../../../../store/apis/GenericApis";
import BaseDatePicker from "components/shared/baseForm/BaseDatePicker";
import dayjs from "dayjs";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { BlobHelper } from "components/shared/utils/helpers";
import { BaseButton } from "components/shared/BaseButton";

export default function ExportTimeMileage() {
  const { data: groupList, isFetching: groupListLoading } =
    useGetDiningRDGroupSelectListQuery();

  const [
    getReport,
    {
      isError: isError,
      isSuccess: isSuccess,
      isLoading: isLoading,
      data: report,
    },
  ] = useGetSageTimeMileageReportMutation();

  const [readyToMark, setReadyToMark] = useState(false);

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    control,
    watch,
    clearErrors,
    formState: { errors },
  } = methods;

  const diningRDGroupId = watch("diningRDGroupId");
  const startDate = watch("reportStartDate");
  const endDate = watch("reportEndDate");
  useEffect(() => {
    if (
      dayjs(startDate).format("MM-DD-YYYY") <=
      dayjs(endDate).format("MM-DD-YYYY")
    ) {
      clearErrors("reportStartDate");
      clearErrors("reportEndDate");
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (report && isSuccess) {
      if (report?.data?.file) {
        BlobHelper(report.data.file, report.data.filename, report.data.type);
        setReadyToMark(true);
      } else {
        setReadyToMark(false);
      }
    }
  }, [report, isSuccess]);

  useEffect(() => {
    if (readyToMark) {
      setReadyToMark(false);
    }
  }, [diningRDGroupId]);

  const onSubmit = (data) => {
    data.markAsExported = false;
    getReport({
      ...data,
      reportEndDate: dayjs(data?.reportEndDate).isValid()
        ? dayjs(data?.reportEndDate).format("YYYY-MM-DD")
        : null,
      reportStartDate: dayjs(data?.reportStartDate).isValid()
        ? dayjs(data?.reportStartDate).format("YYYY-MM-DD")
        : null,
    });
  };

  const onMarkExported = (data) => {
    data.markAsExported = true;
    getReport(data);
  };

  return (<>
    <Box sx={{
      marginTop: "10px"
    }}>
      <BaseButton
        text="Back To Billing Reports"
        startIcon={"keyboard_arrow_left"}
        onClick={() => navigate(-1)}
      />
    </Box>
    <Box sx={{
      marginLeft: "10px"
    }}>
      <Typography
        variant="h5"
        className="userFacilityTitle"
        sx={{
          color: "primary",
          marginTop: "10px"
        }}>
        Export Time/Mileage to Sage
      </Typography>
    </Box>
    <React.Fragment>
      <FormProvider>
        <form>
          <Grid container spacing={2} style={{ marginTop: "24px" }}>
            <Grid item xs={5}>
              <BaseDatePicker
                label={"Start Date"}
                name={"reportStartDate"}
                control={control}
                errors={errors}
                validationProps={{
                  max: {
                    value: endDate ? new Date(endDate) : null,
                    message: "Start Date must be before End Date",
                  },
                  required: REQUIRED_ERROR("Start Date"),
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <BaseDatePicker
                label={"End Date"}
                name={"reportEndDate"}
                control={control}
                errors={errors}
                validationProps={{
                  min: {
                    value: startDate ? new Date(startDate) : null,
                    message: "End Date must be after Start Date",
                  },
                  required: REQUIRED_ERROR("End Date"),
                }}
              />
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={5}>
              {groupListLoading ? (
                <Skeleton />
              ) : (
                <BaseSelect
                  label="DiningRD Group"
                  name="diningRDGroupId"
                  id="diningRDGroupId"
                  control={control}
                  options={groupList}
                  errors={errors}
                  defaultValue={groupList[0]}
                  validationProps={{
                    required: REQUIRED_ERROR("DiningRD Group"),
                  }}
                />
              )}
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={5}>
              <BaseCheckBox
                control={control}
                label={"Include Zero Amount Values"}
                id="includeZeroAmount"
                name="includeZeroHours"
                errors={errors}
              />
            </Grid>
            <Grid item xs={8}></Grid>
          </Grid>

          <Stack spacing={2} sx={{ marginTop: "10px" }}>
            {/* sx={{ display: "flex", flexDirection: "row", pt: 2 }} */}
            {isLoading ? (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  disabled
                  onClick={handleSubmit(onSubmit)}>
                  Export Time & Mileage
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}>
                  Export Time & Mileage
                </Button>
              </div>
            )}
            <Fade
              in={isLoading}
              unmountOnExit
              sx={{ marginTop: "10px", marginBottom: "10px" }}>
              <LinearProgress />
            </Fade>
            {isError ? (
              <Typography sx={{
                color: "error"
              }}>
                Error occured during report generation. Please try again
                later.
              </Typography>
            ) : undefined}
            {readyToMark ? (
              <div>
                <div>
                  <Typography sx={{
                    color: "error"
                  }}>
                    If the reports look correct, you should mark these records
                    as &apos;Exported&apos; so they don&apos;t show up on the
                    report next time.
                  </Typography>
                </div>
                <div>
                  <Button
                    sx={{ marginTop: "10px" }}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(onMarkExported)}>
                    Mark These Records as Exported
                  </Button>
                </div>
              </div>
            ) : undefined}
          </Stack>
        </form>
      </FormProvider>
    </React.Fragment>
  </>);
}
