import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { MealCardSetupElements } from "./mealcardsetup.constants";
import { Card } from "@mui/material";
import AdminTabs from "../../shared/AdminTabs";
import { useSelector } from "react-redux";
import OutletContainer from "components/shared/styledComponents/OutletContainer";

export default function MealCardSetup() {
  const featureMealExtra = useSelector((state) => state.featureInfo.mealExtra);
  const [menuItems, setMenuItems] = useState(MealCardSetupElements);
  useEffect(() => {
    var setActive = featureMealExtra?.enabled == "true";
    if (setActive) {
      setMenuItems(MealCardSetupElements);
    } else if (!setActive) {
      setMenuItems(
        MealCardSetupElements.filter(
          (x) => x.Name !== featureMealExtra?.description
        )
      );
    }
  }, [featureMealExtra]);

  return (
    <>
      <AdminTabs Data={menuItems} />
      <Card
        id="mealcard-setup-card"
        sx={{
          flexGrow: 1,
          display: "flex",
          overflow: "hidden",
          flexDirection: "column",
        }}>
        <OutletContainer id="mealcard-setup-outlet">
          <Outlet />
        </OutletContainer>
      </Card>
    </>
  );
}
