import React, { useEffect, useState } from "react";
import GeneratedForm from "../../../../../../shared/generatedForm/GeneratedForm";
import { Box, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { sendReportFields } from "./form.constants";
import BaseDataGrid from "../../../../../../shared/baseDataGrid/BaseDataGrid";
import {
  useGetVisitDetailsQuery,
  useGetVisitReportDetailsByIdQuery,
  // useSubmitReportToHealthTechMutation,
} from "../../../../../../../store/apis/ConsultantApis";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SCREEN_CONSTANT } from "../../../constants";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { useSubmitReportsMutation } from "store/apis/DietitianAPis";
import { setErrorsForProperties } from "components/shared/utils/helpers";
import { WarningText } from "components/shared/WarningText";
import CreateBaseColumns from "components/shared/CreateBaseColumn";

export const SendReportDetails = () => {
  // states
  const [searchParams] = useSearchParams();
  const [selectedRows, setSelectedRows] = useState([]);
  const [isReportError, setIsReportError] = useState(false);
  const visitPlanId = searchParams.get(SCREEN_CONSTANT.VISIT_PLAN_ID_QUERY);
  const navigate = useNavigate();
  // useForm Functionality Constant
  const method = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  // destructuring of method
  const {
    control,
    setValue,
    reset,
    setError,
    handleSubmit,
    formState: { errors },
  } = method;

  // Api Section
  // Api use to get data on the basis of visitPlanData
  const { data: visitData } = useGetVisitDetailsQuery(
    visitPlanId,

    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { id } = visitData || [];
  // api to get reportList to show on frontEnd
  const { data: reportDetails } = useGetVisitReportDetailsByIdQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  // destructing Data
  const { reportList } = reportDetails || [];

  // Mutation
  // submitReport function used to submit report Data
  const [
    submitReport,
    { error: submitReportError, isLoading, isSuccess: submitReportSuccess },
  ] = useSubmitReportsMutation();
  // used to show existing data to edit
  useEffect(() => {
    reset(reportDetails);
  }, [reportDetails]);

  const checkReportList = (data) => {
    setIsReportError(false);
    setSelectedRows((prevSelectedRows) => {
      const idExists = prevSelectedRows.some(
        (item) => item.visitReportId === data.visitReportId
      );

      return idExists
        ? prevSelectedRows.filter(
            (item) => item.visitReportId !== data.visitReportId
          )
        : [...prevSelectedRows, data];
    });
  };
  // used to print api response message
  useEffect(() => {
    if (submitReportError) {
      setErrorsForProperties({
        setError: setError,
        responseError: submitReportError,
      });
    }
  }, [submitReportError]);
  useEffect(() => {
    if (submitReportSuccess) {
      navigate(`/Consultant/MyRosters/VisitDetails?visitPlanId=${visitPlanId}`);
    }
  }, [submitReportSuccess]);
  // function used to call submitReport api
  const onSubmit = (data) => {
    if (selectedRows.length > 0) {
      setIsReportError(false);
      let updatedReports = selectedRows.map((report) => ({
        ...report,
        sendYN: true,
      }));

      const newObject = {
        sendTo: data.sendTo,
        subject: data.subject,
        message: data.message === null ? " " : data.message,
        messageForHTIOffice: "messageForHTIOffice",
        visitId: data.visitId,
        highPriority: true,
        finished: data.finished,
        reportList: updatedReports,
      };
      submitReport(newObject);
    } else {
      setIsReportError(true);
    }
  };
  // column used for dataGrid
  const sentReportColumns = [
    {
      field: "Select",
      headerName: "Select",
      // minWidth: 30,
      renderCell: (params) => {
        return (
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  // checked={params?.row?.isInList}
                  onClick={() => {
                    checkReportList(params.row);
                  }}
                />
              }
            />
          </div>
        );
      },
    },
    {
      field: "reportType",
      minWidth: 200,
      flex: 1,
      headerName: "Report Type",
      // cellClassName: "cellCenterAlign",
      // headerAlign: "center",
    },
    {
      field: "reportDescription",
      minWidth: 200,
      flex: 1,
      headerName: "Description",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
  ];
  return (
    <>
      <Grid container spacing={1.5} sx={{ width: "40%" }}>
        <GeneratedForm
          list={sendReportFields}
          control={control}
          setValue={setValue}
          errors={errors}
        />
      </Grid>
      <Box
        sx={{
          marginY: "5px",
          width: "70%",
        }}>
        <BaseDataGrid
          rows={reportList || []}
          columns={CreateBaseColumns(sentReportColumns)}
          height={"100%"}
          getRowHeight={() => "auto"}
          getRowId={(row) => row.visitReportId}
          sx={{
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: "8px",
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "15px",
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
              py: "22px",
            },
          }}
        />
      </Box>
      <Box
        sx={{
          width: "70%",
          display: "flex",
          justifyContent: isReportError ? "space-between" : "flex-end",
          marginTop: "5px",
        }}>
        {isReportError ? (
          <Box>
            <WarningText
              margin={"0px"}
              color={"red"}
              text={"Please Select at least one report"}
            />
          </Box>
        ) : null}
        <BaseSubmitButton
          isSubmitting={isLoading}
          text="Send Selected Report"
          onClick={handleSubmit(onSubmit)}
        />
      </Box>
    </>
  );
};
