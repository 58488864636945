import React from "react";
import metadata from "../../metadata.json";

export default function About() {

    return (
        <>

        <h5>{`Version ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}</h5>

        </>
    )

}