import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  useGetUserCredentialsQuery,
  useUpdateUserCredentialsMutation,
  useLazyGenerateReportPasswordQuery,
} from "../../store/apis/UserApis";
import { EditSkeleton } from "../admin/admin.overlays";
import { Box, Grid, Typography, FormHelperText, Button } from "@mui/material";
import BaseInput from "../shared/baseForm/BaseInput";
import BaseSubmitButton from "../shared/baseSubmitButton/BaseSubmitButton";
import { useSnackbar } from "notistack";
import BaseContent from "../shared/baseContent/BaseContent";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { useIsAuthenticated } from "@azure/msal-react";
import BaseBlockerModal from "components/shared/blockerModal/BaseBlockerModal";
import useMsalAccount from "utils/useMsalAccount";

export default function MyCredentials() {
  const { userId: currentUserId } = useMsalAccount();

  // This authentication check is needed because of an infinite loading bug in this page.
  // To reproduce go to myCredentials page and refresh.
  const isAuthenticated = useIsAuthenticated();
  const [isDietitian, setIsDietitian] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const [getPassword, userPassword] = useLazyGenerateReportPasswordQuery();

  const {
    data: userCredentials,
    isFetching: isFetching,
    isSuccess: isSuccess,
    // isError: fetchError,
  } = useGetUserCredentialsQuery(currentUserId, {
    skip: !currentUserId || !isAuthenticated,
  });

  const [
    updateCredentials,
    {
      isError: updateError,
      isSuccess: updateSuccess,
      isLoading: updateLoading,
      error: updateErrorMessage,
    },
  ] = useUpdateUserCredentialsMutation();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isSuccess && !isFetching && userCredentials) {
      reset(userCredentials);
      setIsDietitian(userCredentials?.htiEmployee);
    }
  }, [userCredentials]);
  useEffect(() => {
    if (userPassword && userPassword.data) {
      setValue("dietitianPassword", userPassword.data);
    }
  }, [userPassword]);

  const updateCredentialApi = (data, removeTag = false) => {
    const data2 = new FormData();
    const imageFile = uploadedFile || null;
    data2.append("id", data?.id);
    data2.append("dietitianName", data?.dietitianName);
    data2.append("credentials", data?.credentials);
    data2.append("dietitianPassword", data?.dietitianPassword);
    data2.append(
      "rdSignatureFileURL",
      removeTag ? null : data?.rdSignatureFileURL
    );
    data2.append("file", removeTag ? null : imageFile);

    updateCredentials(data2);
  };
  const onSubmit = (data) => {
    updateCredentialApi(data);
  };

  const handleFileUpload = (e) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    setUploadedFile(file);
    const { name } = file;
    setFileName(name);
  };

  const removeSignature = (data) => {
    updateCredentialApi(data, true);
    setUploadedFile(null);
    setFileName("");
  };

  useEffect(() => {
    if (updateSuccess) {
      enqueueSnackbar(`Credentials Saved`, { variant: "success" });
    }
  }, [updateSuccess]);

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isDirty },
    setValue,
  } = methods;

  return (<>
    <BaseBlockerModal isDirty={isDirty || !!uploadedFile} />
    <BaseContent headerText={"My Credentials"} disableBack={true}>
      {isFetching ? (
        <EditSkeleton />
      ) : (
        <>
          <FormProvider>
            <form>
              <Grid container spacing={2} style={{ padding: "10px" }}>
                <Grid item xs={6}>
                  <BaseInput
                    label="Consultant Name"
                    name={"dietitianName"}
                    placeholder="Consultant Name"
                    control={control}
                    errors={errors}
                    validationProps={{
                      required: REQUIRED_ERROR("Consultant Name"),
                    }}
                  />
                  {errors && (
                    <FormHelperText error>
                      {errors?.name?.message}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={6}>
                  <BaseInput
                    label="Credentials"
                    name={"credentials"}
                    placeholder="Credentials"
                    control={control}
                    errors={errors}
                    multiline
                    rows={4}
                  />
                  {errors && (
                    <FormHelperText error>
                      {errors?.name?.message}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6} />
                {isDietitian ? (
                  <>
                    <Grid item xs={4}>
                      <BaseInput
                        label="Encryption Password"
                        name={"dietitianPassword"}
                        size={"small"}
                        placeholder="Encryption Password"
                        control={control}
                        errors={errors}
                        disabled={true}
                      />
                      {errors && (
                        <FormHelperText error>
                          {errors?.name?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        sx={{ textTransform: "none" }}
                        onClick={() => getPassword()}>
                        Generate Password
                      </Button>
                    </Grid>
                  </>
                ) : undefined}

                <Grid item xs={6}>
                  <Button
                    fullWidth
                    component="label"
                    variant="outlined"
                    color={"primary"}
                    startIcon={
                      <span className="material-icons">
                        {uploadedFile ? "description" : "border_clear"}
                      </span>
                    }
                    sx={{ marginRight: "1rem", textTransform: "none" }}>
                    {uploadedFile
                      ? `Uploaded Signature Image: `
                      : `Upload Signature Image`}
                    <input
                      type="file"
                      accept=".png, .jpg, ,.jpeg, .bmp"
                      hidden
                      onChange={handleFileUpload}
                    />
                    {fileName}
                  </Button>
                </Grid>
                <Grid item xs={6} />
                {userCredentials?.rdSignatureFileURL?.includes("http") ? (
                  <>
                    <Grid item xs={6}>
                      <Box
                        component="img"
                        sx={{
                          width: "50%",
                          marginLeft: "20px",
                          marginTop: "20px",
                          marginBottom: "10px",
                        }}
                        alt="Signature image"
                        src={userCredentials?.rdSignatureFileURL}
                      />
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={4}>
                      <Button onClick={handleSubmit(removeSignature)}>
                        Remove Signature Image
                      </Button>
                    </Grid>
                  </>
                ) : undefined}
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  pt: 2,
                  marginLeft: "10px",
                }}>
                <BaseSubmitButton
                  onClick={handleSubmit(onSubmit)}
                  isSubmitting={updateLoading}
                  text="Save"
                />
              </Box>
              {updateError ? (
                <Typography
                  sx={{
                    color: "error",
                    marginLeft: "10px"
                  }}>
                  {updateErrorMessage?.data?.messages}
                </Typography>
              ) : undefined}
            </form>
          </FormProvider>
        </>
      )}
    </BaseContent>
  </>);
}
