import React, { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import {
  useAddRecipeDietMutation,
  useUpdateRecipeDietMutation,
  useGetAlternativeRecipeSearchListQuery,
} from "../../../../../../store/apis/PlateFulRecipeDietApis";
import { useGetDietSelectBoxQuery } from "../../../../../../store/apis/DietsApi";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Card, Grid, Typography, Button, Link } from "@mui/material";
import BaseSelect from "../../../../../shared/baseForm/BaseSelect";
import BaseAutoComplete from "../../../../../shared/baseForm/BaseAutoComplete";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../../../../../store/slices/authSlice/AuthSlice";
import useMsalAccount from "utils/useMsalAccount";

export function AddRDiet() {
  const facilityId = useSelector(selectFacilityId);
  const { userId } = useMsalAccount();

  const [
    addRecipeDiet,
    { isSuccess: addSuccess, isError: addError, isLoading: addLoading },
  ] = useAddRecipeDietMutation();

  const [
    updateRecipeDiet,
    {
      isSuccess: updateSuccess,
      isError: updateError,
      isLoading: updateLoading,
    },
  ] = useUpdateRecipeDietMutation();

  const isSuccess = addSuccess || updateSuccess;
  const isError = addError || updateError;

  const isSubmitting = addLoading || updateLoading;
  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get("recipeId");

  const location = useLocation();
  const recipeDietData = location.state?.data;
  const currentId = location.state?.data?.id;
  const isEdit = !!currentId;

  const [defaultRecipeType, setDefaultRecipeType] = useState([]);

  useEffect(() => {
    if (location.state && recipeDietData) {
      reset(recipeDietData);
      setDefaultRecipeType([
        {
          id: recipeDietData.alternateRecipeId,
          label: recipeDietData.alternateRecipeName,
        },
      ]);
    }
  }, [recipeDietData]);

  const { data: diets, isLoading: loading } = useGetDietSelectBoxQuery(" ", {
    refetchOnMountOrArgChange: true,
  });

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    if (isEdit) {
      updateRecipeDiet({
        dietId: data.dietId,
        alternateRecipeId: data.alternateRecipeId,
        recipeId: parseInt(recipeId),
        id: currentId,
      });
    } else {
      addRecipeDiet({
        dietId: data.dietId,
        alternateRecipeId: data.alternateRecipeId,
        recipeId: parseInt(recipeId),
      });
    }
  };

  useEffect(() => {
    if (!isError && isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);

  const [debouncedSearch, setDebouncedSearch] = useState("");
  const { data: searchData, isLoading: isLoadingSearch } =
    useGetAlternativeRecipeSearchListQuery(
      {
        userId: userId,
        facilityId: facilityId,
        searchText: debouncedSearch,
      },
      {
        skip: !debouncedSearch,
        refetchOnMountOrArgChange: true,
      }
    );

  const handleSearch = useMemo(
    () =>
      debounce((query) => {
        setDebouncedSearch(query.replace("&", "%26"));
      }, 400),
    []
  );

  const filterOptions = (options, { inputValue }) => {
    const searchValues = inputValue.split(" ");

    const filtered = options.filter((v) =>
      searchValues.every((searchValue) =>
        v.label.toLowerCase().includes(searchValue.toLowerCase())
      )
    );

    return filtered;
  };

  return (<>
    <Button
      component={Link}
      color={"primary"}
      className="baseButton"
      variant="contained"
      onClick={() => navigate(-1)}
      startIcon={<span className="material-icons">keyboard_arrow_left</span>}>
      {"Back"}
    </Button>
    <Card sx={{ minHeight: "70vh" }}>
      <Box sx={{
        padding: "20px"
      }}>
        <FormProvider>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <BaseSelect
                  label="Diet"
                  name="dietId"
                  id="dietId"
                  control={control}
                  options={diets || []}
                  errors={errors}
                  loading={loading}
                />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />
              <Grid item xs={5}>
                <BaseAutoComplete
                  formSelectProps={{
                    props: {
                      name: "alternateRecipeId",
                      id: "alternateRecipeId",
                      label: "Alternate Recipe	",
                    },
                    validationProps: {},
                  }}
                  control={control}
                  errors={errors}
                  options={searchData || []}
                  loading={isLoadingSearch}
                  defaultOptions={defaultRecipeType}
                  handleSearch={handleSearch}
                  filterOptions={filterOptions}
                />
              </Grid>

              <Grid item xs={3} />
              <Grid item xs={3} />

              <Grid item xs={5}>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <BaseSubmitButton
                    fullWidth
                    onClick={handleSubmit(onSubmit)}
                    isSubmitting={isSubmitting}
                    text={isEdit ? "Submit" : "Create"}
                  />
                </Box>
                {isError ? (
                  <Typography sx={{
                    color: "error"
                  }}>
                    There is already an alternate defined for this diet.
                  </Typography>
                ) : undefined}
              </Grid>
              <Grid item xs={3} />
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </Card>
  </>);
}
