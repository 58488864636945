import React from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../../store/slices/authSlice/AuthSlice";
import { useGetResidentChangeByFacilityQuery } from "../../../store/apis/ResidentApis";
import BaseDataGrid from "../../shared/baseDataGrid/BaseDataGrid";
import dayjs from "dayjs";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import utcPlugin from "dayjs/plugin/utc";

export default function ChangeHistory() {
  const facilityId = useSelector(selectFacilityId);
  dayjs.extend(utcPlugin);

  const {
    data: ResidentChange,
    isFetching: loading,
    isError: hasError,
  } = useGetResidentChangeByFacilityQuery(
    {
      facilityId: facilityId,
    },
    {
      skip: !facilityId,
      refetchOnMountOrArgChange: true,
    }
  );
  const Data = ResidentChange?.residentChanges;

  const residentsColumns = [
    {
      field: "lastUpdated",
      headerName: "Date",
      flex: 1,
      // valueFormatter: (params) => {
      //   if (null === params.value) {
      //     return "";
      //   } else {
      //     return new Date(params.value).toLocaleString();
      //   }
      // },
      valueGetter: (value) => dayjs(value).toDate(),
      valueFormatter: (value) => {
        const isDateValid = dayjs(value).isValid();
        return isDateValid
          ? dayjs
              .utc(value)
              .local()
              .format(`${SCREEN_CONSTANTS.Date_Format} h:mm:ss A`)
          : "--";
      },
    },
    {
      field: "updatedBy",
      headerName: "UserId",
      flex: 1,
    },
    {
      field: "residentName",
      headerName: "Resident",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
    },
  ];

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          display: "flex",
          overflow: "hidden",
          flexDirection: "column",
          gap: 1,
        }}>
        <Box>
          <Typography
            variant="h6"
            component="h2"
            sx={{
              backgroundColor: "white",
            }}>
            Change History
          </Typography>
        </Box>
        <Box sx={{ height: "100%", flexGrow: 1, overflowY: "auto", pb: 1 }}>
          <BaseDataGrid
            rows={Data}
            columns={residentsColumns}
            loading={loading}
            error={hasError}
            height={"100%"}
          />
        </Box>
      </Box>
    </>
  );
}
