import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useFacilityAdaptiveEquipmentsBulkEditMutation,
  useGetFacilityAdaptiveEquipmentsBulkEditQuery,
} from "../../../../../../../store/apis/FacilityApis";
import { Box, Button, Grid } from "@mui/material";
import BaseDataGrid from "../../../../../../shared/baseDataGrid/BaseDataGrid";

export default function EditCommunityAdaptiveEquipments() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const navigate = useNavigate();

  const {
    data: communityAdaptiveEquipments,
    isFetching: caeLoading,
    isError: caeError,
    isSuccess: caeSuccess,
  } = useGetFacilityAdaptiveEquipmentsBulkEditQuery(id, {
    skip: !id,
  });

  const [bulkEdit, { isLoading: bulkEditLoading, isSuccess: bulkEditSuccess }] =
    useFacilityAdaptiveEquipmentsBulkEditMutation();

  const [selectedRowIds, setSelectedRowIds] = useState(
    communityAdaptiveEquipments?.facilityAdaptiveEquipments ?? []
  );

  useEffect(() => {
    if (!caeLoading && caeSuccess) {
      setSelectedRowIds(
        communityAdaptiveEquipments?.facilityAdaptiveEquipments
          ?.filter((x) => x.selected)
          .map((x) => x.id)
      );
    }
  }, [caeLoading, caeSuccess]);

  const selectedColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
    },
  ];

  useEffect(() => {
    if (bulkEditSuccess) {
      navigate(-1);
    }
  }, [bulkEditSuccess]);

  function handleSave() {
    const newList = communityAdaptiveEquipments.facilityAdaptiveEquipments?.map(
      (arrayElement) => ({
        ...arrayElement,
        selected: selectedRowIds.includes(arrayElement.id) ? true : false,
      })
    );
    bulkEdit({
      facilityId: id,
      facilityAdaptiveEquipments: newList,
    });
  }

  return (<>
    <BaseDataGrid
      autoHeight={false}
      height={{ md: "37dvh", xl: "40dvh" }}
      checkboxSelection
      rows={communityAdaptiveEquipments?.facilityAdaptiveEquipments}
      columns={selectedColumns}
      error={caeError}
      loading={caeLoading}
      disableSelectionOnClick
      rowSelectionModel={selectedRowIds}
      onRowSelectionModelChange={setSelectedRowIds}
      sx={{
        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
          {
            display: "none",
          },
      }}
    />
    <Grid container>
      <Grid item md={12}>
        <Box
          sx={{
            margin: "8px",
            display: "flex",
            justifyContent: "flex-end"
          }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={bulkEditLoading}
            onClick={() => {
              handleSave();
            }}>
            Save
          </Button>
        </Box>
      </Grid>
    </Grid>
  </>);
}
