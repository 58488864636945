import React, { useCallback, useEffect, useState } from "react";
import { unstable_useBlocker } from "react-router-dom";

import BlockerModal from "./BlockerModal";

const BaseBlockerModal = ({ text, isDirty }) => {
  const [hasChange, setHasChange] = useState(false);
  const [blockerModal, setBlockerModal] = useState(false);

  useEffect(() => {
    setHasChange(isDirty);
  }, [isDirty]);

  // UnSaved Changes Feature here
  // Block navigating elsewhere when data has been entered into the input
  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) =>
      hasChange && currentLocation.pathname !== nextLocation.pathname,
    [hasChange]
  );

  const blocker = unstable_useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === "blocked") {
      setBlockerModal(true);
    }
  }, [blocker]);

  // Page Reload and navigation alert implementations
  useEffect(() => {
    window.onbeforeunload = function () {
      if (hasChange) {
        return "You have unsaved changes. Are you sure you want to leave?";
      }
    };
  }, [hasChange]);

  const handleOnLeave = () => {
    blocker.proceed();
    setBlockerModal(false);
    setHasChange(false);
  };
  const handleOnStay = () => {
    blocker.reset();
    setBlockerModal(false);
  };
  return (
    <>
      {blockerModal ? (
        <BlockerModal
          text={
            text ||
            `Hold up! You've got unsaved changes. Are you sure you want to leave?`
          }
          open={blockerModal}
          onStay={handleOnStay}
          onLeave={handleOnLeave}
        />
      ) : null}
    </>
  );
};

export default BaseBlockerModal;
