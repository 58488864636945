export const USER_MENU_ITEMS = ({ dietitian, id, name }) =>
  dietitian
    ? [
        {
          link: `./userMenus?id=${id}&name=${name}`,
          label: `Menus User can Access`,
        },
        { link: `./userRoles?id=${id}`, label: `User Roles` },
        { link: `./DietitianUser?id=${id}`, label: `Consultant` },
        { link: `./userFacilities?id=${id}`, label: `Communities` },
      ]
    : [
        {
          link: `./userMenus?id=${id}&name=${name}`,
          label: `Menus User can Access`,
        },
        { link: `./userRoles?id=${id}`, label: `User Roles` },
        { link: `./userFacilities?id=${id}`, label: `Communities` },
      ];
