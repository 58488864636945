/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Methods/DownloadLinkHelper.md)
 */
export function DownloadLinkHelper(Link, fileName) {
  if (typeof Link !== "string" || !Link.includes("/")) {
    throw new Error("Invalid link");
  }
  const file_path = Link;
  const a = document.createElement("A");
  a.href = file_path;
  a.target = "_blank";
  a.download = fileName
    ? fileName
    : file_path.substr(file_path.lastIndexOf("/") + 1);

  if (document.body) {
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    throw new Error("document.body is not available");
  }
}

function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

export function BlobHelper(FileData, FileName, type) {
  try {
    const arrayBuffer = base64ToArrayBuffer(FileData);
    const blob = new Blob([arrayBuffer], {
      type: type || "application/octet-stream",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = FileName;
    document.body.appendChild(link); // This line is needed for Firefox
    link.click();
    document.body.removeChild(link); // Remove the link from the DOM
  } catch (error) {
    console.error("Error in BlobHelper:", error);
  }
}

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Methods/lowerCaseFirstLetter.md)
 */
export function lowerCaseFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Methods/setErrorsForProperties.md)
 */
export function setErrorsForProperties({
  setError,
  responseError,
  setValidationSummary,
}) {
  if (responseError) {
    let errorData = responseError?.errors ? responseError : null;
    errorData ??= responseError?.data?.errors ? responseError?.data : null;
    errorData ??= responseError?.data?.data?.errors
      ? responseError?.data?.data
      : null;

    let errorStatus = responseError?.status;
    errorStatus ??= responseError?.data?.status;
    errorStatus ??= responseError?.data?.data?.status;

    let errorMessages = responseError?.messages;
    errorMessages ??= responseError?.data?.messages;
    errorMessages ??= responseError?.data?.data?.messages;

    const { errors, title } = errorData || [];

    if (typeof setValidationSummary === "function") {
      setValidationSummary(`${errorStatus}: ${title || errorMessages}`);
    }
    for (let property in errors) {
      if (Array.isArray(errors[property]) && errors[property]?.length > 0) {
        if (typeof setError === "function") {
          setError(lowerCaseFirstLetter(property), {
            message: errors[property][0],
          });
        }
      }
    }
  }
}

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Methods/errorsForSnackbar.md)
 */
export function errorsForSnackbar(payload) {
  let errorMessages = "";

  for (let property in payload?.data?.errors) {
    errorMessages += `${payload?.data?.errors[property]}`;
  }
  return errorMessages;
}

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Methods/newSnackbar.md)
 */
export function newSnackbar({
  key,
  category,
  message,
  variant,
  entityName,
  snackbarType,
  errorPayload,
}) {
  switch (snackbarType) {
    case SNACKBAR_TYPES.AddSuccess:
      return {
        key: key || new Date().getTime() + Math.random(),
        message: `"${entityName || category}" added successfully.`,
        type: category,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "success",
          style: { whiteSpace: "pre-line" },
        },
      };
    case SNACKBAR_TYPES.AddFailure:
      return {
        key: key || new Date().getTime() + Math.random(),
        message: `An error occurred while adding ${entityName || category}: \n${
          errorPayload?.status
        } ${errorsForSnackbar(errorPayload)}`,
        type: category,
        options: {
          key: key || new Date().getTime() + Math.random(),
          variant: "error",
          style: { whiteSpace: "pre-line" },
        },
      };
    case SNACKBAR_TYPES.UpdateSuccess:
      return {
        key: key || new Date().getTime() + Math.random(),
        message: `"${entityName || category}" updated successfully.`,
        type: category,
        options: {
          key: key || new Date().getTime() + Math.random(),
          variant: "success",
          style: { whiteSpace: "pre-line" },
        },
      };
    case SNACKBAR_TYPES.UpdateFailure:
      return {
        key: key || new Date().getTime() + Math.random(),
        message: `An error occurred while updating ${
          entityName || category
        }: \n${errorPayload?.status} ${errorsForSnackbar(errorPayload)}`,
        type: category,
        options: {
          key: key || new Date().getTime() + Math.random(),
          variant: "error",
          style: { whiteSpace: "pre-line" },
        },
      };
    case SNACKBAR_TYPES.DeleteSuccess:
      return {
        key: key || new Date().getTime() + Math.random(),
        message: `"${entityName || category}" deleted successfully.`,
        type: category,
        options: {
          key: key || new Date().getTime() + Math.random(),
          variant: "warning",
          style: { whiteSpace: "pre-line" },
        },
      };
    case SNACKBAR_TYPES.DeleteFailure:
      return {
        key: key || new Date().getTime() + Math.random(),
        message: `An error occurred while deleting ${
          entityName || category
        }: \n${errorPayload?.status} ${errorsForSnackbar(errorPayload)}`,
        type: category,
        options: {
          key: key || new Date().getTime() + Math.random(),
          variant: "error",
          style: { whiteSpace: "pre-line" },
        },
      };
    case SNACKBAR_TYPES.Pending:
      return {
        key: key,
        message: message || "Task started",
        type: category,
        options: {
          key: key,
          variant: "default",
          style: { whiteSpace: "pre-line" },
        },
      };
    case SNACKBAR_TYPES.ShareStatus:
      return {
        key: key,
        message: message,
        type: category,
        options: {
          key: key,
          variant: "success",
          style: { whiteSpace: "pre-line" },
        },
      };
    default:
      return {
        key: key,
        message: message || "Task completed successfully.",
        type: category,
        options: {
          key: key,
          variant: variant || "success",
          style: { whiteSpace: "pre-line" },
        },
      };
  }
}

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Methods/removePending.md)
 */
export function removePending(meta, state) {
  const args = meta?.arg;
  const pendingKey = args?.endpointName + args?.originalArgs?.id + "pending";

  return state.notifications.map((notification) =>
    notification.key === pendingKey
      ? { ...notification, dismissed: true }
      : { ...notification }
  );
}

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Methods/isUserAuthorized.md)
 */
export const isUserAuthorized = (allowedRoles, userRoles) => {
  return allowedRoles && userRoles
    ? allowedRoles.some((role) => userRoles.includes(role))
    : false;
};

export const SNACKBAR_TYPES = {
  AddSuccess: "AddSuccess",
  AddFailure: "AddFailure",
  UpdateSuccess: "UpdateSuccess",
  UpdateFailure: "UpdateFailure",
  DeleteSuccess: "DeleteSuccess",
  DeleteFailure: "DeleteFailure",
  Pending: "Pending",
  ShareStatus: "ShareStatus",
};
