import {ApplicationInsights, DistributedTracingModes} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: "InstrumentationKey=4318cb76-0b61-4635-ad9e-7927eb23d3c7;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/",
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: false,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    distributedTracingMode: DistributedTracingModes.AI,
    disableCorrelationHeaders: true,
  }
});
appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((env) => {

    env.tags = env.tags || [];
    env.tags["ai.cloud.role"] = process.env.REACT_APP_APPINSIGHTS_ROLENAME;
    env.tags["ai.user.authUserId"] = appInsights.context.user.authenticatedId;
    env.tags["ai.user.accountId"] = appInsights.context.user.accountId;
});

export { reactPlugin, appInsights };