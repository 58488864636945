import React, { useEffect, useState, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Button, Typography, Grid, Fade } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import { useSnackbar } from "notistack";

import { useGetVendorSelectBoxQuery } from "../../../../store/apis/VendorApis";
import BaseAutoComplete from "../../../shared/baseForm/BaseAutoComplete";
import { useGetMenusForActiveSeasonQuery } from "../../../../store/apis/MenuApis";
import { useGetMissingVendorCostReportMutation } from "../../../../store/apis/ReportAPIs";
import { DownloadLinkHelper } from "../../../shared/utils/helpers";

import LinearProgress from "@mui/material/LinearProgress";

import { debounce } from "lodash";
import { BaseButton } from "components/shared/BaseButton";

export default function IngredientRecipeMissingCost() {
  const [
    getReport,
    {
      isError: isError,
      isSuccess: isSuccess,
      isLoading: isLoading,
      data: report,
    },
  ] = useGetMissingVendorCostReportMutation();

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      DownloadLinkHelper(report?.data);
    }
  }, [report?.data]);

  const onSubmitIngredients = (data) => {
    data.isRecipe = false;

    // console.log(data);

    getReport(data);
  };

  const onSubmitRecipes = (data) => {
    data.isRecipe = true;

    // console.log(data);

    getReport(data);
  };

  return (<>
    <Box sx={{
      marginTop: "10px"
    }}>
      <BaseButton
        text="Back To Vendor Reports"
        startIcon={"keyboard_arrow_left"}
        onClick={() => navigate(-1)}
      />
    </Box>
    <Box sx={{
      marginLeft: "10px"
    }}>
      <Typography
        variant="h5"
        className="userFacilityTitle"
        sx={{
          color: "primary",
          marginTop: "10px"
        }}>
        Missing Vendor Cost
      </Typography>
    </Box>
    <React.Fragment>
      <FormProvider>
        <form>
          <Grid container spacing={2} style={{ marginTop: "24px" }}>
            <Grid item xs={6}>
              <MenuSelectBox
                control={control}
                errors={errors}
                defaultOptions={{ id: null, label: "" }}
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <VendorSelectBox
                control={control}
                errors={errors}
                defaultOptions={{ id: null, label: "" }}
              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>

          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            {isLoading ? (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  disabled
                  onClick={handleSubmit(onSubmitIngredients)}>
                  Ingredients
                </Button>
                <Button
                  sx={{ marginLeft: "10px" }}
                  variant="contained"
                  color="primary"
                  disabled
                  onClick={handleSubmit(onSubmitRecipes)}>
                  Recipes
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmitIngredients)}>
                  Ingredients
                </Button>
                <Button
                  sx={{ marginLeft: "10px" }}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmitRecipes)}>
                  Recipes
                </Button>
              </div>
            )}
          </Box>
          <Fade
            in={isLoading}
            unmountOnExit
            sx={{ marginTop: "10px", marginBottom: "10px" }}>
            <LinearProgress />
          </Fade>
          {isError ? (
            <Typography sx={{
              color: "error"
            }}>
              {/* {error?.data?.messages} */}
            </Typography>
          ) : undefined}
        </form>
      </FormProvider>
    </React.Fragment>
  </>);
}

function VendorSelectBox({ control, errors, defaultOptions }) {
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const { data, isLoading } = useGetVendorSelectBoxQuery(debouncedSearch, {
    skip: !debouncedSearch,
  });
  const handleSearch = useMemo(
    () =>
      debounce((query) => {
        setDebouncedSearch(query);
      }, 400),
    []
  );
  return (
    <BaseAutoComplete
      formSelectProps={{
        props: {
          name: "vendorId",
          id: "vendorId",
          label: "Vendor Name",
        },
        validationProps: { required: "Vendor name required" },
      }}
      control={control}
      errors={errors}
      options={data || []}
      loading={isLoading}
      defaultOptions={defaultOptions}
      handleSearch={handleSearch}
    />
  );
}

function MenuSelectBox({ control, errors, defaultOptions }) {
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const { data, isLoading } = useGetMenusForActiveSeasonQuery(debouncedSearch, {
    skip: !debouncedSearch,
  });
  const handleSearch = useMemo(
    () =>
      debounce((query) => {
        setDebouncedSearch(query);
      }, 400),
    []
  );
  return (
    <BaseAutoComplete
      formSelectProps={{
        props: {
          name: "menuId",
          id: "menuId",
          label: "Menu Name",
        },
        validationProps: { required: "Menu name required" },
      }}
      control={control}
      errors={errors}
      options={data || []}
      loading={isLoading}
      defaultOptions={defaultOptions}
      handleSearch={handleSearch}
    />
  );
}
