import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useFacilityEHRDietMappingQuery } from "../../../../../../store/apis/FacilityApis";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import YesNoDisplay from "../../../../../shared/YesNoDisplay";
import BaseSearch from "components/shared/baseSearch/BaseSearch";
import { BackButton } from "components/shared/BackButton";
import { BaseButton } from "components/shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";
import { PlaylistAdd } from "@mui/icons-material";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function CommunityEHRMapping() {
  const SEARCH_PARAM = "search";

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );

  const currentId = searchParams.get("id");
  // const [searchParams] = useSearchParams();

  // const id = searchParams.get("id");

  const {
    data: ehrMapping,
    isLoading,
    isError,
  } = useFacilityEHRDietMappingQuery(
    { facilityId: currentId, searchTerm: debouncedSearchValue || "" },
    { skip: !currentId, refetchOnMountOrArgChange: true }
  );

  const eHRMappingColumns = [
    {
      field: "ehrDietName",
      headerName: "EHR Diet Name",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "isNPO",
      headerName: "Is NPO",
      flex: 1,
      maxWidth: 80,
      renderCell: (params) => {
        return <YesNoDisplay value={params?.row?.isNPO} />;
      },
    },
    {
      field: "dietName",
      headerName: "Diet Name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "dietTextureName",
      headerName: "Diet Texture",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "dietOther",
      headerName: "Diet Other",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      maxWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={ehrMapping}
            hasEdit={true}
            hasDelete={true}
            editProps={{
              To: `addcommunityehrmapping?id=${currentId}&mappingId=${params.row.id}`,
              title: "Community EHR Diet Mapping",
            }}
            deleteProps={{
              entityName: params.row?.ehrDietName,
              apiPath: "deletefacilityEHRDietMapping",
              title: "Community EHR Diet Mapping",
            }}
          />
        );
      },
    },
  ];

  return (<>
    <FlexBox
      justifyContent={{ xs: "space-between", sm: "flex-start" }}
      my={"8px"}>
      <BackButton text={"Back to EHR List"} />
      <BaseButton
        text={"Add EHR Diet Mapping"}
        endIcon={<PlaylistAdd />}
        To={`addcommunityehrmapping?id=${currentId}`}
      />
    </FlexBox>
    <Grid sx={{
      marginBottom: "8px"
    }}>
      <BaseSearch
        placeholder="Search..."
        disableUnderline={true}
        type="text"
        SEARCH_PARAM={SEARCH_PARAM}
        value={searchValue}
        setSearchValue={setSearchValue}
        setDebouncedValue={setDebouncedSearchValue}
        setSearchParams={setSearchParams}
        isId={true}
        Id={currentId}
      />
    </Grid>
    <BaseDataGrid
      autoHeight={true}
      rows={ehrMapping}
      columns={eHRMappingColumns}
      error={isError}
      loading={isLoading}
      getRowHeight={() => "auto"}
      sx={{
        "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
          py: "15px",
        },
        "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
          py: "22px",
        },
      }}
    />
  </>);
}
