/**
 * @see [Documentation](../../../Wiki/Documentations/FrontEnd/Constants/DistributorsList.md)
 */
export const DistributorsList = [
  {
    label: "None",
    id: 0,
  },
  {
    label: "DSSI",
    id: 1,
  },
  {
    label: "NCR/Graves",
    id: 2,
  },
  {
    label: "Procurement Partners",
    id: 3,
  },
  {
    label: "Gordon Food Service",
    id: 4,
  },
];
