import React from "react";
import {
  useResidentFoodTypesQuery,
  useGetResidentAllergyQuery,
} from "../../../../store/apis/ResidentApis";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import { Box, Grid2 } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { BaseButton } from "components/shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";
import { PostAdd } from "@mui/icons-material";

export default function ResidentDislikes() {
  const [searchParams] = useSearchParams();
  const residentId = searchParams.get("id");

  const {
    data: residentDislikes,
    isLoading,
    isError,
  } = useResidentFoodTypesQuery(
    { id: residentId, like: false },
    { skip: !residentId }
  );

  const {
    data: Allergies,
    isLoading: isAllergyLoading,
    isAllergyError,
  } = useGetResidentAllergyQuery(residentId, { skip: !residentId });

  const residentAllergies = Allergies;

  const dislikeColumns = [
    {
      field: "foodTypeName",
      minWidth: 50,
      flex: 2,
      headerName: "Dislike / Intolerance",
      // cellClassName: "cellCenterAlign",
      // headerAlign: "center",
    },
    {
      field: "description",
      minWidth: 50,
      flex: 2,
      headerName: "Description",
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={residentDislikes}
            editProps={{
              To: `addresidentdislike?dislikeId=${params.row.id}&id=${residentId}`,
              title: "Resident Dislike",
            }}
            deleteProps={{
              entityName: params.row?.foodTypeName,
              apiPath: "deleteResidentFoodType",
              title: "Resident Dislike",
            }}
            hasEdit={true}
            hasDelete={true}
          />
        );
      },
    },
  ];

  const allergyColumns = [
    {
      field: "allergyFoodName",
      minWidth: 50,
      flex: 2,
      headerName: "Food Allergy",
    },
    {
      field: "description",
      minWidth: 50,
      flex: 2,
      headerName: "Description",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={Allergies}
            editProps={{
              To: `/mealcard/resident/residentAllergy/addresidentAllergy?id=${residentId}`,
              title: "Resident Allergy",
            }}
            deleteProps={{
              entityName: params.row?.allergyFoodName,
              apiPath: "deleteResidentAllergy",
              title: "Resident Allergy",
            }}
            hasEdit={true}
            hasDelete={true}
          />
        );
      },
    },
  ];
  return (
    <Box
      sx={{
        flexGrow: 1,
        height: "100%",
        display: "flex",
        overflow: "hidden",
        flexDirection: "column",
        gap: 1,
      }}>
      <Grid2 container spacing={1} sx={{ height: "100%" }}>
        <Grid2 size={{ sm: 12, md: 6 }}>
          <Box
            sx={{
              flexGrow: 1,
              height: "100%",
              display: "flex",
              overflow: "hidden",
              flexDirection: "column",
              gap: 1,
            }}>
            <Box>
              <BaseButton
                text="Add Allergy"
                To={`/MealCard/resident/residentAllergy/addresidentAllergy?id=${residentId}`}
                state={{
                  name:
                    Allergies?.resident?.firstName +
                    " " +
                    Allergies?.resident?.lastName,
                }}
                endIcon={<PostAdd />}
                marginLeft="0px !important"
              />
            </Box>
            <Box sx={{ height: "100%", flexGrow: 1, overflowY: "auto", pb: 1 }}>
              <BaseDataGrid
                rows={residentAllergies}
                columns={allergyColumns}
                error={isAllergyError}
                loading={isAllergyLoading}
                autoHeight={false}
                height="70vh"
              />
            </Box>
          </Box>
        </Grid2>
        <Grid2 size={{ sm: 12, md: 6 }}>
          <Box
            sx={{
              flexGrow: 1,
              height: "100%",
              display: "flex",
              overflow: "hidden",
              flexDirection: "column",
              gap: 1,
            }}>
            <Box>
              <BaseButton
                text="Add Dislike"
                To={`addresidentdislike?id=${residentId}`}
                endIcon={<PostAdd />}
                marginLeft="0px !important"
              />
            </Box>
            <Box sx={{ height: "100%", flexGrow: 1, overflowY: "auto", pb: 1 }}>
              <BaseDataGrid
                rows={residentDislikes}
                columns={dislikeColumns}
                error={isError}
                loading={isLoading}
                autoHeight={false}
                height="70vh"
              />
            </Box>
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
}
