import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { STANDARD_TEXTS } = API_ENDPOINTS;

export const StandardTextApis = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getStandardTextList: builder.query({
      query: () => `/${STANDARD_TEXTS}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: STANDARD_TEXTS, id })),
              STANDARD_TEXTS,
            ]
          : [STANDARD_TEXTS];
      },
    }),
    getStandardTextById: builder.query({
      query: (id) => `/${STANDARD_TEXTS}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: STANDARD_TEXTS, id: response.id }, STANDARD_TEXTS]
          : [STANDARD_TEXTS],
    }),
    updateStandardText: builder.mutation({
      query: (data) => ({
        url: `/${STANDARD_TEXTS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: STANDARD_TEXTS, id: result?.id }],
    }),
  }),
});

export const {
  useGetStandardTextListQuery,
  useGetStandardTextByIdQuery,
  useUpdateStandardTextMutation,
} = StandardTextApis;
