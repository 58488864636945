export const DayFields = {
  MenuCheckBoxes: [
    {
      props: {
        label: "Sunday",
        name: "onSun",
      },
    },
    {
      props: {
        label: "Monday",
        name: "onMon",
      },
    },
    {
      props: {
        label: "Tuesday",
        name: "onTue",
      },
    },
    {
      props: {
        label: "Wednesday",
        name: "onWed",
      },
    },
    {
      props: {
        label: "Thursday",
        name: "onThu",
      },
    },
    {
      props: {
        label: "Friday",
        name: "onFri",
      },
    },
    {
      props: {
        label: "Saturday",
        name: "onSat",
      },
    },
  ],
};
