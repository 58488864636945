import { AddBox } from "@mui/icons-material";
import { Button, Chip } from "@mui/material";
import BaseActionBox from "components/shared/BaseActionBox";
import BaseDataGrid from "components/shared/baseDataGrid/BaseDataGrid";
import FlexBox from "components/shared/styledComponents/FlexBox";
import YesNoDisplay from "components/shared/YesNoDisplay";
import React from "react";
import { Link } from "react-router-dom";
import { useGetVisitReportTypesQuery } from "store/apis/VisitReportAPIs";

export default function VisitReportBuilder() {
  const { data, isLoading } = useGetVisitReportTypesQuery();
  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "name", headerName: "Name", flex: 3 },
    { field: "sortOrder", headerName: "Sort Order", flex: 1 },
    {
      field: "color",
      headerName: "Color",
      flex: 2,
      renderCell: (params) => (
        <Chip
          size="small"
          color={params?.row?.color}
          sx={{
            marginRight: "8px",
            padding: "8px 16px 8px 16px",
            borderRadius: "6px",
            marginY: "4px",
            fontWeight: "600",
          }}
          label={params?.row?.name}></Chip>
      ),
    },
    {
      field: "hasResidents",
      headerName: "Has Residents",
      flex: 1,
      renderCell: (params) => (
        <YesNoDisplay value={params?.row?.hasResidents} />
      ),
    },
    {
      field: "isVisibleToConsultant",
      headerName: "Is Visible",
      flex: 1,
      renderCell: (params) => (
        <YesNoDisplay value={params?.row?.isVisibleToConsultant} />
      ),
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={data}
            hasEdit={true}
            editProps={{
              To: `addReportType?id=${params.row.id}`,
              title: "Report Type",
            }}
            hasDelete={true}
            deleteProps={{
              entityName: params.row?.name,
              apiPath: "deleteVisitReportType",
              title: "Visit Report Type",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <FlexBox my="8px" justifyContent={"start"}>
        <Button
          component={Link}
          to="addReportType"
          color={"primary"}
          variant="contained"
          endIcon={<AddBox />}>
          Add Report Type
        </Button>
      </FlexBox>
      <BaseDataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: "sortOrder", sort: "asc" }],
          },
        }}
        loading={isLoading}
        rows={data}
        columns={columns}
      />
      ;
    </>
  );
}
