import { BaseApi } from "./BaseApi";
import { API_ENDPOINTS } from "../store.constants";

const { RESIDENT_ALLERGIES_DISLIKES } = API_ENDPOINTS;

export const ResidentAllergyDislikeApis = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // define an endpoint for getting ResidentAllergyDislike
    getResidentAllergyDislikes: builder.query({
      query: ({ residentId, menuId }) =>
        `/${RESIDENT_ALLERGIES_DISLIKES}?residentId=${residentId}&menuId=${menuId}`,
      transformResponse: (response) => response?.data,
      keepUnusedDataFor: 0,
      providesTags: [RESIDENT_ALLERGIES_DISLIKES],
    }),

    getResidentAllergySubstitutionsSelectBox: builder.query({
      query: ({ menuCategoryId, menuId, residentId }) =>
        `/${RESIDENT_ALLERGIES_DISLIKES}/AllergySubstitutions?menuCategoryId=${menuCategoryId}&menuId=${menuId}&residentId=${residentId}`,
      transformResponse: (response) => response?.data,
      //      keepUnusedDataFor: 0,
    }),
    getResidentDislikesSubstitutionsSelectBox: builder.query({
      query: ({ menuCategoryId, menuId, residentId }) =>
        `/${RESIDENT_ALLERGIES_DISLIKES}/DislikesSubstitutions?menuCategoryId=${menuCategoryId}&menuId=${menuId}&residentId=${residentId}`,
      transformResponse: (response) => response?.data,
      //      keepUnusedDataFor: 0,
    }),
    getResidentDietText: builder.query({
      query: ({ residentId }) =>
        `/${RESIDENT_ALLERGIES_DISLIKES}/ResidentDietText?residentId=${residentId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: RESIDENT_ALLERGIES_DISLIKES, id: response.residentId }]
          : [RESIDENT_ALLERGIES_DISLIKES],
    }),
    getResidentAllergyDislikesById: builder.query({
      query: (id) => `/${RESIDENT_ALLERGIES_DISLIKES}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              { type: RESIDENT_ALLERGIES_DISLIKES, id: response.id },
              RESIDENT_ALLERGIES_DISLIKES,
            ]
          : [RESIDENT_ALLERGIES_DISLIKES],
    }),
    getResidentMealsByDay: builder.query({
      query: ({ menuDayId, menuId, residentId }) =>
        `/${RESIDENT_ALLERGIES_DISLIKES}/MealsByDay?menuDayId=${menuDayId}&menuId=${menuId}&residentId=${residentId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              { type: RESIDENT_ALLERGIES_DISLIKES, id: response.id },
              RESIDENT_ALLERGIES_DISLIKES,
            ]
          : [RESIDENT_ALLERGIES_DISLIKES],
      keepUnusedDataFor: 0,
    }),
    deleteResidentAllergyDislikes: builder.mutation({
      query: ({ id, residentId }) => ({
        url: `/${RESIDENT_ALLERGIES_DISLIKES}/${id}/${residentId}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: [RESIDENT_ALLERGIES_DISLIKES],
    }),
    addResidentAllergyDislikes: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENT_ALLERGIES_DISLIKES}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [RESIDENT_ALLERGIES_DISLIKES],
    }),
    insertMultiple: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENT_ALLERGIES_DISLIKES}/InsertMultiple`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [RESIDENT_ALLERGIES_DISLIKES],
    }),

    updateResidentAllergyDislikes: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENT_ALLERGIES_DISLIKES}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: RESIDENT_ALLERGIES_DISLIKES, id: result?.id },
      ],
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetResidentAllergyDislikesQuery,
  useGetResidentAllergyDislikesByIdQuery,
  useGetResidentAllergySubstitutionsSelectBoxQuery,
  useGetResidentDislikesSubstitutionsSelectBoxQuery,
  useGetResidentDietTextQuery,
  useGetResidentMealsByDayQuery,
  useDeleteResidentAllergyDislikesMutation,
  useAddResidentAllergyDislikesMutation,
  useInsertMultipleMutation,
  useUpdateResidentAllergyDislikesMutation,
} = ResidentAllergyDislikeApis;
