import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import BaseSelect from "../../../../../../shared/baseForm/BaseSelect";
import BaseCheckBox from "../../../../../../shared/baseForm/BaseCheckBox";
import { useGetRegionListQuery } from "../../../../../../../store/apis/RegionsApis";
import {
  useAddUserRegionMutation,
  useGetUserRegionsQuery,
} from "../../../../../../../store/apis/DietitianAPis";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import BaseDataGrid from "../../../../../../shared/baseDataGrid/BaseDataGrid";
import { REQUIRED_ERROR } from "utils/errorMessages";
import BaseActionBox from "components/shared/BaseActionBox";
import { WarningText } from "components/shared/WarningText";

export default function RegionManagement({
  control1,
  currentDietitian,
  isAuthenticated,
  errors1,
}) {
  const methods = useForm();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const [primaryRegions, setPrimaryRegions] = useState([]);
  const [secondaryRegions, setSecondaryRegions] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [isRegionalManager, setIsRegionalManager] = useState(false);

  const {
    data: allRegions,
    isFetching: loading,
    isSuccess: allRegionsSuccess,
  } = useGetRegionListQuery(undefined, {
    skip: !currentDietitian || !isAuthenticated,
  });
  const allRegionsSelect = allRegions?.map((value) => ({
    id: value.id,
    label: value.name,
  }));

  const {
    data: userRegions,
    isSuccess: userRegionsSuccess,
    isFetching: userRegionsFetching,
    isError: userRegionsError,
  } = useGetUserRegionsQuery(currentDietitian?.id, {
    skip: !currentDietitian?.id || currentDietitian?.id === 0,
  });

  const [addUserRegion, { isSubmitting: isSubmitting }] =
    useAddUserRegionMutation();

  useEffect(() => {
    if (userRegionsSuccess && !userRegionsFetching && userRegions?.length > 0) {
      setSelectedRegions(userRegions?.map((value) => value?.regionId));
    }
  }, [userRegions, userRegionsSuccess, userRegionsFetching]);

  useEffect(() => {
    if (allRegionsSuccess && !loading && allRegions?.length > 0) {
      setIsRegionalManager(currentDietitian?.isRegionalManager);
      setPrimaryRegions(
        allRegions
          .filter(
            (value) =>
              !selectedRegions.includes(value.id) ||
              value.id === currentDietitian?.primaryRegionId
          )
          .map((value) => ({
            id: value.id,
            label: `${value.name} (${value.managerName})`,
          }))
      );
      setSecondaryRegions(
        allRegions
          .filter((value) => !selectedRegions.includes(value.id))
          .map((value) => ({
            id: value.id,
            label: `${value.name} (${value.managerName})`,
          }))
      );
    }
  }, [allRegionsSuccess, selectedRegions]);

  const userRegionColumns = [
    { field: "regionName", headerName: "Region", flex: 3, minWidth: 200 },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={userRegions}
            hasDelete={true}
            deleteProps={{
              entityName: "Dietitian Region",
              apiPath: "deleteUserRegion",
            }}
          />
        );
      },
    },
  ];

  const onSubmit = (data) => {
    console.log(currentDietitian.id);
    data["dietitianUserId"] = currentDietitian.id;
    if (isAuthenticated) {
      setSelectedRegions([...selectedRegions, data?.regionId]);
      addUserRegion(data);
      reset({ dietitianUserId: null, regionId: undefined });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <BaseSelect
          options={primaryRegions}
          label="Primary Region"
          name="primaryRegionId"
          id="primaryRegionId"
          loading={loading}
          control={control1}
          errors={errors1}
          validationProps={{
            required: REQUIRED_ERROR("Primary Region"),
          }}></BaseSelect>
      </Grid>
      {currentDietitian?.id ? (
        <>
          <Grid item xs={8}>
            <BaseSelect
              options={secondaryRegions}
              label="Secondary Region"
              name="regionId"
              id="regionId"
              errors={errors}
              validationProps={{
                required: REQUIRED_ERROR("Secondary Region"),
              }}
              loading={loading}
              control={control}></BaseSelect>
          </Grid>
          <Grid item xs={4}>
            <BaseSubmitButton
              text={"Add"}
              onClick={handleSubmit(onSubmit)}
              isSubmitting={isSubmitting}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <BaseDataGrid
              autoHeight={false}
              width={"99%"}
              rows={userRegions}
              columns={userRegionColumns}
              loading={loading}
              error={userRegionsError}
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <WarningText
            text={`Secondary regions can be added once basic configuration has been
            saved.`}></WarningText>
        </Grid>
      )}

      <Grid item xs={5}>
        <BaseCheckBox
          label={"Is Regional Manager"}
          name={"isRegionalManager"}
          control={control1}
          onClick={() => setIsRegionalManager(!isRegionalManager)}
          errors={errors1}></BaseCheckBox>
      </Grid>
      {isRegionalManager == true ? (
        <Grid item xs={7}>
          <BaseSelect
            options={allRegionsSelect}
            label="Managed Region Id"
            name="managedRegionId"
            id="managedRegionId"
            loading={loading}
            control={control1}></BaseSelect>
        </Grid>
      ) : (
        ""
      )}
      <Grid item xs={12}>
        <BaseCheckBox
          label={"Is Global Manager"}
          name={"isGlobalRegionalManager"}
          control={control1}
          errors={errors1}></BaseCheckBox>
      </Grid>
    </Grid>
  );
}
