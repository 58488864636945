import { ListItemIcon, ListItemText, ListItemButton } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./SidebarLink.module.css";
import classNames from "classnames";
import { matchPath, matchRoutes, useLocation } from "react-router-dom";

export function SidebarLink({
  Name,
  Icon,
  isActive,
  SetActive,
  open,
  SetOpen,
  altLink,
}) {
  const offsetPointer = useRef(null);

  const location = useLocation();
  const { pathname } = location;
  const isAltActive = useMemo(() => {
    if (
      matchRoutes(
        [
          { path: "", end: true },
          { path: "forbidden", end: true },
        ],
        location
      ) &&
      Name === "Home"
    ) {
      return true;
    }
    return altLink ? matchPath({ path: altLink, end: false }, pathname) : null;
  }, [altLink, pathname]);

  const [bindingRect, setBindingRect] = useState(null);

  useEffect(() => {
    if (offsetPointer.current) {
      setBindingRect(offsetPointer.current.getBoundingClientRect());
    }
  }, [offsetPointer.current]);

  useEffect(() => {
    if ((isActive || isAltActive) && bindingRect) {
      SetActive(bindingRect.top, 48, Name);
    }
  }, [isActive, isAltActive, bindingRect, SetActive, Name]);

  return (
    <ListItemButton
      title={Name}
      ref={offsetPointer}
      className={classNames(styles.baseLink, {
        [styles.active]: isActive || isAltActive,
      })}
      onClick={() => {
        if (SetOpen) {
          SetOpen(false);
        }
      }}
      sx={{
        minHeight: 48,
        justifyContent: open ? "initial" : "center",
        px: 2.5,
      }}
      aria-label={Name}>
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: open ? 1 : "auto",
          justifyContent: "center",
        }}>
        <span className={`${styles.sidebarIcon} material-icons`}>{Icon}</span>
      </ListItemIcon>
      <ListItemText
        className={classNames(styles.baseLink, {
          [styles.active]: isActive || isAltActive,
        })}
        primary={Name}
        disableTypography={true}
        sx={{ opacity: open ? 1 : 0 }}
      />
    </ListItemButton>
  );
}
