/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { EditSkeleton } from "components/admin/admin.overlays";
import { BackButton } from "components/shared/BackButton";
import BaseDataGrid from "components/shared/baseDataGrid/BaseDataGrid";
import { WarningText } from "components/shared/WarningText";
import { useSelector } from "react-redux";
import { json, useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetHepByIngredientIdQuery,
  useAddHepMutation,
  useUpdateHepMutation,
} from "store/apis/IngredientAPIs";
import { selectFacilityId } from "store/slices/authSlice/AuthSlice";
import useMsalAccount from "utils/useMsalAccount";
import { useForm } from "react-hook-form";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import "./hep.css";
export default function AddHep() {
  const facilityId = useSelector(selectFacilityId);
  const { userId } = useMsalAccount();
  const [searchParams] = useSearchParams();
  const ingredientId = searchParams.get("id");
  const ingredientName = searchParams.get("name");
  const navigate = useNavigate();
  const [rowModesModel, setRowModesModel] = useState({});
  const [DataArray, setDataArray] = useState([]);
  const [rowValues, setRowValues] = useState({});
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    data: currentIngredientData,
    isFetching,
    isSuccess,
  } = useGetHepByIngredientIdQuery(ingredientId);

  useEffect(() => {
    setDataArray(currentIngredientData);
  }, [currentIngredientData]);

  const [
    addHep,
    { isLoading: addHepLoading, error: addHepErrorData, isSuccess: addSuccess },
  ] = useAddHepMutation();

  const [
    updateHep,
    {
      isLoading: updateLoading,
      error: updateErrorData,
      isSuccess: updateSuccess,
    },
  ] = useUpdateHepMutation();

  const success = addSuccess || updateSuccess;

  useEffect(() => {
    if (success) navigate(-1);
  }, [success]);

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const processRowUpdate = (newRow, handleCheck) => {
    try {
      if (!handleCheck) {
        const updatedRow = {
          ...newRow,
        };
        setDataArray(
          DataArray.map((row) =>
            row.hepCategoryId === newRow.hepCategoryId ? updatedRow : row
          )
        );
      } else {
        const data = DataArray?.find(
          (item) => item?.hepCategoryId === newRow.hepCategoryId
        );
        const updatedRow = {
          ...data,
          amountGrams: newRow.amountGrams,
        };
        setDataArray(
          DataArray?.map((row) =>
            row.hepCategoryId === newRow.hepCategoryId ? updatedRow : row
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Modify handleCheckboxChanges to update both checkbox values at once
  const handleCheckboxChanges = (event, params, field) => {
    const newValue = event.target.checked;
    const currentRow = rowValues[params.id] || params.row;

    // Apply the new checkbox value and retain other fields
    const updatedRow = {
      ...currentRow,
      [field]: newValue,
    };

    // Update both state and row data to maintain synchronization
    setRowValues((prev) => ({
      ...prev,
      [params.id]: updatedRow,
    }));
    const handleCheck = false;
    // Process row update with the updated row directly
    processRowUpdate(updatedRow, handleCheck);
  };

  const columns = [
    {
      field: "hepCategoryName",
      headerName: "HEP Category Name",
      flex: 1.8,
      minWidth: 150,
      headerAlign: "center",
      renderCell: (params) => (
        <Box>
          <Typography variant="body1" fontWeight="bold">
            {params?.row?.hepCategoryName}
          </Typography>
          <Tooltip title={params?.row?.hepCategoryText}>
            <Typography
              variant="body2"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}>
              {params?.row?.hepCategoryText}
            </Typography>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: "amountGrams",
      headerName: "Amount Grams",
      flex: 0.6,
      headerAlign: "center",
      type: "number",
      editable: true,
    },
    {
      field: "isEntree",
      headerName: "Entree",
      flex: 0.3,
      headerAlign: "center",
      renderCell: (params) => {
        // Use local state for each checkbox cell
        const [checked, setChecked] = useState(!!params.value);

        const handleCheckboxChange = (event) => {
          const newValue = event.target.checked;
          setChecked(newValue);
          // Call your function with the new params
          handleCheckboxChanges(event, params, "isEntree");
        };

        return (
          <Box textAlign="center">
            <input
              className="custom-checkbox"
              type="checkbox"
              style={{
                appearance: "none",
                width: "17px",
                height: "17px",
                border: "2px solid #00bcbe",
                borderRadius: "4px",
                backgroundColor: checked ? "#00bcbe" : "transparent",
                position: "relative",
                cursor: "pointer",
                accentColor: "#00bcbe",
                color: "white",
              }}
              checked={checked}
              onChange={handleCheckboxChange}
            />
          </Box>
        );
      },
    },
    {
      field: "isSide",
      headerName: "Side",
      flex: 0.3,
      headerAlign: "center",
      renderCell: (params) => {
        // Use local state for each checkbox cell
        const [checked, setChecked] = useState(!!params.value);

        const handleCheckboxChange = (event) => {
          const newValue = event.target.checked;
          setChecked(newValue);
          // Call your function with the new params
          handleCheckboxChanges(event, params, "isSide");
        };

        return (
          <Box textAlign="center">
            <input
              className="custom-checkbox"
              type="checkbox"
              style={{
                appearance: "none",
                width: "17px",
                height: "17px",
                border: "2px solid #00bcbe",
                borderRadius: "4px",
                backgroundColor: checked ? "#00bcbe" : "transparent",
                position: "relative",
                cursor: "pointer",
                accentColor: "#00bcbe",
                color: "white",
              }}
              checked={checked}
              onChange={handleCheckboxChange}
            />
          </Box>
        );
      },
    },
    {
      field: "isBeverage",
      headerName: "Beverage",
      flex: 0.4,
      headerAlign: "center",
      renderCell: (params) => {
        // Use local state for each checkbox cell
        const [checked, setChecked] = useState(!!params.value);

        const handleCheckboxChange = (event) => {
          const newValue = event.target.checked;
          setChecked(newValue);
          // Call your function with the new params
          handleCheckboxChanges(event, params, "isBeverage");
        };

        return (
          <Box textAlign="center">
            <input
              className="custom-checkbox"
              type="checkbox"
              style={{
                appearance: "none",
                width: "17px",
                height: "17px",
                border: "2px solid #00bcbe",
                borderRadius: "4px",
                backgroundColor: checked ? "#00bcbe" : "transparent",
                position: "relative",
                cursor: "pointer",
                accentColor: "#00bcbe",
                color: "white",
              }}
              checked={checked}
              onChange={handleCheckboxChange}
            />
          </Box>
        );
      },
    },
    {
      field: "isBread",
      headerName: "Bread",
      flex: 0.3,
      headerAlign: "center",
      renderCell: (params) => {
        // Use local state for each checkbox cell
        const [checked, setChecked] = useState(!!params.value);

        const handleCheckboxChange = (event) => {
          const newValue = event.target.checked;
          setChecked(newValue);
          // Call your function with the new params
          handleCheckboxChanges(event, params, "isBread");
        };

        return (
          <Box textAlign="center">
            <input
              className="custom-checkbox"
              type="checkbox"
              style={{
                appearance: "none",
                width: "17px",
                height: "17px",
                border: "2px solid #00bcbe",
                borderRadius: "4px",
                backgroundColor: checked ? "#00bcbe" : "transparent",
                position: "relative",
                cursor: "pointer",
                accentColor: "#00bcbe",
                color: "white",
              }}
              checked={checked}
              onChange={handleCheckboxChange}
            />
          </Box>
        );
      },
    },
    {
      field: "isDessert",
      headerName: "Dessert",
      flex: 0.4,
      headerAlign: "center",
      renderCell: (params) => {
        // Use local state for each checkbox cell
        const [checked, setChecked] = useState(!!params.value);

        const handleCheckboxChange = (event) => {
          const newValue = event.target.checked;
          setChecked(newValue);
          // Call your function with the new params
          handleCheckboxChanges(event, params, "isDessert");
        };

        return (
          <Box textAlign="center">
            <input
              className="custom-checkbox"
              type="checkbox"
              style={{
                appearance: "none",
                width: "17px",
                height: "17px",
                border: "2px solid #00bcbe",
                borderRadius: "4px",
                backgroundColor: checked ? "#00bcbe" : "transparent",
                position: "relative",
                cursor: "pointer",
                accentColor: "#00bcbe",
                color: "white",
              }}
              checked={checked}
              onChange={handleCheckboxChange}
            />
          </Box>
        );
      },
    },
    {
      field: "isCondiments",
      headerName: "Condiments",
      flex: 0.5,
      headerAlign: "center",
      renderCell: (params) => {
        // Use local state for each checkbox cell
        const [checked, setChecked] = useState(!!params.value);

        const handleCheckboxChange = (event) => {
          const newValue = event.target.checked;
          setChecked(newValue);
          // Call your function with the new params
          handleCheckboxChanges(event, params, "isCondiments");
        };

        return (
          <Box textAlign="center">
            <input
              className="custom-checkbox"
              type="checkbox"
              style={{
                appearance: "none",
                width: "17px",
                height: "17px",
                border: "2px solid #00bcbe",
                borderRadius: "4px",
                backgroundColor: checked ? "#00bcbe" : "transparent",
                position: "relative",
                cursor: "pointer",
                accentColor: "#00bcbe",
                color: "white",
              }}
              checked={checked}
              onChange={handleCheckboxChange}
            />
          </Box>
        );
      },
    },
    {
      field: "isSandwichBuilder",
      headerName: "Sandwich Builder",
      flex: 0.7,
      headerAlign: "center",
      renderCell: (params) => {
        // Use local state for each checkbox cell
        const [checked, setChecked] = useState(!!params.value);

        const handleCheckboxChange = (event) => {
          const newValue = event.target.checked;
          setChecked(newValue);
          // Call your function with the new params
          handleCheckboxChanges(event, params, "isSandwichBuilder");
        };

        return (
          <Box textAlign="center">
            <input
              className="custom-checkbox"
              type="checkbox"
              style={{
                appearance: "none",
                width: "17px",
                height: "17px",
                border: "2px solid #00bcbe",
                borderRadius: "4px",
                backgroundColor: checked ? "#00bcbe" : "transparent",
                position: "relative",
                cursor: "pointer",
                accentColor: "#00bcbe",
                color: "white",
              }}
              checked={checked}
              onChange={handleCheckboxChange}
            />
          </Box>
        );
      },
    },
    {
      field: "isAppetizer",
      headerName: "Appetizer",
      flex: 0.4,
      headerAlign: "center",
      renderCell: (params) => {
        // Use local state for each checkbox cell
        const [checked, setChecked] = useState(!!params.value);

        const handleCheckboxChange = (event) => {
          const newValue = event.target.checked;
          setChecked(newValue);
          // Call your function with the new params
          handleCheckboxChanges(event, params, "isAppetizer");
        };

        return (
          <Box textAlign="center">
            <input
              className="custom-checkbox"
              type="checkbox"
              style={{
                appearance: "none",
                width: "17px",
                height: "17px",
                border: "2px solid #00bcbe",
                borderRadius: "4px",
                backgroundColor: checked ? "#00bcbe" : "transparent",
                position: "relative",
                cursor: "pointer",
                accentColor: "#00bcbe",
                color: "white",
              }}
              checked={checked}
              onChange={handleCheckboxChange}
            />
          </Box>
        );
      },
    },
  ];

  const onSubmit = () => {
    if (DataArray[0]?.id == 0) {
      addHep(DataArray);
    } else {
      updateHep(DataArray);
    }
  };

  if (isFetching && !isSuccess) {
    return <EditSkeleton />;
  }

  return (
    <Box pl={0.5}>
      <Box display="flex" justifyContent="space-between">
        <BackButton text="Back to Ingredients" />
        <BaseSubmitButton
          onClick={handleSubmit(onSubmit)}
          isSubmitting={updateLoading}
        />
      </Box>
      <WarningText text="The Healthy Eating Patterns are based on the Dietary Guidelines for Americans. Following these guidelines ensures a variety of food and beverage choices within each food group and gives the best nutrition outcome. The parameters are set by day on a weekly average." />
      <Typography variant="h6" fontWeight="bold" fontSize="17px">
        HEALTHY EATING PATTERN ({ingredientName})
      </Typography>
      <BaseDataGrid
        rows={currentIngredientData || []}
        columns={columns}
        getRowId={(row) => row?.hepCategoryId}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={(error) => console.log(error)}
        autoHeight={true}
        defaultPageSize={100}
      />
    </Box>
  );
}
