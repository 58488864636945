import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  headerText: "...loading",
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setHeaderText: (state, action) => {
      state.headerText = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setHeaderText } = appSlice.actions;
export const selectHeaderText = (state) => state?.app?.headerText;

export default appSlice.reducer;
