import React from "react";
import { Grid, FormHelperText, Skeleton } from "@mui/material";
import BaseSelect from "../../../../../../shared/baseForm/BaseSelect";
import BaseInput from "../../../../../../shared/baseForm/BaseInput";
import { RecipeSettingFields } from "./forms.constants";

export default function RecipeSettingsForm({
  sharingGroupLoading,
  sharingGroupList,
  errors,
  control,
  defaultRecipeSharingGroup,
}) {
  const { formTextFields, formSelectProps } = RecipeSettingFields;
  return (
    <Grid container spacing={2}>
      {formTextFields.map((data, index) => {
        const props = data?.props;
        return (
          <Grid key={index} item xs={4}>
            <BaseInput
              {...props}
              control={control}
              name={props?.name}
              validationProps={data?.validationProps}
              errors={errors}
            />
            {errors[props?.id] && (
              <FormHelperText error>{errors[props?.id].message}</FormHelperText>
            )}
          </Grid>
        );
      })}
      <Grid item xs={6}>
        {sharingGroupLoading ? (
          <Skeleton />
        ) : (
          <BaseSelect
            defaultValue={defaultRecipeSharingGroup}
            {...formSelectProps.props}
            options={sharingGroupList}
            validationProps={formSelectProps.validationProps}
            errors={errors}
            control={control}
          />
        )}
      </Grid>
    </Grid>
  );
}
