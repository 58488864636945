import React from "react";
import { Outlet } from "react-router-dom";
import { UserMenuElements } from "./usermenu.constants";
import { Card } from "@mui/material";
import AdminTabs from "../../shared/AdminTabs";
import "./UserMenu.css";
import OutletContainer from "components/shared/styledComponents/OutletContainer";
// import { Box } from "@mui/system";

export default function UserMenu() {
  return (
    <>
      <AdminTabs Data={UserMenuElements} />
      <Card
        id="users-group-card"
        sx={{
          flexGrow: 1,
          display: "flex",
          overflow: "hidden",
          flexDirection: "column",
        }}>
        <OutletContainer id="users-group-outlet">
          <Outlet />
        </OutletContainer>
      </Card>
    </>
  );
}
