import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { SUB_RECIPES } = API_ENDPOINTS;

export const PlateFulSubRecipesAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSubRecipeList: builder.query({
      query: ({ recipeId }) => `/${SUB_RECIPES}?parentId=${recipeId}`,
      transformResponse: (response) => response?.data,
      keepUnusedDataFor: 2,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: SUB_RECIPES, id })),
              SUB_RECIPES,
            ]
          : [SUB_RECIPES];
      },
    }),
    getSubRecipeSearchList: builder.query({
      query: ({ key }) => `/${SUB_RECIPES}/Search?key=${key}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
            description: res.description
          };
        }),
      providesTags: (response) =>
        response?.data
          ? [
              ...response.data.map(({ id }) => ({
                type: SUB_RECIPES,
                id,
              })),
              SUB_RECIPES,
            ]
          : [SUB_RECIPES],
    }),
    deleteSubRecipe: builder.mutation({
      query: (id) => ({
        url: `/${SUB_RECIPES}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: SUB_RECIPES, id: arg.id },
      ],
    }),
    addSubRecipe: builder.mutation({
      query: (data) => ({
        url: `/${SUB_RECIPES}?parentId=${data.parentId}&subRecipeId=${data.subRecipeId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: SUB_RECIPES, id: result?.id }],
    }),
  }),
});
export const {
  useGetSubRecipeListQuery,
  useGetSubRecipeSearchListQuery,
  useDeleteSubRecipeMutation,
  useAddSubRecipeMutation,
} = PlateFulSubRecipesAPIs;
