import React, { useEffect } from "react";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import { useGetMenuFooterByIdQuery } from "store/apis/MenuApis";

export default function MealForm({
  watch,
  list,
  errors,
  control,
  menuId,
  setValue,
}) {
  const [hiddenList, setHiddenList] = React.useState([]);
  const reportDateOptionId = watch("reportDateOptionId");
  const ReportId = watch("reportId");

  const { isSuccess: isMenuFooterSuccess, data: menuFooter } =
    useGetMenuFooterByIdQuery(menuId, {
      skip: !menuId || !list.some((x) => x.props?.name == "reportFooter"),
    });

  useEffect(() => {
    if (isMenuFooterSuccess && menuFooter) {
      setValue("reportFooter", menuFooter, { shouldValidate: true });
    }
  }, [isMenuFooterSuccess, menuFooter]);

  useEffect(() => {
    if (
      ReportId == 4 ||
      ReportId == 6 ||
      ReportId == 7 ||
      ReportId == 8 ||
      ReportId == 10
    ) {
      switch (Number(reportDateOptionId)) {
        case 2:
          setHiddenList([
            ...hiddenList.filter(
              (item) => item?.name != "firstDate" && item?.name != "lastDate"
            ),
          ]);
          break;
        case 3:
          setHiddenList([
            ...hiddenList.filter((item) => item?.name != "firstDate"),
            { name: "lastDate" },
          ]);
          break;
        default:
          setHiddenList([
            ...hiddenList.filter(
              (item) => item?.name != "startDate" && item?.name != "lastDate"
            ),
            { name: "firstDate" },
            { name: "lastDate" },
          ]);
          break;
      }
    }
  }, [reportDateOptionId]);

  React.useEffect(() => {}, [ReportId]);

  return (
    <GeneratedForm
      hiddenList={hiddenList}
      list={list}
      errors={errors}
      control={control}
    />
  );
}
