import React, { useEffect, useState } from "react";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";

export default function CensusForm({
  watch,
  list,
  errors,
  control,
  menuCensus,
}) {
  const reportId = watch("reportId");
  const byDayMealId = watch("byDayMealId");

  const [hiddenList, setHiddenList] = useState(
    reportId == 7
      ? [
          { name: "includeDescription" },
          { name: "includeNutrients" },
          { name: "includeMenuMorningSnacks" },
          { name: "includeMenuAfternoonSnacks" },
          { name: "includeMenuEveningSnacks" },
          { name: "includeMenuOtherItems" },
        ]
      : []
  );

  useEffect(() => {
    if (reportId == 7) {
      if (byDayMealId != 2) {
        setHiddenList([
          { name: "includeDescription" },
          { name: "includeNutrients" },
          { name: "includeMenuMorningSnacks" },
          { name: "includeMenuAfternoonSnacks" },
          { name: "includeMenuEveningSnacks" },
          { name: "includeMenuOtherItems" },
        ]);
      } else {
        setHiddenList([]);
      }
    }
  }, [reportId, byDayMealId]);

  return (
    <GeneratedForm
      defaultValues={[
        { name: "qty1", value: menuCensus },
        { name: "qty2", value: menuCensus },
        { name: "qty3", value: menuCensus },
      ]}
      list={list}
      hiddenList={hiddenList || []}
      errors={errors}
      control={control}
    />
  );
}
