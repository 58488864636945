import {
  ByBuildingWingDefaults,
  ByBuildingWingFields,
  ByDietDefaults,
  ByDietFields,
  ByDietTextureDefaults,
  ByDietTextureFields,
  ByDislikeAllergiesDefaults,
  ByDislikeAllergiesFields,
  ByFluidRestrictionDefaults,
  ByFluidRestrictionFields,
  ByLiquidConsistencyDefaults,
  ByLiquidConsistencyFields,
  ByMealDiningAreaDefaults,
  ByMealDiningAreaFields,
  ByResidentDefaults,
  ByResidentFields,
} from "./reports/dietRosters.constants";
import {
  MealCardAllMealsDefaultValues,
  MealCardAllMealsFields,
  MealCardAllMealsWithMenuFields,
  MealCardDefaultValues,
  MealCardFields,
  MealCardsAllMealsWithMenuDefaultValues,
  MealCardsWithMenuDefaultValues,
  MealCardsWithMenuFields,
} from "./reports/mealCards.constants";
import {
  AssessmentsDueDefaults,
  AssessmentsDueFields,
  AssessmentsLessThanMonthDefaults,
  AssessmentsLessThanMonthDueFields,
  BirthdayListDefaults,
  BirthdayListFields,
  CarePlanLessThanMonthDefaults,
  CarePlanLessThanMonthDueFields,
  CarePlansDefaults,
  CarePlansFields,
  NewAdmissionsLast4DaysDefaults,
  NewAdmissionsLast4DaysFields,
  ResidentWeightBWERDefaults,
  ResidentWeightBWERFields,
  ResidentProfileDefaults,
  ResidentProfileFields,
  ResidentWeightNameDefaults,
  ResidentWeightNameFields,
  ResidentsByDoctorDefaults,
  ResidentsByDoctorFields,
  WeeklyWeightReportDefaults,
  WeeklyWeightReportFields,
} from "./reports/residentcare.constants";
import {
  AdaptiveEquipmentRosterDefaults,
  AdaptiveEquipmentRosterFields,
  AllergyRosterDefaults,
  AllergyRosterFields,
  // BeverageRosterDefaults,
  // BeverageRosterFields,
  DislikeRosterDefaults,
  DislikeRosterFields,
  FluidRestrictionRosterDefaults,
  FluidRestrictionRosterFields,
  MealExtrasRosterDefaults,
  MealExtrasRosterFields,
  SnackRosterDefaults,
  SnackRosterFields,
  SupplementRosterDefaults,
  SupplementRosterFields,
  TubeFeedingRosterDefaults,
  TubeFeedingRosterFields,
} from "./reports/rosterReports.constants";
import {
  SnackSupplementLabelsDefaults,
  SnackSupplementLabelsFields,
  SnackSupplementProductionsDefaults,
  SnackSupplementProductionsFields,
} from "./reports/snacksupplements.constants";
import {
  AdaptiveEquipmentTallyDefaults,
  AdaptiveEquipmentTallyFields,
  AllergyTallyDefaults,
  AllergyTallyFields,
  // BeverageTallyByWingDefaults,
  // BeverageTallyByWingFields,
  // BeverageTallyDefaults,
  // BeverageTallyFields,
  DietOtherTallyDefaults,
  DietOtherTallyFields,
  DietTallyDefaults,
  DietTallyFields,
  DietTextureByWingTallyDefaults,
  DietTextureByWingTallyFields,
  DietTextureTallyDefaults,
  DietTextureTallyFields,
  DislikeIntoleranceTallyDefaults,
  DislikeIntoleranceTallyFields,
  LiquidConsistencyTallyDefaults,
  LiquidConsistencyTallyFields,
  MealExtrasTallyByWingDefaults,
  MealExtrasTallyByWingFields,
  MealExtrasTallyDefaults,
  MealExtrasTallyFields,
  ResidentsPerDiningRoomDefaults,
  ResidentsPerDiningRoomFields,
} from "./reports/tallies.constants";

export const REPORT_CATEGORIES = {
  Mealcards: "Meal Cards",
  SnacksSupplements: "Snacks/Supplements",
  DietRosters: "Diet Rosters",
  Tallies: "Tallies",
  RosterReports: "Rosters Reports",
  ResidentCare: "Resident Care",
};

export const RECIPE_REPORT_TYPE = {
  MealCards: {
    name: "Meal Cards by Meal",
    id: 1,
    form: MealCardFields,
    defaultValues: MealCardDefaultValues,
  },
  MealCardsAllMeals: {
    name: "Meal Cards for All Meals",
    id: 3,
    form: MealCardAllMealsFields,
    defaultValues: MealCardAllMealsDefaultValues,
  },
  MealCardsAllMealsWithMenu: {
    name: "Meal Cards/All Meals-with Menu",
    id: 38,
    form: MealCardAllMealsWithMenuFields,
    defaultValues: MealCardsAllMealsWithMenuDefaultValues,
  },
  MealCardsWithMenu: {
    name: "Meal Cards-with Menu",
    id: 37,
    form: MealCardsWithMenuFields,
    defaultValues: MealCardsWithMenuDefaultValues,
  },
  SnackSupplementLabels: {
    name: "Snack/Supplement Labels",
    id: 28,
    form: SnackSupplementLabelsFields,
    defaultValues: SnackSupplementLabelsDefaults,
  },
  SnackSupplementProduction: {
    name: "Snack/Supplement Production",
    id: 26,
    form: SnackSupplementProductionsFields,
    defaultValues: SnackSupplementProductionsDefaults,
  },
  ByBuildingWing: {
    name: "By Building/Wing",
    id: 8,
    dietRosterSortOrder: 1,
    form: ByBuildingWingFields,
    defaultValues: ByBuildingWingDefaults,
  },
  ByDiet: {
    name: "By Diet",
    id: 8,
    dietRosterSortOrder: 2,
    form: ByDietFields,
    defaultValues: ByDietDefaults,
  },
  ByDietTexture: {
    name: "By Diet Texture",
    id: 21,
    dietRosterSortOrder: 3,
    form: ByDietTextureFields,
    defaultValues: ByDietTextureDefaults,
  },
  ByMealDiningArea: {
    name: "By Meal/Dining Area",
    id: 48,
    dietRosterSortOrder: 5,
    form: ByMealDiningAreaFields,
    defaultValues: ByMealDiningAreaDefaults,
  },
  ByResident: {
    name: "By Resident",
    id: 8,
    dietRosterSortOrder: 6,
    form: ByResidentFields,
    defaultValues: ByResidentDefaults,
  },
  ByLiquidConsistency: {
    name: "By Liquid Consistency",
    id: 22,
    dietRosterSortOrder: 4,
    form: ByLiquidConsistencyFields,
    defaultValues: ByLiquidConsistencyDefaults,
  },
  DislikeAllergies: {
    name: "Dislike/Allergies",
    id: 35,
    form: ByDislikeAllergiesFields,
    defaultValues: ByDislikeAllergiesDefaults,
  },
  FluidRestrictions: {
    name: "Fluid Restrictions",
    id: 43,
    form: ByFluidRestrictionFields,
    defaultValues: ByFluidRestrictionDefaults,
  },
  AdaptiveEquipmentTally: {
    name: "Adaptive Equipment Tally",
    id: 15,
    form: AdaptiveEquipmentTallyFields,
    defaultValues: AdaptiveEquipmentTallyDefaults,
  },
  AllergyTally: {
    name: "Allergy Tally",
    id: 20,
    form: AllergyTallyFields,
    defaultValues: AllergyTallyDefaults,
  },
  // BeverageTally: {
  //   name: "Beverage Tally",
  //   id: 16,
  //   form: BeverageTallyFields,
  //   defaultValues: BeverageTallyDefaults,
  // },
  // BeverageTallyByBuildingWing: {
  //   name: "Beverage Tally - By Building/Wing",
  //   id: 18,
  //   form: BeverageTallyByWingFields,
  //   defaultValues: BeverageTallyByWingDefaults,
  // },
  DietTally: {
    name: "Diet Tally",
    id: 13,
    form: DietTallyFields,
    defaultValues: DietTallyDefaults,
  },
  DietTextureTally: {
    name: "Diet Texture Tally",
    id: 44,
    form: DietTextureTallyFields,
    defaultValues: DietTextureTallyDefaults,
  },
  DietTextureTallyByBuildingWing: {
    name: "Diet Texture Tally - By Building/Wing",
    id: 45,
    form: DietTextureByWingTallyFields,
    defaultValues: DietTextureByWingTallyDefaults,
  },
  DietOtherTally: {
    name: "Diet Other Tally",
    id: 34,
    form: DietOtherTallyFields,
    defaultValues: DietOtherTallyDefaults,
  },
  DislikeIntoleranceTally: {
    name: "Dislike/Intolerance Tally",
    id: 11,
    form: DislikeIntoleranceTallyFields,
    defaultValues: DislikeIntoleranceTallyDefaults,
  },
  ResidentsPerDiningRoom: {
    name: "Residents per Dining Room",
    id: 4,
    form: ResidentsPerDiningRoomFields,
    defaultValues: ResidentsPerDiningRoomDefaults,
  },
  LiquidConsistencyTally: {
    name: "Liquid Consistency Tally",
    id: 14,
    form: LiquidConsistencyTallyFields,
    defaultValues: LiquidConsistencyTallyDefaults,
  },
  MealExtrasTally: {
    name: "Meal Add-Ons Tally",
    id: 50,
    form: MealExtrasTallyFields,
    defaultValues: MealExtrasTallyDefaults,
  },
  MealExtrasTallyByBuildingWing: {
    name: "Meal Add-Ons Tally - By Building/Wing",
    id: 51,
    form: MealExtrasTallyByWingFields,
    defaultValues: MealExtrasTallyByWingDefaults,
  },
  AdaptiveEquipmentRoster: {
    name: "Adaptive Equipment Roster",
    id: 32,
    form: AdaptiveEquipmentRosterFields,
    defaultValues: AdaptiveEquipmentRosterDefaults,
  },
  AllergyRoster: {
    name: "Allergy Roster",
    id: 46,
    form: AllergyRosterFields,
    defaultValues: AllergyRosterDefaults,
  },
  // BeverageRoster: {
  //   name: "Beverage Roster",
  //   id: 40,
  //   form: BeverageRosterFields,
  //   defaultValues: BeverageRosterDefaults,
  // },
  MealExtrasRoster: {
    name: "Meal Add-Ons Roster",
    id: 52,
    form: MealExtrasRosterFields,
    defaultValues: MealExtrasRosterDefaults,
  },
  DislikesRoster: {
    name: "Dislikes Roster",
    id: 47,
    form: DislikeRosterFields,
    defaultValues: DislikeRosterDefaults,
  },
  FluidRestrictionRoster: {
    name: "Fluid Restriction Roster",
    id: 41,
    form: FluidRestrictionRosterFields,
    defaultValues: FluidRestrictionRosterDefaults,
  },
  SnackRoster: {
    name: "Snack/Supplement Roster",
    id: 36,
    form: SnackRosterFields,
    defaultValues: SnackRosterDefaults,
  },
  SupplementRoster: {
    name: "Supplement Roster",
    id: 39,
    form: SupplementRosterFields,
    defaultValues: SupplementRosterDefaults,
  },
  TubeFeedingRoster: {
    name: "Tube Feeding Roster",
    id: 31,
    form: TubeFeedingRosterFields,
    defaultValues: TubeFeedingRosterDefaults,
  },
  AssessmentsDue: {
    name: "Assessments Due",
    id: 24,
    form: AssessmentsDueFields,
    defaultValues: AssessmentsDueDefaults,
  },
  AssessmentsDueLessThanMonth: {
    name: "Assessments Due (< 30 days)",
    id: 5,
    form: AssessmentsLessThanMonthDueFields,
    defaultValues: AssessmentsLessThanMonthDefaults,
  },
  BirthdayList: {
    name: "Birthday List",
    id: 7,
    form: BirthdayListFields,
    defaultValues: BirthdayListDefaults,
  },
  CarePlansDueLessThanMonth: {
    name: "Care Plans Due (< 30 days)",
    id: 6,
    form: CarePlanLessThanMonthDueFields,
    defaultValues: CarePlanLessThanMonthDefaults,
  },
  CarePlansDue: {
    name: "Care Plans Due",
    id: 25,
    form: CarePlansFields,
    defaultValues: CarePlansDefaults,
  },
  NewAdmissionsLast4Days: {
    name: "New Admissions (Last 4 days)",
    id: 42,
    form: NewAdmissionsLast4DaysFields,
    defaultValues: NewAdmissionsLast4DaysDefaults,
  },
  ResidentProfile: {
    name: "Resident Profile",
    id: 19,
    form: ResidentProfileFields,
    defaultValues: ResidentProfileDefaults,
  },
  ResidentWeightName: {
    name: "Resident Weight (Name)",
    id: 17,
    form: ResidentWeightNameFields,
    defaultValues: ResidentWeightNameDefaults,
  },
  ResidentWeightBWR: {
    name: "Resident Weight (B/W/R)",
    id: 29,
    form: ResidentWeightBWERFields,
    defaultValues: ResidentWeightBWERDefaults,
  },
  ResidentsByDoctor: {
    name: "Residents by Doctor",
    id: 10,
    form: ResidentsByDoctorFields,
    defaultValues: ResidentsByDoctorDefaults,
  },
  WeeklyWeightReport: {
    name: "Weekly Weight Report",
    id: 30,
    form: WeeklyWeightReportFields,
    defaultValues: WeeklyWeightReportDefaults,
  },
};

export const MealCardReports = [
  {
    id: RECIPE_REPORT_TYPE.MealCards.name,
    label: RECIPE_REPORT_TYPE.MealCards.name,
  },
  {
    id: RECIPE_REPORT_TYPE.MealCardsAllMeals.name,
    label: RECIPE_REPORT_TYPE.MealCardsAllMeals.name,
  },
  {
    id: RECIPE_REPORT_TYPE.MealCardsAllMealsWithMenu.name,
    label: RECIPE_REPORT_TYPE.MealCardsAllMealsWithMenu.name,
  },
  {
    id: RECIPE_REPORT_TYPE.MealCardsWithMenu.name,
    label: RECIPE_REPORT_TYPE.MealCardsWithMenu.name,
  },
];

export const SnacksSupplements = [
  {
    id: RECIPE_REPORT_TYPE.SnackSupplementLabels.name,
    label: RECIPE_REPORT_TYPE.SnackSupplementLabels.name,
  },
  {
    id: RECIPE_REPORT_TYPE.SnackSupplementProduction.name,
    label: RECIPE_REPORT_TYPE.SnackSupplementProduction.name,
  },
];

export const DietRosters = [
  {
    id: RECIPE_REPORT_TYPE.ByBuildingWing.name,
    label: RECIPE_REPORT_TYPE.ByBuildingWing.name,
  },
  {
    id: RECIPE_REPORT_TYPE.ByDiet.name,
    label: RECIPE_REPORT_TYPE.ByDiet.name,
  },
  {
    id: RECIPE_REPORT_TYPE.ByDietTexture.name,
    label: RECIPE_REPORT_TYPE.ByDietTexture.name,
  },
  {
    id: RECIPE_REPORT_TYPE.ByMealDiningArea.name,
    label: RECIPE_REPORT_TYPE.ByMealDiningArea.name,
  },
  {
    id: RECIPE_REPORT_TYPE.ByResident.name,
    label: RECIPE_REPORT_TYPE.ByResident.name,
  },
  {
    id: RECIPE_REPORT_TYPE.ByLiquidConsistency.name,
    label: RECIPE_REPORT_TYPE.ByLiquidConsistency.name,
  },
  {
    id: RECIPE_REPORT_TYPE.DislikeAllergies.name,
    label: RECIPE_REPORT_TYPE.DislikeAllergies.name,
  },
  {
    id: RECIPE_REPORT_TYPE.FluidRestrictions.name,
    label: RECIPE_REPORT_TYPE.FluidRestrictions.name,
  },
];

export const Tallies = [
  {
    id: RECIPE_REPORT_TYPE.AdaptiveEquipmentTally.name,
    label: RECIPE_REPORT_TYPE.AdaptiveEquipmentTally.name,
  },
  {
    id: RECIPE_REPORT_TYPE.AllergyTally.name,
    label: RECIPE_REPORT_TYPE.AllergyTally.name,
  },
  // {
  //   id: RECIPE_REPORT_TYPE.BeverageTally.name,
  //   label: RECIPE_REPORT_TYPE.BeverageTally.name,
  // },
  // {
  //   id: RECIPE_REPORT_TYPE.BeverageTallyByBuildingWing.name,
  //   label: RECIPE_REPORT_TYPE.BeverageTallyByBuildingWing.name,
  // },
  {
    id: RECIPE_REPORT_TYPE.DietTally.name,
    label: RECIPE_REPORT_TYPE.DietTally.name,
  },
  {
    id: RECIPE_REPORT_TYPE.DietTextureTally.name,
    label: RECIPE_REPORT_TYPE.DietTextureTally.name,
  },
  {
    id: RECIPE_REPORT_TYPE.DietTextureTallyByBuildingWing.name,
    label: RECIPE_REPORT_TYPE.DietTextureTallyByBuildingWing.name,
  },
  {
    id: RECIPE_REPORT_TYPE.DietOtherTally.name,
    label: RECIPE_REPORT_TYPE.DietOtherTally.name,
  },
  {
    id: RECIPE_REPORT_TYPE.DislikeIntoleranceTally.name,
    label: RECIPE_REPORT_TYPE.DislikeIntoleranceTally.name,
  },
  {
    id: RECIPE_REPORT_TYPE.MealExtrasTally.name,
    label: RECIPE_REPORT_TYPE.MealExtrasTally.name,
  },
  {
    id: RECIPE_REPORT_TYPE.MealExtrasTallyByBuildingWing.name,
    label: RECIPE_REPORT_TYPE.MealExtrasTallyByBuildingWing.name,
  },
  {
    id: RECIPE_REPORT_TYPE.ResidentsPerDiningRoom.name,
    label: RECIPE_REPORT_TYPE.ResidentsPerDiningRoom.name,
  },
  {
    id: RECIPE_REPORT_TYPE.LiquidConsistencyTally.name,
    label: RECIPE_REPORT_TYPE.LiquidConsistencyTally.name,
  },
];

export const RostersReports = [
  {
    id: RECIPE_REPORT_TYPE.AdaptiveEquipmentRoster.name,
    label: RECIPE_REPORT_TYPE.AdaptiveEquipmentRoster.name,
  },
  {
    id: RECIPE_REPORT_TYPE.AllergyRoster.name,
    label: RECIPE_REPORT_TYPE.AllergyRoster.name,
  },
  // {
  //   id: RECIPE_REPORT_TYPE.BeverageRoster.name,
  //   label: RECIPE_REPORT_TYPE.BeverageRoster.name,
  // },
  {
    id: RECIPE_REPORT_TYPE.DislikesRoster.name,
    label: RECIPE_REPORT_TYPE.DislikesRoster.name,
  },
  {
    id: RECIPE_REPORT_TYPE.FluidRestrictionRoster.name,
    label: RECIPE_REPORT_TYPE.FluidRestrictionRoster.name,
  },
  {
    id: RECIPE_REPORT_TYPE.MealExtrasRoster.name,
    label: RECIPE_REPORT_TYPE.MealExtrasRoster.name,
  },
  {
    id: RECIPE_REPORT_TYPE.SnackRoster.name,
    label: RECIPE_REPORT_TYPE.SnackRoster.name,
  },
  // {
  //   id: RECIPE_REPORT_TYPE.SupplementRoster.name,
  //   label: RECIPE_REPORT_TYPE.SupplementRoster.name,
  // },
  {
    id: RECIPE_REPORT_TYPE.TubeFeedingRoster.name,
    label: RECIPE_REPORT_TYPE.TubeFeedingRoster.name,
  },
];

export const ResidentCare = [
  {
    id: RECIPE_REPORT_TYPE.AssessmentsDue.name,
    label: RECIPE_REPORT_TYPE.AssessmentsDue.name,
  },
  {
    id: RECIPE_REPORT_TYPE.AssessmentsDueLessThanMonth.name,
    label: RECIPE_REPORT_TYPE.AssessmentsDueLessThanMonth.name,
  },
  {
    id: RECIPE_REPORT_TYPE.BirthdayList.name,
    label: RECIPE_REPORT_TYPE.BirthdayList.name,
  },
  {
    id: RECIPE_REPORT_TYPE.CarePlansDueLessThanMonth.name,
    label: RECIPE_REPORT_TYPE.CarePlansDueLessThanMonth.name,
  },
  {
    id: RECIPE_REPORT_TYPE.CarePlansDue.name,
    label: RECIPE_REPORT_TYPE.CarePlansDue.name,
  },
  {
    id: RECIPE_REPORT_TYPE.NewAdmissionsLast4Days.name,
    label: RECIPE_REPORT_TYPE.NewAdmissionsLast4Days.name,
  },
  {
    id: RECIPE_REPORT_TYPE.ResidentProfile.name,
    label: RECIPE_REPORT_TYPE.ResidentProfile.name,
  },
  {
    id: RECIPE_REPORT_TYPE.ResidentWeightName.name,
    label: RECIPE_REPORT_TYPE.ResidentWeightName.name,
  },
  {
    id: RECIPE_REPORT_TYPE.ResidentWeightBWR.name,
    label: RECIPE_REPORT_TYPE.ResidentWeightBWR.name,
  },
  {
    id: RECIPE_REPORT_TYPE.ResidentsByDoctor.name,
    label: RECIPE_REPORT_TYPE.ResidentsByDoctor.name,
  },
  {
    id: RECIPE_REPORT_TYPE.WeeklyWeightReport.name,
    label: RECIPE_REPORT_TYPE.WeeklyWeightReport.name,
  },
];
