import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { WEIGHT_ALERTS, RESIDENT_WEIGHT_BULK_ENTRY } = API_ENDPOINTS;

//${WEIGHT_ALERTS}APIs for mealcard
export const WeightAlertAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // define an endpoint for getting Weight Alerts of mealCard
    getWeightAlert: builder.query({
      query: ({ facilityId }) =>
        `/${WEIGHT_ALERTS}/${facilityId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: WEIGHT_ALERTS, id })),
              WEIGHT_ALERTS,
            ]
          : [WEIGHT_ALERTS];
      },
    }),
    updateWeightAlert: builder.mutation({
      query: (data) => ({
        url: `/${WEIGHT_ALERTS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: WEIGHT_ALERTS, id: result?.id }],
    }),
    // define an endpoint for getting Bulk Weight Entry of mealCard
    getWeightBulk: builder.query({
      query: ({ facilityId }) =>
        `/${RESIDENT_WEIGHT_BULK_ENTRY}/BulkEntry?FacilityID=${facilityId}&sortorder=0`,
      transformResponse: (response) => response?.data,
    }),
    addWeightBulk: builder.mutation({
      query: (data) => ({
        url: `/${RESIDENT_WEIGHT_BULK_ENTRY}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: WEIGHT_ALERTS, id: result?.id }],
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetWeightAlertQuery,
  useUpdateWeightAlertMutation,
  useGetWeightBulkQuery,
  useAddWeightBulkMutation,
} = WeightAlertAPIs;
