import {
  formatTypeId,
  startingDay,
  endingDay,
  offset6,
} from "../form.constants";

export const RecipeListForms = {
  Category: true,
  DayForm: [startingDay, offset6, endingDay, offset6],
  Diet: false,
  Report: [formatTypeId],
};
