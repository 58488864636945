import React from "react";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import { Box, Grid, Typography } from "@mui/material";
import {
  productionSheetCategories,
  productionSheetExtras,
} from "../constants/reports/productionSheet";

const Categories = ({ control, errors }) => {
  return (<>
    <Box sx={{
      width: "100%"
    }}>
      <Box>
        <Typography variant="h5" gutterBottom>
          Menu Categories
        </Typography>
      </Box>
      <Box sx={{ border: "1px solid #55c2c5", borderRadius: 2 }}>
        <Grid container spacing={2} sx={{
          padding: 3
        }}>
          <GeneratedForm
            list={productionSheetCategories}
            control={control}
            errors={errors}
          />
        </Grid>
      </Box>
    </Box>
    <Box sx={{
      width: "100%"
    }}>
      <Box>
        <Typography variant="h5" gutterBottom>
          Extras
        </Typography>
      </Box>
      <Box sx={{ border: "1px solid #55c2c5", borderRadius: 2 }}>
        <Grid container spacing={2} sx={{
          padding: 3
        }}>
          <GeneratedForm
            list={productionSheetExtras}
            control={control}
            errors={errors}
          />
        </Grid>
      </Box>
    </Box>
  </>);
};

export default Categories;
