import React from "react";
import Field from "./Field";
import { FIELD_GROUP_FORM } from "./forms.constants";
import GenericFieldForm from "./GenericFieldForm";

export default function FieldGroup({
  control,
  nestIndex,
  sectionIndex,
  errors,
}) {
  return (
    <GenericFieldForm
      name={`sectionGroups[${sectionIndex}].sections[${nestIndex}].fieldGroups`}
      control={control}
      errors={errors}
      fieldList={FIELD_GROUP_FORM}
      appendObjectExample={{ fields: [{}] }}
      nameOfObject={"Field Group"}
      fieldPropName={`sectionGroups[${sectionIndex}].sections[${nestIndex}].fieldGroups`}
      ChildComponent={Field}
      childProps={{
        control,
        errors,
        fieldGroupIndex: nestIndex,
        sectionIndex: sectionIndex,
      }}
      isSortOrder={true}></GenericFieldForm>
  );
}
