import { Box, Button, Grid } from "@mui/material";
import React from "react";

import { useSearchParams, Link } from "react-router-dom";
import { useGetMenuMissingVendorListQuery } from "../../../../store/apis/MenuApis";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import { WarningText } from "../../../shared/WarningText";
import BaseContent from "../../../shared/baseContent/BaseContent";
import BaseActionBox from "components/shared/BaseActionBox";
import CreateBaseColumns from "components/shared/CreateBaseColumn";

export default function MissingVendor() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("menuId");

  const {
    data: MissingVendorItemsData,
    isError: hasError,
    isLoading,
  } = useGetMenuMissingVendorListQuery({ menuId: id });
  const vendorColumns = [
    {
      field: "name",
      minWidth: 100,
      flex: 3,
      headerName: "Ingredient Name",
      renderCell: (params) => {
        return (
          <Link
            to={`/plateFul/Menus/MissingVendors/editIngredient?id=${params.row.ingredientId}&menuId=${id}`}
            style={{ color: "black" }}
            target="_blank"
            rel="noopener noreferrer">
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox childrenLocation={"end"}>
            <Button
              component={Link}
              color={"primary"}
              to={`/plateFul/Menus/MissingVendors/VendorList?id=${params.row.ingredientId}&name=${params.row.name}&menuId=${id}`}
              state={{
                title: params.row.menuName,
                data: params.row,
              }}
              variant="contained"
              target="_blank"
              rel="noopener noreferrer">
              Add Vendor to Ingredient
            </Button>
          </BaseActionBox>
        );
      },
    },
  ];

  return (
    <>
      <BaseContent
        backLink={"/plateful/Menus"}
        headerText={"Missing Vendor Items"}
        disableHeight={true}>
        <Box
          sx={{
            height: { md: "80vh", xl: "84vh" },
          }}>
          <Grid container>
            <WarningText
              margin={"10px 0"}
              text={`Check the 'Ignore on Shopping List' checkbox for any ingredient that you want to remove from this list. This will keep the ingredient from being transmitted to any vendor.`}
              width="100%"
            />
            <BaseDataGrid
              getRowId={(row) => row.ingredientId}
              rows={MissingVendorItemsData}
              loading={isLoading}
              columns={CreateBaseColumns(vendorColumns)}
              error={hasError}
              autoHeight={false}
              height={{ md: "65vh", xl: "75vh" }}
            />
          </Grid>
        </Box>
      </BaseContent>
    </>
  );
}
