import { Box, Skeleton, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DMStyles } from "../../../../../../styles/Styles";

// import { KeysButton, typeAbbreviations } from "../../constants";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import {
  useGetVisitsByCorporateGroupQuery,
  useGenerateVisitReportsMutation,
  useGenerateVisitReportMutation,
} from "../../../../../../store/apis/ConsultantApis";
import { DownloadLinkHelper } from "../../../../../shared/utils/helpers";
import { Link, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";

import { SCREEN_CONSTANTS } from "components/shared/constants";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import { theme } from "styles/theme";
import { size } from "lodash";
import { useGetVisitReportTypesQuery } from "store/apis/VisitReportAPIs";
import BaseReportChip from "components/shared/BaseReportChip";
import { Print } from "@mui/icons-material";
import FlexBox from "components/shared/styledComponents/FlexBox";
import CreateBaseColumns from "components/shared/CreateBaseColumn";

export const EnterpriseProfile = () => {
  const [loadingId, setLoadingId] = useState(null);

  const [searchParams] = useSearchParams();
  const facilityId = searchParams.get("facilityId");
  const corporateId = searchParams.get("corporateId");
  const facilityCorporateGroup = searchParams.get("facilityCorporateGroup");

  const {
    data: currentFacility,
    isError: hasError,
    isLoading: loading,
  } = useGetVisitsByCorporateGroupQuery(corporateId);

  const { data: reportTypeData, isLoading: reportTypeLoading } =
    useGetVisitReportTypesQuery();

  const [
    GenerateReports,
    {
      data: report,
      isError: reportError,
      isSuccess: isSuccess,
      isLoading: reportLoading,
    },
  ] = useGenerateVisitReportsMutation();

  const [
    GenerateReport,
    {
      data: visitreport,
      isError: visitreportError,
      isSuccess: isvisitSuccess,
      isLoading: visitreportLoading,
    },
  ] = useGenerateVisitReportMutation();

  const EnterpriseData = currentFacility ? currentFacility : [];
  const isError = hasError || reportError || visitreportError;
  const isLoading = loading;

  useEffect(() => {
    if (isSuccess) {
      DownloadLinkHelper(report?.data);
    }
    if (isvisitSuccess) {
      DownloadLinkHelper(visitreport?.data);
    }
  }, [report, visitreport]);

  const EnterpriseColumns = [
    {
      field: "facilityName",
      flex: 1,
      headerName: "Community/Date",

      renderCell: (params) => {
        const isDateValid = dayjs(params?.row?.dateOfVisit).isValid();

        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}>
            <Link
              to={`/Consultant/Community/Profile?facilityId=${facilityId}&facilityName=${params.row.facilityName}`}
              style={{ color: "rgba(42, 83, 126, 1)", marginBottom: "5px" }}>
              {params.row["facilityName"]}
            </Link>
            {isDateValid
              ? dayjs(params?.row?.dateOfVisit).format(
                  SCREEN_CONSTANTS.Date_Format
                )
              : "--"}
          </Box>
        );
      },
    },
    // {
    //   field: "dateOfVisit",
    //   flex: 1,
    //   headerName: "Dates",
    //   valueGetter: (params) => {
    //     return params.value ? dayjs(params.value).toDate() : "";
    //   },
    //   valueFormatter: (params) => {
    //     const isDateValid = dayjs(params?.value).isValid();
    //     return isDateValid
    //       ? dayjs(params?.value).format(SCREEN_CONSTANTS.Date_Format)
    //       : "--";
    //   },
    // },

    {
      field: "dietitianName",
      flex: 1.5,
      headerName: "Consultant",
    },
    {
      field: "visitTypes",
      flex: 5,
      headerName: "Reports",
      renderCell: (params) => {
        return size(params?.row?.visitTypes) < 1 ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}>
            <Typography
              sx={{
                textAlign: "center",
              }}>
              No Reports Available
            </Typography>
          </Box>
        ) : (
          <FlexBox flexWrap={"wrap"} justifyContent={"start"} gap={0}>
            {reportTypeLoading ? (
              <Skeleton />
            ) : (
              <>
                {params?.row?.visitTypes.map((item) => {
                  return (
                    <BaseReportChip
                      key={item?.id}
                      id={item?.id}
                      typeId={item?.typeId}
                      onClick={() => {
                        setLoadingId(`report-${item?.id}`);
                        GenerateReport({ id: item?.id });
                      }}
                      loading={
                        visitreportLoading && loadingId === `report-${item?.id}`
                      }
                      reportTypes={reportTypeData}
                    />
                  );
                })}
              </>
            )}
          </FlexBox>
        );
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        const isDisabled = params?.row?.visitTypes?.length < 1;
        return (
          <>
            {!isDisabled ? (
              <Tooltip title={"Print All"}>
                <FlexBox>
                  <BaseSubmitButton
                    icon="true"
                    text={<Print />}
                    isSubmitting={
                      reportLoading && loadingId == `reports-${params.row.id}`
                        ? true
                        : false
                    }
                    onClick={() => {
                      setLoadingId(`reports-${params.row.id}`);
                      GenerateReports({ id: params.row.id });
                    }}
                  />
                </FlexBox>
              </Tooltip>
            ) : null}
          </>
        );
      },
    },
  ];

  return (
    <Box
      id="enterprise-profile"
      sx={{
        padding: "10px",
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          p: "16px",
          color: DMStyles.White,
          bgcolor: theme.palette.primary.main,
        }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 500,
          }}>
          Corporate Profile
        </Typography>

        {!facilityCorporateGroup ? (
          <Skeleton variant="text" width={200} height={40} />
        ) : (
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
            }}>
            {facilityCorporateGroup}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          marginY: "10px",
        }}>
        <Typography
          id={`copy-to-corporate-group-modal-title`}
          x
          variant="h6"
          component="h2"
          className="modalTitle">
          Past Visits
        </Typography>
      </Box>
      <Box
        sx={{
          height: "60vh",
        }}>
        <BaseDataGrid
          rows={EnterpriseData || []}
          columns={CreateBaseColumns(EnterpriseColumns)}
          loading={isLoading}
          error={isError}
          getRowId={(row) => row.id}
          height={"100%"}
          getRowHeight={() => "auto"}
          sx={{
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: "8px",
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "15px",
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
              py: "22px",
            },
          }}
        />
      </Box>
    </Box>
  );
};
