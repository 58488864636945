import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  month: null,
};

export const monthSlice = createSlice({
  name: "month",
  initialState,
  reducers: {
    setMonth: (state, action) => {
      state.month = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMonth } = monthSlice.actions;

export default monthSlice.reducer;
