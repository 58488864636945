import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Controller } from "react-hook-form";
import { FormControl, FormHelperText } from "@mui/material";

export default function BaseMultiSelectSearch({
  control,
  validationProps,
  options = [],
  defaultValue,
  errors,
  ...rest
}) {
  return (
    <FormControl fullWidth>
      <Controller
        control={control}
        name={rest?.name}
        rules={{ ...validationProps }}
        defaultValue={defaultValue || []}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            multiple
            disablePortal
            options={options.filter((option) => !value?.includes(option.id))} // Filter selected values from options
            getOptionLabel={(option) => option.label || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, value) => onChange(value.map((v) => v.id))}
            renderInput={(params) => <TextField {...params} {...rest} />}
          />
        )}
      />

      <FormHelperText error={!!errors?.[rest?.name]}>
        {errors?.[rest?.name]?.message ?? ""}
      </FormHelperText>
    </FormControl>
  );
}
