import { Typography } from "@mui/material";
import React from "react";
import { useDrag } from "react-dnd";
import { ButtonWithCard } from "./ButtonWithCard";

export const Card = ({ item }) => {
  const { facilityName, facilityId, id, dietitianId, visitId, visitPlanId } =
    item;
  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: "event",
      item: { ...item },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [item]
  );

  return (
    <>
      <div
        className="fc-event"
        ref={dragRef}
        title={facilityName}
        data-facilityid={`${facilityId}`}
        data-dietitianid={`${dietitianId}`}
        data-visitid={`${visitId}`}
        data-visitplanid={`${visitPlanId}`}
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "90%",
          backgroundColor: "#FCFCFC",
          padding: "8px",
          marginBottom: "10px",
          opacity: isDragging ? "0.7px " : "1px",
        }}>
        <Typography>{facilityName}</Typography>
        <ButtonWithCard
          id={id}
          facilityId={facilityId}
          dietitianId={dietitianId}
          visitId={visitId}
        />
      </div>
    </>
  );
};
