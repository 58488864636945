import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { DOCUMENTS } = API_ENDPOINTS;

export const DocumentApi = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // define an endpoint for getting Diets
    getDocumentList: builder.query({
      query: () => `/${DOCUMENTS}/GetFrontPageDocuments`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [...response.map(({ id }) => ({ type: DOCUMENTS, id })), DOCUMENTS]
          : [DOCUMENTS];
      },
    }),
    getCorporateGroupDocumentList: builder.query({
      query: (facilityId) =>
        `/${DOCUMENTS}/GetCorporateGroupDocuments?facilityId=${facilityId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [...response.map(({ id }) => ({ type: DOCUMENTS, id })), DOCUMENTS]
          : [DOCUMENTS];
      },
    }),
    getDocumentById: builder.query({
      query: (documentId) => `/${DOCUMENTS}/${documentId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: DOCUMENTS, id: response.id }, DOCUMENTS]
          : [DOCUMENTS],
    }),
    deleteDocument: builder.mutation({
      query: (id) => ({
        url: `/${DOCUMENTS}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: DOCUMENTS, id: arg.id },
      ],
    }),
    addDocument: builder.mutation({
      query: (data) => ({
        url: `/${DOCUMENTS}`,
        method: "POST",
        body: data,
        formData: true,
      }),
      invalidatesTags: (result) => [{ type: DOCUMENTS, id: result?.id }],
    }),
    updateDocument: builder.mutation({
      query: (data) => ({
        url: `/${DOCUMENTS}/`,
        method: "PUT",
        body: data,
        formData: true,
      }),
      invalidatesTags: (result) => [{ type: DOCUMENTS, id: result?.id }],
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetDocumentListQuery,
  useGetCorporateGroupDocumentListQuery,
  useGetDocumentByIdQuery,
  useDeleteDocumentMutation,
  useAddDocumentMutation,
  useUpdateDocumentMutation,
} = DocumentApi;
