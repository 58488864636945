import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";

import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { useGetVendorProductBackfeedQuery } from "../../../../../../store/apis/VendorApis";
import BaseSearch from "../../../../../shared/baseSearch/BaseSearch";
import { BaseButton } from "../../../../../shared/BaseButton";

export default function VendorProducts() {
  const SEARCH_PARAM = "search";

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");

  const vendorId = searchParams.get("id");

  const location = useLocation();
  const [vendorName] = useState(location.state?.vendorName);

  const {
    data: userPagedData,
    isFetching: loading,
    isError: hasError,
    error: errorMessage,
  } = useGetVendorProductBackfeedQuery({
    vendorId: vendorId,
    searchTerm: debouncedSearchValue || "",
    skip: paginationModel.pageSize * paginationModel.page,
    take: paginationModel.pageSize,
  });
  const navigate = useNavigate();

  const vendorProducts = userPagedData?.items || [];

  const vendorProductColumns = [
    {
      field: "description",
      headerName: "Ingredient",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "vendorItemNumber",
      headerName: "Vendor Item #",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "casePrice",
      headerName: "Case Price",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "caseSize",
      headerName: "Case Size",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "uom",
      headerName: "UOM",
      flex: 1,
      minWidth: 100,
    },
  ];

  return (<>
    <Grid
      container
      spacing={0.5}
      sx={{
        marginTop: 0.5,
        paddingX: 1
      }}>
      <Grid item xs={5} sm={5} md={3} lg={2} xl={2}>
        <BaseButton
          text="Back To Vendors"
          startIcon={"keyboard_arrow_left"}
          onClick={() => navigate(`/admin/vendors/vendorSetup`)}
        />{" "}
      </Grid>
      <Grid item xs={7} sm={7} md={9} lg={10} xl={10}>
        <Typography variant="p">{`Vendor: ${vendorName}`}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <BaseSearch
          placeholder="Search..."
          disableUnderline={true}
          type="text"
          sx={{ ml: 1 }}
          SEARCH_PARAM={SEARCH_PARAM}
          value={searchValue}
          setSearchValue={setSearchValue}
          setDebouncedValue={setDebouncedSearchValue}
          setSearchParams={setSearchParams}
          isId={true}
          Id={vendorId}
        />{" "}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <BaseDataGrid
          rows={vendorProducts}
          rowCount={userPagedData?.totalCount || 0}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          loading={loading}
          columns={vendorProductColumns}
          error={hasError}
          errorMessage={errorMessage}
          autoHeight={false}
          height={{ md: "42vh", lg: "43vh", xl: "53vh" }}
        />
      </Grid>
    </Grid>
  </>);
}
