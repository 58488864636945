import React from "react";
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import mealCardIcon from "../../../assets/imgs/meal_card_check.svg";
import mealCardIconSecondary from "../../../assets/imgs/meal_card_cross.svg";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import profileImg from "../../../assets/imgs/profile_img.svg";
import uploadIcon from "../../../assets/imgs/upload_icon.svg";
import deleteIcon from "../../../assets/imgs/delete_icon.svg";
import dayjs from "dayjs";

export default function ResidentDetailsCardSection({
  photo,
  residentId,
  resident,
  photoEnabled,
  handleFileUpload,
  setOpenModal,
}) {
  return (
    <Grid
      container
      sx={{
        mt: 2,
        borderRadius: "4px",
        marginLeft: 1,
        boxShadow: "0px 4px 4px 0px #00000040",
      }}>
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: "var(--tealColor)",
          borderRadius: "4px 4px 0 0",
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "8px 16px 0px 16px",
          }}>
          <Box
            sx={{
              display: "flex",
              gap: "0.8rem",
              padding: "8px 16px",
            }}>
            <Box
              component="img"
              alt="Profile Image"
              src={photo ? photo : profileImg}
              sx={{
                border: "1px solid white",
                borderRadius: "4px !important",
                padding: "0.1rem",
                width: "6.25rem",
                height: "6.25rem",
                objectFit: "contain",
              }}></Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}>
                <Typography
                  component="span"
                  sx={{
                    textTransform: "uppercase",
                    fontSize: 28,
                    fontWeight: 800,
                    color: "white !important",
                  }}>
                  {resident.firstName} {resident.lastName}
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 600,
                    color: "white !important",
                  }}>
                  Preferred Name: {resident.nickname}
                </Typography>
                <Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "white !important",
                      lineHeight: "24.55px",
                      marginTop: "0.6rem",
                    }}>
                    Print Meal Card:
                    {resident.printTrayCard ? (
                      <Box
                        component="img"
                        alt="Default Profile Image"
                        src={mealCardIcon}></Box>
                    ) : (
                      <Box
                        component="img"
                        alt="Default Profile Image"
                        src={mealCardIconSecondary}></Box>
                    )}
                  </Box>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              paddingTop: "0.6rem",
            }}>
            <Link
              to={`/MealCard/addResident?id=${residentId}`}
              className="newEditButton">
              <Typography
                component="span"
                sx={{
                  fontWeight: 700,
                }}>
                Edit
              </Typography>
            </Link>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "1.03rem",
            gap: "0.2rem",
          }}>
          {photoEnabled ? (
            <Button
              component="label"
              sx={{
                height: "30px",
              }}>
              <input
                type="file"
                accept=".png, .jpg, ,.jpeg, .bmp"
                hidden
                onChange={handleFileUpload}
              />
              <Tooltip title="Click to upload a resident profile picture">
                <Box
                  component="img"
                  alt="Default Profile Image"
                  sx={{
                    border: "1px solid white",
                    padding: "0.3rem 0.4rem",
                    borderRadius: "6px",
                  }}
                  src={uploadIcon}></Box>
              </Tooltip>
            </Button>
          ) : null}

          {photo ? (
            <Box
              component="img"
              size="small"
              sx={{
                border: "1px solid white",
                padding: "0.3rem 0.5rem",
                borderRadius: "6px",
                cursor: "pointer",
                marginLeft: "-4px !important",
              }}
              onClick={() => {
                setOpenModal(true);
              }}
              src={deleteIcon}></Box>
          ) : null}
        </Box>

        <Grid
          item
          xs={12}
          sx={{
            mt: 2,
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "0 1rem",
            }}>
            <Grid
              container
              spacing={1}
              sx={{
                padding: "0 16px 8px 16px",
                display: "flex",
                justifyContent: "space-between",
              }}>
              <Grid
                item
                xs={6}
                sm={2.4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  flexGrow: 1,
                }}>
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 800,
                    color: "white !important",
                    fontSize: "20px",
                  }}>
                  Room Number:
                </Typography>
                <Typography
                  sx={{ color: "white !important", fontSize: "20px" }}>
                  {resident.roomNumber}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2.4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  flexGrow: 1,
                }}>
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 800,
                    color: "white !important",
                    fontSize: "20px",
                  }}>
                  Bed:
                </Typography>
                <Typography
                  sx={{ color: "white !important", fontSize: "20px" }}>
                  {resident.bed}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2.4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  flexGrow: 1,
                }}>
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 800,
                    color: "white !important",
                    fontSize: "20px",
                  }}>
                  Wing:
                </Typography>
                <Typography
                  sx={{ color: "white !important", fontSize: "20px" }}>
                  {resident.wing}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2.4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  flexGrow: 1,
                }}>
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 800,
                    color: "white !important",
                    fontSize: "20px",
                  }}>
                  Building:
                </Typography>
                <Typography
                  component="span"
                  sx={{ color: "white !important", fontSize: "20px" }}>
                  {resident.building}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2.4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  flexGrow: 1,
                }}>
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 800,
                    color: "white !important",
                    fontSize: "20px",
                  }}>
                  EHR Identifier:
                </Typography>
                <Typography
                  component="span"
                  sx={{ color: "white !important", fontSize: "20px" }}>
                  {resident.ahtpatientId}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0.5rem 1rem",
        }}>
        <Grid
          item
          xs={6}
          sm={2.4}
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexGrow: 1,
          }}>
          <Typography
            component="span"
            sx={{
              fontWeight: 700,
            }}>
            DOB:
          </Typography>
          <Typography>
            {resident.dateOfBirth
              ? dayjs(resident.dateOfBirth).format(SCREEN_CONSTANTS.Date_Format)
              : ""}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sm={2.4}
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexGrow: 1,
          }}>
          <Typography
            component="span"
            sx={{
              fontWeight: 700,
            }}>
            Isolation:
          </Typography>
          <Typography>{resident.isIsolation ? "Yes" : "No"}</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sm={2.4}
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexGrow: 1,
          }}>
          <Typography
            component="span"
            sx={{
              fontWeight: 700,
            }}>
            Room Tray:
          </Typography>
          <Typography>{resident.isRoomTray ? "Yes" : "No"}</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sm={2.4}
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexGrow: 1,
          }}>
          <Typography
            component="span"
            sx={{
              fontWeight: 700,
            }}>
            Unlimited Meals
          </Typography>
          <Typography component="span">
            {resident.hasUnlimitedMeals ? "Yes" : "No"}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sm={2.4}
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexGrow: 1,
          }}>
          <Typography
            component="span"
            sx={{
              fontWeight: 700,
            }}>
            Care Plan Due Date:
          </Typography>
          <Typography component="span">
            {resident.carePlanDueDate
              ? dayjs(resident.carePlanDueDate).format(
                  SCREEN_CONSTANTS.Date_Format
                )
              : ""}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0rem 1rem 0rem 1rem",
        }}>
        <Grid
          item
          xs={6}
          sm={2.4}
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexGrow: 1,
          }}>
          <Typography
            component="span"
            sx={{
              fontWeight: 700,
            }}>
            Sex:
          </Typography>
          <Typography>{resident.gender}</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sm={2.4}
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexGrow: 1,
          }}>
          <Typography
            component="span"
            sx={{
              fontWeight: 700,
            }}>
            Physician:
          </Typography>
          <Typography>{resident.physician}</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sm={2.4}
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexGrow: 1,
          }}>
          <Typography
            component="span"
            sx={{
              fontWeight: 700,
            }}>
            Admission Date:
          </Typography>
          <Typography>
            {resident.admissionDate
              ? dayjs(resident.admissionDate).format(
                  SCREEN_CONSTANTS.Date_Format
                )
              : ""}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sm={2.4}
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexGrow: 1,
          }}>
          <Typography
            component="span"
            sx={{
              fontWeight: 700,
            }}>
            Assessment Due Date:
          </Typography>
          <Typography component="span">
            {resident.assessmentDueDate
              ? dayjs(resident.assessmentDueDate).format(
                  SCREEN_CONSTANTS.Date_Format
                )
              : ""}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sm={2.4}
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexGrow: 1,
          }}>
          <Typography
            component="span"
            sx={{
              fontWeight: 700,
            }}>
            Resident Id:
          </Typography>
          <Typography component="span">{resident.residentIdNumber}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
