import React, { useEffect, useState } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  useGetBaseMenusQuery,
  useAddBaseMenuMutation,
} from "../../../../../store/apis/BaseMenuApi";
import { Button, FormControl, TextField } from "@mui/material";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "components/shared/baseSearch/BaseSearch";
// import styles from "./BaseMenus.module.css";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { Add } from "@mui/icons-material";

export default function BaseMenus() {
  const [newBaseMenu, setNewBaseMenu] = useState("");

  const appInsights = useAppInsightsContext();
  const trackNewBaseMenu = useTrackEvent(appInsights, "New Base Menu Added", {
    menu: newBaseMenu,
    title: "Another test",
    number: 5,
  });
  const isAuthenticated = useIsAuthenticated();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );

  const [
    addBaseMenu,
    { isError: addError, isSuccess: addSuccess, isLoading: addLoading },
  ] = useAddBaseMenuMutation();
  const {
    data: baseMenus,
    isError: hasError,
    isLoading: loading,
  } = useGetBaseMenusQuery(searchValue);

  const isError = addError || hasError;
  const isSuccess = addSuccess;
  const isLoading = addLoading || loading;

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(`Base Menu ${newBaseMenu} ${"added"} successfully.`, {
        variant: "success",
      });
      setNewBaseMenu("");
    }
  }, [isSuccess]);

  useEffect(() => {
    setSearchParams({ ["search"]: searchValue });
  }, [searchValue]);

  const onSubmit = async (data) => {
    data["name"] = newBaseMenu;
    if (isAuthenticated) {
      await addBaseMenu({ name: newBaseMenu });
      if (trackNewBaseMenu) trackNewBaseMenu();
    }
  };

  const baseMenuColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 4,
      minWidth: 100,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            hasDelete={true}
            deleteProps={{
              entityName: params.row?.name,
              apiPath: "deleteBaseMenu",
              title: "Base Menu",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <FlexBox
        justifyContent={"space-between"}
        my={"8px"}
        flexWrap={{ xs: "wrap", md: "nowrap" }}>
        <FormControl>
          <BaseSearch setSearchValue={setSearchValue} value={searchValue} />
        </FormControl>
        <FlexBox justifyContent={"end"}>
          <FormControl>
            <TextField
              size="small"
              type="text"
              variant="outlined"
              // className={styles.basicInput}
              value={newBaseMenu}
              placeholder="Enter new BaseMenu name"
              required={true}
              onChange={(event) => {
                setNewBaseMenu(event.target.value);
              }}
            />
          </FormControl>
          <Button
            color={"primary"}
            variant="contained"
            endIcon={<Add />}
            onClick={() => {
              if (newBaseMenu.trim().length > 0) {
                onSubmit({ newBaseMenu });
              } else {
                // display message that Base menu name is required?
              }
            }}>
            Add
          </Button>
        </FlexBox>
      </FlexBox>

      <BaseDataGrid
        loading={isLoading}
        columns={baseMenuColumns}
        error={isError}
        rows={baseMenus}
      />
    </>
  );
}
