import { MoreVert } from "@mui/icons-material";
import { Button, CircularProgress, Menu } from "@mui/material";
import React, { useState } from "react";

export default function BaseMenu({
  color = "warning",
  prefix = "menu",
  index,
  children,
  endIcon = null,
  loading,
  text,
  icon = "true",
  variant = undefined,
  loadingColor = "warning",
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id={`${prefix}-button-${index}`}
        aria-controls={open ? `${prefix}-${index}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        icon={icon}
        color={color}
        variant={variant}
        sx={icon == "false" ? null : { paddingX: "1px" }}
        startIcon={
          loading ? (
            <CircularProgress color={loadingColor} size={"1rem"} />
          ) : null
        }
        endIcon={endIcon}
        onClick={handleClick}>
        {text ? text : <MoreVert />}
      </Button>
      <Menu
        id={`edit-${prefix}-${index}`}
        MenuListProps={{
          "aria-labelledby": `${prefix}-button-${index}`,
        }}
        anchorEl={anchorEl}
        open={open}
        onClick={handleClose}
        onClose={handleClose}>
        {children}
      </Menu>
    </>
  );
}
