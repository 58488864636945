import {
  formatTypeId,
  includeAfternoonSnacks,
  includeEveningSnacks,
  includeMealOtherMenuOption,
  includeMorningSnacks,
  offset6,
} from "../form.constants";

export const NutrientAnalysisForms = {
  Category: true,
  Diet: true,
  SnackMeal: [
    includeMorningSnacks,
    offset6,
    includeAfternoonSnacks,
    offset6,
    includeEveningSnacks,
    offset6,
    includeMealOtherMenuOption,
    offset6,
  ],
  Report: [formatTypeId],
};
