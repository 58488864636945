import { Grid, Typography } from "@mui/material";
import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { useVendorRecipeProductsQuery } from "../../../../../../store/apis/RecipeAPIs";
import { BackButton } from "components/shared/BackButton";

export default function VendorRecipeProducts() {
  const [searchParams] = useSearchParams();
  const vendorId = searchParams.get("vendorId");
  const recipeId = searchParams.get("recipeId");

  const {
    data: vendorProducts,
    isFetching: loading,
    isError: hasError,
    error: errorMessage,
  } = useVendorRecipeProductsQuery(
    {
      vendorId: vendorId,
      recipeId: recipeId,
    },
    { skip: !recipeId || !vendorId }
  );

  const vendorProductColumns = [
    {
      field: "ingredientName",
      headerName: "Ingredient",
      flex: 2,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={`/admin/recipes/ingredients/VendorIngredient?id=${params.row["ingredientId"]}&name=${params.row["ingredientName"]}`}
              style={{ color: "rgba(0, 0, 0, 0.87)" }}>
              {params.row["ingredientName"]}
            </Link>
          </>
        );
      },
    },
    {
      field: "costPerServing",
      headerName: "Cost",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (params?.value ? `$ ${params.value}` : `$ 0`),
    },
  ];

  return (<>
    <Grid container spacing={1} sx={{
      padding: 1
    }}>
      <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
        <BackButton text="Back To Recipe Vendor Costs" />
      </Grid>
      <Grid item xs={6} sm={6} md={8} lg={9} xl={10}>
        <Typography
          sx={{
            marginLeft: "20px",
            textAlign: "center",
            fontWeight: "700",
            fontSize: "1.2rem"
          }}>{`"${vendorProducts?.vendorName}" ingredients listing for "${vendorProducts?.recipeName}"`}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <BaseDataGrid
          autoHeight={false}
          rows={vendorProducts?.recipeVendorIngredients}
          loading={loading}
          columns={vendorProductColumns}
          error={hasError}
          errorMessage={errorMessage}
          height={{ md: "60vh", lg: "61vh", xl: "63vh" }}
        />
      </Grid>
    </Grid>
  </>);
}
