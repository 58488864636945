import React, { useState } from "react";
import { Button, Box } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../store/slices/authSlice/AuthSlice";
import "./MealCard.css";

import { useGetResidentQuery } from "../../store/apis/ResidentApis";
import BaseDataGrid from "../shared/baseDataGrid/BaseDataGrid";
import BaseContent from "../shared/baseContent/BaseContent";
import BaseSearch from "../shared/baseSearch/BaseSearch";
import BaseActionBox from "components/shared/BaseActionBox";
import { GroupAdd, Print, Settings } from "@mui/icons-material";
import FlexBox from "components/shared/styledComponents/FlexBox";
import CreateBaseColumns from "components/shared/CreateBaseColumn";

export default function MealCard() {
  const { state } = useLocation();
  const facilityId = useSelector(selectFacilityId);
  const facilityName = useSelector((state) => state.userInfo.facilityName);

  const [debouncedValue, setDebouncedValue] = useState(state?.search || "");
  const [filterTray, setFilterTray] = useState(false);
  const [npo, setNPO] = useState(false);

  const [searchValue, setSearchValue] = useState(state?.search || "");

  const {
    data: MealCardResident,
    isFetching: loading,
    isError: hasError,
  } = useGetResidentQuery(
    {
      searchTerm: debouncedValue,
      facilityId: facilityId,
      filterNoTrayCards: filterTray,
      filterNPO: npo,
    },
    { skip: !facilityId, refetchOnMountOrArgChange: true }
  );

  const residents = MealCardResident?.data;

  const residentsColumns = [
    {
      field: "lastName",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={`/MealCard/resident/details?id=${params.row["id"]}`}
              style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}>
              {params.row["lastName"]}
            </Link>
          </>
        );
      },
    },
    {
      field: "firstName",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={`/MealCard/resident/details?id=${params.row["id"]}`}
              style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}>
              {params.row["firstName"]}
            </Link>
          </>
        );
      },
    },
    {
      field: "diningArea",
      flex: 2,
    },
    {
      field: "building",
    },
    {
      field: "wing",
    },
    {
      field: "roomNumber",
      headerName: "Room",
    },
    {
      field: "bed",
      headerName: "Bed",
    },

    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={residents}
            hasView={true}
            viewProps={{
              index: params.row.id,
              data: residents,
              To: `/MealCard/resident/details?id=${params.row.id}`,
              title: "Mealcard",
              state: {
                search: searchValue,
              },
            }}
          />
        );
      },
    },
  ];

  return (
    <BaseContent
      disableBack={true}
      headerText={`Resident List for ${facilityName ? facilityName : ""}`}>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          overflow: "hidden",
          flexDirection: "column",
        }}>
        <FlexBox justifyContent={"space-between"}>
          {MealCardResident?.canEdit ? (
            <Button
              component={Link}
              to="addResidentShortForm"
              color={"primary"}
              variant="contained"
              endIcon={<GroupAdd />}>
              Add New Resident
            </Button>
          ) : (
            <div style={{ width: "50%" }}></div>
          )}
          <FlexBox width={"50%"} justifyContent={"end"}>
            <Button
              component={Link}
              color={"error"}
              to={`printReport?id=${facilityId}`}
              variant="contained"
              endIcon={<Print />}>
              Print Report
            </Button>
            <Button
              component={Link}
              to="/MealCard/settings/changehistory"
              color={"warning"}
              variant="contained"
              endIcon={<Settings />}>
              MealCard Settings
            </Button>
          </FlexBox>
        </FlexBox>

        <Box sx={{ my: 1 }}>
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedValue}
          />
        </Box>

        <FlexBox
          justifyContent={"start"}
          flexDirection={"row"}
          marginBottom={"8px"}>
          <Button
            color={"primary"}
            sx={{ marginLeft: "0 !important" }}
            className={`${
              (filterTray == true || npo == true) && "baseButton-filter"
            }`}
            variant="contained"
            onClick={() => {
              setFilterTray(false);
              setNPO(false);
            }}>
            Census : {MealCardResident?.totalCensus}
          </Button>
          <Button
            color={"primary"}
            className={`${filterTray == false && "baseButton-filter"}`}
            variant="contained"
            onClick={() => {
              setFilterTray(true);
              setNPO(false);
            }}>
            No Tray Tickets : {MealCardResident?.totalNoTrayTickets}
          </Button>
          <Button
            color={"primary"}
            className={`${npo == false && "baseButton-filter"}`}
            variant="contained"
            onClick={() => {
              setNPO(true);
              setFilterTray(false);
            }}>
            NPO : {MealCardResident?.totalNPO}
          </Button>
        </FlexBox>
        <BaseDataGrid
          rows={residents}
          loading={loading}
          columns={CreateBaseColumns(residentsColumns)}
          error={hasError}
          getRowHeight={() => "auto"}
          height={"100%"}
          width={"100%"}
          EmptyRowMessage={"No resident found"}
          autoHeight={false}
          sx={{
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: "8px",
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "15px",
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
              py: "22px",
            },
          }}
        />
      </Box>
    </BaseContent>
  );
}
