import React, { useEffect } from "react";
import {
  useAddBeverageMutation,
  useUpdateBeverageMutation,
  useGetBeverageByIdQuery,
} from "../../../../../../store/apis/BeverageApis";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { BaseButton } from "../../../../../shared/BaseButton";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import GeneratedForm from "../../../../../shared/generatedForm/GeneratedForm";
import { EditSkeleton } from "../../../../admin.overlays";
import { BEVERAGES_FIELDS } from "./forms.constants";

export default function AddBeverage() {
  const [addBeverage, { isError: addError, isLoading: addLoading }] =
    useAddBeverageMutation();

  const [updateBeverage, { isError: updateError, isLoading: updateLoading }] =
    useUpdateBeverageMutation();

  const isError = addError || updateError;
  const isSubmitting = addLoading || updateLoading;
  const [searchParams] = useSearchParams();
  const currentRecipeCategoryId = searchParams.get("id");

  const isEdit = !!currentRecipeCategoryId;

  const {
    data: currentRecipeCategory,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetBeverageByIdQuery(currentRecipeCategoryId, { skip: !isEdit });

  const isEditLoading = isEdit && currentFetching;

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (currentSuccess && !currentFetching && currentRecipeCategory) {
      reset(currentRecipeCategory);
    }
  }, [currentRecipeCategory]);

  const onSubmit = (data) => {
    if (isEdit) {
      updateBeverage(data);
    } else {
      addBeverage({ ...data, defaultUnitName: "" });
    }
    if (!isError) {
      navigate(-1);
    }
  };

  return (<>
    {isEditLoading ? (
      <EditSkeleton />
    ) : (
      <>
        <div className="leftActions">
          <BaseButton
            text="Back To Beverages List"
            startIcon={"keyboard_arrow_left"}
            onClick={() => navigate(-1)}
          />
        </div>
        <Divider sx={{ marginBottom: "15px", border: "none" }} />
        <Box sx={{
          padding: "8px"
        }}>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}>
            <GeneratedForm
              control={control}
              list={BEVERAGES_FIELDS}
              errors={errors}
            />

            <Grid item md={6}>
              <BaseSubmitButton
                fullWidth
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
                text={isEdit ? "Submit" : "Create"}
              />
            </Grid>
            {isError ? (
              <Typography sx={{
                color: "error"
              }}>
                There was an error submitting the form, please try again.
              </Typography>
            ) : undefined}
          </Grid>
        </Box>
      </>
    )}
  </>);
}
