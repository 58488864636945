import { REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "../../../../../shared/generatedForm/GeneratedForm";

export const BEVERAGES_FIELDS = [
  {
    props: {
      name: "name",
      id: "name",
      label: "Name",
    },
    validationProps: {
      required: REQUIRED_ERROR("Name"),
    },
    size: 6,
  },
  {
    props: {
      name: "defaultUnitId",
      id: "defaultUnitId",
      label: "Default Unit",
    },
    size: 6,
    type: FORM_TYPES.SelectAPI,
    endpoint: "getBeverageUnit",
  },
  {
    props: {
      name: "isSupplement",
      id: "isSupplement",
      label: "Is Supplement",
    },
    size: 6,
    type: FORM_TYPES.Checkbox,
  },
];
