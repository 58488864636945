import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ContactUs.css";
import { ApiCall } from "../../utils/ApiCall";
import {
  ContactUsBlurb,
  ContactUsElements,
  ContactUsFields,
} from "./contactUs.constants";
import { Box, Modal } from "@mui/material";
import { DMStyles } from "../../styles/Styles";
import BaseContent from "../shared/baseContent/BaseContent";
import BaseInput from "../shared/baseForm/BaseInput";
import { useForm } from "react-hook-form";

function ContactUs() {
  const navigate = useNavigate();

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    control,
    // watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const [showSubmitModal, setShowSubmitModal] = useState(false);

  const modalStyle = {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    outline: "none",
    boxShadow: 24,
    p: 4,
  };

  const onSubmit = (data) => {
    ApiCall(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_BASE_URL}/ContactUs`,
      "POST",
      JSON.stringify({
        facility: data.facility,
        name: data.name,
        email: data.email,
        phoneNumber: data.phoneNumber,
        subject: data.subject,
        message: data.message,
      }),
      "no token needed"
    )
      .then((response) => {
        if (response.success) {
          setShowSubmitModal(true);
          reset({});
        }
      })
      .then(() => {
        setTimeout(() => {
          setShowSubmitModal(false);
          navigate("/home");
        }, 2500);
      })
      .catch((error) => console.error(error));
  };

  return (
    <BaseContent headerText={"Contact Us"} disableBack={true}>
      <div className="contactUsWrapper top">
        <div className="contactInfoWrapper">
          {ContactUsElements.map((data, index) => (
            <div key={index} className="contactInfoInnerWrapper ">
              <div className="contactUsIcon">
                <span className="material-icons" style={{ color: "black" }}>
                  {data.icon}
                </span>
              </div>
              <div className="contactInfoMain">
                <div className="contactInfoMainLabel">{data.label}</div>
                {data.details.map((innerData, ind) => (
                  <div key={ind} className="contactInfoGroup">
                    {innerData.Label ? (
                      <div className="contactInfoLabel">{innerData.Label}</div>
                    ) : null}
                    <div>{innerData.Value}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="contactInputWrapper">
          <div className="contactInputHeader">
            <div className="contactInfoMainLabel">Need more information?</div>
            <div className="contactUsBlurb">{ContactUsBlurb}</div>
          </div>
          {ContactUsFields?.map((data, index) => {
            const props = data?.props;
            return (
              <label key={index}>
                <div className="contactTextPairName">
                  {data.title}
                  <span style={{ color: DMStyles.Red }}>*</span>
                </div>
                <BaseInput
                  control={control}
                  {...props}
                  name={props?.name}
                  validationProps={data?.validationProps}
                  errors={errors}
                  className="contactUsInput"
                />
              </label>
            );
          })}

          <button
            className={"contactSubmitButtonActive"}
            onClick={handleSubmit(onSubmit)}>
            Submit
          </button>
        </div>
        <Modal open={showSubmitModal}>
          <Box sx={modalStyle}>
            <h4 className="nutrientModal" style={{ color: DMStyles.Teal }}>
              Your message has been sent.
            </h4>
          </Box>
        </Modal>
      </div>
    </BaseContent>
  );
}

export default ContactUs;
