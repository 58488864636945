import { Grid, Typography } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useGetFacilityCustomMenuQuery } from "../../../../../../store/apis/FacilityApis";
import DOMPurify from "dompurify";
import { EditButton } from "components/shared/EditButton";

export default function CommunityMenuCustomize() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { data: customMenu } = useGetFacilityCustomMenuQuery(id, { skip: !id });

  const renderTableRow = (value, desc) => {
    return (
      <Grid container className="customMenu">
        <Grid className="title" item xs={3}>
          <Typography className="text-padding" variant="p">
            {value}
          </Typography>
        </Grid>
        <Grid className="menuDesc" item xs={9}>
          <div
            className="text-padding"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(desc) }}
          />
        </Grid>
      </Grid>
    );
  };

  return (<>
    <Grid sx={{
      paddingBottom: 2
    }}>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-end"
        }}>
        <EditButton To={`edit?id=${id}`} state={{ data: customMenu }} />
      </Grid>
    </Grid>
    <div>
      {renderTableRow("WAAG Disclaimer", customMenu?.waagDisclaimer)}
      {renderTableRow(
        "Spreadsheet Guidelines",
        customMenu?.spreadsheetGuidelines
      )}
    </div>
  </>);
}
