import React from "react";
import { useGetStandardTextListQuery } from "../../../../../store/apis/StandardTextApis";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import BaseActionBox from "components/shared/BaseActionBox";

export default function RecipeCategories() {
  const {
    data: standardText,
    isLoading: isLoading,
    isError: isError,
  } = useGetStandardTextListQuery();

  const StandardTextColumns = [
    {
      field: "description",
      headerName: "Description",
      flex: 3,
      minWidth: 150,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={standardText}
            hasEdit={true}
            editProps={{
              To: `editStandardText?id=${params.row.id}`,
              title: "Standard Text",
            }}
          />
        );
      },
    },
  ];

  return (
    <BaseDataGrid
      rows={standardText}
      columns={StandardTextColumns}
      loading={isLoading}
      error={isError}
      height={"100%"}
      autoHeight={false}
    />
  );
}
