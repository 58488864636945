import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { RECIPE_SHARING_GROUPS } = API_ENDPOINTS;

export const RecipeSharingGroupAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRecipeSharingGroups: builder.query({
      query: () => `/${RECIPE_SHARING_GROUPS}`,
      keepUnusedDataFor: 120, // Sharing Groups shouldn't change much, keep around for a longer time
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      providesTags: (response) =>
        response?.data
          ? [
              ...response.data.map(({ id }) => ({
                type: RECIPE_SHARING_GROUPS,
                id,
              })),
              RECIPE_SHARING_GROUPS,
            ]
          : [RECIPE_SHARING_GROUPS],
    }),
  }),
});

// export hooks for calling endpoints
export const { useGetRecipeSharingGroupsQuery } = RecipeSharingGroupAPIs;
