import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { FACILITY_MEAL_EXTRAS } = API_ENDPOINTS;

export const FacilityMealExtraAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // define an endpoint for getting FacilityMealExtra
    getFacilityMealExtra: builder.query({
      query: ({ facilityId, searchText }) =>
        `/${FACILITY_MEAL_EXTRAS}?facilityId=${facilityId}&searchText=${searchText}&skip=0&take=1000`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: FACILITY_MEAL_EXTRAS,
                id,
              })),
              FACILITY_MEAL_EXTRAS,
            ]
          : [FACILITY_MEAL_EXTRAS];
      },
    }),
    getFacilityMealExtraById: builder.query({
      query: (mealExtraId) => `/${FACILITY_MEAL_EXTRAS}/${mealExtraId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              {
                type: FACILITY_MEAL_EXTRAS,
                id: response.id,
              },
              FACILITY_MEAL_EXTRAS,
            ]
          : [FACILITY_MEAL_EXTRAS],
    }),
    deleteFacilityMealExtra: builder.mutation({
      query: (id) => ({
        url: `/${FACILITY_MEAL_EXTRAS}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: FACILITY_MEAL_EXTRAS, id: arg.id },
      ],
    }),
    addFacilityMealExtra: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_MEAL_EXTRAS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: FACILITY_MEAL_EXTRAS, id: result?.id },
      ],
    }),
    updateFacilityMealExtra: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_MEAL_EXTRAS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: FACILITY_MEAL_EXTRAS, id: result?.id },
      ],
    }),
    addCustomFacilityMealExtra: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_MEAL_EXTRAS}/CustomMealExtra`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: FACILITY_MEAL_EXTRAS, id: result?.id },
      ],
    }),
    updateCustomFacilityMealExtra: builder.mutation({
      query: (data) => ({
        url: `/${FACILITY_MEAL_EXTRAS}/CustomMealExtra`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: FACILITY_MEAL_EXTRAS, id: result?.id },
      ],
    }),
    updateImportStandardMealExtra: builder.mutation({
      query: (facilityId) => ({
        url: `/${FACILITY_MEAL_EXTRAS}/ImportStandardMealExtras?facilityId=${facilityId}`,
        method: "PUT",
      }),
      invalidatesTags: (result) => [
        { type: FACILITY_MEAL_EXTRAS, id: result?.id },
      ],
    }),
    updateRemoveStandardMealExtra: builder.mutation({
      query: (id) => ({
        url: `/${FACILITY_MEAL_EXTRAS}/DeleteStandardMealExtras?facilityId=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result) => [
        { type: FACILITY_MEAL_EXTRAS, id: result?.id },
      ],
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetFacilityMealExtraQuery,
  useGetFacilityMealExtraByIdQuery,
  useDeleteFacilityMealExtraMutation,
  useAddFacilityMealExtraMutation,
  useUpdateFacilityMealExtraMutation,
  useAddCustomFacilityMealExtraMutation,
  useUpdateCustomFacilityMealExtraMutation,
  useUpdateImportStandardMealExtraMutation,
  useUpdateRemoveStandardMealExtraMutation,
} = FacilityMealExtraAPIs;
