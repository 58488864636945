import { MIN_LENGTH_VALIDATION } from "utils/errorMessages";
import {
  // firstDate,
  formatTypeId,
  includeCalories,
  includeCarbohydrateCount,
  includeCost,
  includePortionSize,
  includeSodium,
  // lastDate,
  offset6,
  reportFooter,
  reportFooterText,
  selectedMealId,
  showDiabeticChoice,
  showHeartHealthy,
  showIDDSIAbbreviations,
  startDateMonth,
  startMenuDay,
  useLegalSize,
} from "../form.constants";

export const MonthAtAGlanceForms = {
  Category: true,
  DayForm: [
    startDateMonth,
    offset6,
    {
      ...startMenuDay,
      validationProps: {
        ...startDateMonth.validationProps,
        min: MIN_LENGTH_VALIDATION("Start Menu Day", 1),
      },
    },
    offset6,
    { ...selectedMealId, validationProps: { required: true } },
    offset6,
    includeCost,
    offset6,
    includeCalories,
    offset6,
    includePortionSize,
    offset6,
    includeCarbohydrateCount,
    offset6,
    includeSodium,
    offset6,
    showHeartHealthy,
    offset6,
    showDiabeticChoice,
    offset6,
    showIDDSIAbbreviations,
    offset6,
  ],
  Diet: false,
  SnackMeal: [
    // firstDate,
    // offset6,
    // lastDate,
    // offset6,
    useLegalSize,
    offset6,
    reportFooterText,
    reportFooter,
  ],
  Report: [formatTypeId],
};
