import { Box } from "@mui/material";
import React from "react";
import { OptionsDetails } from "../../../../shared/OptionsDetails";
import { OptionsList } from "./headerConstant";
import BaseContent from "../../../../shared/baseContent/BaseContent";

export const EnterpriseMenu = () => {
  return (
    <>
      <BaseContent
        cardOverFlow={false}
        disableBack={true}
        headerText="Consultant"
        transparent={true}>
        <Box
          sx={{
            marginTop: "-32px",
            marginLeft: "-15px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            overflow: "hidden",
          }}>
          <OptionsDetails data={OptionsList} />
        </Box>
      </BaseContent>
    </>
  );
};
