import { oneOrTwoDigitPositiveNumber } from "utils/ValidationRegex";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "components/shared/generatedForm/GeneratedForm";
import dayjs from "dayjs";

export const mTypes = [
  { value: 0, label: "System" },
  { value: 1, label: "Corporate Group" },
  { value: 2, label: "Community" },
  { value: 3, label: "User" },
];

export const SYSTEM_MESSAGE_FIELDS = [
  {
    props: {
      name: "effectiveDate",
      id: "effectiveDate",
      label: "Effective Date",
    },
    validationProps: {
      required: REQUIRED_ERROR("Effective Date"),
      validate: (value, formValues) =>
        dayjs(value).format("YYYY-MM-DD") >
        dayjs(formValues.expirationDate).format("YYYY-MM-DD")
          ? "Effective date should be less than Expiration date"
          : null,
    },
    type: FORM_TYPES.DatePicker,
    size: 6,
  },
  {
    props: {
      name: "expirationDate",
      id: "expirationDate",
      label: "Expiration Date",
    },
    validationProps: {
      required: REQUIRED_ERROR("Expiration Date"),
      validate: (value, formValues) =>
        dayjs(value).format("YYYY-MM-DD") <
        dayjs(formValues.effectiveDate).format("YYYY-MM-DD")
          ? "Expiration date should be greater than Effective date"
          : null,
    },
    type: FORM_TYPES.DatePicker,
    size: 6,
  },
  {
    props: {
      name: "type",
      id: "type",
      label: "Type",
    },
    size: 6,
    type: FORM_TYPES.Radio,
    options: mTypes,
  },
  {
    props: {
      label: "Corporate Group",
      id: "corporateGroupId",
      name: "corporateGroupId",
    },
    type: FORM_TYPES.AutocompleteAPI,
    endpoint: "getCorporateGroupSelect",
    size: 6,
    validationProps: {
      required: REQUIRED_ERROR("Corporate Group"),
    },
  },
  {
    props: {
      label: "Community",
      id: "facilityId",
      name: "facilityId",
    },
    size: 6,
    type: FORM_TYPES.AutocompleteAPI,
    endpoint: "getFacilitySelectBox",
    validationProps: {
      required: REQUIRED_ERROR("Community"),
    },
  },
  {
    props: {
      label: "User",
      id: "userId",
      name: "userId",
    },
    size: 6,
    type: FORM_TYPES.AutocompleteAPI,
    endpoint: "getUserSelectBox",
    validationProps: {
      required: REQUIRED_ERROR("User"),
    },
  },
  {
    props: {
      name: "priority",
      id: "priority",
      label: "Priority",
      type: "number",
      min: 0,
      max: 99,
    },
    size: 6,
    validationProps: {
      required: REQUIRED_ERROR("Priority"),
      pattern: {
        value: oneOrTwoDigitPositiveNumber,
        message: "Please enter one or two digit positive number",
      },
    },
  },
  {
    props: {
      label: "Title",
      id: "title",
      name: "title",
    },
    validationProps: {
      required: REQUIRED_ERROR("Title"),
      maxLength: {
        value: 200,
        message: "Title should be less then 200 characters ",
      },
    },
    size: 6,
  },
];
