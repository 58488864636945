import React from "react";
import FullScreenMessage from "../FullScreenMessage";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/NotFound.md)
 */
export default function NotFound() {
  return (
    <FullScreenMessage
      title={`Not Found!`}
      code={404}
      color={`primary`}
      message={`The resource you were looking for was not found!`}
      instructions={`Please check the URL or contact your administrator for more details.`}
    />
  );
}
