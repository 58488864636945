import React, { useEffect } from "react";
import {
  Card,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  useTheme,
} from "@mui/material";
import "@fontsource/material-icons";
import "./UserSystemMessages.css";
import { useSelector } from "react-redux";
import { useIsAuthenticated } from "@azure/msal-react";
import { useGetSystemMessagesByUserIdQuery } from "../../../store/apis/UserApis";
import { selectFacilityId } from "../../../store/slices/authSlice/AuthSlice";
import DOMPurify from "dompurify";
import useMsalAccount from "utils/useMsalAccount";

export default function UserSystemMessages({ open, close, anchorEl }) {
  const theme = useTheme();

  const messageColors = {
    0: {
      color: theme.palette.error.light,
      textColor: theme.palette.error.contrastText,
    },
    1: {
      color: theme.palette.success.light,
      textColor: theme.palette.success.dark,
    },
    2: {
      color: theme.palette.primary.light,
      textColor: theme.palette.primary.dark,
    },
    3: {
      color: theme.palette.warning.light,
      textColor: theme.palette.warning.dark,
    },
  };

  const isAuthenticated = useIsAuthenticated();
  const { userId: currentUserId } = useMsalAccount();
  const facilityId = useSelector(selectFacilityId);

  const { data: systemMessages, refetch: refetchUser } =
    useGetSystemMessagesByUserIdQuery(currentUserId, {
      skip: !isAuthenticated || currentUserId == null,
    });

  useEffect(() => {
    if (isAuthenticated && currentUserId) {
      refetchUser();
    }
  }, [facilityId]);

  return (
    (<Popover
      id={"UserSystemMessages"}
      open={open}
      anchorEl={anchorEl}
      onClose={close}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}>
      <Card sx={{ minWidth: "400px", padding: "8px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between"
          }}>
          <Typography variant="h5">Notification</Typography>
          <Typography
            variant="h5"
            onClick={close}
            sx={{
              margin: "4px",
              cursor: "pointer"
            }}>
            <span className="material-icons">close</span>
          </Typography>
        </Box>
        <Divider sx={{ my: 0.5 }} />

        <Box
          sx={{
            display: "flex",
            maxHeight: "500px",
            overflow: "auto",
            alignItems: "center"
          }}>
          <List dense={true} sx={{ width: "95%", maxHeight: "500px" }}>
            {systemMessages?.map((value, index) => {
              const { color, textColor } = messageColors[value?.type] || {};
              return (
                <div key={index}>
                  <ListItem
                    sx={{
                      backgroundColor: color,
                      color: textColor,
                      borderRadius: "10px",
                      padding: "15px",
                    }}>
                    <ListItemText
                      sx={{ marginLeft: "10px" }}
                      primary={
                        <Typography
                          sx={{
                            display: "inline",
                            fontWeight: 600,
                            color: textColor,
                          }}
                          component="span"
                          variant="body2">
                          {value?.title}
                        </Typography>
                      }
                      secondary={
                        <span
                          color={textColor}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(value?.message),
                          }}></span>
                      }
                    />
                  </ListItem>
                  <Divider sx={{ my: 0.5 }} />
                </div>
              );
            })}
          </List>
        </Box>
      </Card>
    </Popover>)
  );
}
