import {
  endingDay,
  formatTypeId,
  offset6,
  startingDay,
} from "../form.constants";

export const MenuAllergensForms = {
  Category: true,
  DayForm: [startingDay, offset6, endingDay, offset6],
  Diet: true,
  Report: [formatTypeId],
};
