import React, { useEffect } from "react";
import { useUpdateFacilityTableSideMutation } from "../../../../../../../store/apis/FacilityApis";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Grid, Typography } from "@mui/material";
import BaseCheckBox from "../../../../../../shared/baseForm/BaseCheckBox";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";

export default function EditTableSide() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const tableSideData = location.state.data;
  const tableSideId = searchParams.get("id");

  const [updateTableSide, { isError: updateError, isLoading: updateLoading }] =
    useUpdateFacilityTableSideMutation();

  const isError = updateError;
  const isSubmitting = updateLoading;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (location.state && tableSideData) {
      reset(tableSideData);
    }
  }, [tableSideData]);

  const onSubmit = (data) => {
    updateTableSide({
      ...data,
      id: tableSideId,
    });
    if (!isError) navigate(-1);
  };

  const informationFormFields = [
    {
      props: {
        name: "tableSideDecliningBalance",
        id: "tableSideDecliningBalance",
        label: "Declining Balance",
      },
    },
    {
      props: {
        name: "tablesidePrintMealCardNotes",
        id: "tablesidePrintMealCardNotes",
        label: "Print MealCard Notes",
      },
    },
    {
      props: {
        name: "tablesideDirectPrint",
        id: "tablesideDirectPrint",
        label: "Direct Printing",
      },
    },
    {
      props: {
        name: "tableSidePrintSignatureLine",
        id: "tableSidePrintSignatureLine",
        label: "Print Signature Line on Receipt",
      },
    },
    {
      props: {
        name: "tablesideAllItemsSelectedByDefault",
        id: "tablesideAllItemsSelectedByDefault",
        label: "Items Selected by Default",
      },
    },
    {
      props: {
        name: "enableTotalTicketPrice",
        id: "enableTotalTicketPrice",
        label: "Show Total Price on Receipt",
      },
    },
    {
      props: {
        name: "tablesideTicketHidePortion",
        id: "tablesideTicketHidePortion",
        label: "Hide Portion Size on Tickets",
      },
    },
    {
      props: {
        name: "tablesideTicketUseOrderPrinter",
        id: "tablesideTicketUseOrderPrinter",
        label: "Use OrderPrinter app",
      },
    },
  ];

  return (
    (<div style={{ padding: 15 }}>
      <FormProvider>
        <form>
          <Grid container spacing={2}>
            {informationFormFields?.map((data, index) => {
              const props = data?.props;
              return (
                <Grid key={index} item xs={10}>
                  <BaseCheckBox
                    {...props}
                    control={control}
                    errors={errors}
                    defaultValue={false}
                  />
                </Grid>
              );
            })}

            <Grid item xs={5}>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <BaseSubmitButton
                  fullWidth
                  onClick={handleSubmit(onSubmit)}
                  isSubmitting={isSubmitting}
                />
              </Box>
              {isError ? (
                <Typography sx={{
                  color: "error"
                }}>
                  There was an error submitting the form, please try again.
                </Typography>
              ) : undefined}
            </Grid>
            <Grid item xs={3} />
          </Grid>
        </form>
      </FormProvider>
    </div>)
  );
}
