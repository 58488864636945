import { Button, Tooltip } from "@mui/material";
import React, { useState } from "react";
import DeleteModal from "./deleteModal/DeleteModal";
import { useGetFoodTypeByIdQuery } from "../../store/apis/FoodTypesApis";
import { Delete } from "@mui/icons-material";

/**
 * @see [Documentation](../../../Wiki/Documentations/FrontEnd/Components/DeleteButton.md)
 */
const DeleteButton = ({
  index,
  entityName,
  apiPath,
  text,
  variant,
  apiId,
  additionalText,
  FacilityId,
  disabled,
  hintText,
  To,
  sx,
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const foodTypeName = useGetFoodTypeByIdQuery(apiId, { skip: !apiId }).data
    ?.name;

  return (
    <>
      <Tooltip title={hintText || `Delete ${entityName}`}>
        <span>
          <Button
            color="error"
            disabled={disabled}
            variant={variant || "contained"}
            sx={sx}
            onClick={() => {
              !disabled ? setOpenDeleteModal(true) : null;
            }}
            icon="true">
            {text || <Delete />}
          </Button>
        </span>
      </Tooltip>

      {openDeleteModal && (
        <DeleteModal
          To={To}
          FacilityId={FacilityId}
          id={index}
          apiPath={apiPath}
          entityName={entityName ? entityName : foodTypeName}
          additionalText={additionalText}
          open={openDeleteModal}
          close={() => {
            setOpenDeleteModal(false);
          }}
        />
      )}
    </>
  );
};

export default DeleteButton;
