import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import BaseSelect from "../../../shared/baseForm/BaseSelect";
import BaseCheckBox from "../../../shared/baseForm/BaseCheckBox";
import BaseInput from "../../../shared/baseForm/BaseInput";
import { DiningFields } from "./forms.constants";
import { useResidentDiningTableSelectQuery } from "store/apis/ResidentApis";

export default function DiningForm({
  diningmeal,
  control,
  errors,
  watch,
  setValue,
  decliningBalanceEnabled,
}) {
  const { formTextFields } = DiningFields;

  const breakfastDiningAreaId = watch("breakfastDiningAreaId");
  const lunchDiningAreaId = watch("lunchDiningAreaId");
  const supperDiningAreaId = watch("supperDiningAreaId");

  const [initialBreakfastAreaId, setInitialBreakfastAreaId] = useState(null);
  const [initialLunchAreaId, setInitialLunchAreaId] = useState(null);
  const [initialSupperAreaId, setInitialSupperAreaId] = useState(null);

  const { data: breakfastTables } = useResidentDiningTableSelectQuery(
    {
      diningAreaId: breakfastDiningAreaId,
    },
    { skip: !breakfastDiningAreaId }
  );

  const { data: lunchTables } = useResidentDiningTableSelectQuery(
    {
      diningAreaId: lunchDiningAreaId,
    },
    { skip: !lunchDiningAreaId }
  );

  const { data: supperTables } = useResidentDiningTableSelectQuery(
    {
      diningAreaId: supperDiningAreaId,
    },
    { skip: !supperDiningAreaId }
  );

  // Reset table selection when dining area changes so that if no table is selected, a null value will be saved.
  useEffect(() => {
    if (breakfastDiningAreaId && !initialBreakfastAreaId) {
      setInitialBreakfastAreaId(breakfastDiningAreaId);
    }
    if (
      initialBreakfastAreaId &&
      breakfastDiningAreaId !== initialBreakfastAreaId
    ) {
      setValue("breakfastTableId", null);
      setValue("breakfastTable", "");
    }
  }, [breakfastDiningAreaId, initialBreakfastAreaId]);

  useEffect(() => {
    if (lunchDiningAreaId && !initialLunchAreaId) {
      setInitialLunchAreaId(lunchDiningAreaId);
    }
    if (initialLunchAreaId && lunchDiningAreaId !== initialLunchAreaId) {
      setValue("lunchTableId", null);
      setValue("lunchTable", "");
    }
  }, [lunchDiningAreaId, initialLunchAreaId]);

  useEffect(() => {
    if (supperDiningAreaId && !initialSupperAreaId) {
      setInitialSupperAreaId(supperDiningAreaId);
    }
    if (initialSupperAreaId && supperDiningAreaId !== initialSupperAreaId) {
      setValue("supperTableId", null);
      setValue("supperTable", "");
    }
  }, [supperDiningAreaId, initialSupperAreaId]);

  return (
    <>
      {decliningBalanceEnabled
        ? formTextFields.map((data, index) => {
            const props = data?.props;
            return (
              <Grid key={index} item xs={8}>
                <BaseInput
                  {...props}
                  control={control}
                  name={props?.name}
                  validationProps={data?.validationProps}
                  errors={errors}
                />
              </Grid>
            );
          })
        : undefined}

      <Grid item xs={12}>
        <div className="TableRow dining-table">
          <Grid
            className="dining-table-column dining-table-bg-color"
            item
            xs={2}>
            <Typography className="dining-table-cell"></Typography>
            <Typography className="dining-table-cell">Breakfast</Typography>
            <Typography className="dining-table-cell">Lunch</Typography>
            <Typography className="dining-table-cell">Dinner</Typography>
          </Grid>
          <Grid className="dining-table-column" item xs={3}>
            <Typography className="dining-table-cell dining-table-bg-color">
              Dining Area
            </Typography>
            <BaseSelect
              options={diningmeal || []}
              name={"breakfastDiningAreaId"}
              id={"breakfastDiningAreaId"}
              label=" "
              errors={errors}
              control={control}
              defaultValue={null}
            />
            <BaseSelect
              options={diningmeal || []}
              name={"lunchDiningAreaId"}
              id={"lunchDiningAreaId"}
              label=" "
              errors={errors}
              control={control}
              defaultValue={null}
            />
            <BaseSelect
              options={diningmeal || []}
              name={"supperDiningAreaId"}
              id={"supperDiningAreaId"}
              label=" "
              placeholder="Choose"
              errors={errors}
              control={control}
              defaultValue={null}
            />
          </Grid>

          <Grid className="dining-table-column" item xs={2}>
            <Typography className="dining-table-cell dining-table-bg-color">
              Table
            </Typography>
            <BaseSelect
              options={breakfastTables || []}
              name={"breakfastTableId"}
              id={"breakfastTableId"}
              placeholder="Select"
              label=" "
              errors={errors}
              control={control}
              defaultValue={null}
            />
            <BaseSelect
              options={lunchTables || []}
              name={"lunchTableId"}
              id={"lunchTableId"}
              placeholder="Select"
              label=" "
              errors={errors}
              control={control}
              defaultValue={null}
            />
            <BaseSelect
              options={supperTables || []}
              name={"supperTableId"}
              id={"supperTableId"}
              placeholder="Select"
              label=" "
              errors={errors}
              control={control}
              defaultValue={null}
            />
          </Grid>
          <Grid className="dining-table-column" item xs={2}>
            <Typography className="dining-table-cell dining-table-bg-color">
              Seat
            </Typography>
            <BaseInput
              name={"breakfastSeat"}
              control={control}
              errors={errors}
              defaultValue={""}
              validationProps={{
                maxLength: {
                  value: 10,
                  message: "Breakfast Seat must be 10 characters or less",
                },
              }}
            />
            <BaseInput
              name={"lunchSeat"}
              control={control}
              errors={errors}
              defaultValue={""}
              validationProps={{
                maxLength: {
                  value: 10,
                  message: "Lunch Seat must be 10 characters or less",
                },
              }}
            />
            <BaseInput
              name={"supperSeat"}
              control={control}
              errors={errors}
              defaultValue={""}
              validationProps={{
                maxLength: {
                  value: 10,
                  message: "Dinner Seat must be 10 characters or less",
                },
              }}
            />
          </Grid>

          <Grid className="dining-table-column" item xs={2}>
            <Typography className="dining-table-cell dining-table-bg-color">
              Fluid Restriction
            </Typography>
            <BaseInput
              name={"breakfastRestrictQty"}
              control={control}
              errors={errors}
              defaultValue={""}
              validationProps={{
                maxLength: {
                  value: 20,
                  message:
                    "Breakfast Fluid Restriction Quantity must be less than 20 characters",
                },
              }}
            />
            <BaseInput
              name={"lunchRestrictQty"}
              control={control}
              errors={errors}
              defaultValue={""}
              validationProps={{
                maxLength: {
                  value: 20,
                  message:
                    "Lunch Fluid Restriction Quantity must be less than 20 characters",
                },
              }}
            />
            <BaseInput
              name={"supperRestrictQty"}
              control={control}
              errors={errors}
              defaultValue={""}
              validationProps={{
                maxLength: {
                  value: 20,
                  message:
                    "Dinner Fluid Restriction Quantity must be less than 20 characters",
                },
              }}
            />
          </Grid>
        </div>
      </Grid>
      <Grid item xs={8}>
        <BaseCheckBox
          name="printTrayCard"
          id="printTrayCard"
          label="Print Meal Card"
          control={control}
          errors={errors}
          defaultValue={true}
        />
      </Grid>
    </>
  );
}
