import {
  Fade,
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
// import MenuBookIcon from '@mui/icons-material/MenuBook';
import ListIcon from "@mui/icons-material/List";
import WarningIcon from "@mui/icons-material/Warning";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import LinearProgress from "@mui/material/LinearProgress";
import React, { useEffect } from "react";
import { useGetReportMutation } from "../../../../store/apis/ReportAPIs";
import { DownloadLinkHelper } from "../../../shared/utils/helpers";
import "../Reports.css";

export default function RecipeReport() {
  const [
    getReport,
    {
      isError: isError,
      isSuccess: isSuccess,
      isLoading: isLoading,
      data: report,
    },
  ] = useGetReportMutation();

  // useEffect(() => {
  //     if (isSuccess) {
  //         console.log(result);
  //     }
  // }, [isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      DownloadLinkHelper(report?.data);
    }
  }, [report?.data]);

  // const handleDownload = (name) => {
  //     handleClose();
  //     if (!reportsFetching && reportsSuccess && reportName === name) {
  //       DownloadLinkHelper(usersReport?.data);
  //       return;
  //     }
  //     setReportName(() => name);
  //   };

  const handleClick = (report, type) => {
    // console.log(report, type);

    const data = {
      name: report,
      parameters: {},
      format: type,
    };

    getReport(data);

    // console.log(isError, isSuccess, isLoading);
  };

  return (
    (<Card sx={{ flexGrow: 1, marginY: 2, paddingX: 2 }}>
      <Typography
        variant="h5"
        className="userFacilityTitle"
        sx={{
          color: "primary",
          marginTop: "15px"
        }}>
        Select Recipe Report to View
      </Typography>
      <Fade
        in={isLoading}
        unmountOnExit
        sx={{ marginTop: "10px", marginBottom: "10px" }}>
        <LinearProgress />
      </Fade>
      <nav aria-label="main mailbox folders">
        <List>
          {/* <ListItem key={1} disablePadding onClick={() => handleClick("RecipeList", 0)}>
                            <ListItemButton >
                                <ListItemIcon>
                                    <MenuBookIcon  />
                                </ListItemIcon>
                                <ListItemText primary="Recipe List"/>
                            </ListItemButton>
                        </ListItem> */}
          <ListItem
            key={2}
            disablePadding
            onClick={() => handleClick("IngredientList", 1)}>
            <ListItemButton>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Ingredient List" />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={3}
            disablePadding
            onClick={() => handleClick("NutrientProblems", 0)}>
            <ListItemButton>
              <ListItemIcon>
                <WarningIcon />
              </ListItemIcon>
              <ListItemText primary="Nutrient Problems" />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={4}
            disablePadding
            onClick={() => handleClick("ShoppingListProblems", 0)}>
            <ListItemButton>
              <ListItemIcon>
                <NotificationImportantIcon />
              </ListItemIcon>
              <ListItemText primary="Shopping List Problems" />
            </ListItemButton>
          </ListItem>
        </List>
      </nav>
      {isError ? (
        <Typography sx={{
          color: "error"
        }}>
          Error occured during report generation. Please try again later.
        </Typography>
      ) : undefined}
    </Card>)
  );
}
