import React, { useState, useEffect, useCallback } from "react";
import { Typography, Button, Grid, CircularProgress, Box } from "@mui/material";
import { unstable_useBlocker as useBlocker } from "react-router-dom";
import {
  useGetHighlightQuery,
  useAddHighlightMutation,
} from "../../../store/apis/PrintReportApis";
import { SketchPicker } from "react-color";
import "./Highlight.css";
import { EditSkeleton } from "../../admin/admin.overlays";
import BlockerModal from "components/shared/blockerModal/BlockerModal";

export default function Highlight() {

  const whiteColor = "#ffffff";

  const { data: hightlights, isFetching: loading } = useGetHighlightQuery();

  // unsaved changes functionality here
  const [blockerModal, setBlockerModal] = useState(false);
  const [hasChange, setHasChange] = useState(false);

  // Block navigating elsewhere when data has been entered into the input
  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) =>
      hasChange && currentLocation.pathname !== nextLocation.pathname,
    [hasChange]
  );

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === "blocked") {
      setBlockerModal(true);
    }
  }, [blocker]);

  // Page Reload and navigation alert implementations
  useEffect(() => {
    window.onbeforeunload = function () {
      if (hasChange) {
        return "You have unsaved changes. Are you sure you want to leave?";
      }
    };
  }, [hasChange]);

  const handleOnLeave = () => {
    blocker.proceed();
    setBlockerModal(false);
    setHasChange(false);
  };
  const handleOnStay = () => {
    blocker.reset();
    setBlockerModal(false);
  };

  const [addhighlight, { isError: hasError, isLoading: saveLoading }] =
    useAddHighlightMutation();

  const onSubmit = () => {
    setHasChange(false);
    addhighlight({
      printOptions: {
        id: hightlights?.printOptions?.id,
        facilityId: hightlights?.printOptions?.facilityId,
        mealcardNotesColor: mealcardNotesColor ?? whiteColor,
        mealcardDislikeColor: mealcardDislikeColor ?? whiteColor,
        mealcardAllergyColor: mealcardAllergyColor ?? whiteColor,
        mealcardDietColor: mealcardDietColor ?? whiteColor,
        mealcardDietTextureColor: mealcardDietTextureColor ?? whiteColor,
        mealcardDietOtherColor: mealcardDietOtherColor ?? whiteColor,
        mealcardThickenedLiquidColor: mealcardThickenedLiquidColor ?? whiteColor,
        mealcardRestrictionQuantity: mealcardRestrictionQuantity ?? whiteColor,
        mealcardAdaptiveEquipmentColor: mealcardAdaptiveEquipmentColor ?? whiteColor,
        mealcardMealAddOnColor: mealcardMealAddOnColor ?? whiteColor,
      },
      listFacilityDiet: listFacilityDiet,
    });
    if (!hasError) {
      // navigate(-1);
    }
  };


  const mealCardColors = hightlights?.printOptions;
  const [iscolorUpdate, setIscolorUpdatev] = useState(false);
  const [listFacilityDiet, setlistFacilityDiet] = useState();
  const [colorShow, setColorShow] = useState(false);
  const [mealcardNotesColor, setmealcardNotesColor] = useState(whiteColor);
  const [mealcardDislikeColor, setmealcardDislikeColor] = useState(whiteColor);
  const [mealcardAllergyColor, setmealcardAllergyColor] = useState(whiteColor);
  const [mealcardDietColor, setmealcardDietColor] = useState(whiteColor);
  const [mealcardDietTextureColor, setmealcardDietTextureColor] =
    useState(whiteColor);
  const [mealcardDietOtherColor, setmealcardDietOtherColor] =
    useState(whiteColor);
  const [mealcardThickenedLiquidColor, setmealcardThickenedLiquidColor] =
    useState(whiteColor);
  const [mealcardRestrictionQuantity, setmealcardRestrictionQuantity] =
    useState(whiteColor);
  const [mealcardAdaptiveEquipmentColor, setmealcardAdaptiveEquipmentColor] =
    useState(whiteColor);
  const [mealcardMealAddOnColor, setmealcardMealAddOnColor] =
    useState(whiteColor);

  useEffect(() => {
    const temp = [];
    hightlights?.listFacilityDiet?.length > 0 &&
      hightlights?.listFacilityDiet?.map((item) => {
        temp?.push({
          id: item.id,
          dietId: item.dietId,
          facilityId: item.facilityId,
          dietName: item.dietName,
          lastUpdatedBy: item.lastUpdatedBy,
          lastUpdated: item.lastUpdated,
          sortOrder: item.sortOrder,
          dietColor: item.dietColor,
          commonDietName: item.commonDietName,
          isTexture: item.isTexture,
        });
      });

    setlistFacilityDiet(temp);
    setmealcardNotesColor(mealCardColors?.mealcardNotesColor);
    setmealcardDislikeColor(mealCardColors?.mealcardDislikeColor);
    setmealcardAllergyColor(mealCardColors?.mealcardAllergyColor);
    setmealcardDietColor(mealCardColors?.mealcardDietColor);
    setmealcardDietTextureColor(mealCardColors?.mealcardDietTextureColor);
    setmealcardDietOtherColor(mealCardColors?.mealcardDietOtherColor);
    setmealcardThickenedLiquidColor(
      mealCardColors?.mealcardThickenedLiquidColor
    );
    setmealcardRestrictionQuantity(mealCardColors?.mealcardRestrictionQuantity);
    setmealcardAdaptiveEquipmentColor(
      mealCardColors?.mealcardAdaptiveEquipmentColor
    );
    setmealcardMealAddOnColor(mealCardColors?.mealcardMealAddOnColor);
  }, [hightlights]);

  function ColorBoxUI(colorText, color, setColor, type, ColorId, isTexture) {
    return (
      <div>
        <div className="color-picker">
          <div className="color-picker-box">
            <div
              type="button"
              className="color-box"
              style={{ background: color ? color : "black" }}
              onClick={() => {
                setColorShow(ColorId);
              }}
            />

            <p className="color-text">
              {colorText}
              {isTexture == true ? " Texture" : ""}
            </p>
          </div>
        </div>
        {colorShow == ColorId && (
          <div className="popover">
            <div className="cover">
              <span
                onClick={() => {
                  setColorShow("");
                }}
                className="material-icons close">
                close
              </span>
              <SketchPicker
                onChangeComplete={(color) => {
                  if (type == "dietArray") {
                    listFacilityDiet[setColor].dietColor = color.hex;
                    setIscolorUpdatev(!iscolorUpdate);
                    setHasChange(true);
                  } else {
                    setColor(color.hex);
                    setHasChange(true);
                  }
                }}
                color={color ? color : "black"}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  return (<>
    {blockerModal ? (
      <BlockerModal
        text={`Hold up! You've got unsaved changes. Are you sure you want to leave?`}
        open={blockerModal}
        onStay={handleOnStay}
        onLeave={handleOnLeave}
      />
    ) : null}
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        margin: "5px"
      }}>
      <Typography variant="h6" component="h2">
        {`Highlights Setup`}
      </Typography>
      <Button
        color={"primary"}
        className="baseButton"
        variant="contained"
        onClick={() => onSubmit()}
        startIcon={
          saveLoading ? <CircularProgress color="white" size={18} /> : ""
        }>
        {"Save"}
      </Button>
    </Box>
    {loading ? (
      <EditSkeleton />
    ) : (
      <Box
        sx={{
          overflow: "auto",
          height: { md: "70vh", xl: "75vh" },
          margin: "5px"
        }}>
        <Grid container spacing={2} sx={{
          marginBottom: "5px"
        }}>
          <Grid item xs={6}>
            <Typography variant="h5" component={"h5"} sx={{
              fontWeight: 600
            }}>
              MealCard Highlights
            </Typography>
            {ColorBoxUI(
              "Notes Color",
              mealcardNotesColor,
              setmealcardNotesColor,
              "mealCardHighlights",
              1
            )}
            {ColorBoxUI(
              "Dislike Color",
              mealcardDislikeColor,
              setmealcardDislikeColor,
              "mealCardHighlights",
              2
            )}
            {ColorBoxUI(
              "Allergy Color",
              mealcardAllergyColor,
              setmealcardAllergyColor,
              "mealCardHighlights",
              3
            )}
            {ColorBoxUI(
              "Diet Color",
              mealcardDietColor,
              setmealcardDietColor,
              "mealCardHighlights",
              4
            )}
            {ColorBoxUI(
              "Texture Color",
              mealcardDietTextureColor,
              setmealcardDietTextureColor,
              "mealCardHighlights",
              5
            )}
            {ColorBoxUI(
              "Diet Other Color",
              mealcardDietOtherColor,
              setmealcardDietOtherColor,
              "mealCardHighlights",
              6
            )}
            {ColorBoxUI(
              "Liquid Consistency Color",
              mealcardThickenedLiquidColor,
              setmealcardThickenedLiquidColor,
              "mealCardHighlights",
              7
            )}
            {ColorBoxUI(
              "Restriction Color",
              mealcardRestrictionQuantity,
              setmealcardRestrictionQuantity,
              "mealCardHighlights",
              8
            )}
            {ColorBoxUI(
              "Adaptive Equipment Color",
              mealcardAdaptiveEquipmentColor,
              setmealcardAdaptiveEquipmentColor,
              "mealCardHighlights",
              9
            )}
            {ColorBoxUI(
              "Meal Add-Ons Color",
              mealcardMealAddOnColor,
              setmealcardMealAddOnColor,
              "mealCardHighlights",
              10
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5" component={"h5"} sx={{
              fontWeight: 600
            }}>
              Diet Highlights
            </Typography>
            {listFacilityDiet?.map((item, index) => {
              return ColorBoxUI(
                item?.dietName,
                item.dietColor,
                index,
                "dietArray",
                item?.id,
                item?.isTexture
              );
            })}
          </Grid>
        </Grid>
      </Box>
    )}
  </>);
}
