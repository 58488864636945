import React from "react";
import { Controller } from "react-hook-form";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FormControl } from "@mui/material";
import dayjs from "dayjs";
import { isUndefined } from "lodash";
import { TimePicker } from "@mui/x-date-pickers";
// import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { SCREEN_CONSTANTS } from "../constants";
import "./BaseForm.css";
/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseDatePicker.md)
 */
export default function BaseDatePicker({
  control,
  defaultValue,
  validationProps,
  errors,
  ...rest
}) {
  const validateDate = (value, formValues) => {
    const selectedDate = dayjs(value);
    const isValidDate = selectedDate.isValid();
    const isTimeType = rest?.type === "time";

    if (
      validationProps?.min &&
      selectedDate.isBefore(dayjs(validationProps.min.value), "day")
    ) {
      return (
        validationProps.min.message ||
        "Selected date is before the minimum date."
      );
    }

    if (
      validationProps?.max &&
      selectedDate.isAfter(dayjs(validationProps.max.value), "day")
    ) {
      return (
        validationProps.max.message ||
        "Selected date is after the maximum date."
      );
    }

    if (validationProps?.required && !isTimeType && !isValidDate) {
      return validationProps.required;
    }

    if (validationProps?.required && isTimeType && !value) {
      return validationProps.required;
    }

    if (value && !isValidDate && !isTimeType) {
      return `Selected date is Invalid`;
    }

    if (isTimeType) {
      const timeValue = dayjs(`2022-04-17T${value}`);
      if (!timeValue.isValid() && !isValidDate) {
        return `Selected time is Invalid`;
      }
    }

    if (validationProps?.validate) {
      return validationProps.validate(value, formValues);
    }

    return true;
  };

  return (
    <FormControl
      sx={{
        ...rest.sx,
      }}
      fullWidth
      variant="outlined">
      <Controller
        control={control}
        name={rest?.name || rest?.id}
        defaultValue={
          !isUndefined(defaultValue)
            ? rest?.type == "time"
              ? dayjs(`2022-04-17T${defaultValue}`)
              : defaultValue
            : null
        }
        rules={{
          validate: validateDate,
        }}
        render={({ field: { onChange, value } }) => {
          let timeValue;
          if (rest?.type === "time" && typeof value === "string") {
            timeValue = dayjs(`2022-04-17T${value}`);
          } else {
            timeValue = false;
          }
          const slotProps = {
            textField: {
              size: rest?.size,
              error: !!(errors && rest?.name && errors[rest.name]),
              helperText: errors && rest?.name && errors[rest.name]?.message,
            },
            field: { clearable: true },
          };
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker,TimePicker"]}>
                {rest.type !== "time" ? (
                  <DatePicker
                    sx={{
                      width: "100%",
                    }}
                    views={rest?.openTo ? ["year", "month"] : null}
                    label={rest?.label || rest?.name}
                    format={rest?.dateFormat || SCREEN_CONSTANTS.Date_Format}
                    value={value ? dayjs(value) : null}
                    onChange={(date) => {
                      onChange(date ? dayjs(date).format("YYYY-MM-DD") : null);
                    }}
                    {...rest}
                    slotProps={slotProps}
                  />
                ) : (
                  <TimePicker
                    sx={{
                      width: "100%",
                    }}
                    // viewRenderers={{
                    //   hours: renderTimeViewClock,
                    //   minutes: renderTimeViewClock,
                    //   seconds: renderTimeViewClock,
                    // }}
                    timeSteps={{ minutes: 15 }}
                    label={rest?.label || rest?.name}
                    value={value ? (timeValue ? timeValue : value) : null}
                    onChange={(date) => {
                      onChange(
                        rest?.timeFormat
                          ? dayjs(date).format(rest?.timeFormat)
                          : date
                      );
                    }}
                    {...rest}
                    slotProps={slotProps}
                  />
                )}
              </DemoContainer>
            </LocalizationProvider>
          );
        }}
      />
    </FormControl>
  );
}
