// MUI Imports
import { Box, Card, Grid, Button, Link } from "@mui/material";
// React Hook Imports
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
// Custom Components Imports
import GeneratedForm from "../../../../../shared/generatedForm/GeneratedForm";
import { RecipeIngredientsFields } from "./forms.constant";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
// Mutation and Query Imports
import {
  useAddRecipeIngredientsMutation,
  useGetRecipeIngredientByIdQuery,
  // useGetRecipeIngredientUnitsQuery,
  useUpdateRecipeIngredientsMutation,
} from "../../../../../../store/apis/RecipeIngredientApi";
import { EditSkeleton } from "../../../../../admin/admin.overlays";
import isEqual from "lodash/isEqual";

export const AddRecipesIngredients = () => {
  // Local State Declaration
  const [searchParams] = useSearchParams();
  const [defaultValues, setDefaultValues] = useState([]);
  const id = searchParams.get("id");
  const recipeId = searchParams.get("recipeId");
  const navigate = useNavigate();

  // UseForm Declaration
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  // destructuring of method
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  // Query Section
  const {
    data: RecipeIngredientData,
    isLoading: currentRecipeIngredientLoading,
    isSuccess: currentRecipeIngredientSuccess,
  } = useGetRecipeIngredientByIdQuery(id, { skip: !id });

  // Mutation Section
  const [
    updateRecipeIngredient,
    {
      isLoading: updateRecipeIngredientLoading,
      isSuccess: updateRecipeIngredientSuccess,
    },
  ] = useUpdateRecipeIngredientsMutation();
  const [
    AddRecipeIngredient,
    {
      isLoading: addRecipeIngredientLoading,
      isSuccess: addRecipeIngredientSuccess,
    },
  ] = useAddRecipeIngredientsMutation();

  // Veriables Declaration
  const isEdit = !!id;
  const isEditLoading = isEdit && currentRecipeIngredientLoading;
  const isSubmitting =
    addRecipeIngredientLoading || updateRecipeIngredientLoading;
  const isSuccess = addRecipeIngredientSuccess || updateRecipeIngredientSuccess;

  // UseEffect Section
  useEffect(() => {
    if (currentRecipeIngredientSuccess && !currentRecipeIngredientLoading) {
      reset(RecipeIngredientData);
      setDefaultValues([
        ...defaultValues,
        {
          name: "ingredientId",
          option: [
            {
              id: RecipeIngredientData.ingredientId,
              label: RecipeIngredientData.ingredient,
            },
          ],
        },
      ]);
    }
  }, [
    RecipeIngredientData,
    currentRecipeIngredientSuccess,
    currentRecipeIngredientLoading,
  ]);

  useEffect(() => {
    isSuccess ? navigate(-1) : null;
  }, [isSuccess]);

  const onSubmit = (data) => {
    if (isEdit) {
      isEqual(data.quantity2, "") ? (data.quantity2 = 0) : null;

      updateRecipeIngredient(data);
      return;
    } else {
      const formData = {
        ...data,
        recipeId,
      };
      AddRecipeIngredient(formData);
    }
  };

  return (<>
    <Button
      component={Link}
      color={"primary"}
      className="baseButton"
      variant="contained"
      onClick={() => navigate(-1)}
      startIcon={<span className="material-icons">keyboard_arrow_left</span>}>
      {"Back"}
    </Button>
    {isEditLoading ? (
      <EditSkeleton />
    ) : (
      <Card>
        <Box sx={{
          padding: "15px"
        }}>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              maxHeight: "65vh",
            }}>
            <GeneratedForm
              list={RecipeIngredientsFields}
              control={control}
              setValue={setValue}
              errors={errors}
              defaultOptions={defaultValues}
            />
          </Grid>
          <Grid item md={6}>
            <BaseSubmitButton
              onClick={handleSubmit(onSubmit)}
              sx={{ margin: "20px 0" }}
              isSubmitting={isSubmitting}
              text={isEdit ? "Save" : "Create"}
            />
          </Grid>
        </Box>
      </Card>
    )}
  </>);
};
