import React from "react";

// admin/ReportMenu
import RecipeReport from "components/admin/reportMenu/recipeReport/RecipeReport";
import VendorReport from "components/admin/reportMenu/vendorReport/VendorReport";
import IngredientRecipeMissingCost from "components/admin/reportMenu/vendorReport/IngredientRecipeMissingCost";
import CompareVendorPricing from "components/admin/reportMenu/vendorReport/CompareVendorPricing";
import BillingReport from "components/admin/reportMenu/billingReport/BillingReport";

import ExportTimeMileage from "components/admin/reportMenu/billingReport/ExportTimeMileage";
import ExportDietitian1099 from "components/admin/reportMenu/billingReport/ExportDietitian1099";
import ExportHTIOrders from "components/admin/reportMenu/billingReport/ExportHTIOrders";
// Admin Report section
const AdminRecipeReportRoute = {
  path: "RecipeReport",
  element: <RecipeReport />,
};

const AdminVendorReportRoute = {
  path: "VendorReport",
  element: <VendorReport />,
};

const AdminRecipeMissingCostRoute = {
  path: "VendorReport/IngredientRecipeMissingCost",
  element: <IngredientRecipeMissingCost />,
};

const AdminCompareVendorPricingRoute = {
  path: "VendorReport/CompareVendorPricing",
  element: <CompareVendorPricing />,
};

const AdminbillingreportRoute = {
  path: "billingreport",
  element: <BillingReport />,
};

const AdminExportTimeMileageRoute = {
  path: "billingreport/ExportTimeMileage",
  element: <ExportTimeMileage />,
};

const AdminExportDietitianRoute = {
  path: "billingreport/ExportDietitian1099",
  element: <ExportDietitian1099 />,
};

const AdminExportHTIOrdersRoute = {
  path: "billingreport/ExportHTIOrders",
  element: <ExportHTIOrders />,
};

export const AdminReportRoute = {
  AdminRecipeReportRoute,
  AdminVendorReportRoute,
  AdminRecipeMissingCostRoute,
  AdminCompareVendorPricingRoute,
  AdminbillingreportRoute,
  AdminExportTimeMileageRoute,
  AdminExportDietitianRoute,
  AdminExportHTIOrdersRoute,
};
