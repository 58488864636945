import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Button } from "@mui/material";
import { useGetPurchasingGroupListQuery } from "../../../../../store/apis/PurchasingGroupApis";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";
import { GroupAdd } from "@mui/icons-material";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function PurchasingGroups() {
  const SEARCH_PARAM = "search";
  const [searchParams, setSearchParams] = useSearchParams();
  const [debouncedValue, setDebouncedValue] = useState(
    searchParams.get("search") || ""
  );
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );

  const {
    data: purchasingGroups,
    isLoading: isLoading,
    isError: isError,
  } = useGetPurchasingGroupListQuery(debouncedValue || "", {
    refetchOnMountOrArgChange: true,
  });

  const purchasingGroupColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "sageCustomerId",
      headerName: "Sage Customer Id",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "numberOfFacilities",
      headerName: "# of Communities",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        if (params.row["numberOfFacilities"] === 0) {
          return params.row["numberOfFacilities"];
        } else
          return (
            <Link
              to={`/admin/communities/communitySetup?purchasingGroupId=${params.row["id"]}`}
              style={{ textDecoration: "none", color: "blue" }}>
              {params.row["numberOfFacilities"]}
            </Link>
          );
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={purchasingGroups}
            hasEdit={true}
            hasDelete={true}
            editProps={{
              To: `addPurchasingGroup?id=${params.row.id}`,
              title: "Purchasing Group",
            }}
            deleteProps={{
              hintText:
                params.row["numberOfFacilities"] > 0
                  ? "Purchasing Groups associated with Communities cannot be deleted."
                  : "",
              entityName: params.row?.name,
              apiPath: "deletePurchasingGroup",
              disabled: params.row["numberOfFacilities"] > 0,
            }}
            childrenLocation={"end"}></BaseActionBox>
        );
      },
    },
  ];

  return (
    <>
      <FlexBox
        justifyContent={"space-between"}
        mb={"3px"}
        flexWrap={{ xs: "wrap", md: "nowrap" }}>
        <BaseSearch
          placeholder="Search..."
          disableUnderline={true}
          type="text"
          SEARCH_PARAM={SEARCH_PARAM}
          value={searchValue}
          setSearchValue={setSearchValue}
          setDebouncedValue={setDebouncedValue}
          setSearchParams={setSearchParams}
        />
        <Button
          component={Link}
          to="addPurchasingGroup"
          color={"primary"}
          variant="contained"
          endIcon={<GroupAdd />}>
          Add Purchasing Group
        </Button>
      </FlexBox>

      <BaseDataGrid
        loading={isLoading}
        columns={purchasingGroupColumns}
        rows={purchasingGroups}
        error={isError}
        autoHeight={false}
        overflow={"none"}
      />
    </>
  );
}
