import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { PURCHASING_GROUPS } = API_ENDPOINTS;

export const PurchasingGroupApis = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPurchasingGroupList: builder.query({
      query: (searchText) =>
        `/${PURCHASING_GROUPS}?searchText=${searchText}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: PURCHASING_GROUPS, id })),
              PURCHASING_GROUPS,
            ]
          : [PURCHASING_GROUPS];
      },
    }),
    getPurchasingGroupSelectList: builder.query({
      query: () => `/${PURCHASING_GROUPS}?searchText=`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res?.id,
            label: res?.name,
          };
        }),
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: PURCHASING_GROUPS, id })),
              PURCHASING_GROUPS,
            ]
          : [PURCHASING_GROUPS];
      },
    }),
    getPurchasingGroupById: builder.query({
      query: (id) => `/${PURCHASING_GROUPS}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: PURCHASING_GROUPS, id: response.id }, PURCHASING_GROUPS]
          : [PURCHASING_GROUPS],
    }),
    addPurchasingGroup: builder.mutation({
      query: (data) => ({
        url: `/${PURCHASING_GROUPS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: PURCHASING_GROUPS, id: result?.id },
      ],
    }),
    updatePurchasingGroup: builder.mutation({
      query: (data) => ({
        url: `/${PURCHASING_GROUPS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: PURCHASING_GROUPS, id: result?.id },
      ],
    }),
    deletePurchasingGroup: builder.mutation({
      query: (id) => ({
        url: `/${PURCHASING_GROUPS}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result) => [
        { type: PURCHASING_GROUPS, id: result?.id },
      ],
    }),
  }),
});

export const {
  useGetPurchasingGroupListQuery,
  useGetPurchasingGroupSelectListQuery,
  useGetPurchasingGroupByIdQuery,
  useAddPurchasingGroupMutation,
  useUpdatePurchasingGroupMutation,
  useDeletePurchasingGroupMutation,
} = PurchasingGroupApis;
