import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Controller } from "react-hook-form";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseRadio.md)
 */
export default function BaseRadio({ control, errors, ...rest }) {
  return (
    <FormControl fullWidth>
      <FormLabel>{rest.label}</FormLabel>
      {control ? (
        <Controller
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              row={rest?.rows === "column" ? false : true}
              value={value}
              onChange={onChange}
              error={errors[rest?.name]}
              sx={{ width: "100% !important" }}>
              {rest.options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          )}
          control={control}
          name={rest?.name}
          defaultValue={rest?.defaultValue}
          rules={rest?.validationProps}
        />
      ) : null}
    </FormControl>
  );
}
