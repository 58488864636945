import React from "react";
import { Link } from "react-router-dom";
import { MenuItem } from "@mui/material";

import { useGetDocumentListQuery } from "../../../../../store/apis/DocumentApi";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import { BaseButton } from "components/shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";
import BaseMenu from "components/shared/BaseMenu";
import DataGridContainer from "components/shared/styledComponents/DataGridContainer";

function ManageDocuments() {
  const {
    data: documentList,
    isError: hasError,
    isLoading: loading,
  } = useGetDocumentListQuery();

  const isError = hasError;
  const isLoading = loading;

  const DocumentColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      minWidth: 400,
    },
    {
      field: "documentTypeId",
      headerName: "Document Type",
      flex: 2,
      minWidth: 100,
      renderCell: (params) => {
        return params.row?.documentTypeId === 1
          ? "Program Training"
          : params.row?.documentTypeId === 2
          ? "Menu Guide Book"
          : params.row?.documentTypeId === 3
          ? "Forms & Information Sheets"
          : "";
      },
    },
    {
      field: "groupName",
      headerName: "Document Group",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "sortOrder",
      headerName: "Sort Order",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={documentList}
            hasEdit={true}
            hasDelete={true}
            editProps={{
              To: `/admin/userMenu/managedocuments/AddManageDocument?id=${params.row.id}`,
              title: "Document",
            }}
            deleteProps={{
              entityName: params.row?.name || "",
              apiPath: "deleteDocument",
              title: "Document",
            }}
            childrenLocation={"end"}>
            <BaseMenu index={params.row.id}>
              <MenuItem
                component={Link}
                to={params.row.url}
                download={params.row.id || 0 + params.row.name}
                target="_blank"
                disableRipple>
                Download
              </MenuItem>
            </BaseMenu>
          </BaseActionBox>
        );
      },
    },
  ];

  return (
    <>
      <FlexBox justifyContent="start" mb={1}>
        <BaseButton
          text={"Create Document"}
          endIcon={"post_add"}
          To={`AddManageDocument`}
        />
      </FlexBox>

      <DataGridContainer>
        <BaseDataGrid
          rows={documentList}
          columns={DocumentColumns}
          loading={isLoading}
          error={isError}
          height={"100%"}
        />
      </DataGridContainer>
    </>
  );
}

export default ManageDocuments;
