import { REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "../../../../../../shared/generatedForm/GeneratedForm";

export const DIET_INFO_FIELDS = [
  {
    props: {
      label: "Diet Name",
      id: "name",
      name: "name",
      placeholder: "Diet Name",
    },
    validationProps: {
      required: REQUIRED_ERROR("Diet Name"),
    },
    size: 12,
  },
  {
    props: {
      label: "Diet Rank",
      id: "dietRank",
      name: "dietRank",
      type: "number",
    },
    validationProps: {
      required: REQUIRED_ERROR("Diet Rank"),
    },
    size: 6,
  },
  {
    props: {
      label: "Sort Order",
      id: "sortOrder",
      name: "sortOrder",
      type: "number",
      defaultValue: 1,
    },
    validationProps: {
      required: REQUIRED_ERROR("Sort Order"),
    },
    size: 6,
  },

  {
    props: {
      label: "Include in Menus",
      name: "includeInMenu",
    },
    type: FORM_TYPES.Checkbox,
    size: 12,
  },
  {
    props: {
      label: "Is Texture",
      name: "isTexture",
    },
    type: FORM_TYPES.Checkbox,
    size: 12,
  },
  {
    props: {
      label: "Default - Will be assigned to all new communities",
      name: "defaultDiet",
    },
    type: FORM_TYPES.Checkbox,
    size: 12,
  },
  {
    props: {
      label: "IDDSI Diet (enables IDDSI symbols)",
      name: "isIDDSI",
    },
    type: FORM_TYPES.Checkbox,
    size: 12,
  },
];
