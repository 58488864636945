import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
  removeGenericSnackbar,
  selectGenericNotifications,
} from "../store/slices/snackbarSlice/GenericSnackbarSlice";

let displayed = [];

const useNotifier = () => {
  const dispatch = useDispatch();
  const genericNotifications = useSelector(selectGenericNotifications);
  const notifications = [...genericNotifications];
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  React.useEffect(() => {
    notifications.forEach(
      ({ key, message, options, type = {}, dismissed = false }) => {
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key);
          return;
        }

        // do nothing if snackbar is already displayed
        if (displayed.includes(key)) return;

        let customMessage = message;
        if (options && options.messagelist) {
          customMessage = (
            <div>
              {message}
              <br />

              {options.messagelist.map((value, index) => {
                return (
                  <div key={index}>
                    {value}
                    <br />
                  </div>
                );
              })}
            </div>
          );
        }

        // display snackbar using notistack
        enqueueSnackbar(customMessage, {
          key,
          ...options,
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey);
            }
          },
          onExited: (event, myKey) => {
            // remove this snackbar from redux store
            switch (type) {
              case "generic":
                dispatch(removeGenericSnackbar(myKey));
                break;
            }
            removeDisplayed(myKey);
          },
        });

        // keep track of snackbars that we've displayed
        storeDisplayed(key);
      }
    );
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
};

export default useNotifier;
