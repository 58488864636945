import { Box, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import dietManual from "../../../../../assets/imgs/DietManual.png";
import { DietManual2022, DietManual2017 } from "../constants";
import { DropdownUI } from "../dropdownUI/DropdownUI";
import { useGetFacilityByIdQuery } from "../../../../../store/apis/FacilityApis";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../../../../store/slices/authSlice/AuthSlice";
import styles from "../Resource.module.css";
import { Link } from "react-router-dom";

export const DietManual = () => {
  const [isActiveId, setIsActiveId] = useState("");

  const facilityId = useSelector(selectFacilityId);

  const { data: currentFacility } = useGetFacilityByIdQuery(facilityId, {
    skip: !facilityId,
  });

  return (<>
    <Box sx={{
      padding: "8px"
    }}>
      <Box
        sx={{
          display: "Flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
          width: "100%"
        }}>
        <Typography variant="h6" component="h4" className={styles.title}>
          Diet Manual
        </Typography>

        <Button
          className={styles.downloadBtn}
          variant="contained"
          endIcon={<span className="material-icons">download</span>}
          href={
            "https://healthtechdocs.blob.core.windows.net/2022dietmanuals/2022%20Diet%20Manual%20Complete_DiningRD%20Publish.pdf"
          }
          target={"_blank"}>
          {"Download The Complete Manual"}
        </Button>
      </Box>

      <Box className={styles.resourceDietMain}>
        <img src={dietManual} />
        <Typography
          variant="h6"
          component="h2"
          sx={{
            fontWeight: 800,
            color: "primary"
          }}>
          Long Term care Diet Manual
        </Typography>
        <Typography variant="p" component={"p"} sx={{
          fontWeight: 300
        }}>
          Modified as of February{" "}
          {currentFacility?.corporateGroupId == 336 ? "2017" : "2022"}
        </Typography>
      </Box>

      {currentFacility?.corporateGroupId == 336
        ? DietManual2017?.map((item, index) => {
            return (
              <Link
                key={index}
                className={`${styles.communityInfo} ${styles.resourceBarRow}`}
                to={`${item?.url}`}
                target={"_blank"}
                style={{ textDecoration: "none" }}>
                <Typography
                  variant="h6"
                  component="h4"
                  className={styles.title}>
                  {item.name}
                </Typography>
              </Link>
            );
          })
        : DietManual2022?.map((item, index) => {
            return (
              <DropdownUI
                key={index}
                item={item}
                index={index}
                isActiveId={isActiveId}
                setIsActiveId={setIsActiveId}
                isDietScreen={true}
              />
            );
          })}
    </Box>
  </>);
};
