import React from "react";
import { useSearchParams } from "react-router-dom";
import { Typography, Box, Grid } from "@mui/material";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "../../../../../shared/generatedForm/GeneratedForm";
import { useCreatePortalDocumentByFacilityMutation } from "store/apis/ConsultantApis";
import { useForm } from "react-hook-form";
import GeneratedForm from "../../../../../shared/generatedForm/GeneratedForm";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";

export default function CommunityPortalDocuments() {
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");
//   const navigate = useNavigate();

  const [createPortalDocuments, { isError, isSubmitting }] =
    useCreatePortalDocumentByFacilityMutation();

    const onSubmit = (data) => {

        data.facilityId = id;

        createPortalDocuments(data);

        // if (!isError) {
        //     navigate(-1);
        // }
    };
    
    const methods = useForm({
        shouldUnregister: false,
        mode: "all",
    });
    
    const {
        handleSubmit,
        control,
        formState: { errors },
        } = methods;

    const FIELDS = [
    {
        props: {
        name: "startDate",
        id: "startDate",
        label: "Start Date",
        },
        validationProps: {
        required: REQUIRED_ERROR("Start Date field is required"),
        },
        type: FORM_TYPES.DatePicker,
        size: 6,
    },
    {
        props: {
          name: "endDate",
          id: "endDate",
          label: "End Date",
        },
        validationProps: {
          required: REQUIRED_ERROR("End Date field is required"),
        },
        type: FORM_TYPES.DatePicker,
        size: 6,
      },
  ];

  return (<>
    <Typography variant="h6">
      Generate Community Portal Documents for Facility:
    </Typography>
    <Box sx={{
      padding: "8px"
    }}>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}>
            <GeneratedForm
              control={control}
              list={FIELDS}
              errors={errors}
            />

            <Grid item md={6}>
              <BaseSubmitButton
                fullWidth
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
                text={"Create"}
              />
            </Grid>
            {isError ? (
              <Typography sx={{
                color: "error"
              }}>
                There was an error generating portal documents.
              </Typography>
            ) : undefined}
          </Grid>
        </Box>
  </>);
}
