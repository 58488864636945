import { FORM_TYPES } from "components/shared/generatedForm/GeneratedForm";

export const dayFields = [
  {
    props: {},
    type: FORM_TYPES.Offset,
    size: 6.3,
  },
  {
    props: {
      name: "onSun",
      id: "onSun",
      label: "Sunday",
    },
    size: 2,
    type: "checkbox",
  },
  {
    props: {},
    type: FORM_TYPES.Offset,
    size: 6.3,
  },
  {
    props: {
      name: "onMon",
      id: "onMon",
      label: "Monday",
    },
    size: 2,
    type: "checkbox",
  },
  {
    props: {},
    type: FORM_TYPES.Offset,
    size: 6.3,
  },
  {
    props: {
      name: "onTue",
      id: "onTue",
      label: "Tuesday",
    },
    size: 2,
    type: "checkbox",
  },
  {
    props: {},
    type: FORM_TYPES.Offset,
    size: 6.3,
  },
  {
    props: {
      name: "onWed",
      id: "onWed",
      label: "Wednesday",
    },
    size: 2,
    type: "checkbox",
  },
  {
    props: {},
    type: FORM_TYPES.Offset,
    size: 6.3,
  },
  {
    props: {
      name: "onThu",
      id: "onThu",
      label: "Thursday",
    },
    size: 2,
    type: "checkbox",
  },
  {
    props: {},
    type: FORM_TYPES.Offset,
    size: 6.3,
  },
  {
    props: {
      name: "onFri",
      id: "onFri",
      label: "Friday",
    },
    size: 2,
    type: "checkbox",
  },
  {
    props: {},
    type: FORM_TYPES.Offset,
    size: 6.3,
  },
  {
    props: {
      name: "onSat",
      id: "onSat",
      label: "Saturday",
    },
    size: 2,
    type: "checkbox",
  },
];
