import React from "react";
import { Grid, FormHelperText, Typography } from "@mui/material";
import BaseSelect from "../../../../../../shared/baseForm/BaseSelect";
import { REQUIRED_ERROR } from "utils/errorMessages";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import { addVendorFields } from "./forms.constants";
import { EditSkeleton } from "components/admin/admin.overlays";

export default function VendorIngredientInfoForm({
  control,
  errors,
  getValue,
  vendorList,
  vendorListLoading,
  unitList,
  unitListLoading,
}) {
  const computedCostValue = getValue("calculatedCostPerIngredientBaseId");
  const problems = getValue("problems");
  const computedCost =
    computedCostValue === undefined ? 0.0 : computedCostValue;
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const loading = vendorListLoading && unitListLoading;

  return (<>
    {loading ? (
      <EditSkeleton />
    ) : (
      <Grid container spacing={1.5} style={{ marginTop: 2, marginLeft: 2 }}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          {!vendorListLoading && (
            <BaseSelect
              label="Vendor"
              name="vendorId"
              id="vendorId"
              control={control}
              options={vendorList}
              errors={errors}
              validationProps={{
                required: REQUIRED_ERROR("Vendor"),
              }}
            />
          )}
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid container spacing={1}>
            <GeneratedForm
              control={control}
              list={addVendorFields}
              errors={errors}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}></Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <BaseSelect
            label="Units"
            name="unitId"
            id="unitId"
            control={control}
            options={unitList}
            errors={errors}
            validationProps={{ required: "Units is required" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="p" component={"p"} sx={{
            fontWeight: 200
          }}>
            Computed Base Cost: {currencyFormatter.format(computedCost)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {problems == 1 && (
            <FormHelperText error>
              Base cost cannot be computed due to a mismatch of Units.
            </FormHelperText>
          )}
          {problems == 2 && (
            <FormHelperText error>
              Base cost cannot be computed because no Price was entered.
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    )}
  </>);
}
