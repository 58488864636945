// Mui Imports
import { Box, Grid, Typography } from "@mui/material";
// Hooks Imports
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
// Mutation and Queries Imports
import {
  useAddUserRoleMutation,
  useAvailableUserRolesQuery,
  useGetCurrentUserQuery,
  useUserRolesQuery,
} from "../../../../../../store/apis/UserApis";
// Custom Components Imports
import ErrorPage from "../../../../../shared/errorPage/ErrorPage";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import { WarningText } from "components/shared/WarningText";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "authConfig";
import useMsalAccount from "utils/useMsalAccount";
import BaseActionBox from "components/shared/BaseActionBox";
import { Add } from "@mui/icons-material";
import FlexBox from "components/shared/styledComponents/FlexBox";

function UserRoles() {
  const { instance } = useMsal();
  // const loggedInAccount = useSelector(selectAccount);
  // States Declaration
  // const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [selectedId, setSelectedId] = useState();
  // Hooks Declaration
  const [searchParams] = useSearchParams();
  // Get Id Query from link
  const currentUserId = searchParams.get("id");
  // local Store Variable
  const { userId: loggedInUser } = useMsalAccount();
  //Get Query Section
  const { data: currentUser, isError: currentUserError } =
    useGetCurrentUserQuery(currentUserId, {
      skip: !currentUserId,
      refetchOnMountOrArgChange: true,
    });
  const {
    data: selectedRoles,
    isFetching: loadingSelected,
    isSuccess: selectedSuccess,
    isError: selectedError,
  } = useUserRolesQuery(currentUserId);
  const {
    data: availableRoles,
    isFetching: loadingAvailable,
    isError: errorAvailable,
  } = useAvailableUserRolesQuery({ selectedRoles });
  const loadError = currentUserError || selectedError;
  // Mutation Section
  const [addUserRole, { isLoading: isAddLoading }] = useAddUserRoleMutation();
  // Onsubmit function which add roles to the api
  function handleAdd(id) {
    setSelectedId(id);
    addUserRole({ roleId: id, userId: currentUser?.id });
  }

  useEffect(() => {
    if (selectedSuccess && !loadingSelected) {
      if (currentUserId == loggedInUser) {
        instance.acquireTokenSilent({
          ...loginRequest,
          account: instance.getActiveAccount(),
          forceRefresh: true,
        });
      }
    }
  }, [selectedSuccess, loadingSelected]);

  // Column data for Data Grid
  const selectedColumns = [
    {
      field: "roleName",
      headerName: "Role Name",
      flex: 3,
      minWidth: 100,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            hasDelete={true}
            deleteProps={{
              apiPath: "deleteUserRole",
              entityName: params.row?.roleName,
              title: "User Role",
            }}
          />
        );
      },
    },
  ];

  const availableColumns = [
    {
      field: "name",
      headerName: "Role Name",
      flex: 3,
      minWidth: 100,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox childrenLocation={"end"}>
            <BaseSubmitButton
              icon="true"
              onClick={() => handleAdd(params.row.id)}
              isSubmitting={selectedId === params.row.id ? isAddLoading : false}
              text={<Add />}
            />
          </BaseActionBox>
        );
      },
    },
  ];

  return (<>
    {loadError === true ? (
      <ErrorPage {...loadError} />
    ) : (
      <>
        <FlexBox my={"8px"} justifyContent={"space-between"}>
          {currentUser && (
            <Typography
              // variant="h5"
              className="userFacilityTitle"
              sx={{
                color: "primary",
                fontSize: "18px"
              }}>
              Change role assignment for{" "}
              {currentUser?.firstName + " " + currentUser?.lastName}
            </Typography>
          )}
          {loggedInUser == currentUserId && (
            <Box sx={{
              display: "flex"
            }}>
              <WarningText
                sx={{ margin: "0px !important" }}
                text={
                  "You are currently viewing your own roles. You need to login again for the changes to take effect."
                }
              />
            </Box>
          )}
        </FlexBox>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid
            item
            md={6}
            sx={{
              mt: 1,
              height: "100%"
            }}>
            <BaseDataGrid
              rows={selectedRoles}
              loading={loadingSelected}
              columns={selectedColumns}
              error={selectedError}
              autoHeight={false}
              height={{ md: "40vh", xl: "52vh" }}
              // onSelectionModelChange={(ids) => {
              //   setSelectedRowIds(ids);
              // }}
            />
          </Grid>
          <Grid
            item
            md={6}
            sx={{
              mt: 1,
              height: "100%"
            }}>
            <BaseDataGrid
              rows={availableRoles}
              loading={loadingAvailable}
              columns={availableColumns}
              autoHeight={false}
              height={{ md: "40vh", xl: "52vh" }}
              // onSelectionModelChange={(ids) => {
              //   setAvailableRowIds(ids);
              // }}
              error={errorAvailable}
            />
          </Grid>
        </Grid>
      </>
    )}
  </>);
}

export default UserRoles;
