export const OptionsList = [
  {
    id: 0,
    optionName: "My Rosters",
    name: "My Roster",
    Link: "/Consultant/MyRosters",
    // isActive: false,
  },
  {
    id: 1,
    optionName: "Enterprise Visit History",
    Link: "/Consultant/Enterprise/Visit",
    isActive: false,
  },
  {
    id: 2,
    optionName: "Enterprise Information",
    Link: "/Consultant/Enterprise/Information",
    isActive: false,
  },
];
