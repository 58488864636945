import {
  CustomformatTypeId,
  startingDay,
  offset6,
  endingDay,
  mealDate,
  printSimplified,
  useLegalSize,
} from "../form.constants";

export const CustomPostedMenuMultiForms = {
  Category: true,
  DayForm: [
    startingDay,
    offset6,
    endingDay,
    offset6,
    mealDate,
    offset6,
    printSimplified,
    offset6,
    useLegalSize,
    offset6,
  ],
  Report: [CustomformatTypeId],
};
