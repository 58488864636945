import { REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "../../shared/generatedForm/GeneratedForm";
import { positiveIntegerNumber } from "../../../utils/ValidationRegex";
export const MealIds = [
  { id: 1, label: "Breakfast" },
  { id: 2, label: "Lunch" },
  { id: 3, label: "Dinner" },
];
export const tableSideMealFields = [
  {
    props: {
      name: "meal",
      id: "meal",
      label: "Meal ",
    },
    validationProps: {
      required: REQUIRED_ERROR("Meal"),
    },
    options: MealIds,
    type: FORM_TYPES.Select,
    size: 6,
  },
  {
    props: {
      name: "seasonId",
      id: "seasonId",
      label: "Season",
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "seasonSelect",
    size: 6,
  },
  {
    props: {
      name: "menuId",
      id: "menuId",
      label: "Menu",
    },
    validationProps: {
      required: REQUIRED_ERROR("Menu"),
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getMenusBySeasonSelectBox",
    size: 6,
  },
  {
    props: {
      name: "menuDay",
      id: "menuDay",
      label: "Menu Day",
      type: "number",
      defaultValue: 0,
    },
    validationProps: {
      required: REQUIRED_ERROR("Menu Day"),
      min: {
        value: 1,
        message:
          "Entered day is less than the minimum day configured for the Menu",
      },
      max: {
        value: 28,
        message: "Menu Day Should be between 0 to 28",
      },
      pattern: {
        value: positiveIntegerNumber,
        message: "Please enter a positive whole number",
      },
    },

    size: 6,
  },
  {
    props: {
      name: "diningDate",
      id: "diningDate",
      label: "Dining Date",
      placeholder: "Dining Date",
    },
    type: FORM_TYPES.DatePicker,
    size: 6,
  },
  {
    props: {
      name: "startTime",
      id: "startTime",
      label: "Start Time",
      type: "time",
    },
    type: FORM_TYPES.DatePicker,
    size: 6,
  },
];
