import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { FOOD_TYPES } = API_ENDPOINTS;

export const FoodTypesApis = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // define an endpoint for getting FoodTypes
    getFoodTypes: builder.query({
      query: ({ searchTerm, skip, take }) =>
        `/${FOOD_TYPES}?searchText=${searchTerm}&skip=${skip}&take=${take}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.items.map(({ id }) => ({ type: FOOD_TYPES, id })),
              FOOD_TYPES,
            ]
          : [FOOD_TYPES];
      },
    }),
    getFoodTypesSelectList: builder.query({
      query: () => `/${FOOD_TYPES}?searchText=&skip=0&take=1000`,
      transformResponse: (response) => {
        return response?.data?.items.filter((item) => item.isDislike).map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        });
      },
    }),
    getFoodTypesSelectBox: builder.query({
      query: (searchTerm) =>
        `/${FOOD_TYPES}?searchText=${searchTerm}&skip=0&take=1000`,
      transformResponse: (response) =>
        response?.data?.items.map((item) => ({
          id: item?.id,
          label: item?.name,
          isDislike: item?.isDislike,
          // description: `${item?.availableBreakfast ? "Breakfast" : ""}${
          //   item?.availableLunch ? " Lunch" : ""
          // }${item?.availableSupper ? " Supper" : ""}`,
        })),
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: FOOD_TYPES, id })),
              FOOD_TYPES,
            ]
          : [FOOD_TYPES];
      },
    }),
    getFoodTypeById: builder.query({
      query: (foodTypeId) => `/${FOOD_TYPES}/${foodTypeId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: FOOD_TYPES, id: response.id }, FOOD_TYPES]
          : [FOOD_TYPES],
    }),
    addFoodType: builder.mutation({
      query: (data) => ({
        url: `/${FOOD_TYPES}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: FOOD_TYPES, id: result?.id }],
    }),
    updateFoodType: builder.mutation({
      query: (data) => ({
        url: `/${FOOD_TYPES}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: FOOD_TYPES, id: result?.id }],
    }),
    deleteFoodType: builder.mutation({
      query: (id) => ({
        url: `/${FOOD_TYPES}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: FOOD_TYPES, id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetFoodTypesQuery,
  useGetFoodTypesSelectBoxQuery,
  useGetFoodTypesSelectListQuery,
  useGetFoodTypeByIdQuery,
  useAddFoodTypeMutation,
  useUpdateFoodTypeMutation,
  useDeleteFoodTypeMutation,
} = FoodTypesApis;
