import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { PACKING_SLIPS } = API_ENDPOINTS;

export const PackingSlipAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPackingSlipList: builder.query({
      query: (facilityId) => ({
        url: `/${PACKING_SLIPS}?facilityId=${facilityId}`,
      }),
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              ...response.map(({ id }) => ({ type: PACKING_SLIPS, id })),
              PACKING_SLIPS,
            ]
          : [PACKING_SLIPS],
    }),
    deletePackingSlip: builder.mutation({
      query: (id) => ({
        url: `/${PACKING_SLIPS}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: PACKING_SLIPS, id: arg.id },
      ],
      transformResponse: (response) => response?.data,
    }),
    printPackingSlip: builder.mutation({
      query: ({ id, printPrice }) => ({
        url: `/${PACKING_SLIPS}/Print?packingSlipId=${id}&showPrice=${printPrice}`,
        method: "POST",
      }),
      providesTags: (result, error, arg) => [
        { type: PACKING_SLIPS, id: arg.id },
      ],
      transformResponse: (response) => response?.data,
    }),
    addPackingSlip: builder.mutation({
      query: (data) => ({
        url: `/${PACKING_SLIPS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: PACKING_SLIPS, id: arg.id },
      ],
    }),
    updatePackingSlip: builder.mutation({
      query: (data) => ({
        url: `/${PACKING_SLIPS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: PACKING_SLIPS, id: arg.id },
      ],
    }),
    getPackingSlipToEdit: builder.query({
      query: (id) => ({
        url: `/${PACKING_SLIPS}/edit/${id}`,
        method: "GET",
      }),
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [{ type: PACKING_SLIPS, id }],
    }),
  }),
});

export const {
  useGetPackingSlipListQuery,
  useDeletePackingSlipMutation,
  usePrintPackingSlipMutation,
  useAddPackingSlipMutation,
  useGetPackingSlipToEditQuery,
  useUpdatePackingSlipMutation,
} = PackingSlipAPIs;
