import React from "react";
import { useGetResidentTablesideOrdersQuery } from "../../../../store/apis/ResidentApis";
import { useSearchParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import dayjs from "dayjs";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import { BaseButton } from "components/shared/BaseButton";
import { PostAdd } from "@mui/icons-material";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";
import CreateBaseColumns from "components/shared/CreateBaseColumn";

export default function ResidentTablesideHistory() {
  const [searchParams] = useSearchParams();
  const residentId = searchParams.get("id");

  const {
    data: residentTablesideOrders,
    isLoading: isLoadingOrders,
    isError: isErrorOrders,
  } = useGetResidentTablesideOrdersQuery(residentId, { skip: !residentId });

  const tableSideColumns = [
    {
      field: "diningDate",
      minWidth: 50,
      flex: 1,
      headerName: "Date",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      valueGetter: (value) => dayjs(value).toDate(),
      valueFormatter: (value) => {
        const isDateValid = dayjs(value).isValid();
        return isDateValid
          ? dayjs(value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },
    {
      field: "mealName",
      minWidth: 50,
      flex: 1,
      headerName: "Meal",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "recipeName",
      minWidth: 50,
      flex: 2,
      headerName: "Item",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "quantity",
      minWidth: 50,
      flex: 2,
      headerName: "Serving",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Typography variant="body1" component="div">
            {`${params?.row?.quantity} - ${params?.row?.servingSize}`}
          </Typography>
        );
      },
    },
    {
      field: "deleteActions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={residentTablesideOrders}
            hasEdit={true}
            hasDelete={true}
            deleteProps={{
              entityName: params.row?.recipeName,
              apiPath: "deleteResidentTablesideOrders",
              title: "Tableside Order",
            }}
            editProps={{
              To: `addTablesideOrders?orderItemId=${params.row.id}&id=${residentId}&orderId=${params?.row?.orderId}&quantity=${params?.row?.quantity}`,
              title: "Tableside Order",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          display: "flex",
          overflow: "hidden",
          flexDirection: "column",
          gap: 1,
        }}>
        <FlexBox sx={{ justifyContent: "space-between" }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "800",
            }}>
            Tableside orders for the last 30 days
          </Typography>

          <BaseButton
            To={`addTablesideOrders?id=${residentId}`}
            endIcon={<PostAdd />}
            text="Add Order Item"
          />
        </FlexBox>
        <Box sx={{ height: "100%", flexGrow: 1, overflowY: "auto", pb: 1 }}>
          <BaseDataGrid
            rows={residentTablesideOrders}
            columns={CreateBaseColumns(tableSideColumns)}
            error={isErrorOrders}
            loading={isLoadingOrders}
            height="100%"
          />
        </Box>
      </Box>
    </>
  );
}
