/**
 * @see [Documentation](../../../Wiki/Documentations/FrontEnd/Constants/TimeIntervalList.md)
 */
export const TimeIntervalList = [
  {
    label: "Week",
    id: "W",
  },
  {
    label: "Month",
    id: "M",
  },
  {
    label: "Quarter",
    id: "Q",
  },
  {
    label: "Semi-Annual",
    id: "S",
  },
  {
    label: "Annual",
    id: "A",
  },
];
