import {
  AllResidents,
  PrintAllTimes,
  includeAdaptativeEquipment,
  labelsToSkip,
  showAllResidentId,
  snackDiningAreaId,
  snackSort,
  snackTimeId,
  startDate,
  type,
  useExcelFormat,
  weekDay,
  wing,
} from "../forms.constants";
import { REQUIRED_ERROR } from "utils/errorMessages";
import dayjs from "dayjs";

export const SnackSupplementLabelsFields = [
  snackTimeId,
  PrintAllTimes,
  snackDiningAreaId,
  type,
  showAllResidentId,
  AllResidents,
  snackSort,
  includeAdaptativeEquipment,
  startDate,
  wing,
  useExcelFormat,
  labelsToSkip,
];

export const SnackSupplementLabelsDefaults = {
  snackTimeId: null,
  snackDiningAreaId: null,
  type: 1,
  residentId: null,
  snackSort: 7,
  includeAdaptativeEquipment: false,
  startDate: dayjs(new Date()).format("YYYY-MM-DD"),
  wing: "",
  useExcelFormat: false,
  labelsToSkip: 0,
};

export const SnackSupplementProductionsFields = [
  {
    ...weekDay,
    validationProps: {
      required: REQUIRED_ERROR("Day of Week"),
    },
  },
  useExcelFormat,
];

export const SnackSupplementProductionsDefaults = {
  weekDay: null,
  useExcelFormat: false,
};
