import { emailValidation } from "utils/ValidationRegex";

export const FORM_FIELDS = [
  {
    props: {
      labelprop: "Admin",
      id: "administratorEmail",
      name: "administratorEmail",
      size: "small",
    },
    validationProps: {
      pattern: {
        value: emailValidation,
        message: "Invalid Email",
      },
    },
    size: 12,
  },
  {
    props: {
      labelprop: "DON",
      id: "don",
      name: "don",
      size: "small",
    },
    validationProps: {
      pattern: {
        value: emailValidation,
        message: "Invalid Email",
      },
    },
    size: 12,
  },
  {
    props: {
      labelprop: "MDS Coord",
      id: "mdsCoord",
      name: "mdsCoord",
      size: "small",
    },
    validationProps: {
      pattern: {
        value: emailValidation,
        message: "Invalid Email",
      },
    },
    size: 12,
  },
  {
    props: {
      labelprop: "FSD",
      id: "fssDsdemail",
      name: "fssDsdemail",
      size: "small",
    },
    validationProps: {
      pattern: {
        value: emailValidation,
        message: "Invalid Email",
      },
    },
    size: 12,
  },
];
