import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
// MUI Components
import { Box, Typography } from "@mui/material";
// Shared Components
import { WarningText } from "components/shared/WarningText";
import { BaseButton } from "components/shared/BaseButton";
import BaseDataGrid from "components/shared/baseDataGrid/BaseDataGrid";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "components/admin/admin.overlays";
// APIs
import {
  useGetVisiPlansFinishedVisitQuery,
  useSubmitFinishedVisitsMutation,
} from "store/apis/ConsultantApis";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import dayjs from "dayjs";
import CreateBaseColumns from "components/shared/CreateBaseColumn";

const SubmitFinishedVisit = () => {
  const [searchParams] = useSearchParams();
  const dietitianId = searchParams.get("dietitianId");
  const [selectionModel, setSelectionModel] = useState([]);

  const navigate = useNavigate();

  // useForm Hooks destructuring
  const { handleSubmit } = useForm({
    method: "all",
    shouldUnregister: false,
  });

  // APIs
  // Get
  const { data, isError, isLoading } = useGetVisiPlansFinishedVisitQuery(
    dietitianId,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  // POST
  const [submitFinishedVisits, { isSuccess, isLoading: isSubmitting }] =
    useSubmitFinishedVisitsMutation();

  useEffect(() => {
    if (isSuccess) {
      navigate("/Consultant/MyRosters");
    }
  }, [isSuccess]);

  const dataColumns = [
    {
      field: "dietitianName",
      minWidth: 100,
      flex: 1,
      headerName: "Consultant Name",
    },
    {
      field: "facilityName",
      minWidth: 100,
      flex: 1,
      headerName: "Facility Name",
    },
    {
      field: "dateOfVisit",
      minWidth: 20,
      flex: 1,
      headerName: "Visit Date",
      renderCell: (params) =>
        dayjs(params?.row?.dateOfVisit).format(SCREEN_CONSTANTS.Date_Format),
    },
    {
      field: "dateFinished",
      minWidth: 20,
      flex: 1,
      headerName: "Finished Date",
      renderCell: (params) =>
        dayjs(params?.row?.dateFinished).format(SCREEN_CONSTANTS.Date_Format),
    },
  ];

  const handleSelection = (newSelection) => {
    setSelectionModel(newSelection);
  };

  const onSubmit = () => {
    if (selectionModel.length > 0) {
      submitFinishedVisits(selectionModel);
    }
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            paddingTop: "10px",
            width: "10%",
          }}>
          <BaseButton
            size="small"
            text="Back"
            To={`/Consultant/MyRosters`}
            startIcon={"arrow_back"}
          />
        </Box>
        <WarningText
          text={`This will submit the report to DiningRD, and mark it as final. Once this is done, no further changes can be made to this visit report. Are you sure you want to do this?`}
        />
        {data && !isLoading ? (
          <>
            <Box
              sx={{
                paddingX: "10px",
              }}>
              <BaseDataGrid
                rows={data && isError ? [] : data || []}
                columns={CreateBaseColumns(dataColumns)}
                getRowId={(row) => row?.visitId}
                checkboxSelection
                onRowSelectionModelChange={handleSelection}
                selectionModel={selectionModel}
                loading={isLoading}
                error={isError}
                autoHeight={false}
                height={{ md: "50vh", xl: "60vh" }}
              />
            </Box>
            <Box
              sx={{
                padding: "8px",
                display: "flex",
                justifyContent: "end",
              }}>
              <BaseSubmitButton
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
                text="Finish Submit"
                padding="5px"
                className="baseButton"
              />
            </Box>
          </>
        ) : !data && !isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}>
            <Typography
              sx={{
                color: "error",
              }}>
              No records in database
            </Typography>
          </Box>
        ) : (
          <EditSkeleton />
        )}
      </Box>
    </>
  );
};

export default SubmitFinishedVisit;
