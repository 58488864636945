export const replaceKeys = (response) => {
  let placeholder = response;
  for (let i = 0; i < placeholder.length; i++) {
    if (placeholder[i]["dietitianId"]) {
      placeholder[i].value = placeholder[i]["dietitianId"];
      delete placeholder[i].dietitianId;
    }
    // else if (placeholder[i]["id"]) {
    //   placeholder[i].value = placeholder[i]["id"];
    //   delete placeholder[i].id;
    // }
    if (placeholder[i]["name"]) {
      placeholder[i].label = placeholder[i]["name"];
      delete placeholder[i].name;
    } else if (placeholder[i]["fullName"]) {
      placeholder[i].label = placeholder[i]["fullName"];
      delete placeholder[i].fullName;
    }
  }
  return placeholder;
};
