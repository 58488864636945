import React from "react";
import FullScreenMessage from "../FullScreenMessage";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/ErrorPage.md)
 */
export default function ErrorPage({
  message = "We’re sorry. We’re experiencing some difficulties.",
  title = "Something Went Wrong!",
  icon,
  code,
  color = "error",
}) {
  return (
    <FullScreenMessage
      title={title}
      code={code}
      color={color}
      message={message}
      instructions={`Please refresh your browser.`}
      icon={icon}
    />
  );
}
