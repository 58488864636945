import { REQUIRED_ERROR } from "utils/errorMessages";

export const ALLERGY_FIELDS = [
  {
    props: {
      name: "name",
      id: "name",
      label: "Name",
    },
    validationProps: {
      required: REQUIRED_ERROR("Name"),
    },
    size: 6,
  },
];
