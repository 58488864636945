import React from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { CommunityVisitsReports } from "./communityvisitmenu.constants";
import { Card } from "@mui/material";
import AdminTabs from "../../../../../shared/AdminTabs";
import styles from "./CommunityVisitsMenu.module.css";

import "../../../../../shared/AdminTabs.css";

export default function CommunityVisitMenu() {
  const [searchParams] = useSearchParams("visitId");
  const visitId = searchParams.get("visitId");
  const facilityId = searchParams.get("facilityId");
  const visitPlanId = searchParams.get("visitPlanId");
  const query = `?visitId=${visitId}&facilityId=${facilityId}&visitPlanId=${visitPlanId}`;
  const backQuery = `?id=${facilityId}`;
  return (
    <>
      <AdminTabs
        Data={CommunityVisitsReports}
        query={query}
        backQuery={backQuery}
      />
      <Card className={styles.adminData}>
        <Outlet />
      </Card>
    </>
  );
}
