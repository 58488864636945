import isEqual from "lodash/isEqual";

export const getLink = (params, visitPlanId) => {
  if (
    isEqual(params.row.visitReportTypeId, 1) ||
    isEqual(params.row.visitReportTypeId, 2) ||
    isEqual(params.row.visitReportTypeId, 3) ||
    isEqual(params.row.visitReportTypeId, 6)
  ) {
    return `/Consultant/MyRosters/VisitDetails/VisitReport?Id=${params.row.id}&visitPlanId=${visitPlanId}&visitReportTypeId=${params.row.visitReportTypeId}&visitId=${params.row.visitId}`;
  } else if (isEqual(params.row.visitReportTypeId, 5)) {
    return `/Consultant/MyRosters/VisitDetails/addMemo?visitId=${params.row.visitId}&visitReportTypeId=${params.row.visitReportTypeId}&id=${params.row.id}&visitPlanId=${visitPlanId}`;
  } else if (params.row.visitReportTypeId) {
    return `/Consultant/MyRosters/VisitDetails/NutritionalCares?visitPlanId=${visitPlanId}&visitReportId=${params.row.id}&visitReportTypeId=${params.row.visitReportTypeId}&visitId=${params.row.visitId}&isEdit=true`;
  } else {
    return `/Consultant/MyRosters/VisitDetails/AddMenus?recipeId=${params.row.id}`;
  }
};
