import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import { useResidentBeveragesQuery } from "../../../../store/apis/ResidentApis";
import { WarningText } from "../../../shared/WarningText";
import { useSelector } from "react-redux";
import { BaseButton } from "components/shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";
export default function ResidentBeverages() {
  const [searchParams] = useSearchParams();
  const residentId = searchParams.get("id");
  const featureMealExtra = useSelector((state) => state.featureInfo.mealExtra);
  const [hideItems, setHideItems] = useState(true);

  const {
    data: residentBeverages,
    isLoading,
    isError,
  } = useResidentBeveragesQuery(residentId, { skip: !residentId });

  useEffect(() => {
    if (featureMealExtra !== null) {
      var setActive = featureMealExtra?.enabled == "true";
      if (setActive) {
        setHideItems(true);
      } else if (!setActive) {
        setHideItems(false);
      }
    }
  }, [residentBeverages, featureMealExtra]);

  const noteColumns = [
    {
      field: "beverageName",
      minWidth: 50,
      flex: 2,
      headerName: "Beverage",
      // cellClassName: "cellCenterAlign",
      // headerAlign: "center",
    },
    {
      field: "description",
      minWidth: 50,
      flex: 2,
      headerName: "Description",
    },
    {
      field: "amount",
      minWidth: 50,
      flex: 1,
      headerName: "Amount",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {params?.row?.quantity} {params?.row?.unitName}
        </>
      ),
    },
    {
      field: "mealName",
      minWidth: 50,
      flex: 1,
      headerName: "Meal",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    !hideItems && {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={residentBeverages}
            hasEdit={true}
            editProps={{
              To: `addresidentBeverage?beverageId=${params.row.id}&id=${residentId}`,
              title: "Resident Beverage",
            }}
            hasDelete={true}
            deleteProps={{
              entityName: params.row?.beverageName,
              apiPath: "deleteResidentBeverage",
              title: "Resident Beverage",
            }}
          />
        );
      },
    },
  ];

  return (<>
    <Grid container spacing={1}>
      {!hideItems ? (
        <Grid item sm={12} md={4} lg={3} xl={3} sx={{
          marginTop: 1
        }}>
          <BaseButton
            To={`addresidentBeverage?id=${residentId}`}
            endIcon={<span className="material-icons">post_add</span>}
            text="Add Beverage"
            marginLeft="0px !important"
          />
        </Grid>
      ) : (
        <Grid item sm={12} md={10} lg={12} xl={12} sx={{
          marginTop: 1
        }}>
          <WarningText
            text={`Please navigate to the Meal Add-On tab to modify beverage selections, as well as add additional food and supplement items to the MealCard.`}
            width="100%"
            color="primary.main"
            sx={{ marginLeft: "0px !important", marginY: "0px !important" }}
          />
        </Grid>
      )}
      <Grid item sm={12} md={12} lg={12} xl={12}>
        <BaseDataGrid
          rows={residentBeverages}
          columns={noteColumns}
          error={isError}
          loading={isLoading}
          autoHeight={false}
          height={{
            xs: "50vh",
            sm: "65vh",
            md: "55vh",
            lg: "60.5vh",
            xl: "67vh",
          }}
          sx={{
            marginY: "0px !important",
            paddingY: "0px !important",
          }}
        />
      </Grid>
    </Grid>
  </>);
}
