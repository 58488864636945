import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import SignInSignOutButton from "./SignInSignOutButton";

export default function PersonMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}>
        <PersonIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        {/* {isSuccess && currentUserData ? (
          <div>
            <div className="personMenuItem">
              <div className="personMenuItemLabel">Logged in as: </div>
              <div>{userInformation.firstName + " " + userInformation.lastName}</div>
            </div>
            <div className="personMenuItem">
              <div className="personMenuItemLabel">Community: </div>
              <div>{userInformation.facilityName}</div>
            </div>
            <div className="personMenuItem">
              <div className="personMenuItemLabel">Expiration Date: </div>
              <div>{userInformation.expirationDateString}</div>
            </div>
          </div>
        ) : null} */}

        <div className="signInOutButtonWrapper">
          <SignInSignOutButton handleClose={handleClose} />
        </div>
      </Menu>
    </div>
  );
}
