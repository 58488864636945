/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import {
  useGetFacilityThickenedLiquidBulkEditQuery,
  useAddThickenedLiquidBulkEditMutation,
} from "../../../../../../../store/apis/FacilityApis";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import {
  Box,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../../admin.overlays";

export default function EditLiquidConsistency() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const facilityId = searchParams.get("id");

  const { data: liquidData, isFetching: isLoadingLiquidConsistency } =
    useGetFacilityThickenedLiquidBulkEditQuery(facilityId);

  const [updateliquid, { isError: updateError, isLoading: updateLoading }] =
    useAddThickenedLiquidBulkEditMutation();

  const [thickenedLiquids, setDataLiquid] = useState([]);
  const [isthickenedLiquids, setIsDataLiquid] = useState(false);

  const isError = updateError;
  const isSubmitting = updateLoading;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const { handleSubmit } = methods;

  useEffect(() => {
    liquidData?.map((item) => {
      thickenedLiquids.push({
        thickenedLiquidId: item.thickenedLiquidId,
        name: item.name,
        selected: item.selected,
      });
    });
    setIsDataLiquid(!isthickenedLiquids);
  }, [liquidData]);

  const onSubmit = () => {
    updateliquid({
      thickenedLiquids: thickenedLiquids,
      facilityName: "",
      facilityId: facilityId,
    });
    if (!isError) navigate(-1);
  };

  const updateftn = (data, index) => {
    const newArr = [...thickenedLiquids];
    newArr[index].selected = !data.selected;
    setDataLiquid(newArr);
  };

  return (<>
    {isLoadingLiquidConsistency ? (
      <EditSkeleton />
    ) : (
      <div style={{ padding: 15 }}>
        <FormProvider>
          <form>
            <Grid container spacing={2}>
              {thickenedLiquids?.map((data, index) => {
                return (
                  <Grid key={index} item xs={10}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data?.selected}
                          onClick={() => {
                            updateftn(data, index);
                          }}
                        />
                      }
                      label={data?.name}
                    />
                  </Grid>
                );
              })}

              <Grid item xs={5}>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <BaseSubmitButton
                    fullWidth
                    onClick={handleSubmit(onSubmit)}
                    isSubmitting={isSubmitting}
                  />
                </Box>
                {isError ? (
                  <Typography sx={{
                    color: "error"
                  }}>
                    There was an error submitting the form, please try again.
                  </Typography>
                ) : undefined}
              </Grid>
              <Grid item xs={3} />
            </Grid>
          </form>
        </FormProvider>
      </div>
    )}
  </>);
}
