import { Grid, Typography } from "@mui/material";
import { uniqueId } from "lodash";
import React from "react";
import BaseSelect from "../baseForm/BaseSelect";
import BaseRadio from "../../shared/baseForm/BaseRadio";
import BaseCheckBox from "../../shared/baseForm/BaseCheckBox";
import BaseInput from "../../shared/baseForm/BaseInput";
import BaseSelectBox from "../baseSelectBox/BaseSelectBox";
import BaseAutoCompleteBox from "../baseAutoCompleteBox/BaseAutoCompleteBox";
import BaseMultiSelectBox from "../baseMultiSelectBox/BaseMultiSelectBox";
import { BaseMultiSelect } from "../baseForm/BaseMultiSelect";
import BaseDatePicker from "../baseForm/BaseDatePicker";
import BaseTimePicker from "../baseForm/BaseTimePicker";
import BaseMultiSelectSearch from "../baseForm/BaseMultiSelectSearch";
import BaseMultiSelectSearchBox from "../baseMultiSelectSearchBox/BaseMultiSelectSearchBox";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/GeneratedForm.md)
 */
export default function GeneratedForm({
  list,
  control,
  errors,
  defaultValues,
  defaultOptions,
  disabledList,
  hiddenList,
  getValue,
  queries,
  setValue,
  marginY,
  ...rest
}) {
  return list?.map((data) => {
    const { props, size } = data || {};
    const { name } = props || {};

    if (hiddenList?.some((hidden) => hidden.name === name)) {
      return null;
    }

    const queryData = queries?.find((query) => query.name === name) || {};
    const { query, skip } = queryData;

    return (
      <Grid
        key={`Grid-${name || uniqueId()}`}
        item
        xs={size}
        sx={{
          marginY: marginY,
        }}>
        {defaultOptions?.some((x) => x.name === props?.name)
          ? generateForm({
              data: data,
              defaultOption: defaultOptions.find((x) => x.name === props?.name),
              defaultValue: defaultValues?.find((x) => x.name === props?.name)
                ?.value,
              control: control,
              disabledList,
              errors: errors,
              getValue: getValue,
              setValue: setValue,
              query: query,
              skip: skip,
              ...rest,
            })
          : generateForm({
              data: data,
              defaultValue: defaultValues?.find((x) => x.name === props?.name)
                ?.value,
              control: control,
              errors: errors,
              getValue: getValue,
              setValue: setValue,
              disabledList,
              query: query,
              skip: skip,
              ...rest,
            })}
      </Grid>
    );
  });
}

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Methods/generatedForm.md)
 */
export function generateForm({
  data,
  control,
  defaultOption,
  defaultValue,
  errors,
  disabledList,
  getValue,
  setValue,
  query,
  skip,
  ...rest
}) {
  const {
    props: dataProps,
    type,
    validationProps,
    options,
    endpoint,
    title,
  } = data || {};

  const {
    name,
    size,
    fontSize,
    defaultValue: propDefaultValue,
    label,
    id,
    variant,
    component,
    text,
    ...restProps
  } = {
    ...dataProps,
    disabled: disabledList?.includes(dataProps?.name),
  };

  const formTypeComponents = {
    [FORM_TYPES.Select]: (
      <>
        {title && (
          <Typography
            variant="p"
            component={"p"}
            sx={{
              fontWeight: 600,
            }}>
            {title}
          </Typography>
        )}
        <BaseSelect
          // From DataProps
          id={id}
          label={label}
          fontSize={fontSize}
          defaultValue={defaultValue || propDefaultValue}
          size={size}
          variant={variant}
          component={component}
          name={name}
          disabled={restProps.disabled}
          {...restProps}
          // For React Hook Form
          control={control}
          validationProps={validationProps}
          errors={errors}
          // Rest
          {...rest}
          // For Select
          options={options}
        />
      </>
    ),
    [FORM_TYPES.Checkbox]: (
      <BaseCheckBox
        // From DataProps
        id={id}
        label={label}
        fontSize={fontSize}
        defaultValue={defaultValue || propDefaultValue}
        size={size}
        variant={variant}
        // component={component}
        name={name}
        disabled={restProps.disabled}
        {...restProps}
        // For React Hook Form
        control={control}
        validationProps={validationProps}
        errors={errors}
        // Rest
        {...rest}
      />
    ),
    [FORM_TYPES.Offset]: <></>,
    [FORM_TYPES.SelectAPI]: (
      <>
        {title && (
          <Typography
            variant="p"
            component={"p"}
            sx={{
              fontWeight: 600,
            }}>
            {title}
          </Typography>
        )}
        <BaseSelectBox
          // From DataProps
          id={id}
          label={label}
          fontSize={fontSize}
          defaultValue={defaultValue || propDefaultValue}
          size={size}
          variant={variant}
          component={component}
          name={name}
          disabled={restProps.disabled}
          {...restProps}
          // For React Hook Form
          control={control}
          validationProps={validationProps}
          errors={errors}
          // Rest
          {...rest}
          // For Select API
          endpoint={endpoint}
          skip={skip}
          query={query}
        />
      </>
    ),
    [FORM_TYPES.AutocompleteAPI]: (
      <>
        {title && (
          <Typography
            variant="p"
            component={"p"}
            sx={{
              fontWeight: 600,
            }}>
            {title}
          </Typography>
        )}
        <BaseAutoCompleteBox
          // From DataProps
          id={id}
          label={label}
          fontSize={fontSize}
          defaultValue={defaultValue || propDefaultValue}
          size={size}
          variant={variant}
          component={component}
          name={name}
          disabled={restProps.disabled}
          {...restProps}
          // For React Hook Form
          control={control}
          validationProps={validationProps}
          errors={errors}
          // Rest
          {...rest}
          // For AutoComplete API
          endpoint={endpoint}
          defaultOptions={
            defaultOption?.option ? defaultOption?.option : defaultOption || []
          }
        />
      </>
    ),
    [FORM_TYPES.MultiSelectAPI]: (
      <BaseMultiSelectBox
        // From DataProps
        id={id}
        label={label}
        fontSize={fontSize}
        defaultValue={defaultValue || propDefaultValue}
        size={size}
        variant={variant}
        component={component}
        name={name}
        disabled={restProps.disabled}
        {...restProps}
        // For React Hook Form
        control={control}
        validationProps={validationProps}
        errors={errors}
        // Rest
        {...rest}
        // For MultiSelect API
        setValue={setValue}
        endpoint={endpoint}
        defaultOptions={defaultOption?.option || []}
        query={query}
        skip={skip}
      />
    ),
    [FORM_TYPES.MultiSelect]: (
      <BaseMultiSelect
        // From DataProps
        id={id}
        label={label}
        fontSize={fontSize}
        defaultValue={defaultValue || propDefaultValue}
        size={size}
        variant={variant}
        component={component}
        name={name}
        disabled={restProps.disabled}
        {...restProps}
        // For React Hook Form
        control={control}
        validationProps={validationProps}
        errors={errors}
        // Rest
        {...rest}
        // For MultiSelect API
        options={options}
        setValue={setValue}
        defaultOptions={defaultOption?.option || []}
        skip={skip}
      />
    ),
    [FORM_TYPES.MultiSelectSearch]: (
      <BaseMultiSelectSearch
        // From DataProps without id
        label={label}
        fontSize={fontSize}
        defaultValue={defaultValue || propDefaultValue}
        size={size}
        variant={variant}
        component={component}
        name={name}
        disabled={restProps.disabled}
        {...restProps}
        // For React Hook Form
        control={control}
        validationProps={validationProps}
        errors={errors}
        // Rest
        {...rest}
      />
    ),
    [FORM_TYPES.MultiSelectSearchApi]: (
      <BaseMultiSelectSearchBox
        // From DataProps without id
        id={id}
        label={label}
        fontSize={fontSize}
        defaultValue={defaultValue || propDefaultValue}
        size={size}
        variant={variant}
        component={component}
        name={name}
        disabled={restProps.disabled}
        {...restProps}
        // For React Hook Form
        control={control}
        validationProps={validationProps}
        errors={errors}
        // Rest
        {...rest}
        // For MultiSelect API
        setValue={setValue}
        endpoint={endpoint}
        defaultOptions={defaultOption?.option || []}
        query={query}
        skip={skip}
      />
    ),
    [FORM_TYPES.Text]: (
      <Typography
        // From DataProps
        id={id}
        label={label}
        defaultValue={defaultValue || propDefaultValue}
        size={size}
        variant={variant || "p"}
        component={component || "p"}
        name={name}
        disabled={restProps.disabled}
        // Rest
        {...rest}
        {...restProps}
        sx={[
          {
            fontSize: fontSize,
          },
          ...(Array.isArray(restProps.sx) ? restProps.sx : [restProps.sx]),
        ]}>
        {text}
      </Typography>
    ),
    [FORM_TYPES.Radio]: (
      <BaseRadio
        // From DataProps without id
        label={label}
        fontSize={fontSize}
        defaultValue={defaultValue || propDefaultValue}
        size={size}
        variant={variant}
        component={component}
        name={name}
        disabled={restProps.disabled}
        {...restProps}
        // For React Hook Form
        control={control}
        validationProps={validationProps}
        errors={errors}
        // Rest
        {...rest}
        rows={restProps.rows}
        // For Radio
        id={type}
        options={options}
        value={typeof getValue === "function" ? getValue("type") : undefined}
      />
    ),
    [FORM_TYPES.DatePicker]: (
      <BaseDatePicker
        // From DataProps without id
        label={label}
        fontSize={fontSize}
        defaultValue={defaultValue || propDefaultValue}
        size={size}
        variant={variant}
        component={component}
        name={name}
        disabled={restProps.disabled}
        {...restProps}
        // For React Hook Form
        control={control}
        validationProps={validationProps}
        errors={errors}
        // Rest
        {...rest}
      />
    ),
    [FORM_TYPES.TimePicker]: (
      <BaseTimePicker
        // From DataProps without id
        label={label}
        fontSize={fontSize}
        defaultValue={defaultValue || propDefaultValue}
        size={size}
        variant={variant}
        component={component}
        name={name}
        disabled={restProps.disabled}
        {...restProps}
        // For React Hook Form
        control={control}
        validationProps={validationProps}
        errors={errors}
        // Rest
        {...rest}
      />
    ),
  };

  return (
    formTypeComponents[type] || (
      <>
        {title && (
          <Typography
            variant="p"
            component={"p"}
            sx={{
              fontWeight: 600,
            }}>
            {title}
          </Typography>
        )}
        <BaseInput
          // From DataProps
          id={id}
          label={label}
          fontSize={fontSize}
          defaultValue={defaultValue || propDefaultValue}
          size={size}
          variant={variant}
          component={component}
          name={name}
          disabled={restProps.disabled}
          {...restProps}
          // For React Hook Form
          control={control}
          validationProps={validationProps}
          errors={errors}
          hideArrows={true}
          // Rest
          {...rest}
        />
      </>
    )
  );
}

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Constants/FORMTYPES.md)
 */
export const FORM_TYPES = {
  AutocompleteAPI: "autocompleteAPI",
  SelectAPI: "selectAPI",
  Select: "select",
  Checkbox: "checkbox",
  Offset: "offset",
  MultiSelectAPI: "multiSelectAPI",
  MultiSelect: "multiSelect",
  Text: "text",
  DatePicker: "date",
  TimePicker: "time",
  Radio: "radio",
  MultiSelectSearch: "multiSelectSearch",
  MultiSelectSearchApi: "multiSelectSearchApi",
};
