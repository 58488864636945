import { REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "../../../../../shared/generatedForm/GeneratedForm";
import { oneToThreeDigitPositiveNumber } from "utils/ValidationRegex";

export const documentTypes = [
  { id: 1, label: "Program Training" },
  { id: 2, label: "Menu Guide Book" },
  { id: 3, label: "Forms & Information Sheets" },
];

export const MANAGE_DOC_FIELDS = [
  {
    props: {
      name: "name",
      id: "name",
      label: "Document Name",
      placeholder: "Document Name",
    },
    validationProps: {
      required: REQUIRED_ERROR("Document Name"),
    },
    size: 6,
  },
  {
    props: {
      name: "documentTypeId",
      id: "documentTypeId",
      label: "Document Type",
    },
    validationProps: {
      required: REQUIRED_ERROR("Document Type"),
    },
    size: 6,
    type: FORM_TYPES.Select,
    options: documentTypes,
  },
  {
    props: {
      name: "groupName",
      id: "groupName",
      label: "Document Group Name",
      placeholder: "Document Group Name",
    },
    size: 6,
  },
  {
    props: {
      name: "sortOrder",
      id: "sortOrder",
      label: "Sort Order",
      placeholder: "Sort Order",
      min: 0,
      max: 999,
      step: 1,
      type: "number",
    },
    validationProps: {
      required: REQUIRED_ERROR("Sort Order"),
      pattern: {
        value: oneToThreeDigitPositiveNumber,
        message: "Please enter a valid number",
      },
    },
    size: 6,
  },
];
