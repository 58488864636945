import { Box, Skeleton, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DMStyles } from "../../../../../../styles/Styles";
// import { KeysButton, typeAbbreviations } from "../../constants";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { DownloadLinkHelper } from "../../../../../shared/utils/helpers";
import {
  useGetVisitsByFacilityQuery,
  useGenerateVisitReportsMutation,
  useGenerateVisitReportMutation,
} from "../../../../../../store/apis/ConsultantApis";
import { useSearchParams } from "react-router-dom";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import dayjs from "dayjs";
import { size } from "lodash";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import { theme } from "styles/theme";
import { useGetVisitReportTypesQuery } from "store/apis/VisitReportAPIs";
import BaseReportChip from "components/shared/BaseReportChip";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { Print } from "@mui/icons-material";
import CreateBaseColumns from "components/shared/CreateBaseColumn";

export const CommunityProfile = () => {
  const [loadingId, setLoadingId] = useState(null);
  const [searchParams] = useSearchParams();
  const facilityId = searchParams.get("facilityId");
  const facilityName = searchParams.get("facilityName");

  const {
    data: currentFacility,
    isError: hasError,
    isLoading: loading,
  } = useGetVisitsByFacilityQuery(facilityId);

  const { data: reportTypeData, isLoading: reportTypeLoading } =
    useGetVisitReportTypesQuery();

  const [
    GenerateReports,
    {
      data: report,
      isError: reportError,
      isSuccess: isSuccess,
      isLoading: reportLoading,
    },
  ] = useGenerateVisitReportsMutation();

  const [
    GenerateReport,
    {
      data: visitreport,
      isError: visitreportError,
      isSuccess: isvisitSuccess,
      isLoading: visitreportLoading,
    },
  ] = useGenerateVisitReportMutation();

  const CommunityData = currentFacility ? currentFacility : [];
  const isError = hasError || reportError || visitreportError;
  const isLoading = loading;

  useEffect(() => {
    if (isSuccess) {
      DownloadLinkHelper(report?.data);
    }
    if (isvisitSuccess) {
      DownloadLinkHelper(visitreport?.data);
    }
  }, [report, visitreport]);

  const rostersColumns = [
    {
      field: "dateOfVisit",
      flex: 1,
      headerName: "Dates",
      valueGetter: (value) => {
        return value ? dayjs(value).toDate() : "";
      },
      valueFormatter: (value) => {
        const isDateValid = dayjs(value).isValid();
        return isDateValid
          ? dayjs(value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },
    {
      field: "dietitianName",
      flex: 1.5,
      headerName: "Consultant",
    },
    {
      field: "visitTypes",
      flex: 5,
      headerName: "Reports",
      renderCell: (params) => {
        return size(params?.row?.visitTypes) < 1 ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}>
            <Typography
              sx={{
                textAlign: "center",
              }}>
              No Reports Available
            </Typography>
          </Box>
        ) : (
          <FlexBox flexWrap={"wrap"} justifyContent={"start"} gap={0}>
            {reportTypeLoading ? (
              <Skeleton />
            ) : (
              <>
                {params?.row?.visitTypes.map((item) => {
                  return (
                    <BaseReportChip
                      key={item.id}
                      typeId={item.typeId}
                      onClick={() => {
                        setLoadingId(`report-${item?.id}`);
                        GenerateReport({ id: item?.id });
                      }}
                      loading={
                        visitreportLoading && loadingId === `report-${item?.id}`
                      }
                      reportTypes={reportTypeData}
                    />
                  );
                })}
              </>
            )}
          </FlexBox>
        );
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        const isDisabled = size(params?.row?.visitTypes) < 1;

        return (
          <>
            {!isDisabled ? (
              <Tooltip title={"Print All"}>
                <FlexBox>
                  <BaseSubmitButton
                    text={<Print />}
                    isSubmitting={
                      reportLoading && loadingId == `reports-${params.row.id}`
                        ? true
                        : false
                    }
                    icon="true"
                    onClick={() => {
                      setLoadingId(`reports-${params.row.id}`);
                      GenerateReports({ id: params.row.id });
                    }}
                  />
                </FlexBox>
              </Tooltip>
            ) : null}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          margin: "10px",
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            p: "16px",
            color: DMStyles.White,
            bgcolor: theme.palette.primary.main,
          }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
            }}>
            Community Profile
          </Typography>

          {!facilityName ? (
            <Skeleton variant="text" width={200} height={40} />
          ) : (
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
              }}>
              {facilityName}
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            marginY: "10px",
          }}>
          <Typography
            id={`copy-to-corporate-group-modal-title`}
            variant="h6"
            component="h2"
            className="modalTitle">
            Past Visits
          </Typography>
        </Box>

        <Box
          sx={{
            height: "60vh",
          }}>
          <BaseDataGrid
            rows={CommunityData || []}
            columns={CreateBaseColumns(rostersColumns)}
            loading={isLoading}
            error={isError}
            getRowId={(row) => row.id}
            height={"100%"}
            getRowHeight={() => "auto"}
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "8px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "15px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "22px",
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
};
